/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-children-connection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stammbaum-tree-children-connection.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/stammbaum-display.service";
var styles_StammbaumTreeChildrenConnectionComponent = [i0.styles];
var RenderType_StammbaumTreeChildrenConnectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeChildrenConnectionComponent, data: {} });
export { RenderType_StammbaumTreeChildrenConnectionComponent as RenderType_StammbaumTreeChildrenConnectionComponent };
function View_StammbaumTreeChildrenConnectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lv1_x1; var currVal_1 = _co.lv1_y1; var currVal_2 = _co.lv1_x2; var currVal_3 = _co.lv1_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeChildrenConnectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lv2_x1; var currVal_1 = _co.lv2_y1; var currVal_2 = _co.lv2_x2; var currVal_3 = _co.lv2_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeChildrenConnectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lh_x1; var currVal_1 = _co.lh_y1; var currVal_2 = _co.lh_x2; var currVal_3 = _co.lh_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeChildrenConnectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "#f0f0f0"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lv1_x1; var currVal_1 = _co.lv1_y1; var currVal_2 = _co.lv1_x2; var currVal_3 = _co.lv1_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeChildrenConnectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "#f0f0f0"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lv2_x1; var currVal_1 = _co.lv2_y1; var currVal_2 = _co.lv2_x2; var currVal_3 = _co.lv2_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeChildrenConnectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "#f0f0f0"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lh_x1; var currVal_1 = _co.lh_y1; var currVal_2 = _co.lh_x2; var currVal_3 = _co.lh_y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_StammbaumTreeChildrenConnectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeChildrenConnectionComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideConnection(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hideConnection(); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((((!_co.hideConnection() && (_co.lh_x1 > 0)) && (_co.lh_x2 > 0)) && (_co.lh_y1 > 0)) && (_co.lh_y2 > 0)); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.hideConnection(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.hideConnection(); _ck(_v, 9, 0, currVal_4); var currVal_5 = ((((_co.hideConnection() && (_co.lh_x1 > 0)) && (_co.lh_x2 > 0)) && (_co.lh_y1 > 0)) && (_co.lh_y2 > 0)); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_StammbaumTreeChildrenConnectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["stammbaum-tree-children-connection", ""]], null, null, null, View_StammbaumTreeChildrenConnectionComponent_0, RenderType_StammbaumTreeChildrenConnectionComponent)), i1.ɵdid(1, 180224, null, 0, i3.StammbaumTreeChildrenConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], null, null)], null, null); }
var StammbaumTreeChildrenConnectionComponentNgFactory = i1.ɵccf("[stammbaum-tree-children-connection]", i3.StammbaumTreeChildrenConnectionComponent, View_StammbaumTreeChildrenConnectionComponent_Host_0, { treeViewMode: "treeViewMode", version: "version", mapOnly: "mapOnly", p: "p", c: "c" }, {}, []);
export { StammbaumTreeChildrenConnectionComponentNgFactory as StammbaumTreeChildrenConnectionComponentNgFactory };

<div class="modal-header">
  <h5 *ngIf="mode === 'below12'" class="modal-title">Person zu jung für Nachkommen</h5>
  <h5 *ngIf="mode === 'nachverstorben'" class="modal-title">Person nach dem Erblasser verstorben</h5>
</div>

<div class="modal-body">
<b *ngIf="mode === 'below12'">Das Sterbe-Alter der bearbeiteten Person beträgt 12 oder kleiner. Soll die Option "Sicher keine Nachkommen" aktiviert werden?</b>
<b *ngIf="mode === 'nachverstorben'">Das Sterbe-Alter der bearbeiteten Person liegt nach dem Sterbedatum des Erblassers. Soll die Person als nachverstorbener Erbe gekennzeichnet werden?</b>
</div>

<div class="modal-footer">
  <button (click)="confirm()" type="button" id="confirm" class="m-2 btn btn-success pull-right">Ja</button>
  <button (click)="deny()" type="button" id="deny" class="m-2 btn btn-danger pull-right">Nein</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kassenbuch-edit-kostenposition-status-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./kassenbuch-edit-kostenposition-status-popup.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_KassenbuchEditKostenpositionStatusPopupComponent = [i0.styles];
var RenderType_KassenbuchEditKostenpositionStatusPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KassenbuchEditKostenpositionStatusPopupComponent, data: {} });
export { RenderType_KassenbuchEditKostenpositionStatusPopupComponent as RenderType_KassenbuchEditKostenpositionStatusPopupComponent };
function View_KassenbuchEditKostenpositionStatusPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCompleted() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Status auf erledigt setzen"]))], null, null); }
function View_KassenbuchEditKostenpositionStatusPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUnCompleted() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Status auf offen zur\u00FCcksetzen"]))], null, null); }
function View_KassenbuchEditKostenpositionStatusPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchEditKostenpositionStatusPopupComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchEditKostenpositionStatusPopupComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmUndoPayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Zahlung r\u00FCckg\u00E4ngig machen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.kostenposition.done; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.kostenposition.done; _ck(_v, 5, 0, currVal_1); }, null); }
function View_KassenbuchEditKostenpositionStatusPopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bitte best\u00E4tigen Sie, dass die Zahlungsinformationen tats\u00E4chlich entfernt werden sollen"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelUndoPayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur\u00FCck"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.undoPayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Zahlung r\u00FCckg\u00E4ngig machen"]))], null, null); }
export function View_KassenbuchEditKostenpositionStatusPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Status \u00E4ndern "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" Wollen Sie den Status von Beleg ", " wirklich \u00E4ndern? "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchEditKostenpositionStatusPopupComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchEditKostenpositionStatusPopupComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-warning pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.confirmToUndoPayment; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.confirmToUndoPayment; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kostenposition.belegtitel; _ck(_v, 9, 0, currVal_0); }); }
export function View_KassenbuchEditKostenpositionStatusPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-edit-kostenposition-status-popup", [], null, null, null, View_KassenbuchEditKostenpositionStatusPopupComponent_0, RenderType_KassenbuchEditKostenpositionStatusPopupComponent)), i1.ɵdid(1, 114688, null, 0, i3.KassenbuchEditKostenpositionStatusPopupComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KassenbuchEditKostenpositionStatusPopupComponentNgFactory = i1.ɵccf("app-kassenbuch-edit-kostenposition-status-popup", i3.KassenbuchEditKostenpositionStatusPopupComponent, View_KassenbuchEditKostenpositionStatusPopupComponent_Host_0, {}, {}, []);
export { KassenbuchEditKostenpositionStatusPopupComponentNgFactory as KassenbuchEditKostenpositionStatusPopupComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reject-version.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./reject-version.component";
import * as i4 from "../../services/constants.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_RejectVersionComponent = [i0.styles];
var RenderType_RejectVersionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RejectVersionComponent, data: {} });
export { RenderType_RejectVersionComponent as RenderType_RejectVersionComponent };
function View_RejectVersionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "p-3 m-3 main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Veraltete Software-Version"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" Sie verwenden die veraltete Software-Version ", ", die nicht mehr zugelassen ist. Bitte aktualisieren Sie Ihre Version durch Leeren des Browser-Caches und anschlie\u00DFendem Reload. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" In den meisten Browsern kann dies durch Dr\u00FCcken von CTRL + F5 bzw. durch Halten von CTRL oder Shift und gleichzeitiges Anklicken des Reload-Buttons erreicht werden. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 4, 0, currVal_0); }); }
function View_RejectVersionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "p-3 m-3 main-rec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Neue Software-Version verf\u00FCgbar"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" Es ist eine aktuellere Software-Version als die von Ihnen verwendete Version ", " verf\u00FCgbar. Bitte aktualisieren Sie bei Gelegenheit Ihre Version durch Leeren des Browser-Caches und anschlie\u00DFendem Reload. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" In den meisten Browsern kann dies durch Dr\u00FCcken von CTRL + F5 bzw. durch Halten von CTRL oder Shift und gleichzeitiges Anklicken des Reload-Buttons erreicht werden. "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success m-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 4, 0, currVal_0); }); }
export function View_RejectVersionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RejectVersionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RejectVersionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.recommend_only; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.recommend_only; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RejectVersionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reject-version", [], null, null, null, View_RejectVersionComponent_0, RenderType_RejectVersionComponent)), i1.ɵdid(1, 49152, null, 0, i3.RejectVersionComponent, [i4.ConstantsService, i5.NgbActiveModal], null, null)], null, null); }
var RejectVersionComponentNgFactory = i1.ɵccf("reject-version", i3.RejectVersionComponent, View_RejectVersionComponent_Host_0, {}, {}, []);
export { RejectVersionComponentNgFactory as RejectVersionComponentNgFactory };

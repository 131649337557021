/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./case-history.component";
import * as i4 from "../../services/case.service";
import * as i5 from "@ngrx/store";
var styles_CaseHistoryComponent = [i0.styles];
var RenderType_CaseHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseHistoryComponent, data: {} });
export { RenderType_CaseHistoryComponent as RenderType_CaseHistoryComponent };
function View_CaseHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "case-badge badge badge-pill badge-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToCaseId(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_CaseHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseHistoryComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.cases == null) ? null : _co.cases.activeCaseHistory); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CaseHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseHistoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.cases == null) ? null : _co.cases.activeCaseHistory); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaseHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-history", [], null, null, null, View_CaseHistoryComponent_0, RenderType_CaseHistoryComponent)), i1.ɵdid(1, 180224, null, 0, i3.CaseHistoryComponent, [i4.CaseService, i5.Store], null, null)], null, null); }
var CaseHistoryComponentNgFactory = i1.ɵccf("case-history", i3.CaseHistoryComponent, View_CaseHistoryComponent_Host_0, {}, {}, []);
export { CaseHistoryComponentNgFactory as CaseHistoryComponentNgFactory };

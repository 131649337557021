/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./show-boolean-value.component";
var styles_ShowBooleanValueComponent = [];
var RenderType_ShowBooleanValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShowBooleanValueComponent, data: {} });
export { RenderType_ShowBooleanValueComponent as RenderType_ShowBooleanValueComponent };
function View_ShowBooleanValueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-remove"], ["style", "color: red;"]], null, null, null, null, null))], null, null); }
function View_ShowBooleanValueComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check"], ["style", "color: green;"]], null, null, null, null, null))], null, null); }
export function View_ShowBooleanValueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShowBooleanValueComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShowBooleanValueComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.value || (_co.value == "false")); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.value && (_co.value != "false")) && (_co.value != "")); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ShowBooleanValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "show-boolean-value", [], null, null, null, View_ShowBooleanValueComponent_0, RenderType_ShowBooleanValueComponent)), i0.ɵdid(1, 49152, null, 0, i2.ShowBooleanValueComponent, [], null, null)], null, null); }
var ShowBooleanValueComponentNgFactory = i0.ɵccf("show-boolean-value", i2.ShowBooleanValueComponent, View_ShowBooleanValueComponent_Host_0, { value: "value" }, {}, []);
export { ShowBooleanValueComponentNgFactory as ShowBooleanValueComponentNgFactory };

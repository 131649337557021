<div class="m-2 p-2">
  <h3>Upload-Manager</h3>

  <div *ngIf="!display_only" class="card m-2 p-2">

    <h4>neue Dateien hochladen</h4>
    <p *ngIf="!person_id || person_id === 0" class="bold">Fall: {{case_name}} (Fall-ID {{case_id}})</p>
    <p *ngIf="person_id && person_id > 0" class="bold">Person: {{person_name}} (Personen-ID {{person_id}}) - Fall {{case_id}}</p>

    <form [formGroup]="form" class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label class="control-label formtext">Dateibezeichnung</label>
        </div>
        <div class="col-md-4">
          <label class="control-label formtext">Ordner</label>
        </div>
        <div class="col-md-4">
          <label class="control-label formtext">Kommentar</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <input type="text" formControlName="title" id="title" class="form-control">
        </div>
        <div class="col-md-4">
          <select class="form-control" id="folder_id" formControlName="folder_id">
            <option *ngFor="let opt of folderList" [value]="opt.id">{{opt.description}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <input type="text" formControlName="description" id="description" class="form-control">
        </div>
      </div>
    </form>

    <div *ngIf="!this.form.valid" class="red">
      Bitte eine Dateibezeichnung für das/die Dokumente angeben, um Dateien hinzufügen zu können (max. 50 Zeichen).
    </div>

    <ngx-file-drop *ngIf="this.form.valid" [showBrowseBtn]="true" browseBtnLabel="Datei auswählen"
               (onFileDrop)="dropped($event)"
               (onFileOver)="fileOverDrop($event)" (onFileLeave)="fileLeaveDrop($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <span>Datei hierhin schieben</span>
        <span class="ml-2 fa fa-bullseye fa-3x"></span>
        <span class="mx-3">oder</span>
        <button type="button" class="btn btn-primary" (click)="openFileSelector()">Datei auswählen</button>
      </ng-template>
    </ngx-file-drop>

  </div>

  <div class="card m-2 p-2">
    <h4>Warteschlange</h4>
    <p *ngIf="!(fileUploadService.uploader.queue.length > 0)">Die Warteschlange ist leer.</p>

    <div *ngIf="fileUploadService.uploader.queue.length > 0" class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Dateiname</th>
              <th>Bezeichnung</th>
              <th>Kommentar</th>
              <th>Ordner</th>
              <th>Größe</th>
              <th>Fall</th>
              <th>Person</th>
              <th>Fortschritt</th>
              <th>Status</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of fileUploadService.uploader.queue">
              <td><strong>{{ item?.file?.name }}</strong></td>
              <td>{{getFileInfoForItem(item)?.information?.title}}</td>
              <td>{{getFileInfoForItem(item)?.information?.description}}</td>
              <td>{{get_folder_name(item)}}</td>
              <td *ngIf="fileUploadService.uploader.options?.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
              <td>{{getFileInfoForItem(item)?.information?.case_id}}</td>
              <td *ngIf="getFileInfoForItem(item)?.information?.person_id > 0">{{getFileInfoForItem(item)?.information?.person_id}}</td>
              <td *ngIf="!(getFileInfoForItem(item)?.information?.person_id > 0)">------</td>
              <td *ngIf="fileUploadService.uploader.options?.isHTML5">
                  <div class="progress" style="margin-bottom: 0;">
                      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                  </div>
              </td>
              <td class="text-center">
                  <span *ngIf="item.isSuccess"><span title="Ok" class="green fa fa-check-circle"></span></span>
                  <span *ngIf="item.isCancel"><span title="Abbruch" class="red fa fa-times"></span></span>
                  <span *ngIf="item.isError"><span title="Fehler" class="red fa fa-exclamation-triangle"></span></span>
              </td>
              <td>
                  <button type="button" class="btn btn-success btn-sm m-1"
                          (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                      <span class="fa fa-upload"></span> Hochladen
                  </button>
                  <button type="button" class="btn btn-warning btn-sm m-1"
                          (click)="item.cancel()" [disabled]="!item.isUploading">
                      <span class="fa fa-ban"></span> Abbrechen
                  </button>
                  <button type="button" class="btn btn-danger btn-sm m-1"
                          (click)="item.remove()">
                      <span class="fa fa-trash"></span> Entfernen
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div *ngIf="fileUploadService.uploader.queue.length > 0">
      Fortschritt:
      <div class="progress" style="">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': fileUploadService.uploader.progress + '%' }"></div>
      </div>

      <button type="button" class="m-2 btn btn-success btn-sm" (click)="fileUploadService.uploader.uploadAll()" [disabled]="!fileUploadService.uploader.getNotUploadedItems().length">
        <span class="glyphicon glyphicon-upload"></span> alle hochladen
      </button>
      <button type="button" class="m-2 btn btn-warning btn-sm" (click)="fileUploadService.uploader.cancelAll()" [disabled]="!fileUploadService.uploader.isUploading">
        <span class="glyphicon glyphicon-ban-circle"></span> alle abbrechen
      </button>
      <button type="button" class="m-2 btn btn-danger btn-sm" (click)="fileUploadService.uploader.clearQueue()" [disabled]="!fileUploadService.uploader.queue.length">
        <span class="glyphicon glyphicon-trash"></span> alle entfernen
      </button>
    </div>
  </div>
  <button class="btn btn-sm btn-success m-2" [disabled]="!canClose()" (click)="close()">schliessen</button>
  <p *ngIf="!canClose()" class="m-2">Bitte zunächst den Download starten oder die nicht hochgeladenen Elemente enfernen, um das Fenster zu schließen.</p>
</div>


<div class="card-columns pt-2">
  <div class="card cardperson" *ngIf="teamExists() && editMode && editTeamMemberExists()">
    <div class="card-title">
      <h4 style="text-align: center">
        {{userService.getUserName(editTeamMember.user_id)}}
        <span
          class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(editTeamMember.user_id)}}</span>
        bearbeiten
      </h4>
    </div>
    <table class="table">
      <tr>
        <td>als Fallführer</td>
        <td><input [(ngModel)]="editTeamMember.leader" type="checkbox"></td>
      </tr>
      <tr>
        <td>als Co-Ermittler</td>
        <td><input [(ngModel)]="editTeamMember.co_leader" type="checkbox"></td>
      </tr>
      <tr>
        <td>Stammbaum-Bearbeiter</td>
        <td><input [(ngModel)]="editTeamMember.tree_edit" type="checkbox"></td>
      </tr>
      <tr>
        <td>EBS-Doku</td>
        <td><input [(ngModel)]="editTeamMember.ebs_doku" type="checkbox"></td>
      </tr>
      <tr>
        <td>Automail aktiv</td>
        <td><input [(ngModel)]="editTeamMember.automail" type="checkbox"></td>
      </tr>
      <tr>
        <td>Prozente</td>
        <td><textarea [(ngModel)]="editTeamMember.percentage" placeholder=""></textarea></td>
      </tr>
      <tr>
        <td>Notiz</td>
        <td><textarea [(ngModel)]="editTeamMember.text" placeholder=""></textarea></td>
      </tr>
      <tr>
        <td></td>
        <td>
          <button class="mx-1 btn btn-sm btn-primary" (click)="cancelEdit()">abbrechen</button>
          <button class="mx-1 btn btn-sm btn-danger" (click)="saveTeamMember()">speichern</button>
        </td>
      </tr>
    </table>
  </div>

  <ng-container *ngFor="let member of copiedTeamMembers">
    <div *ngIf="!editTeamMember || editTeamMember.id !== member.id" class="card cardperson">
      <div class="card-body">
        <h4 style="text-align: center">
          {{userService.getUserName(member.user_id)}}
          <span
            class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span></h4>
        <div class="card-title">
          <span class="badge badge-pill mr-2" *ngIf="member.leader" style="background-color: green;">Fallführer</span>
          <span class="badge badge-pill mr-2" *ngIf="member.as_senior"
                style="background-color: darkorange;">Senior</span>
          <span class="badge badge-pill mr-2" *ngIf="member.co_leader"
                style="background-color: lightgreen;">Co-Ermittler</span>
          <span class="badge badge-pill mr-2" *ngIf="member.tree_edit"
                style="background-color: lightcoral;">Stammbaum-Bearbeiter</span>
          <span class="badge badge-pill mr-2" *ngIf="member.ebs_doku"
                style="background-color: lightblue;">EBS-Doku</span>
          <span class="badge badge-pill mr-2" *ngIf="member.automail" style="background-color: blue;">Automail</span>
        </div>
        <div class="card-text">
          <p class="mx-2">{{member.text}}</p>
          <p *ngIf="!member.as_senior" class="mx-2">Prozente: {{member.percentage}}</p>
          <p *ngIf="member.as_senior" class="mx-2">Prozente: {{member.percentage}}</p>
          <p class="mx-2">
            <button [disabled]="editMode" class="btn btn-sm btn-warning" (click)="edit(member.id)">bearbeiten</button>&nbsp;
            <button [disabled]="editMode" *ngIf="rightsManagementService.isAllowedToDeleteTeamMembers()"
                    class="btn btn-sm btn-danger"
                    (click)="delete(member.id)">entfernen
            </button>
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="card cardperson">
    <div class="card-title">
      <h4 style="text-align: center">Teammitglied hinzufügen</h4>
    </div>
    <table class="table table-sm">
      <tr>
        <td>Mitarbeiter</td>
        <td>
          <select [(ngModel)]="newTeamMember.user_id">
            <option *ngFor="let item of activeUsers" [value]="item.id">{{item.abbreviation}}
              ({{item.first_name}} {{item.last_name}})
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>als Fallführer</td>
        <td><input [(ngModel)]="newTeamMember.leader" type="checkbox"></td>
      </tr>
      <tr>
        <td>als Co-Ermittler</td>
        <td><input [(ngModel)]="newTeamMember.co_leader" type="checkbox"></td>
      </tr>
       <tr>
        <td>Stammbaum-Bearbeiter</td>
        <td><input [(ngModel)]="newTeamMember.tree_edit" type="checkbox"></td>
      </tr>
       <tr>
        <td>EBS-Doku</td>
        <td><input [(ngModel)]="newTeamMember.ebs_doku" type="checkbox"></td>
      </tr>
      <tr>
        <td>Automail aktiv</td>
        <td><input [(ngModel)]="newTeamMember.automail" type="checkbox"></td>
      </tr>
      <tr>
        <td>Prozente</td>
        <td><textarea [(ngModel)]="newTeamMember.percentage" placeholder=""></textarea></td>
      </tr>
      <tr>
        <td>Notiz</td>
        <td><textarea [(ngModel)]="newTeamMember.text" placeholder=""></textarea></td>
      </tr>
      <tr>
        <td></td>
        <td>
          <button [disabled]="editMode" class="btn btn-sm btn-primary" (click)="createTeamMember()">anlegen</button>
        </td>
      </tr>
    </table>
  </div>

</div>

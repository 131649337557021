import { Component, OnInit } from '@angular/core';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import { Store } from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {CaseService} from "../../services/case.service";


@Component({
  selector: 'app-edit-erben-steuer',
  templateUrl: './edit-erben-steuer.component.html',
  styleUrls: ['./edit-erben-steuer.component.css']
})
export class EditErbenSteuerComponent implements OnInit {
  erbe: IStammbaumPerson = null;

  constructor(private ngbActiveModal: NgbActiveModal,
              private store: Store<{stammbaum: IStammbaumState}>,
              private caseService: CaseService) {}

  ngOnInit() {}

  close() {
    this.ngbActiveModal.close();
  }

  save() {
    this.store.dispatch(new StammbaumActions.TriggerEditPersonInActiveStammbaum(<IStammbaumPerson>{
      id: this.erbe.id,
      case: this.caseService.getActiveCaseId(),
      steuererklaerung_needed: this.erbe.steuererklaerung_needed,
      steuererklaerung_done: this.erbe.steuererklaerung_done,
      unbedenklichkeit_needed: this.erbe.unbedenklichkeit_needed,
      unbedenklichkeit_done: this.erbe.unbedenklichkeit_done
    }));
    this.ngbActiveModal.close();
  }
}

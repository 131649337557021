<div class="mod">
  <h4>Seiteneinstellungen</h4>
  <form [formGroup]="editForm">
    <div class="row">
      <div class="col-md-3">
        <span>Nachname</span>
        <select class="form-control" formControlName="choose_nachname">
          <option *ngFor="let option of nachname_col_options" [value]="option.value">{{option.text}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <span>Geburtsname</span>
        <select class="form-control" formControlName="choose_geburtsname">
          <option *ngFor="let option of geburtsname_col_options" [value]="option.value">{{option.text}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <span>Vorname</span>
        <select class="form-control" formControlName="choose_vorname">
          <option *ngFor="let option of vorname_col_options" [value]="option.value">{{option.text}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <span>Urkund.nr.</span>
        <select class="form-control" formControlName="choose_urkunden_nr">
          <option *ngFor="let option of urkunden_nr_col_options" [value]="option.value">{{option.text}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <span>jüdisch</span>
        <select class="form-control" formControlName="choose_juedisch">
          <option *ngFor="let option of juedisch_col_options" [value]="option.value">{{option.text}}</option>
        </select>
      </div>
    </div>
    <button (click)="close()" class="mt-3 btn btn-sm btn-success">schliessen</button>
  </form>
</div>

<div class="modal-header">
  <h4 class="modal-title ilacolor">
    {{note.title}}
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </h4>
</div>
<div class="modal-body whitespace-pre-wrap" *ngIf="note">
  <b>Fall: {{note.case_name}} ({{note.case_id}})</b> <br />
  <span *ngIf="+note.person_id > 0"><b>Person: {{note.person_name}} ({{note.person_id}})</b><br /></span>
  <button type="button" class="m-2 btn btn-sm btn-success" (click)="goToCase()">direkt zum Fall</button>
  <button *ngIf="note.person_id > 0" type="button" class="m-2 btn btn-sm btn-warning" (click)="goToPerson()">direkt zur Person</button>
  <button type="button" class="m-2 btn btn-sm btn-primary" (click)="goToNote()">direkt zur Notiz</button>
  <div>
    <span>
      <ng-container *ngIf="note.user_id"><span class="mx-2 badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.user_id)}}</span></ng-container>
      <ng-container *ngIf="note.created"><span class="answer-date">{{note.created | date: 'dd.MM.yyyy HH:mm'}}</span></ng-container>
    </span>
    <quill-editor [(ngModel)]="note.text" readOnly="true" placeholder="" [modules]="{ toolbar: false}"></quill-editor>
  </div>
  <div *ngFor="let resp of note.responses_for_note" class="note-answer">
    <span>
      <ng-container *ngIf="resp.user"><span class="mx-2 badge badge-pill badge-default">{{userService.getAbbreviationForUser(resp.user)}}</span></ng-container>
      <ng-container *ngIf="resp.result_date"><span class="answer-date">{{resp.result_date | date: 'dd.MM.yyyy HH:mm'}}</span></ng-container>
    </span>
    <quill-editor [(ngModel)]="resp.result" readOnly="true" placeholder="" [modules]="{ toolbar: false}"></quill-editor>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">schliessen</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./person-list-search-results.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./person-list-search-results.component";
import * as i4 from "../../services/case.service";
import * as i5 from "../../services/stammbaum.service";
import * as i6 from "../../services/rights-management.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_PersonListSearchResultsComponent = [i0.styles];
var RenderType_PersonListSearchResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonListSearchResultsComponent, data: {} });
export { RenderType_PersonListSearchResultsComponent as RenderType_PersonListSearchResultsComponent };
function View_PersonListSearchResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0 Personen gefunden"]))], null, null); }
function View_PersonListSearchResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " Personen gefunden (max. 200 Ergebnisse)"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchResults.length; _ck(_v, 1, 0, currVal_0); }); }
function View_PersonListSearchResultsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTableClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "td", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "fa fa-circle"]], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.id; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.vorname; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.nachname; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.geburtsname; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.case; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.getTypeTooltip(_v.context.$implicit); _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.getTypeColor(_v.context.$implicit); _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.getTypeText(_v.context.$implicit.person_type); _ck(_v, 15, 0, currVal_7); }); }
function View_PersonListSearchResultsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "table", [["class", "table table-hover table-striped table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personen-ID"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vorname"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachname"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsname"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fall-ID"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personen-Typ"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListSearchResultsComponent_4)), i1.ɵdid(17, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchResults; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_PersonListSearchResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListSearchResultsComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListSearchResultsComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListSearchResultsComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.searchResults || (_co.searchResults.length === 0)); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.searchResults && (_co.searchResults.length > 0)); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.searchResults && (_co.searchResults.length > 0)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_PersonListSearchResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "person-list-search-results", [], null, null, null, View_PersonListSearchResultsComponent_0, RenderType_PersonListSearchResultsComponent)), i1.ɵdid(1, 245760, null, 0, i3.PersonListSearchResultsComponent, [i4.CaseService, i5.StammbaumService, i6.RightsManagementService, i7.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonListSearchResultsComponentNgFactory = i1.ɵccf("person-list-search-results", i3.PersonListSearchResultsComponent, View_PersonListSearchResultsComponent_Host_0, { searchResults: "searchResults" }, {}, []);
export { PersonListSearchResultsComponentNgFactory as PersonListSearchResultsComponentNgFactory };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as StammbaumActions from './stammbaum.actions';
import { StammbaumService } from '../services/stammbaum.service';
import { StammbaumDisplayService } from '../services/stammbaum-display.service';
import { CaseService } from '../services/case.service';
import { captureMessage } from '@sentry/browser';
export class StammbaumEffects {
    constructor(actions, stammbaumService, caseService, stammbaumDisplayService, store) {
        this.actions = actions;
        this.stammbaumService = stammbaumService;
        this.caseService = caseService;
        this.stammbaumDisplayService = stammbaumDisplayService;
        this.store = store;
        this.updateStammbaumPerson = this.actions.pipe(ofType(StammbaumActions.TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM), map((action) => {
            console.log(action.payload);
            this.stammbaumService.updateStammbaumPerson(action.payload).subscribe((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); // todo implement error handling
                captureMessage(`error updating person in active stammbaum: ${action.payload.id}`);
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.updateStammbaumPersonWithoutVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_EDIT_PERSON_WITHOUT_VERSION), map((action) => {
            console.log(action.payload);
            this.stammbaumService.updateStammbaumPersonWithoutVersion(action.payload).subscribe((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); // todo implement error handling
                captureMessage(`error updating person without stammbaum: ${action.payload.id}`);
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(action.payload.case));
            });
        }));
        this.updateStammbaumMarriage = this.actions.pipe(ofType(StammbaumActions.TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM), map((action) => {
            this.stammbaumService.updateStammbaumMarriage(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.updateStammbaumMarriageWithoutVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_EDIT_MARRIAGE_WITHOUT_VERSION), map((action) => {
            this.stammbaumService.updateStammbaumMarriageWithoutVersion(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
            });
        }));
        this.addStammbaumPerson = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM), map((action) => {
            this.stammbaumService.addStammbaumPerson(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.addStammbaumPersonWithoutVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_PERSON_WITHOUT_VERSION), map((action) => {
            this.stammbaumService.addStammbaumPersonWithoutVersion(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
            });
        }));
        this.addStammbaumMarriage = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM), map((action) => {
            this.stammbaumService.addStammbaumMarriage(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.addStammbaumMarriageWithoutVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_MARRIAGE_WITHOUT_VERSION), map((action) => {
            this.stammbaumService.addStammbaumMarriageWithoutVersion(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
            });
        }));
        this.setStammbaumPersonActive = this.actions.pipe(ofType(StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE), map((action) => {
            this.stammbaumService.setStammbaumPersonActive(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.setStammbaumMode = this.actions.pipe(ofType(StammbaumActions.SET_STAMMBAUM_MODE), map((action) => {
            if (action.payload === 'tree-view') {
                console.log('reset person height required');
                this.stammbaumDisplayService.resetPersonHeightRequired();
            }
        }));
        this.setStammbaumPersonInactive = this.actions.pipe(ofType(StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE), map((action) => {
            this.stammbaumService.setStammbaumPersonInactive(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.triggerRecalculateDisplay = this.actions.pipe(ofType(StammbaumActions.TRIGGER_RECALCULATE_DISPLAY), map((action) => {
            console.log('trigger recalculate effect here');
            this.stammbaumDisplayService.calculateDisplay(this.stammbaumState.stammbaum);
        }));
        this.loadStammbaumCheckpoint = this.actions.pipe(ofType(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_CHECKPOINT), map((action) => {
            this.stammbaumService.loadStammbaumCheckpoint(action.payload).subscribe((result) => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumState.stammbaum.version));
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.saveStammbaumCheckpoint = this.actions.pipe(ofType(StammbaumActions.TRIGGER_SAVE_STAMMBAUM_CHECKPOINT), map((action) => {
            this.stammbaumService.saveStammbaumCheckpoint(action.payload).subscribe((result) => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumState.stammbaum.version));
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.loadStammbaumVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_VERSION), map((action) => {
            let stammbaum = null;
            this.stammbaumService.loadStammbaumVersion(action.payload.id).subscribe((result) => {
                if (!result) {
                    this.store.dispatch(new StammbaumActions.ReplaceStammbaum({}));
                }
                else {
                    stammbaum = result;
                    this.stammbaumDisplayService.calculateDisplay(stammbaum);
                }
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.loadStammbaumPersonsAndMarriagesWithoutVersion = this.actions.pipe(ofType(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE), map((action) => {
            this.stammbaumService.loadStammbaumPersonsAndMarriagesWithoutVersion(action.payload).subscribe((result) => {
                if (!result) {
                    this.store.dispatch(new StammbaumActions.ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase({ persons: {}, marriages: [] }));
                }
                else {
                    this.store.dispatch(new StammbaumActions.ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase(result));
                }
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.setStammbaumVersionDefaultAndLoad = this.actions.pipe(ofType(StammbaumActions.TRIGGER_SET_STAMMBAUM_VERSION_AS_DEFAULT_AND_LOAD), map((action) => {
            this.stammbaumService.setStammbaumVersionDefault(action.payload.id).subscribe((result) => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(action.payload));
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.loadStammbaumVersionsForCase = this.actions.pipe(ofType(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_VERSIONS_FOR_CASE), map((action) => {
            this.stammbaumService.loadStammbaumVersionsForCase().subscribe((result) => {
                if (!result) {
                    this.store.dispatch(new StammbaumActions.ReplaceStammbaumVersions([]));
                }
                else {
                    this.store.dispatch(new StammbaumActions.ReplaceStammbaumVersions(result));
                    if (action.payload) { // if true, also directly proceed to load the default version (otherwise just reload the list)
                        if (result.find(x => x.is_default)) {
                            this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(result.find(x => x.is_default)));
                        }
                        else {
                            if (result && (result.length > 0)) {
                                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(result[0]));
                            }
                        }
                    }
                }
            }, (error) => {
                console.log(error); // todo implement error handling
            }, () => { });
        }));
        this.deleteStammbaumPerson = this.actions.pipe(ofType(StammbaumActions.TRIGGER_DELETE_STAMMBAUM_PERSON), map((action) => {
            this.stammbaumService.deleteStammbaumPerson(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.deleteStammbaumMarriage = this.actions.pipe(ofType(StammbaumActions.TRIGGER_DELETE_STAMMBAUM_MARRIAGE), map((action) => {
            this.stammbaumService.deleteStammbaumMarriage(action.payload).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                if (this.stammbaumService.activeVersion && this.stammbaumService.activeVersion.id > 0) {
                    this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
                }
                else {
                    this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
                }
            });
        }));
        this.addMarriageAndPerson = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM), map((action) => {
            this.stammbaumService.addMarriageAndPerson(action.payload.person, action.payload.marriage).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.addChild = this.actions.pipe(ofType(StammbaumActions.TRIGGER_ADD_CHILD), map((action) => {
            this.stammbaumService.addChild(action.payload.first_parent_id, action.payload.second_parent_id, action.payload.child_data).subscribe((result) => { }, (error) => {
                console.log(error); // todo implement error handling
            }, () => {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
            });
        }));
        this.stammbaumStateSub = this.store.select('stammbaum').subscribe((stammbaumState) => {
            this.stammbaumState = stammbaumState;
        });
    }
    ngOnDestroy() {
        this.stammbaumStateSub.unsubscribe();
    }
}
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "updateStammbaumPerson", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "updateStammbaumPersonWithoutVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "updateStammbaumMarriage", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "updateStammbaumMarriageWithoutVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addStammbaumPerson", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addStammbaumPersonWithoutVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addStammbaumMarriage", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addStammbaumMarriageWithoutVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "setStammbaumPersonActive", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "setStammbaumMode", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "setStammbaumPersonInactive", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "triggerRecalculateDisplay", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "loadStammbaumCheckpoint", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "saveStammbaumCheckpoint", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "loadStammbaumVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "loadStammbaumPersonsAndMarriagesWithoutVersion", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "setStammbaumVersionDefaultAndLoad", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "loadStammbaumVersionsForCase", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "deleteStammbaumPerson", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "deleteStammbaumMarriage", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addMarriageAndPerson", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], StammbaumEffects.prototype, "addChild", void 0);

<div *ngIf="copiedActiveCase">
  <h4 *ngIf="displayUrkundensuche()">Urkundensuche
    <ng-container *ngIf="editMode !== CaseSaveMode.Urkundensuche">
      <button (click)="setEditMode(CaseSaveMode.Urkundensuche)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      <button (click)="toggleOpen(CaseDisplayOpenable.Urkundensuche)" class="mx-1 btn btn-sm btn-info">schliessen</button>
    </ng-container>
    <ng-container *ngIf="editMode === CaseSaveMode.Urkundensuche">
      <button (click)="reloadData()" class="mx-1 btn btn-sm btn-danger">abbrechen</button>
      <button (click)="saveData()" class="mx-1 btn btn-sm btn-success">speichern</button>
    </ng-container>
  </h4>
  <ng-container *ngIf="displayUrkundensuche()">
    <div *ngIf="editMode !== CaseSaveMode.Urkundensuche" style="white-space:pre-wrap;">{{copiedActiveCase?.urkundensuche_text}}</div>
    <p *ngIf="editMode === CaseSaveMode.Urkundensuche"><textarea class="form-control" rows="20" [(ngModel)]="copiedActiveCase.urkundensuche_text"></textarea></p>
  </ng-container>
  <ng-container *ngIf="!displayUrkundensuche()">
    <h4>Urkundensuche</h4>
    <span *ngIf="urkundensuchePreview">{{urkundensuchePreview}}</span>
    <button (click)="toggleOpen(CaseDisplayOpenable.Urkundensuche)" class="mx-1 btn btn-sm btn-info">öffnen</button>
  </ng-container>

  <h4 *ngIf="displayFallzusammenfassung()">Fall-Zusammenfassung
    <ng-container *ngIf="editMode !== CaseSaveMode.Fallzusammenfassung">
      <button (click)="setEditMode(CaseSaveMode.Fallzusammenfassung)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      <button (click)="toggleOpen(CaseDisplayOpenable.Fallzusammenfassung)" class="mx-1 btn btn-sm btn-info">schliessen</button>
    </ng-container>
    <ng-container *ngIf="editMode === CaseSaveMode.Fallzusammenfassung">
      <button (click)="reloadData()" class="mx-1 btn btn-sm btn-danger">abbrechen</button>
      <button (click)="saveData()" class="mx-1 btn btn-sm btn-success">speichern</button>
    </ng-container>
  </h4>
  <ng-container *ngIf="displayFallzusammenfassung()">
    <div *ngIf="editMode !== CaseSaveMode.Fallzusammenfassung" style="white-space:pre-wrap;">{{copiedActiveCase?.summary_text}}</div>
    <p *ngIf="editMode === CaseSaveMode.Fallzusammenfassung"><textarea class="form-control" rows="20" [(ngModel)]="copiedActiveCase.summary_text"></textarea></p>
  </ng-container>
  <ng-container *ngIf="!displayFallzusammenfassung()">
    <h4>Fall-Zusammenfassung</h4><button (click)="toggleOpen(CaseDisplayOpenable.Fallzusammenfassung)" class="mx-1 btn btn-sm btn-info">öffnen</button>
  </ng-container>
</div>
<div *ngIf="copiedActiveCase">
  <note-list-max style="margin-top: 10px;" [mode]="'case'" [folderId]="0"></note-list-max>
</div>

<h3 *ngIf="mode==='Variante 1'" class="ilatitle">aktuelle Auskehrungsliste {{startDate | date: 'dd.MM.yy'}} - {{endDate | date: 'dd.MM.yy'}} <button class="btn btn-sm btn-success mx-3" (click)="back()">zurück</button></h3>
<h3 *ngIf="mode==='Variante 2'" class="ilatitle">Liste für %-Regel {{startDate | date: 'dd.MM.yy'}} - {{endDate | date: 'dd.MM.yy'}} <button class="btn btn-sm btn-success mx-3" (click)="back()">zurück</button></h3>
<ng-container *ngIf="!loading">
  <table *ngIf="caselist && caselist.length > 0" class="table table-sm">
    <tr>
      <th>Fallnummer</th>
      <th>Nachname</th>
      <th *ngIf="mode === 'Variante 2'">FallF</th>
      <th *ngIf="mode === 'Variante 2'">CoF</th>
      <th *ngIf="mode === 'Variante 2'">Team</th>
      <th>Auskehr-Zahlungen</th>
      <th>Summe</th>
      <th *ngIf="mode === 'Variante 2'">EQ av.</th>
      <th *ngIf="mode === 'Variante 2'">Honorar</th>
      <th *ngIf="mode === 'Variante 2'">1%</th>
      <th>Status</th>
      <th>auf Ausk. abg.</th>
      <th style="background-color: #c9b381;">EBS erteilt</th>
    </tr>
    <ng-container *ngFor="let c of caselist">
      <tr>
        <td>{{ c.id }}</td>
        <td>{{ c.last_name }}</td>
        <td *ngIf="mode === 'Variante 2'">
          <ng-container *ngFor="let member of c.team_member_details">
            <span *ngIf="!member.deleted && member.leader" class="badge badge-pill badge-success">{{userService.getAbbreviationForUser(member.user_id)}}</span>
          </ng-container>
        </td>
        <td *ngIf="mode === 'Variante 2'">
          <ng-container *ngFor="let member of c.team_member_details">
            <span *ngIf="!member.deleted && member.co_leader" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
          </ng-container>
        </td>
        <td *ngIf="mode === 'Variante 2'">
          <ng-container *ngFor="let member of c.team_member_details">
            <span *ngIf="!member.deleted && !member.leader && !member.co_leader" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor="let i of c.zahlungen">
            <div style="text-decoration: underline;">{{i.institution}}</div>
            <div *ngFor="let ez of i.einzelzahlungen">
              <span>{{ez.date}}: € {{ez.sum | number: '0.2-2'}}</span>
            </div>
          </ng-container>
        </td>
        <td>€ {{c.zahlungen_sum | number: '0.2-2'}}</td>
        <td *ngIf="mode === 'Variante 2'">{{c.average_erbquote | number: '0.2-2'}} %</td>
        <td *ngIf="mode === 'Variante 2'">€ {{c.honorar_sum | number: '0.2-2'}}</td>
        <td *ngIf="mode === 'Variante 2'">€ {{c.honorar_sum * 0.01 | number: '0.2-2'}}</td>
        <td>{{casestatusService.getCaseStatusText(c.status)}}</td>
        <td>{{c.auskehr_abgeschlossen_am}}</td>
        <td style="background-color: #c9b381;"><editfield-date *ngIf="c.ebs_erlassen_date" [(date)]="c.ebs_erlassen_date" [editMode]="false"></editfield-date></td>
      </tr>
    </ng-container>
    <tr>
      <th>Summe</th>
      <th></th>
      <th *ngIf="mode === 'Variante 2'"></th>
      <th *ngIf="mode === 'Variante 2'"></th>
      <th *ngIf="mode === 'Variante 2'"></th>
      <th></th>
      <th>€ {{sum | number: '0.2-2'}}</th>
      <th *ngIf="mode === 'Variante 2'"></th>
      <th *ngIf="mode === 'Variante 2'">€ {{sum_honorar | number: '0.2-2'}}</th>
      <th *ngIf="mode === 'Variante 2'">€ {{sum_honorar * 0.01 | number: '0.2-2'}}</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </table>
  <p *ngIf="!caselist || caselist.length === 0">keine Fälle gefunden</p>
</ng-container>
<ng-container *ngIf="loading">
  <p>Die Liste wird geladen, bitte warten...</p>
</ng-container>
<button class="btn btn-sm btn-success my-1" (click)="back()">zurück</button>

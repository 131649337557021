/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-tree-settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./change-tree-settings.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../services/stammbaum.service";
var styles_ChangeTreeSettingsComponent = [i0.styles];
var RenderType_ChangeTreeSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeTreeSettingsComponent, data: {} });
export { RenderType_ChangeTreeSettingsComponent as RenderType_ChangeTreeSettingsComponent };
export function View_ChangeTreeSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stammbaum-Einstellungen"])), (_l()(), i1.ɵeld(3, 0, null, null, 25, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(5, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 20, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["3. EO ausblenden"])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "input", [["formControlName", "hide_eo_3"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(16, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(18, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["4. EO ausblenden"])), (_l()(), i1.ɵeld(22, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "input", [["formControlName", "hide_eo_4"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(26, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(28, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(29, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 5, 0, currVal_7); var currVal_15 = "hide_eo_3"; _ck(_v, 16, 0, currVal_15); var currVal_23 = "hide_eo_4"; _ck(_v, 26, 0, currVal_23); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 18).ngClassValid; var currVal_13 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_17 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_18 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_19 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_20 = i1.ɵnov(_v, 28).ngClassValid; var currVal_21 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_22 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }); }
export function View_ChangeTreeSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "change-tree-settings", [], null, null, null, View_ChangeTreeSettingsComponent_0, RenderType_ChangeTreeSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChangeTreeSettingsComponent, [i4.NgbActiveModal, i5.StammbaumService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeTreeSettingsComponentNgFactory = i1.ɵccf("change-tree-settings", i3.ChangeTreeSettingsComponent, View_ChangeTreeSettingsComponent_Host_0, { version: "version" }, {}, []);
export { ChangeTreeSettingsComponentNgFactory as ChangeTreeSettingsComponentNgFactory };

<div class="m-2 p-2">
  <h4 class="ilatitle">Stammbaum-Einstellungen</h4>
  <form [formGroup]="form">
    <table class="table table-sm">
      <tr>
        <td>3. EO ausblenden</td>
        <td><input type="checkbox" formControlName="hide_eo_3"></td>
      </tr>
      <tr>
        <td>4. EO ausblenden</td>
        <td><input type="checkbox" formControlName="hide_eo_4"></td>
      </tr>
    </table>
  </form>
  <button class="mx-1 btn btn-sm btn-success" (click)="close()">schliessen</button>
  <button class="mx-1 btn btn-sm btn-warning" (click)="save()">speichern</button>
</div>

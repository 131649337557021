<div *ngIf="copiedActiveCase" class="card-deck">
  <div class="card casebasiccard">
    <div class="card-body">
      <h4 *ngIf="editMode !== CaseSaveMode.Rahmendaten">Rahmendaten
        <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Rahmendaten)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      </h4>
      <h4 *ngIf="editMode === CaseSaveMode.Rahmendaten">Rahmendaten
        <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
        <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm" *ngIf="copiedActiveCase">
          <tr>
            <td style="width: 33%">Fall-ID</td>
            <td>{{copiedActiveCase.id}}</td>
          </tr>
          <tr>
            <td>alte Fallnummer</td>
            <td>
              <editfield-text [maxLength]="30" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.old_id"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <editfield-select [options]="caseStatusOptions" [editMode]="false"
                                [(select)]="copiedActiveCase.status"></editfield-select>
              <button *ngIf="editMode === CaseSaveMode.Kein_Modus_gewaehlt" class="mx-2 btn btn-sm btn-primary" (click)="openChangeStatusModal()">ändern</button>
              <button *ngIf="showStatusChangeHistory" class="mx-2 btn btn-sm btn-info" (click)="toggleShowStatusChangeHistory()">ausblenden</button>
              <button *ngIf="!showStatusChangeHistory" class="mx-2 btn btn-sm btn-info" (click)="toggleShowStatusChangeHistory()">Änderungen</button>
              <span *ngIf="showStatusChangeHistory">
                <ng-container *ngIf="copiedActiveCase.status_change_history && copiedActiveCase.status_change_history.length > 0">
                  <div *ngFor="let ch of copiedActiveCase.status_change_history">{{ch.timestamp | date:'dd.MM.y'}}: {{ch.case_status}} ({{ch.user}})
                  </div>
                </ng-container>
                <div *ngIf="!copiedActiveCase.status_change_history || copiedActiveCase.status_change_history.length === 0">keine Status-Änderungen vorhanden</div>
              </span>
            </td>
          </tr>
          <tr *ngIf="copiedActiveCase.ablagegrund || copiedActiveCase.ablagegrund_erlaeuterung">
            <td>Ablagegrund</td>
            <td>
              <span>{{caseService.getAblagegrundOptionText(copiedActiveCase.ablagegrund)}}</span>
              <span *ngIf="copiedActiveCase.ablagegrund_erlaeuterung"><br />{{copiedActiveCase.ablagegrund_erlaeuterung}}</span>
            </td>
          </tr>
          <tr>
            <td>Gericht alt</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.court"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Gericht</td>
            <td>
              <addressbook-autocomplete [editMode]="editMode === CaseSaveMode.Rahmendaten"
                                        [(fieldValue)]="copiedActiveCase.institution"></addressbook-autocomplete>
            </td>
          </tr>
          <tr>
            <td>Aktenzeichen</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.court_reference"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Akteneinsicht am</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.date_of_record_access"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Akteneinsicht durch</td>
            <td>
              <editfield-select *ngIf="editMode === CaseSaveMode.Rahmendaten" [(select)]="copiedActiveCase.user_made_record_access"
                                [editMode]="true" [options]="userOptions"></editfield-select>
              <span *ngIf="editMode !== CaseSaveMode.Rahmendaten"
                    class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.user_made_record_access)}}</span>
            </td>
          </tr>
          <tr>
            <td>Akteneinsicht angefragt am</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.date_asked_for_record_access"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Akteneinsicht angefragt durch</td>
            <td>
              <editfield-select *ngIf="editMode === CaseSaveMode.Rahmendaten" [(select)]="copiedActiveCase.user_asked_for_record_access"
                                [editMode]="true" [options]="userOptions"></editfield-select>
              <span *ngIf="editMode !== CaseSaveMode.Rahmendaten"
                    class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.user_asked_for_record_access)}}</span>
            </td>
          </tr>
          <tr>
            <td>weitere Akteneinsichten</td>
            <td>
              <editfield-text [maxLength]="100" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.weitere_akteneinsichten"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Rechtspfleger</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.judical_officer"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Fiskus</td>
            <td>
              <editfield-checkbox [(checkbox)]="copiedActiveCase.fiscal"
                                  [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-checkbox>
            </td>
          </tr>
          <tr>
            <td>Fiskus Datum</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.fiscal_date" [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Fiskus Ende</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.fiscal_end_date"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Fiskus aufgehoben am</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.fiskus_aufgehoben"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Quelle</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.source"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Bundesanz. am</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.bundesanzeiger_date"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>ZwischBer Gericht</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.midterm_report_court_date"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>ZwischBer NP</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.midterm_report_curator_date"
                              [editMode]="editMode === CaseSaveMode.Rahmendaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>sonstige Anmerkungen</td>
            <td>
              <editfield-text [maxLength]="100" [editMode]="editMode === CaseSaveMode.Rahmendaten"
                              [(text)]="copiedActiveCase.additional_information"></editfield-text>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>

  <div class="card casebasiccard">
    <div class="card-body">
      <div class="mb-4" *ngIf="copiedActiveCase">
        <h4 *ngIf="editMode !== CaseSaveMode.Unterfall_zu_Fall">übergeordneter Fall
          <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Unterfall_zu_Fall)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
        </h4>
        <h4 *ngIf="editMode === CaseSaveMode.Unterfall_zu_Fall">übergeordneter Fall
          <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
          <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
        </h4>
        <p
          *ngIf="copiedActiveCase.parent_case">{{copiedActiveCase.parent_case}} {{copiedActiveCase.parent_case_data?.first_name}} {{copiedActiveCase.parent_case_data?.last_name}} {{copiedActiveCase.parent_case_data?.birth_name}}
          <button class="btn btn-sm btn-success" (click)="goToCase(copiedActiveCase.parent_case)">direkt zu diesem Fall</button>
        </p>
        <p *ngIf="editMode === CaseSaveMode.Unterfall_zu_Fall">Fall-ID
          <editfield-text class="mx-3" [maxLength]="12" [editMode]="editMode === CaseSaveMode.Unterfall_zu_Fall"
                          [(text)]="copiedActiveCase.parent_case"></editfield-text>
        </p>
      </div>
      <div *ngIf="copiedActiveCase.child_cases && copiedActiveCase.child_cases.length > 0">
        <h4>untergeordnete Fälle</h4>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-sm">
            <tr *ngFor="let cc of copiedActiveCase.child_cases_data">
              <td>{{cc.id}}</td>
              <td>{{cc.first_name}}</td>
              <td>{{cc.last_name}}</td>
              <td>{{cc.birth_name}}</td>
              <td>
                <button class="btn btn-sm btn-success" (click)="goToCase(cc.id)">direkt zu diesem Fall</button>
              </td>
            </tr>
          </table>
        </div>
      </div>


      <h4 *ngIf="editMode !== CaseSaveMode.Erblasserdaten">Erblasser-Daten
        <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Erblasserdaten)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      </h4>
      <h4 *ngIf="editMode === CaseSaveMode.Erblasserdaten">Erblasser-Daten
        <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
        <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm" *ngIf="copiedActiveCase">
          <tr>
            <td style="width: 33%">Nachname</td>
            <td style="width: 67%">
              <editfield-text [maxLength]="50" [placeholder]="'NACHNAME'" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.last_name"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Vornamen</td>
            <td>
              <editfield-text [maxLength]="50" [placeholder]="'Vorname'" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.first_name"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Geburtsname</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.birth_name"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Geschied. Name</td>
            <td>
              <editfield-text [maxLength]="50" [placeholder]="''" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.divorced_name"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Geburtsdatum</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.birth_date" [editMode]="editMode === CaseSaveMode.Erblasserdaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Geburtsort</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.birth_place"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Geburtsland</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.birth_country"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Sterbedatum</td>
            <td>
              <editfield-date [(date)]="copiedActiveCase.deceased_date"
                              [editMode]="editMode === CaseSaveMode.Erblasserdaten"></editfield-date>
            </td>
          </tr>
          <tr>
            <td>Sterbeort</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.deceased_place"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Sterbeland</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                              [(text)]="copiedActiveCase.deceased_country"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>letzter Wohnsitz</td>
            <td><editfield-address [editMode]="editMode === CaseSaveMode.Erblasserdaten"
                                   [(address)]="copiedActiveCase.last_address_full"></editfield-address>
          </tr>
          <tr>
            <td>Testament existiert</td>
            <td>
              <editfield-checkbox [(checkbox)]="copiedActiveCase.testament_vorhanden"
                                  [editMode]="editMode === CaseSaveMode.Erblasserdaten"></editfield-checkbox>
            </td>
          </tr>
          <tr>
            <td>Infos zu Testament</td>
            <td>
              <editfield-textarea [editMode]="editMode === CaseSaveMode.Erblasserdaten" [rows]="4" [maxLength]="'500'"
                              [(text)]="copiedActiveCase.testament_infos"></editfield-textarea>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <h4 *ngIf="displayBundesanzeigertext()">Text Bundesanzeiger
          <ng-container *ngIf="editMode !== CaseSaveMode.Bundesanzeigertext">
            <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Bundesanzeigertext)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
            <button (click)="toggleOpen(CaseDisplayOpenable.Bundesanzeigertext)" class="mx-1 btn btn-sm btn-info">schliessen</button>
          </ng-container>
          <ng-container *ngIf="editMode === CaseSaveMode.Bundesanzeigertext">
            <button (click)="reloadData()" class="mx-1 btn btn-sm btn-danger">abbrechen</button>
            <button (click)="saveData()" class="mx-1 btn btn-sm btn-success">speichern</button>
          </ng-container>
        </h4>
        <ng-container *ngIf="displayBundesanzeigertext()">
          <div *ngIf="editMode !== CaseSaveMode.Bundesanzeigertext" style="white-space:pre-wrap;">{{copiedActiveCase?.bundesanzeiger_text}}</div>
          <p *ngIf="editMode === CaseSaveMode.Bundesanzeigertext"><textarea class="form-control" rows="20" [(ngModel)]="copiedActiveCase.bundesanzeiger_text"></textarea></p>
        </ng-container>
        <h4 *ngIf="!displayBundesanzeigertext()">Text Bundesanzeiger<button (click)="toggleOpen(CaseDisplayOpenable.Bundesanzeigertext)" class="mx-1 btn btn-sm btn-info">öffnen</button></h4>
      </div>
    </div>
  </div>
  <div class="card casebasiccard">
    <div class="card-body">
      <h4>Dokumente
        <button class="m-1 btn btn-sm btn-success pull-right" (click)="createDocxForCase()">Leeres DOCX</button>
        <button *ngIf="false" class="m-1 btn btn-sm btn-success pull-right" (click)="faxEinsichtModal()">Akteneinsicht beantragen (Fax)</button>
      </h4>
      <h4 *ngIf="editMode !== CaseSaveMode.Nachlassdaten">Nachlass
        <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Nachlassdaten)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      </h4>
      <h4 *ngIf="editMode === CaseSaveMode.Nachlassdaten">Nachlass
        <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
        <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm" *ngIf="copiedActiveCase">
          <tr>
            <td>Nachlasspfleger</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Nachlassdaten"
                              [(text)]="copiedActiveCase.curator"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>AZ Nachlasspfleger</td>
            <td>
              <editfield-text [maxLength]="50" [editMode]="editMode === CaseSaveMode.Nachlassdaten"
                              [(text)]="copiedActiveCase.curator_reference"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Wert</td>
            <td>
              <editfield-text [maxLength]="20" [editMode]="editMode === CaseSaveMode.Nachlassdaten"
                              [(text)]="copiedActiveCase.value"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Gesamtwert</td>
            <td>
              <editfield-text [maxLength]="20" [editMode]="editMode === CaseSaveMode.Nachlassdaten"
                              [(text)]="copiedActiveCase.total_value"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Immobilie vorhanden</td>
            <td>
              <editfield-checkbox [(checkbox)]="copiedActiveCase.real_estate"
                                  [editMode]="editMode === CaseSaveMode.Nachlassdaten"></editfield-checkbox>
            </td>
          </tr>
          <tr>
            <td>Immobilie Beschreibung</td>
            <td>
              <editfield-textarea [rows]="3" [maxLength]="400" [editMode]="editMode === CaseSaveMode.Nachlassdaten"
                              [(text)]="copiedActiveCase.real_estate_description"></editfield-textarea>
            </td>
          </tr>
        </table>
      </div>
      <h4 *ngIf="editMode !== CaseSaveMode.Fallliste_Daten">Daten für Fall-Liste
        <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.Fallliste_Daten)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
      </h4>
      <h4 *ngIf="editMode === CaseSaveMode.Fallliste_Daten">Daten für Fall-Liste
        <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
        <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm" *ngIf="copiedActiveCase">
          <tr>
            <td>Bemerkung für Fall-Liste</td>
            <td>
              <editfield-text [maxLength]="'200'" [editMode]="editMode === CaseSaveMode.Fallliste_Daten"
                              [(text)]="copiedActiveCase.bemerkung_fuer_fallliste"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Auskehrungen für Fall-Liste</td>
            <td>
              <editfield-text [maxLength]="'50'" [editMode]="editMode === CaseSaveMode.Fallliste_Daten"
                              [(text)]="copiedActiveCase.auszahlungssumme_fuer_fallliste"></editfield-text>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <h4 *ngIf="displayEBSZusammenfassung()">EBS-Zusammenfassung
          <ng-container *ngIf="editMode !== CaseSaveMode.EBS_Zusammenfassung">
            <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.EBS_Zusammenfassung)" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
            <button (click)="toggleOpen(CaseDisplayOpenable.EBS_Zusammenfassung)" class="mx-1 btn btn-sm btn-info">schliessen</button>
          </ng-container>
          <ng-container *ngIf="editMode === CaseSaveMode.EBS_Zusammenfassung">
            <button (click)="reloadData()" class="mx-1 btn btn-sm btn-danger">abbrechen</button>
            <button (click)="saveData()" class="mx-1 btn btn-sm btn-success">speichern</button>
          </ng-container>
        </h4>
        <ng-container *ngIf="displayEBSZusammenfassung()">
          <div *ngIf="editMode !== CaseSaveMode.EBS_Zusammenfassung" style="white-space:pre-wrap;">{{copiedActiveCase?.ebs_zusammenfassung}}</div>
          <p *ngIf="editMode === CaseSaveMode.EBS_Zusammenfassung"><textarea class="form-control" rows="20" [(ngModel)]="copiedActiveCase.ebs_zusammenfassung"></textarea></p>
        </ng-container>
        <h4 *ngIf="!displayEBSZusammenfassung()">EBS-Zusammenfassung<button (click)="toggleOpen(CaseDisplayOpenable.EBS_Zusammenfassung)" class="mx-1 btn btn-sm btn-info">öffnen</button></h4>
      </div>
    </div>
  </div>
</div>

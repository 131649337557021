<h3>Administration</h3>
<app-notification [limitToSlot]="SLOT_USERS"></app-notification>
<div class="card">
  <div class="card-body">
    <ng-container
      *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER
      || ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER_CREATE
      || ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER_EDIT">
      <button class="btn btn-sm btn-success m-2">Mitarbeiter</button>
    </ng-container>
    <ng-container
      *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION != MODULE_PAGES.ADMINISTRATION.USER
      && ui.ui.routing.activePageInModule.ADMINISTRATION != MODULE_PAGES.ADMINISTRATION.USER_CREATE
      && ui.ui.routing.activePageInModule.ADMINISTRATION != MODULE_PAGES.ADMINISTRATION.USER_EDIT">
      <button class="btn btn-sm btn-outline-success m-2" (click)="navigateTo(MODULE_PAGES.ADMINISTRATION.USER)">Mitarbeiter</button>
    </ng-container>
    <button class="btn btn-sm btn-outline-success m-2" (click)="showBackupStatus()">Backup-Status</button>
    <button class="btn btn-sm btn-outline-success m-2" (click)="showClientConnections()">Client-Verbindungen</button>

    <button *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.GENERATED_DOCS" class="btn btn-sm btn-success m-2">Dokumentenversand</button>
    <button *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION!=MODULE_PAGES.ADMINISTRATION.GENERATED_DOCS" (click)="navigateTo(MODULE_PAGES.ADMINISTRATION.GENERATED_DOCS)" class="btn btn-sm btn-outline-success m-2">Dokumentenversand</button>
  </div>
</div>

<user-management *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER"></user-management>
<user-details *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER_CREATE"></user-details>
<user-management-edit *ngIf="ui.ui.routing.activePageInModule.ADMINISTRATION==MODULE_PAGES.ADMINISTRATION.USER_EDIT"></user-management-edit>

<admin-docs-module></admin-docs-module>

<div class="row">
  <div class="col-8 scroll-in" #scrollIn>
    <h3 class="ilatitle">Auskehr und Abrechnung</h3>
    <h4 class="ilatitle mt-3">Auskehr-Institutionen
      <button title="Institution zum Fall hinzufügen" (click)="addInstitution()" class="ml-1 btn btn-sm btn-success"><span class="fa fa-plus"></span><span class="fa fa-building ml-1"></span></button>
      <button class="btn btn-sm pull-right" [ngClass]="{'btn-primary': notizenMode === 'alle', 'btn-outline-primary': notizenMode !== 'alle'}" (click)="setDisplayMode('alle', 0)">Übersicht</button>
    </h4>
    <table class="mb-2 table table-sm">
      <tr>
        <th class="col-4">Institution</th>
        <th class="col-2" title="Wert zum Todeszeitpunkt">Wert TZP</th>
        <th class="col-2" title="geschätzter Wert der Sache oder erwartete Geldsumme">Schätzwert</th>
        <th class="col-2" title="tatsächlich erhaltener Betrag / Wert nach Verkauf">tatsächlich</th>
        <th class="col-1" title="abgeschlossen">abg.</th>
        <th class="col-1"></th>
      </tr>
      <ng-container *ngFor="let i of institutionen">
        <tr [ngClass]="{'selected-for-note': notizenMode === 'institution' && i.id === notizenInstitutionID }">
          <td [ngClass]="{'highlighted': notizenMode === 'institution' && i.id === notizenInstitutionID }">
            {{i.name}}
            <button *ngIf="notizenMode === 'institution' && i.id === notizenInstitutionID && (!i.geldquellen || i.geldquellen.length === 0)" (click)="removeInstitution(i)" title="Institution entfernen" class="btn btn-sm btn-danger mx-1 pull-right"><span class="fa fa-trash"></span><span class="fa fa-building ml-1"></span></button>
          </td>
          <td><span><span *ngIf="i.sum_at_death">€ </span>{{i.sum_at_death | number: '1.2-2'}}</span></td>
          <td><span>€ {{i.sum_expected | number: '1.2-2'}}</span></td>
          <td>
            <span>€ {{i.sum_received | number: '1.2-2'}}</span>
            <span *ngIf="+i.sum_received === +i.sum_expected" class="fa fa-check ok ml-1"></span>
          </td>
          <td><show-boolean-value [value]="i.auskehr_completed"></show-boolean-value></td>
          <td>
            <button class="btn btn-sm" [ngClass]="{'btn-primary': notizenMode === 'institution' && i.id === notizenInstitutionID, 'btn-outline-primary': notizenMode !== 'institution' || i.id !== notizenInstitutionID}" (click)="setDisplayMode('institution', i.id)">auswählen</button>
          </td>
        </tr>
      </ng-container>
      <tr style="text-decoration: underline;">
        <td>Summe</td>
        <td>€ {{allInstSumAtDeath | number: '1.2-2'}}</td>
        <td>€ {{allInstSumExpected | number: '1.2-2'}}</td>
        <td>€ {{allInstSumReceived | number: '1.2-2'}}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
    <table *ngIf="notizenMode === 'institution'" class="mb-2 table table-sm selected-for-note-border">
      <ng-container *ngFor="let i of institutionen">
        <ng-container *ngIf="notizenMode === 'institution' && i.id === notizenInstitutionID">
          <tr>
            <th class="col-4">
              <span style="text-decoration: underline;">Geldquellen</span>
              <button (click)="createQuelle(i)" title="Geldquelle hinzufügen" class="btn btn-sm btn-success mx-1"><span class="fa fa-plus"></span></button>
            </th>
            <th class="col-2"></th>
            <th class="col-2" title="geschätzter Wert der Sache oder erwartete Geldsumme">Schätzwert</th>
            <th class="col-2"></th>
            <th class="col-1" title="abgeschlossen">abg.</th>
            <th class="col-1"></th>
          </tr>
          <ng-container *ngIf="i.geldquellen && i.geldquellen.length > 0">
            <tr *ngFor="let q of filterDeleted(i.geldquellen)">
              <td>{{q.name}} <span *ngIf="q.description" class="fa fa-info-circle" [title]="q.description"></span></td>
              <td></td>
              <td>€ {{q.sum_expected | number: '1.2-2'}}</td>
              <td></td>
              <td><show-boolean-value [value]="q.completed"></show-boolean-value></td>
              <td>
                <button (click)="editQuelle(i, q)" class="btn btn-sm btn-success mx-1"><span class="fa fa-edit"></span></button>
                <button (click)="deleteQuelle(i, q)" class="btn btn-sm btn-danger mx-1"><span class="fa fa-trash"></span></button>
              </td>
            </tr>
          </ng-container>
          <tr>
            <th class="col-4">
              <span style="text-decoration: underline;">Auskehr-Zahlungen</span>
              <button (click)="createAuskehrung(i)" title="Auskehr-Zahlung hinzufügen" class="btn btn-sm btn-success mx-1"><span class="fa fa-plus"></span></button>
            </th>
            <th class="col-2" title="Wert zum Todeszeitpunkt">Wert TZP</th>
            <th class="col-2"></th>
            <th class="col-2" title="tatsächlich erhaltener Betrag / Wert nach Verkauf">tatsächlich</th>
            <th class="col-1" title="Betrag / Gegenstand erhalten">erhalt.</th>
            <th class="col-1"></th>
          </tr>
          <ng-container *ngIf="i.auskehrungen && i.auskehrungen.length > 0">
            <tr *ngFor="let z of filterDeletedZahlungen(i.auskehrungen)">
              <td>{{z.date | date:'dd.MM.y'}} {{z.text}}</td>
              <td><span *ngIf="z.sum_at_death">€ </span>{{z.sum_at_death | number:'1.2-2'}}</td>
              <td></td>
              <td>€ {{z.sum | number:'1.2-2'}}</td>
              <td><show-boolean-value [value]="z.item_received"></show-boolean-value></td>
              <td>
                <button (click)="editAuskehrung(i, z)" class="btn btn-sm btn-success mx-1"><span class="fa fa-edit"></span></button>
                <button (click)="deleteAuskehrung(i, z)" class="btn btn-sm btn-danger mx-1"><span class="fa fa-trash"></span></button>
              </td>
            </tr>
          </ng-container>
          <tr>
            <th colspan="6" style="text-decoration: underline;" (click)="toggleShowVerlauf()">Verlauf
              <span *ngIf="!showVerlauf" class="fa fa-caret-left"></span>
              <span *ngIf="showVerlauf" class="fa fa-caret-down"></span>
            </th>
          </tr>
          <ng-container *ngIf="showVerlauf && i.verlauf && i.verlauf.length > 0">
            <tr *ngFor="let v of i.verlauf">
              <td colspan="6">{{v.created | date:'dd.MM.y'}}: {{v.text}}</td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </table>

    <h4 class="ilatitle mt-3">Abrechnung Erben</h4>
    <table class="table table-sm">
      <tr>
        <th>ID</th>
        <th>Erbe</th>
        <th>Erbquote</th>
        <th>Honorar</th>
        <th>Steuer</th>
        <th>Unbed.</th>
        <th>Status</th>
      </tr>
      <tr *ngFor="let e of erben" [ngClass]="{'selected-for-note': notizenMode === 'erbe' && e.id === notizenErbenID}">
        <td>{{e.id}}</td>
        <td>{{e.nachname}} {{e.vorname}} <span *ngIf="e.geburtsname">geb. </span>{{e.geburtsname}}</td>
        <td>{{fraction(e.erbquote)}}</td>
        <td>{{e.contract_send_out_percentage}} %</td>
        <td>
          <span *ngIf="!e.steuererklaerung_needed && !e.steuererklaerung_done" title="Steuererklärung nicht gewünscht" class="fa fa-minus"></span>
          <span *ngIf="e.steuererklaerung_needed && !e.steuererklaerung_done" title="Steuererklärung noch offen" style="color: red;" class="fa fa-exclamation-triangle"></span>
          <span *ngIf="e.steuererklaerung_needed && e.steuererklaerung_done" title="Steuererklärung erledigt" style="color: green;" class="fa fa-check"></span>
          <span *ngIf="!e.steuererklaerung_needed && e.steuererklaerung_done" title="Datenfehler" style="color: green;" class="fa fa-question-circle-o"></span>
        </td>
        <td>
          <span *ngIf="!e.unbedenklichkeit_needed && !e.unbedenklichkeit_done" title="Unbedenklichkeitsbescheinigung nicht erforderlich" class="fa fa-minus"></span>
          <span *ngIf="e.unbedenklichkeit_needed && !e.unbedenklichkeit_done" title="Unbedenklichkeitsbescheinigung fehlt noch" style="color: red;" class="fa fa-exclamation-triangle"></span>
          <span *ngIf="e.unbedenklichkeit_needed && e.unbedenklichkeit_done" title="Unbedenklichkeitsbescheinigung vorhanden" style="color: green;" class="fa fa-check"></span>
          <span *ngIf="!e.unbedenklichkeit_needed && e.unbedenklichkeit_done" title="Datenfehler" style="color: green;" class="fa fa-question-circle-o"></span>
        </td>
        <td>
          <span *ngIf="e.abgerechnet_auskehr === true">abgeschlossen <button class="btn btn-sm btn-primary">Abrechnung anzeigen</button></span>
          <span *ngIf="e.abgerechnet_auskehr !== true">offen <button *ngIf="false"
            class="btn btn-sm btn-primary">abrechnen</button></span>
        </td>
        <td><button class="btn btn-sm btn-success" title="Steuererklärung / Unbedenklichkeitsbescheinigung bearbeiten" (click)="openUnbedenklichkeit(e)"><span class="fa fa-pencil-square"></span></button></td>
        <td><button class="btn btn-sm" [ngClass]="{'btn-primary': notizenMode === 'erbe' && e.id === notizenErbenID, 'btn-outline-primary': notizenMode !== 'erbe' || e.id !== notizenErbenID}" (click)="setDisplayMode('erbe', e.id)">auswählen</button></td>
      </tr>
    </table>
    <button *ngIf="false" class="btn btn-sm btn-primary">Sammelabrechnung für alle offenen Erben</button>
    <h4 *ngIf="false" class="ilatitle mt-3">Abrechnung Mitarbeiter</h4>
  </div>
  <div class="col-4">
    <h4 class="ilatitle" *ngIf="notizenMode === 'alle'">Alle Auskehr-Notizen</h4>
    <h4 class="ilatitle" *ngIf="notizenMode === 'erbe' && notizenErbenID !== null">Notizen zu {{getHeirTitel()}}</h4>
    <h4 class="ilatitle" *ngIf="notizenMode === 'institution' && notizenInstitutionID !== null">Notizen zu {{getInstitutionTitel()}}</h4>
    <div *ngIf="copiedActiveCase">
      <note-list-max style="margin-top: 10px;" [mode]="'case-auskehr'" [folderId]="1" [canAddNote]="notizenMode !== 'alle'" [noteSubject]="getNoteSubject()" [notizenErbenID]="notizenErbenID" [notizenInstitutionID]="notizenInstitutionID"></note-list-max>
    </div>
  </div>
</div>

<div class="card my-3">
  <div class="card-text">
    <div class="table-responsive">
      <h3 *ngIf="!searchResults || searchResults.length === 0">0 Personen gefunden</h3>
      <h3 *ngIf="searchResults && searchResults.length > 0">{{searchResults.length}} Personen gefunden (max. 200 Ergebnisse)</h3>
      <table *ngIf="searchResults && searchResults.length > 0" class="table table-hover table-striped table-sm">
        <tr>
          <th>Personen-ID</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Geburtsname</th>
          <th>Fall-ID</th>
          <th>Status</th>
          <th>Personen-Typ</th>
        </tr>
        <ng-container *ngFor="let p of searchResults">
          <tr (click)="onTableClicked(p)">
            <td>{{p.id}}</td>
            <td>{{p.vorname}}</td>
            <td>{{p.nachname}}</td>
            <td>{{p.geburtsname}}</td>
            <td>{{p.case}}</td>
            <td [title]="getTypeTooltip(p)"><span [style.color]="getTypeColor(p)" class="fa fa-circle"></span></td>
            <td>{{getTypeText(p.person_type)}}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>

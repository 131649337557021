<svg:rect *ngIf="!hidePerson()" x="0" y="0" rx="10" ry="10" xmlns:xhtml="http://www.w3.org/1999/xhtml" [attr.width]="width"
          [attr.height]="height" [attr.fill]="personDisplayData.displayParams.color" stroke="black"
          [attr.x]="personDisplayData.displayParams.x" [attr.y]="personDisplayData.displayParams.y"></svg:rect>

<svg:rect *ngIf="hidePerson()" x="0" y="0" rx="10" ry="10" xmlns:xhtml="http://www.w3.org/1999/xhtml" [attr.width]="width"
          [attr.height]="height" [attr.fill]="personDisplayData.displayParams.color" stroke="#f0f0f0"
          [attr.x]="personDisplayData.displayParams.x" [attr.y]="personDisplayData.displayParams.y"></svg:rect>

<svg:rect *ngIf="!hidePerson() && (treeViewMode === 'edit-structure')" x="0" y="0" rx="10" ry="10"
          xmlns:xhtml="http://www.w3.org/1999/xhtml"
          [attr.width]="width"
          [attr.height]="heightOfControlButtons" [attr.fill]="'orange'" stroke="black"
          [attr.x]="personDisplayData.displayParams.x"
          [attr.y]="personDisplayData.displayParams.y + height - heightOfControlButtons"></svg:rect>

<svg:rect *ngIf="!hidePerson() && (treeViewMode === 'edit-person-data')" x="0" y="0" rx="10" ry="10"
          xmlns:xhtml="http://www.w3.org/1999/xhtml"
          [attr.width]="width"
          [attr.height]="heightOfControlButtons" [attr.fill]="'#f0f0f0'" stroke="black"
          [attr.x]="personDisplayData.displayParams.x"
          [attr.y]="personDisplayData.displayParams.y + height - heightOfControlButtons"></svg:rect>

<svg:rect *ngIf="!hidePerson() && (treeViewMode === 'show-extra-info')" x="0" y="0" rx="10" ry="10"
          xmlns:xhtml="http://www.w3.org/1999/xhtml"
          [attr.width]="width"
          [attr.height]="heightOfControlButtons" [attr.fill]="getEOColor(personDisplayData.erbordnung)" stroke="black"
          [attr.x]="personDisplayData.displayParams.x"
          [attr.y]="personDisplayData.displayParams.y + height - heightOfControlButtons"></svg:rect>

<svg:rect *ngIf="!hidePerson() && (treeViewMode === 'morph-structure')" x="0" y="0" rx="10" ry="10"
          xmlns:xhtml="http://www.w3.org/1999/xhtml"
          [attr.width]="width"
          [attr.height]="heightOfControlButtons" [attr.fill]="'lightblue'" stroke="black"
          [attr.x]="personDisplayData.displayParams.x"
          [attr.y]="personDisplayData.displayParams.y + height - heightOfControlButtons"></svg:rect>

<svg:foreignObject [attr.x]="personDisplayData.displayParams.x" [attr.y]="personDisplayData.displayParams.y"
                   [attr.width]="width"
                   [attr.height]="height-heightOfControlButtons">
  <xhtml:div class="person-content-div" #contentdiv>
    <ng-container *ngIf="!hidePerson()">
      <div *ngIf="isOrHasDuplicate()" class="duplicate-mark">
        <span class="badge badge-warning">Doppel-Darstellung</span>
      </div>
      <div *ngIf="person.adoptiert" class="person-adoptiert">ADOPTIERT</div>
      <div *ngIf="printMarriageData().display" class="marriage-data-div" (dblclick)="editMarriageData()">
        <span *ngIf="!printMarriageData().ausserehelich" title="Eheschließung" class="fa fa-lg icon-cont fa-venus-mars"></span>
        <span *ngIf="printMarriageData().ausserehelich" title="außereheliche Partnerschaft" class="fa fa-lg icon-cont fa-heart"></span>
        <span>{{printMarriageData().dataMarriage}}</span>
      </div>
      <div *ngIf="printMarriageData().display && printMarriageData().divorced" class="marriage-data-div"
           (dblclick)="editMarriageData()">
        <span title="Ehe geschieden" class="fa fa-lg icon-cont icon-divorced fa-venus-mars"></span>
        <span>{{printMarriageData().dataScheidung}}</span>
      </div>
      <div title="Trauung" *ngIf="printMarriageData().dataTrauung" class="trauung-data-div"
           (dblclick)="editMarriageData()">
        <span class="fa fa-lg icon-cont fa-circle-notch"></span>
        {{printMarriageData().dataTrauung}}
      </div>
      <div *ngIf="personDisplayData.vorname" (dblclick)="editVorname()" class="person-first-name-div"
           [innerHTML]="printVorname()"></div>
      <div *ngIf="!personDisplayData.vorname" (dblclick)="editVorname()" class="person-first-name-placeholder">
        Vornamen <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div *ngIf="personDisplayData.nachname || personDisplayData.geburtsname" (dblclick)="editNachname()"
           class="person-last-name-div">{{personDisplayData.nachname}}
        <span *ngIf="personDisplayData.geburtsname"> geb. {{personDisplayData.geburtsname}}</span>
      </div>
      <div *ngIf="!personDisplayData.nachname && !personDisplayData.geburtsname" (dblclick)="editNachname()"
           class="person-last-name-placeholder">
        Nachname <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div title="Geburtsinfos" (dblclick)="editBirth()"
           *ngIf="personDisplayData.geburtsdatum || personDisplayData.geburtsinfo"
           class="person-birth-info">
      <span
        class="fa fa-sm fa-star"></span> {{personDisplayData.geburtsdatum | showDate}} {{personDisplayData.geburtsinfo}} <span *ngIf="personDisplayData.geburtsland">({{personDisplayData.geburtsland}})</span>
      </div>
      <div title="Taufeinfos" (dblclick)="editBirth()" *ngIf="personDisplayData.taufdatum || personDisplayData.taufinfo"
           class="person-birth-info">
        <span
          class="fa fa-sm fa-star-o"></span> {{personDisplayData.taufdatum | showDate}} {{personDisplayData.taufinfo}}
      </div>
      <div
        *ngIf="!personDisplayData.geburtsdatum && !personDisplayData.geburtsinfo && !personDisplayData.taufdatum && !personDisplayData.taufinfo"
        (dblclick)="editBirth()"
        class="person-birth-info-placeholder">
        Geburts- und Taufinfos <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div title="Sterbeinfos" (dblclick)="editDeath()"
           *ngIf="personDisplayData.sterbedatum || personDisplayData.sterbeinfo"
           class="person-death-info">
      <span
        class="fa fa-sm fa-plus"></span> {{personDisplayData.sterbedatum | showDate}} {{personDisplayData.sterbeinfo}} <span *ngIf="personDisplayData.sterbeland">({{personDisplayData.sterbeland}})</span>
      </div>
      <div *ngIf="personDisplayData.gefallen" class="person-death-info">
        <span style="font-style: italic;">gefallen</span>
      </div>
      <div title="Begräbnisinfos" (dblclick)="editDeath()"
           *ngIf="personDisplayData.begraebnisdatum || personDisplayData.begraebnisinfo"
           class="person-death-info">
        <span class="fa fa-sm fa-th"></span>
        {{personDisplayData.begraebnisdatum | showDate}}
        {{personDisplayData.begraebnisinfo}}
      </div>
      <div
        *ngIf="!personDisplayData.sterbedatum && !personDisplayData.sterbeinfo && !personDisplayData.begraebnisdatum && !personDisplayData.begraebnisinfo"
        (dblclick)="editDeath()"
        class="person-death-info-placeholder">
        Sterbe- und Begräbnisinfos <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div title="Geschlecht" (dblclick)="editGeschlecht()" *ngIf="personDisplayData.geschlecht" class="person-geschlecht-info">
        <span *ngIf="personDisplayData.geschlecht === 1" class="fa fa-lg icon-cont fa-venus"></span>
        <span *ngIf="personDisplayData.geschlecht === 2" class="fa fa-lg icon-cont fa-mars"></span>
      </div>
      <div (dblclick)="editGeschlecht()" *ngIf="!personDisplayData.geschlecht" class="person-geschlecht-info-placeholder">
        Geschlecht unbekannt <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div title="Religion" (dblclick)="editReligion()" *ngIf="personDisplayData.religion" class="person-religion-info">
        {{personDisplayData.religion}}</div>
      <div *ngIf="!personDisplayData.religion" (dblclick)="editReligion()"
           class="person-religion-info-placeholder">
        Religion <span class="fa fa-sm fa-pencil"></span>
      </div>
      <div (dblclick)="showUrkunden()" class="urkunden-div">
        <a *ngIf="isHeir()" class="badge mx-1 badge-pill badge-warning" title="Personendaten anzeigen" (click)="showPersonInfo()">Info</a>
        <span
          *ngIf="personDisplayData.needs_gu && !personDisplayData.needs_gu_but_do_without && !personDisplayData.urkunden_vorhanden?.has_gu && !personDisplayData.ordered_gu && !personDisplayData.urkunden_vorhanden?.has_gu_in_akte"
          title="GU benötigt" class="mx-1 badge badge-pill badge-danger">GU</span>
        <span
          *ngIf="personDisplayData.needs_gu && personDisplayData.needs_gu_but_do_without && !personDisplayData.urkunden_vorhanden?.has_gu && !personDisplayData.ordered_gu"
          title="auf GU wird verzichtet" class="mx-1 badge badge-pill badge-grey">GU</span>
        <span
          *ngIf="personDisplayData.needs_gu && !personDisplayData.urkunden_vorhanden?.has_gu && personDisplayData.ordered_gu"
          title="GU bestellt" class="mx-1 badge badge-pill badge-ordered">GU</span>
        <span *ngIf="personDisplayData.needs_gu && personDisplayData.urkunden_vorhanden?.has_gu"
              title="GU benötigt und vorhanden" class="mx-1 badge badge-pill badge-success">GU</span>
        <span *ngIf="personDisplayData.needs_gu && personDisplayData.urkunden_vorhanden?.has_gu_in_akte"
              title="GU benötigt und in Akte vorhanden" class="mx-1 badge badge-pill badge-in-akte">GU</span>
        <span *ngIf="!personDisplayData.needs_gu && personDisplayData.urkunden_vorhanden?.has_gu"
              title="GU nicht benötigt, aber vorhanden" class="mx-1 badge badge-pill badge-primary">GU</span>
        <span *ngIf="!personDisplayData.needs_gu && !personDisplayData.urkunden_vorhanden?.has_gu && personDisplayData.urkunden_vorhanden?.has_gu_in_akte"
              title="GU nicht benötigt, aber in Akte vorhanden" class="mx-1 badge badge-pill badge-primary">GU</span>
        <span
          *ngIf="personDisplayData.needs_su && !personDisplayData.needs_su_but_do_without && !personDisplayData.urkunden_vorhanden?.has_su && !personDisplayData.ordered_su && !personDisplayData.urkunden_vorhanden?.has_su_in_akte"
          title="SU benötigt" class="mx-1 badge badge-pill badge-danger">SU</span>
        <span
          *ngIf="personDisplayData.needs_su && personDisplayData.needs_su_but_do_without && !personDisplayData.urkunden_vorhanden?.has_su && !personDisplayData.ordered_su"
          title="auf SU wird verzichtet" class="mx-1 badge badge-pill badge-grey">SU</span>
        <span
          *ngIf="personDisplayData.needs_su && !personDisplayData.urkunden_vorhanden?.has_su && personDisplayData.ordered_su"
          title="SU bestellt" class="mx-1 badge badge-pill badge-ordered">SU</span>
        <span *ngIf="personDisplayData.needs_su && personDisplayData.urkunden_vorhanden?.has_su"
              title="SU benötigt und vorhanden" class="mx-1 badge badge-pill badge-success">SU</span>
        <span *ngIf="personDisplayData.needs_su && personDisplayData.urkunden_vorhanden?.has_su_in_akte"
              title="SU benötigt und in Akte vorhanden" class="mx-1 badge badge-pill badge-in-akte">SU</span>
        <span *ngIf="!personDisplayData.needs_su && personDisplayData.urkunden_vorhanden?.has_su"
              title="SU nicht benötigt, aber vorhanden" class="mx-1 badge badge-pill badge-primary">SU</span>
        <span *ngIf="!personDisplayData.needs_su && !personDisplayData.urkunden_vorhanden?.has_su && personDisplayData.urkunden_vorhanden?.has_su_in_akte"
              title="SU nicht benötigt, aber in Akte vorhanden" class="mx-1 badge badge-pill badge-primary">SU</span>
        <ng-container *ngFor="let mr of personDisplayData.urkunden_vorhanden?.has_hu">
          <span *ngIf="mr.needs_hu && !mr.needs_hu_but_do_without && !mr.has_hu && !mr.ordered_hu &&
          !mr.has_hu_in_akte"
                title="HU benötigt"
                class="mx-1 badge badge-pill badge-danger">HU</span>
          <span *ngIf="mr.needs_hu && mr.needs_hu_but_do_without && !mr.has_hu && !mr.ordered_hu && !mr.has_hu_in_akte"
                title="auf HU wird verzichtet"
                class="mx-1 badge badge-pill badge-grey">HU</span>
          <span *ngIf="mr.needs_hu && !mr.has_hu && mr.ordered_hu" title="HU bestellt"
                class="mx-1 badge badge-pill badge-ordered">HU</span>
          <span *ngIf="mr.needs_hu && mr.has_hu" title="HU benötigt und vorhanden"
                class="mx-1 badge badge-pill badge-success">HU</span>
          <span *ngIf="!mr.needs_hu && mr.has_hu" title="HU nicht benötigt, aber vorhanden"
                class="mx-1 badge badge-pill badge-primary">HU</span>
          <span *ngIf="mr.needs_hu && mr.has_hu_in_akte" title="HU benötigt und in Akte vorhanden"
                class="mx-1 badge badge-pill badge-in-akte">HU</span>
          <span *ngIf="!mr.needs_hu && mr.has_hu_in_akte" title="HU nicht benötigt, aber in Akte vorhanden"
                class="mx-1 badge badge-pill badge-in-akte">HU</span>
        </ng-container>
        <span *ngIf="person.urkunden_vorhanden?.urkunden_count && person.urkunden_vorhanden?.urkunden_count > 0" [title]="'Liste der vorhandenenen ' + person.urkunden_vorhanden?.urkunden_count + ' Urkunden anzeigen'" class="mx-1 badge badge-pill badge-primary"><span class="fa fa-search"></span> {{person.urkunden_vorhanden.urkunden_count}}</span>
      </div>
      <ng-container *ngIf="treeViewMode !== 'show-extra-info'">
        <div *ngIf="personDisplayData.kommentar_beruf" class="comment-div" (dblclick)="editComment('kommentar_beruf')">
          {{personDisplayData.kommentar_beruf}}
        </div>
        <div *ngIf="!personDisplayData.kommentar_beruf" class="comment-div-placeholder"
             (dblclick)="editComment('kommentar_beruf')">
          Kommentar Beruf <span class="fa fa-sm fa-pencil"></span>
        </div>
        <div *ngIf="personDisplayData.kommentar_ab_recherche" class="comment-div"
             (dblclick)="editComment('kommentar_ab_recherche')">
          {{personDisplayData.kommentar_ab_recherche}}
        </div>
        <div *ngIf="!personDisplayData.kommentar_ab_recherche" class="comment-div-placeholder"
             (dblclick)="editComment('kommentar_ab_recherche')">
          Kommentar AB-Recherche <span class="fa fa-sm fa-pencil"></span>
        </div>
        <div *ngIf="personDisplayData.kommentar_urkunden" class="comment-div"
             (dblclick)="editComment('kommentar_urkunden')">
          {{personDisplayData.kommentar_urkunden}}
        </div>
        <div *ngIf="!personDisplayData.kommentar_urkunden" class="comment-div-placeholder"
             (dblclick)="editComment('kommentar_urkunden')">
          Kommentar Urkunden <span class="fa fa-sm fa-pencil"></span>
        </div>
        <div *ngIf="personDisplayData.kommentar" class="comment-div-last" (dblclick)="editComment('kommentar')">
          {{personDisplayData.kommentar}}
        </div>
        <div *ngIf="!personDisplayData.kommentar" class="comment-div-placeholder" (dblclick)="editComment('kommentar')">
          Kommentar sonstiges<span class="fa fa-sm fa-pencil"></span>
        </div>
        <div class="comment-div"></div>
        <div *ngIf="displayVuVSend()" class="comment-div-no-underline">
          V+V verschickt {{printVuVSendDatum()}}
        </div>
        <div *ngIf="displayVuVSigned()" class="comment-div-no-underline">
          V+V unterschrieben {{printVuVSignedDatum()}}
        </div>
      </ng-container>
      <ng-container *ngIf="treeViewMode === 'show-extra-info'">
        <div *ngIf="personDisplayData.erbquote">
          <div class="show-erbquote" [ngStyle]="{'background-color': personDisplayData.erbquote_display === 'Erbe'? 'greenyellow': 'lightblue'}">
            <div>{{personDisplayData.erbquote | number:'1.2-2'}}% {{personDisplayData.erbquote_display}}</div>
            <div>={{fraction(personDisplayData.erbquote)}} des Erbes</div>
          </div>
        </div>
        <div *ngIf="urkunden_for_display.length > 0">
          <b>Urkunden:</b>
          <div *ngFor="let urkunde of urkunden_for_display" class="urkunden-comment">
            <b>{{getUrkundenUnterArtText(urkunde)}}</b> - Nr.: {{urkunde.urkunden_nummer}} - Jahr: {{urkunde.jahr}}
            <div *ngIf="urkunde.standesamt" title="Standesamt">
              SA: {{urkunde.standesamt?.name}}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </xhtml:div>
</svg:foreignObject>

<svg:foreignObject [attr.x]="personDisplayData.displayParams.x"
                   [attr.y]="personDisplayData.displayParams.y + height - heightOfControlButtons"
                   [attr.width]="width" [attr.height]="heightOfControlButtons">
  <xhtml:div>
    <ng-container *ngIf="!hidePerson()">

      <ng-container *ngIf="treeViewMode === 'edit-structure' && !mapOnly">
        <div class="button-container">
          <button class="btn btn-sm btn-default btn-edi" (click)="moveLeft()"><span class="fa fa-lg icon-edi fa-arrow-left"></span></button>
          <button *ngIf="!personDisplayData.no_more_partners && !person.is_duplicate_of"
                  class="btn btn-sm btn-default btn-edi" (click)="addEhe()">
            <span class="fa fa-lg icon-edi fa-circle-thin"></span>
            <span class="fa fa-lg icon-edi fa-circle-thin"></span>
          </button>
          <button *ngIf="personDisplayData.no_more_partners || person.is_duplicate_of"
                  class="btn btn-sm btn-default btn-edi">
            <span class="hidden fa fa-lg icon-edi fa-circle-thin"></span>
            <span class="hidden fa fa-lg icon-edi fa-circle-thin"></span>
          </button>
          <button *ngIf="displayAddChildrenButton()" class="btn btn-sm btn-default btn-edi"><span
            class="fa fa-lg icon-edi fa-user-circle"
            (click)="addChild()"></span></button>
          <button *ngIf="!displayAddChildrenButton()" class="hidden btn btn-sm btn-default btn-edi"><span
            class="fa fa-lg icon-edi fa-user-circle"></span></button>
          <button class="btn btn-sm btn-default btn-edi" (click)="moveRight()"><span class="fa fa-lg icon-edi fa-arrow-right"></span></button>
        </div>
      </ng-container>

      <ng-container *ngIf="treeViewMode === 'morph-structure' && !mapOnly">
        <div class="button-container">
          <button *ngIf="!person.has_duplicate" class="btn btn-sm btn-default btn-edi" (click)="exchangePerson()"><span
            class="fa fa-lg icon-edi fa-exchange" ></span></button>
          <button *ngIf="person.has_duplicate" class="hidden btn btn-sm btn-default btn-edi"><span
            class="fa fa-lg icon-edi fa-exchange"></span>
          </button>
          <button *ngIf="personDisplayData?.marriages_for_stammbaum_person?.length === 1"
                  class="btn btn-sm btn-default btn-cont" (click)="exchangeMarriage()"><span
            class="fa fa-lg icon-cont fa-venus-mars"></span></button>
          <button *ngIf="personDisplayData?.marriages_for_stammbaum_person?.length !== 1"
                  class="btn btn-sm btn-default btn-cont hidden"><span class="fa fa-lg icon-cont fa-venus-mars"></span>
          </button>
          <button class="hidden btn btn-sm btn-default btn-edi"><span class="fa fa-lg icon-edi fa-arrow-left"></span>
          </button>
          <button class="hidden btn btn-sm btn-default btn-edi"><span class="fa fa-lg icon-edi fa-arrow-left"></span>
          </button>
          <button *ngIf="person.is_deletable" class="btn btn-sm btn-default btn-edi" (click)="delete()"><span
            class="fa fa-lg icon-edi fa-trash"></span></button>
          <button *ngIf="!person.is_deletable" class="hidden btn btn-sm btn-default btn-edi"><span
            class="fa fa-lg icon-edi fa-trash"></span></button>
        </div>
      </ng-container>

      <ng-container *ngIf="treeViewMode === 'edit-person-data' && !mapOnly">
        <div class="button-container">
          <button class="btn btn-sm btn-default btn-cont" (click)="editPersonData()"><span class="fa fa-lg icon-cont fa-edit"></span></button>
          <button *ngIf="personDisplayData?.marriages_for_stammbaum_person?.length > 0" (click)="editMarriageData()"
                  class="btn btn-sm btn-default btn-cont"><span class="fa fa-lg icon-cont fa-venus-mars"></span></button>
          <button *ngIf="personDisplayData?.marriages_for_stammbaum_person?.length === 0"
                  class="btn btn-sm btn-default btn-cont hidden"><span class="fa fa-lg icon-cont fa-venus-mars"></span>
          </button>
          <button class=" btn btn-sm btn-default btn-cont" (click)="uploadUrkunden()"><span class="fa fa-lg icon-cont fa-upload"></span></button>
          <button class="btn btn-sm btn-default btn-cont" (click)="showUrkunden()"><span class="fa fa-lg icon-cont fa-picture-o"></span></button>
        </div>
      </ng-container>

      <ng-container *ngIf="treeViewMode === 'show-extra-info' && !mapOnly">
        <div class="show-eo">{{personDisplayData.erbordnung}}</div>
      </ng-container>
    </ng-container>

  </xhtml:div>
</svg:foreignObject>




/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kassenbuch-for-main-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../kassenbuch-list-for-main/kassenbuch-list-for-main.component.ngfactory";
import * as i3 from "../kassenbuch-list-for-main/kassenbuch-list-for-main.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../kassenbuch.service";
import * as i6 from "../../services/user.service";
import * as i7 from "../kassenbuch-stats/kassenbuch-stats.component.ngfactory";
import * as i8 from "../kassenbuch-stats/kassenbuch-stats.component";
import * as i9 from "../kassenbuch-menu-for-main/kassenbuch-menu-for-main.component.ngfactory";
import * as i10 from "../kassenbuch-menu-for-main/kassenbuch-menu-for-main.component";
import * as i11 from "@angular/common";
import * as i12 from "./kassenbuch-for-main-menu.component";
var styles_KassenbuchForMainMenuComponent = [i0.styles];
var RenderType_KassenbuchForMainMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KassenbuchForMainMenuComponent, data: {} });
export { RenderType_KassenbuchForMainMenuComponent as RenderType_KassenbuchForMainMenuComponent };
function View_KassenbuchForMainMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-list-for-main", [], null, null, null, i2.View_KassenbuchListForMainComponent_0, i2.RenderType_KassenbuchListForMainComponent)), i1.ɵdid(1, 245760, null, 0, i3.KassenbuchListForMainComponent, [i4.NgbModal, i5.KassenbuchService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_KassenbuchForMainMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-stats", [], null, null, null, i7.View_KassenbuchStatsComponent_0, i7.RenderType_KassenbuchStatsComponent)), i1.ɵdid(1, 245760, null, 0, i8.KassenbuchStatsComponent, [i5.KassenbuchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_KassenbuchForMainMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kassenbuch"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-kassenbuch-menu-for-main", [], null, null, null, i9.View_KassenbuchMenuForMainComponent_0, i9.RenderType_KassenbuchMenuForMainComponent)), i1.ɵdid(3, 245760, null, 0, i10.KassenbuchMenuForMainComponent, [i5.KassenbuchService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchForMainMenuComponent_1)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchForMainMenuComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = (_co.subpage === "open-positions"); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.subpage === "stats"); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_KassenbuchForMainMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-for-main-menu", [], null, null, null, View_KassenbuchForMainMenuComponent_0, RenderType_KassenbuchForMainMenuComponent)), i1.ɵdid(1, 245760, null, 0, i12.KassenbuchForMainMenuComponent, [i5.KassenbuchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KassenbuchForMainMenuComponentNgFactory = i1.ɵccf("app-kassenbuch-for-main-menu", i12.KassenbuchForMainMenuComponent, View_KassenbuchForMainMenuComponent_Host_0, {}, {}, []);
export { KassenbuchForMainMenuComponentNgFactory as KassenbuchForMainMenuComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addressbook-detail-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./addressbook-detail-popup.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../services/addressbook.service";
import * as i6 from "@ngrx/store";
var styles_AddressbookDetailPopupComponent = [i0.styles];
var RenderType_AddressbookDetailPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressbookDetailPopupComponent, data: {} });
export { RenderType_AddressbookDetailPopupComponent as RenderType_AddressbookDetailPopupComponent };
function View_AddressbookDetailPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institution.name; _ck(_v, 1, 0, currVal_0); }); }
function View_AddressbookDetailPopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.additional_address_information; _ck(_v, 1, 0, currVal_0); }); }
function View_AddressbookDetailPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.additional_address_information != ""); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.street; var currVal_1 = _v.context.$implicit.housenumber; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.zipcode; var currVal_4 = _v.context.$implicit.town; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = _co.printAddressRegion(_v.context.$implicit.region); _ck(_v, 7, 0, currVal_5); }); }
function View_AddressbookDetailPopupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printContactType(_v.context.$implicit.type); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_2); }); }
function View_AddressbookDetailPopupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institution.contacts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookDetailPopupComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printContactType(_v.context.$implicit.type); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_2); }); }
function View_AddressbookDetailPopupComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_10)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.contacts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookDetailPopupComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.context.$implicit.contacts; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.first_name; var currVal_1 = _v.context.$implicit.last_name; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.function; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_3); }); }
function View_AddressbookDetailPopupComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card px-2 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institution.persons; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookDetailPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card px-2 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_3)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_7)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.institution.addresses; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.institution.contacts; _ck(_v, 11, 0, currVal_3); var currVal_4 = (((_co.institution.persons == null) ? null : _co.institution.persons.length) > 0); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printInstitutionType(_co.institution.type); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.institution.comment; _ck(_v, 7, 0, currVal_1); }); }
function View_AddressbookDetailPopupComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-2 btn btn-outline-danger pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["bearbeiten"]))], null, null); }
export function View_AddressbookDetailPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "m-2 btn btn-success pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schlie\u00DFen"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookDetailPopupComponent_11)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institution; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.institution; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.institution; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_AddressbookDetailPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-detail-popup", [], null, null, null, View_AddressbookDetailPopupComponent_0, RenderType_AddressbookDetailPopupComponent)), i1.ɵdid(1, 245760, null, 0, i3.AddressbookDetailPopupComponent, [i4.NgbActiveModal, i5.AddressbookService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressbookDetailPopupComponentNgFactory = i1.ɵccf("addressbook-detail-popup", i3.AddressbookDetailPopupComponent, View_AddressbookDetailPopupComponent_Host_0, {}, {}, []);
export { AddressbookDetailPopupComponentNgFactory as AddressbookDetailPopupComponentNgFactory };

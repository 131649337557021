/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-detail-max-files.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../files/file-upload/file-upload-list/file-upload-list.component.ngfactory";
import * as i3 from "../../files/file-upload/file-upload-list/file-upload-list.component";
import * as i4 from "../../services/file-upload.service";
import * as i5 from "../../services/user.service";
import * as i6 from "../../services/rights-management.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../services/constants.service";
import * as i10 from "@angular/common";
import * as i11 from "./case-detail-max-files.component";
import * as i12 from "../../services/case.service";
import * as i13 from "@angular/router";
import * as i14 from "../../routing/routing.service";
var styles_CaseDetailMaxFilesComponent = [i0.styles];
var RenderType_CaseDetailMaxFilesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseDetailMaxFilesComponent, data: {} });
export { RenderType_CaseDetailMaxFilesComponent as RenderType_CaseDetailMaxFilesComponent };
function View_CaseDetailMaxFilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["filesRef", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "file-upload-list", [], null, null, null, i2.View_FileUploadListComponent_0, i2.RenderType_FileUploadListComponent)), i1.ɵdid(2, 245760, null, 0, i3.FileUploadListComponent, [i4.FileUploadService, i5.UserService, i6.RightsManagementService, i7.Store, i8.NgbModal, i9.ConstantsService], { componentHeadline: [0, "componentHeadline"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_0 = "Urkunden und Dokumente"; var currVal_1 = "case"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CaseDetailMaxFilesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { personsRef: 0 }), i1.ɵqud(671088640, 2, { filesRef: 0 }), i1.ɵqud(671088640, 3, { notesRef: 0 }), i1.ɵqud(671088640, 4, { topRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxFilesComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copiedActiveCase; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CaseDetailMaxFilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-detail-max-files", [], null, null, null, View_CaseDetailMaxFilesComponent_0, RenderType_CaseDetailMaxFilesComponent)), i1.ɵdid(1, 4440064, null, 0, i11.CaseDetailMaxFilesComponent, [i12.CaseService, i13.Router, i7.Store, i6.RightsManagementService, i14.RoutingService, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseDetailMaxFilesComponentNgFactory = i1.ɵccf("case-detail-max-files", i11.CaseDetailMaxFilesComponent, View_CaseDetailMaxFilesComponent_Host_0, { printablePersongroups: "printablePersongroups", ui: "ui" }, {}, []);
export { CaseDetailMaxFilesComponentNgFactory as CaseDetailMaxFilesComponentNgFactory };

<div class="m-2 p-2">
  <h3>Tastatur-Kürzel</h3>
  <table class="table m-2">
    <tr><td><kbd>&#8592;</kbd></td><td>im Menü einen Eintrag nach links wechseln</td></tr>
    <tr><td><kbd>&#8594;</kbd></td><td>im Menü einen Eintrag nach rechts wechseln</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>G</kbd></td><td>direkt zu den <b>G</b>runddaten</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>N</kbd></td><td>direkt zu den <b>N</b>otizen</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>S</kbd></td><td>direkt zum <b>S</b>tammbaum</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>P</kbd></td><td>direkt zu den (Stammbaum-) <b>P</b>ersonen</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>E</kbd></td><td>direkt zu den <b>E</b>rben</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>T</kbd></td><td>direkt zu den Namens-<b>T</b>rägern</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>D</kbd></td><td>direkt zu den <b>D</b>okumenten</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>M</kbd></td><td>direkt zu den <b>M</b>itarbeitern des Falls</td></tr>
    <tr><td><kbd>Alt</kbd> + <kbd>B</kbd></td><td>direkt zur E<b>B</b>S-Dokumentation des Falls</td></tr>
  </table>
  <button class="mx-2 btn btn-sm btn-success" (click)="close()">schliessen</button>
</div>

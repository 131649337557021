<h3>
  NVZ-Suche
  <button *ngIf="isAllowed()" (click)="editMode()" class="mx-2 btn btn-sm btn-warning">wechseln zum Bearbeitungsmodus</button>
  <button (click)="showZufallsfunde()" class="mx-2 btn btn-sm btn-primary">Zufallsfunde anzeigen</button>
  <button *ngIf="isAllowedAutoparser()" (click)="goToAutoparser()" class="mx-2 btn btn-sm btn-danger">zum Autoparser</button>
</h3>
<h4>Suche nach Einträgen (max. 1000 Treffer werden angezeigt)</h4>
<form [formGroup]="form" class="form-horizontal">
  <div class="form-group row">
    <label for="alle_namen" class="col-sm-3 control-label">Nachname, Geburtsname oder Fuzzi (enthält)</label>
    <div class="col-sm-9">
      <input #alle_namen formControlName="alle_namen" type="text" id="alle_namen" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="nachname" class="col-sm-3 control-label">Nachname (enthält)</label>
    <div class="col-sm-9">
      <input #nachname formControlName="nachname" type="text" id="nachname" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="geburtsname" class="col-sm-3 control-label">Geburtsname (enthält)</label>
    <div class="col-sm-9">
      <input #geburtsname formControlName="geburtsname" type="text" id="geburtsname" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="fuzzi" class="col-sm-3 control-label">Fuzzi (enthält)</label>
    <div class="col-sm-9">
      <input #fuzzi formControlName="fuzzi" type="text" id="fuzzi" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="vorname" class="col-sm-3 control-label">Vorname (enthält)</label>
    <div class="col-sm-9">
      <input #vorname formControlName="vorname" type="text" id="vorname" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="standesamt" class="col-sm-3 control-label">Standesamt (enthält)</label>
    <div class="col-sm-9">
      <input #standesamt formControlName="standesamt" type="text" id="standesamt" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="stadt" class="col-sm-3 control-label">Stadt (enthält)</label>
    <div class="col-sm-9">
      <input #stadt formControlName="stadt" type="text" id="stadt" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="art" class="col-sm-3 control-label">Urkunden-Art</label>
    <div class="col-sm-9">
      <select #art formControlName="art" id="art" class="form-control">
        <option value="-1">---alle Urkundenarten---</option>
        <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="juedisch" class="col-sm-3 control-label">Jüdisch</label>
    <div class="col-sm-9">
      <select #juedisch formControlName="juedisch" id="juedisch" class="form-control">
        <option value="-1">---alles---</option>
        <option value="1">nur jüdische Personen</option>
        <option value="0">nur nicht-jüdische Personen</option>
      </select>
    </div>
  </div>
  <button type="button" (click)="search()" class="btn btn-success btn-sm m-2">suchen</button>
</form>

<h3 class="mt-2">Suchergebnisse</h3>
<div *ngIf="search_done">
  <table  class="table table-hover table-striped table-sm" *ngIf="search_results&&search_results.length>0">
    <tr>
      <th>Standesamt</th>
      <th>Urkunden-Art</th>
      <th>Jahrgang</th>
      <th>Urkunden-Nr.</th>
      <th>Nachname</th>
      <th>Fuzzi</th>
      <th>Geburtsname</th>
      <th>Vorname</th>
      <th>jüd.</th>
    </tr>
    <tr *ngFor="let eintrag of search_results">
      <td>{{eintrag.verzeichnis_id.standesamt_id.name}}</td>
      <td>{{showVerzeichnisArt(eintrag.verzeichnis_id.art)}}</td>
      <td>{{eintrag.verzeichnis_id.jahrgang}}</td>
      <td>{{eintrag.urkunden_nr}}</td>
      <td>{{eintrag.nachname}}</td>
      <td>{{eintrag.fuzzi}}</td>
      <td>{{eintrag.geburtsname}}</td>
      <td>{{eintrag.vorname}}</td>
      <td *ngIf="eintrag.juedisch" (click)="selectEintrag(eintrag)"><i class="fa fa-check"></i></td>
      <td *ngIf="!eintrag.juedisch" (click)="selectEintrag(eintrag)"><i class="fa fa-times"></i></td>
    </tr>
  </table>
  <div *ngIf="!search_results || search_results.length == 0">keine Suchergebnisse vorhanden</div>
</div>
<div *ngIf="!search_done">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>




/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calendar-notification.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/calendar-entry.service";
import * as i7 from "../../services/user.service";
var styles_CalendarNotificationComponent = [i0.styles];
var RenderType_CalendarNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarNotificationComponent, data: {} });
export { RenderType_CalendarNotificationComponent as RenderType_CalendarNotificationComponent };
function View_CalendarNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " von"])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.deadline_timestamp, "dd.MM.y")); var currVal_1 = _co.calendarEntryService.convertTimeForOutputWithText(_v.context.$implicit.deadline_hour, _v.context.$implicit.deadline_minute); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.userService.getAbbreviationForUser(_v.context.$implicit.created_by_user_id); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); }); }
function View_CalendarNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarNotificationComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarEntriesOwn; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " f\u00FCr"])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.deadline_timestamp, "dd.MM.y")); var currVal_1 = _co.calendarEntryService.convertTimeForOutputWithText(_v.context.$implicit.deadline_hour, _v.context.$implicit.deadline_minute); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.userService.getAbbreviationForUser(_v.context.$implicit.for_user_id); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); }); }
function View_CalendarNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarNotificationComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarEntriesOther; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CalendarNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card-deck my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Eigene f\u00E4llige Aufgaben"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarNotificationComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["F\u00E4llige Aufgaben f\u00FCr andere"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarNotificationComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.calendarEntriesOwn == null) ? null : _co.calendarEntriesOwn.length) > 0); _ck(_v, 6, 0, currVal_0); var currVal_1 = (((_co.calendarEntriesOther == null) ? null : _co.calendarEntriesOther.length) > 0); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_CalendarNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "calendar-notification", [], null, null, null, View_CalendarNotificationComponent_0, RenderType_CalendarNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i3.CalendarNotificationComponent, [i4.Store, i5.NgbModal, i6.CalendarEntryService, i7.UserService], null, null)], null, null); }
var CalendarNotificationComponentNgFactory = i1.ɵccf("calendar-notification", i3.CalendarNotificationComponent, View_CalendarNotificationComponent_Host_0, {}, {}, []);
export { CalendarNotificationComponentNgFactory as CalendarNotificationComponentNgFactory };

<h5>Statistische Auswertung</h5>

<div *ngIf="statsAggregated">
  <ngx-charts-pie-chart
    [results]="statsAggregated"
    [gradient]="gradient"
    [legend]="showLegend"
    [doughnut]="doughnut"
    [labels]="labels"
    (select)="onSelect($event)">
  </ngx-charts-pie-chart>
</div>

<table class="table table-sm">
  <tr *ngFor="let r of stats">
    <td>{{r.name}}</td>
    <td>€ {{r.value | number: '1.2-2'}}</td>
    <td>{{r.value * 100 / total | number: '1.2-2'}} %</td>
  </tr>
  <tr>
    <th>Gesamtbetrag</th>
    <th>€ {{total | number: '1.2-2'}}</th>
    <th></th>
  </tr>
</table>

<div *ngIf="!heirlist || (heirlist.length == 0)">
  <i class="fa fa-cog fa-spin"></i> loading...
</div>

<div *ngIf="heirlist && (heirlist.length > 0)" class="heirs-for-case">
  <table class="table table-sm">
    <tr>
      <th title="Personen-ID">ID</th>
      <th title="Vorname">Vorname</th>
      <th title="Nachname">Nachname</th>
      <th title="Personenstatus">Status</th>
      <th title="angerufen">Tel</th>
      <th title="angeschrieben">Brief</th>
      <th title="Prozente">%</th>
      <th title="Vertrag">VT</th>
      <th title="Vollmacht">VM</th>
      <th title="beglaubigter Personalausweis">PA</th>
      <th title="Erbnachweis">EN</th>
    </tr>
    <tr *ngFor="let heir of heirlist">
      <td>{{heir.id}}</td>
      <td>{{heir.vorname}}</td>
      <td>{{heir.nachname}}</td>
      <td>{{getPersonTypeText(heir.person_type)}}</td>
      <td><show-boolean-value [value]="heir.phone_call_made"></show-boolean-value></td>
      <td><editfield-date *ngIf="heir.contract_send_out_date" [(date)]="heir.contract_send_out_date" [editMode]="false"></editfield-date></td>
      <td>{{heir.contract_send_out_percentage}}</td>
      <td><editfield-date *ngIf="heir.signature_date" [(date)]="heir.signature_date" [editMode]="false"></editfield-date></td>
      <td><show-boolean-value [value]="heir.mandate"></show-boolean-value></td>
      <td><show-boolean-value [value]="heir.identity_card"></show-boolean-value></td>
      <td><show-boolean-value [value]="heir.inheritance_proof"></show-boolean-value></td>
    </tr>
  </table>
</div>


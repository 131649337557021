<div class="modal-header">
  <h4 class="modal-title ilatitle">
    Zahlung vornehmen
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">Wollen Sie diese Kostenposition als bezahlt kennzeichnen?</div>
      <div class="col-12 custom-control-label">Zahlungsmethode</div>
      <div class="col-12">
        <select class="form-control" formControlName="method">
          <option *ngFor="let op of paymentOptions" [value]="op.id">{{op.hint}}</option>
        </select>
      </div>
      <div class="col-12 custom-control-label">Vorgangs-Status auf abgeschlossen setzen</div>
      <div class="col-12">
        <select class="form-control" formControlName="done">
          <option [value]="1">ja</option>
          <option [value]="0">nein</option>
        </select>
      </div>
      <div class="col-12 custom-control-label">Zahlungsdatum</div>
      <div class="col-12"><input type="date" class="form-control" formControlName="datum" id="datum"></div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="m-2 btn btn-sm btn-danger" (click)="cancel()">abbrechen</button>
  <button type="button" class="m-2 btn btn-sm btn-success" [disabled]="form.invalid" (click)="confirm()">als bezahlt
    setzen
  </button>
</div>

<div class="card my-3">
  <div class="card-body">
    <h4 *ngIf="!copiedUser">Der Datensatz konnte nicht gefunden werden <button (click)="backToList()" class="btn btn-sm btn-success">zurück zur Listenansicht</button></h4>
    <h4 *ngIf="copiedUser">Mitarbeiter {{copiedUser.first_name}} {{copiedUser.last_name}} <button (click)="backToList()" class="btn btn-sm btn-success">zurück zur Listenansicht</button></h4>
    <div class="table-responsive" *ngIf="copiedUser">
      <table class="table table-bordered table-striped">
        <tr *ngIf="!editMode"><td><button class="btn btn-sm btn-warning" (click)="editUser()">bearbeiten</button> <button class="btn btn-sm btn-danger" (click)="newPwd()">neues Passwort zusenden</button></td><td></td></tr>
        <tr *ngIf="editMode"><td><button class="btn btn-sm btn-info" (click)="cancelEdit()">abbrechen</button> <button class="btn btn-sm btn-danger" (click)="save()">speichern</button></td><td></td></tr>
        <tr><td><h5>Grunddaten</h5></td><td></td></tr>
        <tr><td>Vorname</td><td><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="copiedUser.first_name"></editfield-text></td></tr>
        <tr><td>Nachname</td><td><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="copiedUser.last_name"></editfield-text></td></tr>
        <tr><td>Benutzername</td><td><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="copiedUser.username"></editfield-text></td></tr>
        <tr><td>Kürzel</td><td><editfield-text [maxLength]="10" [editMode]="editMode" [(text)]="copiedUser.abbreviation"></editfield-text></td></tr>
        <tr><td>Email</td><td><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="copiedUser.email"></editfield-text></td></tr>
        <tr><td>Handy</td><td><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="copiedUser.mobile"></editfield-text></td></tr>
        <tr><td>Benutzerrolle</td><td><editfield-select [options]="userRoles" [editMode]="editMode" [(select)]="copiedUser.userrole.id"></editfield-select></td></tr>
        <tr><td>Aktiv</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_active" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><h5>Benutzer-Einstellungen</h5></td><td></td></tr>
        <tr><td>Notiz-Antworten aufsteigend sortieren</td><td><editfield-checkbox [(checkbox)]="copiedUser.note_reply_sort_ascending" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><h5>Individuelle Zusatz-Rechte</h5></td><td></td></tr>
        <tr><td><b>Mitarbeiter</b></td><td></td></tr>
        <tr><td>Senior zuweisen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_assign_senior" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Fälle</b></td><td></td></tr>
        <tr><td>neue Fälle anlegen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_create_new_cases" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Zugang zu allen Fällen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_all_cases" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Zugang zur Fall-Liste</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_case_list" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Zugang zu allen Fällen mit Status "Sachstandsanfrage"</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_cases_status_sachstandsanfrage" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Zugang zu allen Fällen mit Status "EBS-Dokumentation"</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_cases_status_ebs_dokumentation" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Benutzung Fall-Pool <b>sperren</b></td><td><editfield-checkbox [(checkbox)]="copiedUser.is_excluded_from_pool" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>darf alle Statuswechsel durchführen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_do_all_status_changes" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Team</b></td><td></td></tr>
        <tr><td>Team-Mitglied von Fall entfernen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_remove_member_from_case" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Dokumente</b></td><td></td></tr>
        <tr><td>Dokumente löschen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_delete_files" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Bundesanzeiger</b></td><td></td></tr>
        <tr><td>Bundesanzeiger-Modul verwenden</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_use_module_bundesanzeiger" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Namensverzeichnisse</b></td><td></td></tr>
        <tr><td>NVZ-Modul verwenden</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_use_nvz" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Bearbeitungsmodus für eigene NVZ-Einträge verwenden</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_edit_nvz" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>alle NVZ-Einträge bearbeiten</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_edit_all_nvz_entries" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Bearbeitungsrechte für NVZ-Verzeichnisse verwalten</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_manage_nvz_edit_rights" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Adressbuch</b></td><td></td></tr>
        <tr><td>darf Adressbuch verwenden</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_addressbook" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Benutzer</b></td><td></td></tr>
        <tr><td>alle Benutzerdaten einsehen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_see_all_user_data" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Kassenbuch</b></td><td></td></tr>
        <tr><td>Kassenbuch in Fall-Tab benutzen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_use_kassenbuch_in_case_tab" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Auskehr</b></td><td></td></tr>
        <tr><td>Auskehr in Fall-Tab benutzen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_use_auskehr_in_case_tab" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Benachrichtigungen</b></td><td></td></tr>
        <tr><td>Verlauf beschränken auf Fälle mit aktiver Teammitgliedschaft</td><td><editfield-checkbox [(checkbox)]="copiedUser.limit_automail_to_own_teammember_cases" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Automail wenn Erbe angelegt (alle Fälle)</td><td><editfield-checkbox [(checkbox)]="copiedUser.receives_automail_on_new_heir_created" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Automail wenn V+V verschickt (alle Fälle)</td><td><editfield-checkbox [(checkbox)]="copiedUser.receives_automail_on_v_and_v_sendout" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Automail wenn V+V unterschrieben (alle Fälle)</td><td><editfield-checkbox [(checkbox)]="copiedUser.receives_automail_on_v_and_v_signed" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Automail für alle Fälle mit Status "EBS-Dokumentation"</td><td><editfield-checkbox [(checkbox)]="copiedUser.receives_automail_for_cases_with_status_ebs_dokumentation" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td>Automail bei Neuanlage von Standesämtern</td><td><editfield-checkbox [(checkbox)]="copiedUser.receives_automail_on_new_standesamt_created" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Mailversand</b></td><td></td></tr>
        <tr><td>darf Mailversand-Tool benutzen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_send_mass_mails" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>ESA-Tool</b></td><td></td></tr>
        <tr><td>darf ESA erstellen</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_allowed_to_create_esa" [editMode]="editMode"></editfield-checkbox></td></tr>
        <tr><td><b>Spezialeinstellungen</b></td><td></td></tr>
        <tr><td>Developer-Account</td><td><editfield-checkbox [(checkbox)]="copiedUser.is_developer_account" [editMode]="editMode"></editfield-checkbox></td></tr>
      </table>
    </div>
  </div>
</div>




<div class="modal-header">
  <h4 *ngIf="!edit_mode" class="modal-titel">
    Notiz mit Kalendereintrag anlegen
  </h4>
  <h4 *ngIf="edit_mode">
    Kalendereintrag zur Notiz aktualisieren
  </h4>
</div>
<div class="modal-body">
  <h5 *ngIf="edit_mode">Erinnerung: <span *ngIf="deadline_hour">{{deadline_display | date: 'dd.MM.y,HH:mm'}}</span> <span *ngIf="!deadline_hour">{{deadline_display | date: 'dd.MM.y'}}</span></h5>
  <form [formGroup]="calendarEntryForm" class="form-horizontal">
    <div class="form-group row">
      <label class="col-sm-2 control-label">Datum</label>
      <div class="col-sm-10">
        <ngb-datepicker #dp formControlName="deadlineTimestamp" id="deadlineTimestamp"></ngb-datepicker>
        <span class="m-3">{{deadline | date: 'dd.MM.y'}}</span>
        <span *ngIf="calendarEntryForm.get('deadlineTimestamp').invalid && calendarEntryForm.get('deadlineTimestamp').touched" class="help-block">Bitte ein gültiges Datum für den Termin angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 control-label">Uhrzeit</label>
      <div class="col-sm-10">
        <ngb-timepicker #dp formControlName="deadlineTimestampTime" id="deadlineTimestampTime"></ngb-timepicker>
        <span *ngIf="calendarEntryForm.get('deadlineTimestampTime').invalid && calendarEntryForm.get('deadlineTimestampTime').touched" class="help-block">Bitte eine gültige Uhrzeit für den Termin angeben!</span>
      </div>
    </div>

    <div *ngIf="!edit_mode" class="form-group row">
      <label for="forUserId" class="col-sm-2 control-label">Termin für Benutzer</label>
      <div class="col-sm-10">
        <select id="forUserId" formControlName="forUserId" class="form-control">
          <option *ngFor="let user of useropts" [value]="user.value">
            {{user.text}}
          </option>
        </select>
      </div>
    </div>

  </form>
</div>

<button class="btn btn-sm my-1 btn-primary mx-1" (click)="close()">Schließen</button>
<button *ngIf="!edit_mode" [disabled]="!calendarEntryForm.valid" class="btn btn-sm my-1 btn-success mx-1" (click)="create()">Anlegen</button>
<button *ngIf="edit_mode" [disabled]="!calendarEntryForm.valid" class="btn btn-sm my-1 btn-success mx-1" (click)="create()">Aktualisieren</button>
<button *ngIf="edit_mode" (click)="delete()" class="btn btn-sm btn-danger my-1 mx-1">Kalendereintrag löschen</button>

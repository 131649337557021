/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-detail-max-notes.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../notes/note-list-max/note-list-max.component.ngfactory";
import * as i5 from "../../notes/note-list-max/note-list-max.component";
import * as i6 from "../../services/note.service";
import * as i7 from "../../services/rights-management.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../services/user.service";
import * as i10 from "../../services/calendar-entry.service";
import * as i11 from "@ngrx/store";
import * as i12 from "./case-detail-max-notes.component";
import * as i13 from "../../services/case.service";
import * as i14 from "@angular/router";
import * as i15 from "../../routing/routing.service";
var styles_CaseDetailMaxNotesComponent = [i0.styles];
var RenderType_CaseDetailMaxNotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseDetailMaxNotesComponent, data: {} });
export { RenderType_CaseDetailMaxNotesComponent as RenderType_CaseDetailMaxNotesComponent };
function View_CaseDetailMaxNotesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setEditMode(_co.CaseSaveMode.Urkundensuche) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["bearbeiten"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen(_co.CaseDisplayOpenable.Urkundensuche) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], null, null); }
function View_CaseDetailMaxNotesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], null, null); }
function View_CaseDetailMaxNotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkundensuche "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editMode !== _co.CaseSaveMode.Urkundensuche); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.editMode === _co.CaseSaveMode.Urkundensuche); _ck(_v, 5, 0, currVal_1); }, null); }
function View_CaseDetailMaxNotesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "white-space:pre-wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.copiedActiveCase == null) ? null : _co.copiedActiveCase.urkundensuche_text); _ck(_v, 1, 0, currVal_0); }); }
function View_CaseDetailMaxNotesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "textarea", [["class", "form-control"], ["rows", "20"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.copiedActiveCase.urkundensuche_text = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.copiedActiveCase.urkundensuche_text; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_CaseDetailMaxNotesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editMode !== _co.CaseSaveMode.Urkundensuche); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.editMode === _co.CaseSaveMode.Urkundensuche); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CaseDetailMaxNotesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urkundensuchePreview; _ck(_v, 1, 0, currVal_0); }); }
function View_CaseDetailMaxNotesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkundensuche"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen(_co.CaseDisplayOpenable.Urkundensuche) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00F6ffnen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urkundensuchePreview; _ck(_v, 4, 0, currVal_0); }, null); }
function View_CaseDetailMaxNotesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setEditMode(_co.CaseSaveMode.Fallzusammenfassung) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["bearbeiten"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen(_co.CaseDisplayOpenable.Fallzusammenfassung) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], null, null); }
function View_CaseDetailMaxNotesComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], null, null); }
function View_CaseDetailMaxNotesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fall-Zusammenfassung "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_11)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_12)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editMode !== _co.CaseSaveMode.Fallzusammenfassung); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.editMode === _co.CaseSaveMode.Fallzusammenfassung); _ck(_v, 5, 0, currVal_1); }, null); }
function View_CaseDetailMaxNotesComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "white-space:pre-wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.copiedActiveCase == null) ? null : _co.copiedActiveCase.summary_text); _ck(_v, 1, 0, currVal_0); }); }
function View_CaseDetailMaxNotesComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "textarea", [["class", "form-control"], ["rows", "20"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.copiedActiveCase.summary_text = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.copiedActiveCase.summary_text; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_CaseDetailMaxNotesComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_15)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editMode !== _co.CaseSaveMode.Fallzusammenfassung); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.editMode === _co.CaseSaveMode.Fallzusammenfassung); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CaseDetailMaxNotesComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fall-Zusammenfassung"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-1 btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen(_co.CaseDisplayOpenable.Fallzusammenfassung) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00F6ffnen"]))], null, null); }
function View_CaseDetailMaxNotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_10)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_13)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_16)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayUrkundensuche(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayUrkundensuche(); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.displayUrkundensuche(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.displayFallzusammenfassung(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.displayFallzusammenfassung(); _ck(_v, 10, 0, currVal_4); var currVal_5 = !_co.displayFallzusammenfassung(); _ck(_v, 12, 0, currVal_5); }, null); }
function View_CaseDetailMaxNotesComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "note-list-max", [["style", "margin-top: 10px;"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_NoteListMaxComponent_0, i4.RenderType_NoteListMaxComponent)), i1.ɵdid(2, 4964352, null, 0, i5.NoteListMaxComponent, [i6.NoteService, i7.RightsManagementService, i1.ElementRef, i8.NgbModal, i9.UserService, i10.CalendarEntryService, i2.DatePipe, i11.Store, i1.Renderer2], { mode: [0, "mode"], folderId: [1, "folderId"] }, null)], function (_ck, _v) { var currVal_0 = "case"; var currVal_1 = 0; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CaseDetailMaxNotesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxNotesComponent_17)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copiedActiveCase; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.copiedActiveCase; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CaseDetailMaxNotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-detail-max-notes", [], null, null, null, View_CaseDetailMaxNotesComponent_0, RenderType_CaseDetailMaxNotesComponent)), i1.ɵdid(1, 4440064, null, 0, i12.CaseDetailMaxNotesComponent, [i13.CaseService, i14.Router, i11.Store, i7.RightsManagementService, i15.RoutingService, i9.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseDetailMaxNotesComponentNgFactory = i1.ɵccf("case-detail-max-notes", i12.CaseDetailMaxNotesComponent, View_CaseDetailMaxNotesComponent_Host_0, { scrollPosition: "scrollPosition", opened: "opened", printablePersongroups: "printablePersongroups", ui: "ui", editMode: "editMode" }, { saveScrollPosition: "saveScrollPosition", openedChanged: "openedChanged", editModeChanged: "editModeChanged" }, []);
export { CaseDetailMaxNotesComponentNgFactory as CaseDetailMaxNotesComponentNgFactory };

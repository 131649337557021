<ng-container *ngIf="personToUploadFor">
  <stammbaum-urkunden-upload [stammbaumState]="stammbaumState" [hasTrueStammbaum]="false"></stammbaum-urkunden-upload>
</ng-container>
<ng-container *ngIf="!personToUploadFor">
  <div class="card my-3">
    <div class="card-body">
      <form [formGroup]="filterForm" class="form-inline m-1">
        <input formControlName="name" id="name" class="form-inline form-control"
               placeholder="Filtern nach Vor-, Nach- und Geburtsnamen">
        <button *ngIf="!showInactive" class="btn btn-sm btn-success mx-2" (click)="toggleInactive()">alle Personen
          zeigen
        </button>
        <button *ngIf="showInactive" class="btn btn-sm btn-success mx-2" (click)="toggleInactive()">nur aktive Personen
          zeigen
        </button>
        <button class="btn btn-sm btn-success mx-2" (click)="printUrkundenliste()">Urkundenliste als PDF</button>
        <button class="btn btn-sm btn-primary mx-2" (click)="createNewPerson()">neue Person anlegen</button>
      </form>
    </div>
  </div>

  <div class="card my-3">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm">
          <tr>
            <th></th>
            <th>ID</th>
            <th (click)="sortNachname()">Nachname <span [class.sort-active]="sortingNachname" class="fa fa-sort"></span>
            </th>
            <th (click)="sortVorname()">Vornamen <span [class.sort-active]="sortingVorname" class="fa fa-sort"></span>
            </th>
            <th (click)="sortGeburtsname()">Geburtsname <span [class.sort-active]="sortingGeburtsname"
                                                              class="fa fa-sort"></span></th>
            <th>Geb.-Datum</th>
            <th>Geb.-Ort</th>
            <th>Sterbedatum</th>
            <th>Sterbeort</th>
            <th>Ehen</th>
            <th>Urkunden</th>
            <th>Aktionen</th>
          </tr>
          <tr person-for-list-without-version [hasTrueStammbaum]="false" [ngClass]="{'inactive': !person.is_active}" [person]="person"
              *ngFor="let person of stammbaumPersonsToShow"></tr>
        </table>
      </div>
    </div>
  </div>
</ng-container>


  <div class="form-group row">
    <label *ngIf="title" for="forUserId" class="col-sm-2 control-label">{{title}}</label>
    <div class="col-sm-10">
      <select [disabled]="disabled" id="forUserId" [ngModel]="userId" class="form-control" (change)="changed($event)">
        <option *ngFor="let user of users"
          [value]="user.id">
          {{user.abbreviation}}
        </option>
      </select>
    </div>
  </div>
  
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./network-manager-messages.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./network-manager-messages.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_NetworkManagerMessagesComponent = [i0.styles];
var RenderType_NetworkManagerMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetworkManagerMessagesComponent, data: {} });
export { RenderType_NetworkManagerMessagesComponent as RenderType_NetworkManagerMessagesComponent };
function View_NetworkManagerMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "error": 0, "ok": 1, "warning": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.level === 2), (_v.context.$implicit.level === 0), (_v.context.$implicit.level === 1)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.event_timestamp, "dd.MM.y HH:mm:ss")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.text; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.displayLevel(_v.context.$implicit.level); _ck(_v, 10, 0, currVal_3); }); }
export function View_NetworkManagerMessagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Netzwerk-Nachrichten "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetworkManagerMessagesComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schlie\u00DFen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_NetworkManagerMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "network-manager-messages", [], null, null, null, View_NetworkManagerMessagesComponent_0, RenderType_NetworkManagerMessagesComponent)), i1.ɵdid(1, 180224, null, 0, i3.NetworkManagerMessagesComponent, [i4.Store, i5.NgbActiveModal], null, null)], null, null); }
var NetworkManagerMessagesComponentNgFactory = i1.ɵccf("network-manager-messages", i3.NetworkManagerMessagesComponent, View_NetworkManagerMessagesComponent_Host_0, {}, {}, []);
export { NetworkManagerMessagesComponentNgFactory as NetworkManagerMessagesComponentNgFactory };

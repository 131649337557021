<div *ngIf="address">
  <editfield-text [maxLength]="50" [placeholder]="'Straße'" [editMode]="editMode" [(text)]="address.street"></editfield-text>
  <editfield-text class="ml-2" [maxLength]="20" [placeholder]="'Nummer'" [editMode]="editMode" [(text)]="address.house_number"></editfield-text>
  <br *ngIf="!editMode">
  <editfield-text [maxLength]="50" [placeholder]="'Zusatzangaben'" [editMode]="editMode" [(text)]="address.additional_information"></editfield-text>
  <br *ngIf="!editMode&&address.additional_information!=''">
  <editfield-text [maxLength]="10" [placeholder]="'PLZ'" [editMode]="editMode" [(text)]="address.zipcode"></editfield-text>
  <editfield-text class="ml-2" [maxLength]="50" [placeholder]="'Ort'" [editMode]="editMode" [(text)]="address.town"></editfield-text>
  <br *ngIf="!editMode">
  <editfield-text [maxLength]="50" [placeholder]="'Land'" [editMode]="editMode" [(text)]="address.country"></editfield-text>
</div>



<div class="p-2 m-2">

  <h4 class="mb-3">Urkunden
    <span *ngIf="person"> für {{person.nachname}} {{person.vorname}} </span>
  </h4>

  <table *ngIf="urkunden && urkunden.length > 0" class="table-responsive table-sm mt-3">
    <tr>
      <th>Kategorie</th>
      <th>Art</th>
      <th>Nr.</th>
      <th>Jahr</th>
      <th>STA / KI / BEH</th>
      <th>Status</th>
      <th>Datum</th>
      <th>MA</th>
      <th></th>
    </tr>
    <tr *ngFor="let urkunde of urkunden">
      <td *ngIf="!urkunde.refers_to" (click)="showUrkunde(urkunde?.file_ref)" title="klicken um die Urkunde anzuzeigen">{{stammbaumService.getUrkundenArtText(urkunde.urkunden_art)}}</td>
      <td *ngIf="urkunde.refers_to" (click)="showUrkunde(urkunde?.file_ref)" title="klicken um die Urkunde anzuzeigen">{{stammbaumService.getDuplicateText(urkunde)}}</td>
      <td *ngIf="!urkunde.refers_to" (click)="showUrkunde(urkunde?.file_ref)" title="klicken um die Urkunde anzuzeigen">{{stammbaumService.getUrkundenUnterartText(urkunde)}}</td>
      <td *ngIf="urkunde.refers_to" (click)="showUrkunde(urkunde?.file_ref)" title="klicken um die Urkunde anzuzeigen">{{stammbaumService.getDuplicateUnterartText(urkunde)}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)">{{urkunde.urkunden_nummer}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)">{{urkunde.jahr}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)">{{getStaKiBeh(urkunde)}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)">{{stammbaumService.getUrkundenStatusText(urkunde.urkunden_status)}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)">{{urkunde.file_ref.created | date: 'dd.MM.y'}}</td>
      <td (click)="showUrkunde(urkunde?.file_ref)"><span class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(urkunde.file_ref.user_id)}}</span></td>
      <td>
        <span *ngIf="!urkunde.refers_to" (click)="editUrkunde(urkunde)" class="mx-2 fa fa-pencil"></span>
        <span (click)="deleteUrkunde(urkunde)" class="mx-2 fa fa-trash"></span>
      </td>
    </tr>
  </table>

  <div *ngIf="!urkunden || urkunden.length === 0">Keine Urkunden vorhanden</div>

  <button (click)="close()" class="mt-3 btn btn-sm btn-success">schließen</button>

</div>

<div class="main">
  <div class="container">
    <app-notification limitToSlot="NOTIFICATION_SLOT_LOGIN"></app-notification>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-3 mr-4 mb-4 mt-1">
        <img class="logo pull-right" src="assets/ilanot-logo-bg-white.png" alt="iLANOT"/>
      </div>
      <form [formGroup]="loginForm" class="form-horizontal col-md-4" (ngSubmit)="onFormSubmit()">
        <h4 *ngIf="true" class="mb-2">Welcome back.</h4>
        <h4 style="color: darkred;" *ngIf="false" class="mb-2">Der Login zur Datenbank ist erst wieder ab Sa. 19.09. 09:00 Uhr möglich</h4>
        <input autofocus type="text" formControlName="user" id="user" class="form-control form-control-sm my-2" placeholder="Benutzername" />
        <input type="password" formControlName="password" id="password" class="form-control form-control-sm my-2" placeholder="Passwort" />
        <button type="submit" class="btn btn-primary btn-sm my-2">einloggen</button>
        <p *ngIf="!this.constantsService.isTest()" style="font-size: 70%; font-style: italic;">iLANOT DB {{this.constantsService.getApiVersion()}}</p>
        <p *ngIf="this.constantsService.isTest()" style="font-size: 70%; font-style: italic; color: red;">iLANOT DB {{this.constantsService.getApiVersion()}} (TEST @{{this.constantsService.getApiServer()}})</p>
        <p *ngIf="false">
          <b>Hinweise</b><br>
          -<br>
        </p>
      </form>
    </div>
  </div>
</div>

<svg #print *ngIf="width > 0 && height > 0 && original_height && original_width" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/1999/XSL/Transform" class="stb-main"
     [attr.width]="width" [attr.height]="height" [attr.viewBox]="getViewBox()">

  <ng-container *ngFor="let c of connections_m">
    <svg:g stammbaum-tree-marriage-connection [version]="version" [treeViewMode]="_stammbaumState.treeViewMode" [p1]="c.p1" [p2]="c.p2" [m]="c.m"></svg:g>
  </ng-container>

  <ng-container *ngFor="let c of connections_c">
    <svg:g stammbaum-tree-children-connection [version]="version" [treeViewMode]="_stammbaumState.treeViewMode" [p]="c.p" [c]="c.c"></svg:g>
  </ng-container>

  <ng-container *ngFor="let p of _stammbaumState?.stammbaum.persons; let i = index;">
    <svg:g stammbaum-tree-person [version]="version" [person]="p" [treeViewMode]="_stammbaumState.treeViewMode"></svg:g>
  </ng-container>

</svg>

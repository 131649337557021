<div *ngIf="!hasOpenModals()" class="container">

  <!-- Menu for Large Screens -->
  <nav class="fixed-nav-bar d-none d-lg-block">
    <h3>
      <span *ngIf="getTitle()">{{getTitle()}}</span>
      <team-icons class="mx-2" style="font-size: 65%;"></team-icons>
      <button class="btn btn-sm btn-success mx-1" (click)="goToCaseSearch()" title="zurück zu Suche/Listen">
        <span class="fa fa-angle-double-left mr-1"></span>Suche
      </button>
      <button [ngClass]="(mode === 1)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(1)" title="Grunddaten anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Grunddaten
      </button>
      <button [ngClass]="(mode === 2)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(2)" title="Notizen anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Notizen
      </button>
      <button *ngIf="hasTrueStammbaum()" [ngClass]="(mode === 3)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(3)" title="Stammbaum anzeigen">
        <span class="fa fa-angle-right mr-1"></span>STB
      </button>
      <button [ngClass]="(mode === 4)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(4)" title="Personen anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Personen
      </button>
      <button [ngClass]="(mode === 5)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(5)" title="Erben anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Erben
      </button>
      <button [ngClass]="(mode === 6)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(6)" title="NT anzeigen">
        <span class="fa fa-angle-right mr-1"></span>NT
      </button>
      <button [ngClass]="(mode === 7)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(7)" title="Dokumente anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Dokumente
      </button>
      <button [ngClass]="(mode === 8)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(8)" title="Team anzeigen">
        <span class="fa fa-angle-right mr-1"></span>Team
      </button>
      <button [ngClass]="(mode === 9)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(9)" title="EBS-Doku anzeigen">
        <span class="fa fa-angle-right mr-1"></span>EBS
      </button>
      <button *ngIf="rightsManagementService.isAllowedToViewKassenbuchTabInCase()" [ngClass]="(mode === 10)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(10)" title="Kassenbuch">
        <span class="fa fa-angle-right mr-1"></span>Kassenbuch
      </button>
      <button *ngIf="rightsManagementService.isAllowedToViewAuskehrTabInCase()"
              [ngClass]="(mode === 11)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(11)" title="Auskehr">
        <span class="fa fa-angle-right mr-1"></span>Auskehr
      </button>
      <button class="btn btn-sm btn-outline-primary mx-1" (click)="showHelp()" title="Hilfe anzeigen">
        <span class="fa fa-info-circle mr-1"></span>
      </button>
      <button *ngIf="!hasTrueStammbaum() && rightsManagementService.isAllowedToCreateNewStammbaum()" class="btn btn-sm btn-outline-danger mx-1" (click)="createStammbaum()" title="Stammbaum anlegen">
        <span class="fa fa-angle-right mr-1"></span>neuer Stammbaum
      </button>
      <span *ngIf="!hasTrueStammbaum()" title="kein Stammbaum vorhanden" class="fa fa-sitemap fa-sm red pull-right mr-4"></span>
      <span *ngIf="hasTrueStammbaum()" title="Stammbaum vorhanden" class="fa fa-sitemap fa-sm green pull-right mr-4"></span>
    </h3>
  </nav>

  <!-- Menu for Small Screens -->
  <nav class="fixed-nav-bar-sm d-lg-none">
    <h3>
      <span *ngIf="getTitle()">{{getTitle()}}</span>
      <button class="btn btn-sm btn-success mx-1" (click)="goToCaseSearch()" title="zurück zur Fall-Liste">
        <span class="fa fa-angle-double-left mr-1"></span>
      </button>
      <button [ngClass]="(mode === 1)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(1)" title="Grunddaten anzeigen">
        <span class="fa fa-angle-right mr-1"></span>G
      </button>
      <button [ngClass]="(mode === 2)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(2)" title="Notizen anzeigen">
        <span class="fa fa-angle-right mr-1"></span>N
      </button>
      <button *ngIf="hasTrueStammbaum()" [ngClass]="(mode === 3)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(3)" title="Stammbaum anzeigen">
        <span class="fa fa-angle-right mr-1"></span>STB
      </button>
      <button [ngClass]="(mode === 4)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(4)" title="Personen anzeigen">
        <span class="fa fa-angle-right mr-1"></span>P
      </button>
      <button [ngClass]="(mode === 5)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(5)" title="Erben anzeigen">
        <span class="fa fa-angle-right mr-1"></span>E
      </button>
      <button [ngClass]="(mode === 6)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(6)" title="NT anzeigen">
        <span class="fa fa-angle-right mr-1"></span>NT
      </button>
      <button [ngClass]="(mode === 7)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(7)" title="Dokumente anzeigen">
        <span class="fa fa-angle-right mr-1"></span>DOK
      </button>
      <button [ngClass]="(mode === 8)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(8)" title="Team anzeigen">
        <span class="fa fa-angle-right mr-1"></span>MA
      </button>
      <button [ngClass]="(mode === 9)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(9)" title="EBS-Doku anzeigen">
        <span class="fa fa-angle-right mr-1"></span>EBS
      </button>
      <button *ngIf="rightsManagementService.isAllowedToViewKassenbuchTabInCase()" [ngClass]="(mode === 10)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(10)" title="Kassenbuch">
        <span class="fa fa-angle-right mr-1"></span>KB
      </button>
      <button *ngIf="rightsManagementService.isAllowedToViewAuskehrTabInCase()" [ngClass]="(mode === 11)?'btn-primary':'btn-outline-primary'" class="btn btn-sm mx-1" (click)="changeToMode(11)" title="Auskehr">
        <span class="fa fa-angle-right mr-1"></span>AK
      </button>
      <button class="btn btn-sm btn-outline-primary mx-1" (click)="showHelp()" title="Hilfe anzeigen">
        <span class="fa fa-info-circle mr-1"></span>
      </button>
      <button *ngIf="!hasTrueStammbaum() && rightsManagementService.isAllowedToCreateNewStammbaum()" class="btn btn-sm btn-outline-danger mx-1" (click)="createStammbaum()" title="Stammbaum anlegen">
        <span class="fa fa-angle-right mr-1"></span>+STB
      </button>
      <span *ngIf="!hasTrueStammbaum()" title="kein Stammbaum vorhanden" class="fa fa-sitemap fa-sm red pull-right mr-4"></span>
      <span *ngIf="!hasTrueStammbaum()" title="Stammbaum vorhanden" class="fa fa-sitemap fa-sm green pull-right mr-4"></span>
    </h3>
  </nav>

</div>

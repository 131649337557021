<div class="modal-header">
  <h4 class="modal-title ilatitle">
    Betrag umrechnen
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">Wollen Sie diese Position umrechnen?</div>
      <div class="col-12 custom-control-label">ursprünglicher Betrag: {{getWaehrungString(posten.waehrung)}}</div>
      <div class="col-12 custom-control-label">Betrag in Euro</div>
      <div class="col-12"><input type="number" class="form-control" formControlName="betrag" id="betrag"></div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="m-2 btn btn-sm btn-danger" (click)="cancel()">abbrechen</button>
  <button type="button" class="m-2 btn btn-sm btn-success" [disabled]="form.invalid" (click)="confirm()">umrechnen
  </button>
</div>

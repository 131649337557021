<div class="p-2 m-2">
  <h3>Standesamt auswählen</h3>
  <form [formGroup]="form">
    <table class="table-responsive">
      <tr>
        <td style="width: 34%;"><input formControlName="freitext" id="freitext" class="form-control" placeholder="Standesamt-Name"></td>
        <td style="width: 33%;"><input formControlName="stadt" id="stadt" class="form-control" placeholder="Kreis / Stadt"></td>
        <td style="width: 33%;"><input formControlName="bundesland" id="bundesland" class="form-control" placeholder="Bundesland"></td>
      </tr>
    </table>
  </form>
  <div *ngIf="!search_in_progress && results && results.length > 0">
    <h4>{{results.length}} Standesämter (max. 500 Suchergebnisse)</h4>
  </div>
  <table class="table-responsive table-sm table-striped mt-2" *ngIf="!search_in_progress && results && results.length > 0">
    <tr><th>Standesamt</th><th>Stadt / Kreis</th><th>Bundesland</th><th>Standesamts-Nr.</th><th>Kommentar</th><th>Land</th></tr>
    <tr (click)="selectStandesamt(row)" *ngFor="let row of results">
      <td>{{row.name}}</td>
      <td><span *ngIf="row.stadt_id?.type">{{row.stadt_id?.type}} </span>{{row.stadt_id?.name}}<span *ngIf="row.stadt_id?.gemeindeschluessel"> ({{row.stadt_id?.gemeindeschluessel}})</span></td>
      <td>{{row.stadt_id?.bundesland}}</td>
      <td>{{row.schluessel}}</td>
      <td>{{row.comment}}</td>
      <td></td>
    </tr>
  </table>
  <div *ngIf="!search_in_progress && (!results || results.length === 0) && searched">
    <span>keine passenden Standesämter gefunden</span>
  </div>
  <div *ngIf="search_in_progress">
    <span class="fa fa-cog fa-spin"></span> Suche läuft
  </div>

  <div *ngIf="searched && allow_creating_new" class="mt-3">
    <form [formGroup]="newAmtForm">
      <h4>Neues Standesamt anlegen</h4>
      <table class="table table-sm">
        <tr>
          <td style="width: 30%;">Name</td>
          <td>
            <input class="form-control" id="standesamt_name" formControlName="standesamt_name" type="text">
          </td>
        </tr>
        <tr>
          <td>Stadt / Kreis / Land / Hinweise</td>
          <td>
            <input class="form-control" id="stadt_name" formControlName="stadt_name" type="text">
          </td>
        </tr><tr>
          <td><p style="color: red; font-weight: bold;">Hinweis: Diese Funktion wurde deaktiviert. Bitte wenden Sie sich für die Neuanlage eines Standesamtes per Mail an Frau Landerer</p></td>
          <td><button [disabled]="true" (click)="createNew()" class="btn btn-sm btn-warning pull-right">neu anlegen</button></td>
        </tr>
      </table>
    </form>
  </div>

  <button class="my-3 btn btn-sm btn-warning" (click)="close()">schliessen</button>
</div>


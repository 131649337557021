<div class="card my-3">
  <div class="card-body">
    <div>
      <button (click)="newUser()" class="m-2 btn btn-sm btn-primary">neuen Mitarbeiter anlegen</button>
    </div>
  </div>
</div>

<div class="card my-3">
  <div class="card-text">
    <div class="table-responsive">
      <h3>Mitarbeiter-Verwaltung
        <button *ngIf="showDisabledUsers" (click)="toggleShowDisabled()" class="btn btn-sm btn-info mx-1" title="inaktive Benutzer ausblenden"><span class="fa fa-eye-slash"></span></button>
        <button *ngIf="!showDisabledUsers" (click)="toggleShowDisabled()" class="btn btn-sm btn-info mx-1" title="inaktive Benutzer einblenden"><span class="fa fa-eye"></span></button>
      </h3>
      <h5 *ngIf="!copiedUsers">keine Datensätze gefunden</h5>
      <h5 *ngIf="copiedUsers">{{resultCount}} Datensätze gefunden</h5>
      <table *ngIf="copiedUsers && !recalculating" class="table table-hover table-striped table-sm">
        <tr>
          <th>Kürzel</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Email</th>
          <th>Mobil</th>
          <th>Rolle</th>
          <th>Aktiv</th>
        </tr>
        <tr (click)="onTableClicked(currentUser.id)"*ngFor="let currentUser of copiedUsers">
          <td><span class="mx-2 badge badge-pill badge-success">{{currentUser.abbreviation}}</span></td>
          <td>{{currentUser.first_name}}</td>
          <td>{{currentUser.last_name}}</td>
          <td>{{currentUser.email}}</td>
          <td>{{currentUser.mobile}}</td>
          <td>{{currentUser.userrole.description}}</td>
          <td>
            <i *ngIf="!currentUser.is_active||currentUser.is_active=='false'" class="fa fa-remove" style="color: red;"></i>
            <i *ngIf="currentUser.is_active&&currentUser.is_active!='false'&&currentUser.is_active!=''" class="fa fa-check" style="color: green;"></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="m-2 p-2" *ngIf="person">
  <h4 *ngIf="!editMode">Urkunde hochladen für {{person.nachname}} {{person.vorname}} <span *ngIf="person.geburtsname">geb. {{person.geburtsname}} </span>im Fall {{caseDisplayInformation()}}</h4>
  <h4 *ngIf="editMode">Urkunde bearbeiten für {{person.nachname}} {{person.vorname}} <span *ngIf="person.geburtsname">geb. {{person.geburtsname}} </span>im Fall {{caseDisplayInformation()}}</h4>
  <button  *ngIf="!editMode" (click)="multipleDocumentUsage()" class="m-2 btn btn-sm btn-success">bestehende PS-Urkunde wiederverwenden</button>
  <form [formGroup]="form" class="mt-3">
    <table class="table-responsive table-sm">
      <tr>
        <td style="width: 34%;">Urkunden-Art</td>
        <td style="width: 33%;">
          <select #urkunden_art formControlName="urkunden_art" id="urkunden_art" class="form-control">
            <option *ngFor="let option of stammbaumService.urkunden_art_options" [value]="option.id">{{option.description}}</option>
          </select>
        </td>
        <td style="width: 33%;">
          <select #urkunden_art_subtyp formControlName="urkunden_art_subtyp" id="urkunden_art_subtyp" class="form-control">
            <option *ngFor="let option of urkunden_art_sub_options" [value]="option.id">{{option.description}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Urkunden-Status</td>
        <td>
          <select #urkunden_status formControlName="urkunden_status" id="urkunden_status" class="form-control">
            <option *ngFor="let option of stammbaumService.urkunden_status_options" [value]="option.id">{{option.description}}</option>
          </select>
        </td>
        <td></td>
      </tr>
      <tr *ngIf="showStandesamtSelect">
        <td>Standesamt<button class="mx-3 btn btn-sm btn-success" (click)="chooseStandesamt()">auswählen</button></td>
        <td *ngIf="standesamt_selected"><span class="bold">Standesamt {{standesamt_selected?.name}}</span><span *ngIf="standesamt_selected.stadt_id && standesamt_selected.stadt_id.name">, {{standesamt_selected.stadt_id.name}}</span> <span *ngIf="standesamt_selected.stadt_id && standesamt_selected.stadt_id.type"> ({{standesamt_selected.stadt_id.type}})</span></td>
        <td *ngIf="standesamt_selected">{{standesamt_selected.stadt_id && standesamt_selected?.stadt_id.land.name}}<span *ngIf="standesamt_selected.stadt_id && standesamt_selected.stadt_id.bundesland"> ({{standesamt_selected.stadt_id.bundesland}})</span></td>
        <td *ngIf="!standesamt_selected" style="color: red;">bitte auswählen</td>
        <td *ngIf="!standesamt_selected"></td>
      </tr>
      <tr *ngIf="showKircheText">
        <td style="width: 30%;">Kirche oder Behörde<br></td>
        <td><input #kirche_text placeholder="Name Kirche / Institution" formControlName="kirche_text" type="text" id="kirche_text" class="form-control"></td>
        <td><input #kirche_ort placeholder="Ort" formControlName="kirche_ort" type="text" id="kirche_ort" class="form-control"></td>
      </tr>
      <tr *ngIf="showUrkundenArtFreitext">
        <td>Erläuterung Urkunden-Art</td>
        <td><input #urkunden_art_freitext formControlName="urkunden_art_freitext" type="text" id="urkunden_art_freitext" class="form-control"></td>
        <td></td>
      </tr>
      <tr *ngIf="showMarriageSelect">
        <td>Eheschließung auswählen</td>
        <td>
          <select formControlName="marriage_select" id="marriage_select" class="form-control">
            <option value="0">---bitte auswählen---</option>
            <option *ngFor="let m of person.marriages_for_stammbaum_person" [value]="m.id">
              {{display_marriage(m)}}
            </option>
          </select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Jahr</td>
        <td><input #jahr formControlName="jahr" type="text" id="jahr" class="form-control"></td>
        <td></td>
      </tr>
      <tr>
        <td>Urkunden-Nr</td>
        <td><input #urkunden_nr formControlName="urkunden_nr" type="text" id="urkunden_nr" class="form-control"></td>
        <td></td>
      </tr>
      <tr>
        <td>Urkunden-Art</td>
        <td>
          <div class="form-check">
            &nbsp;&nbsp;&nbsp;
            <input type="checkbox" id="is_gu" formControlName="is_gu" class="form-check-input">
            <label class="form-check-label" for="is_gu">gilt als GU</label>
          </div>
          <div class="form-check">
            &nbsp;&nbsp;&nbsp;
            <input type="checkbox" id="is_hu" formControlName="is_hu" class="form-check-input">
            <label class="form-check-label" for="is_hu">gilt als HU</label>
          </div>
          <div class="form-check">
            &nbsp;&nbsp;&nbsp;
            <input type="checkbox" id="is_su" formControlName="is_su" class="form-check-input">
            <label class="form-check-label" for="is_su">gilt als SU</label>
          </div>
        </td>
        <td></td>
      </tr>
    </table>
  </form>

  <div *ngIf="!editMode && form.valid && (!standesamt_needed || standesamt_selected)" class="center mb-3">
    <ngx-file-drop [showBrowseBtn]="true" browseBtnLabel="Datei auswählen"
               (onFileDrop)="dropped($event)"  multiple="false"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <span>Datei hierhin schieben</span>
        <span class="ml-2 fa fa-bullseye fa-3x"></span>
        <span class="mx-3">oder</span>
        <button type="button" class="btn btn-primary" (click)="openFileSelector()">Datei auswählen</button>
      </ng-template>
    </ngx-file-drop>
    <div class="upload-table" *ngIf="file">
      <table class="table">
        <tbody class="upload-name-style">
          <tr>
            <td><strong>{{ file.relativePath }}</strong></td>
            <td><button (click)="removeFile()" class="btn btn-sm btn-warning"><span class="fa fa-remove"></span></button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button (click)="close()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
  <button *ngIf="!editMode" [disabled]="!file || form.invalid || (standesamt_needed && !standesamt_selected)" (click)="upload()" class="m-2 btn btn-sm btn-success">hochladen</button>
  <button *ngIf="editMode" [disabled]="form.invalid || (standesamt_needed && !standesamt_selected)" (click)="saveChanges()" class="m-2 btn btn-sm btn-success">Änderungen speichen</button>

  <div *ngIf="(!file && !editMode) || form.invalid || (standesamt_needed && !standesamt_selected)">
    bitte prüfen:
    <ul>
      <li *ngIf="form.valid && (!standesamt_needed || standesamt_selected) && !file && !editMode">Es muss eine Datei ausgewählt werden</li>
      <li *ngIf="standesamt_needed && !standesamt_selected">Es muss ein Standesamt ausgewählt werden</li>
      <li *ngIf="form.get('urkunden_nr').invalid">Es muss eine Urkunden-Nummer angegeben werden, die nur aus Ziffern besteht und maximal 9-stellig ist</li>
      <li *ngIf="form.get('urkunden_art').invalid">Es muss eine Urkunden-Kategorie ausgewählt werden</li>
      <li *ngIf="form.get('urkunden_art_subtyp').invalid">Es muss eine Urkunden-Art ausgewählt werden</li>
      <li *ngIf="form.get('urkunden_status').invalid">Es muss ein Urkunden-Status ausgewählt werden</li>
      <li *ngIf="form.get('urkunden_art_freitext').invalid">Es muss eine Erläuterung für die Urkunden-Art angegeben werden mit max. 100 Zeichen</li>
      <li *ngIf="form.get('jahr').invalid">Es muss eine Jahreszahl nur aus Ziffern bestehend angegeben werden</li>
      <li *ngIf="form.get('kirche_text').invalid">Es muss eine Kirche oder Behörde mit max. 100 Zeichen angegeben werden</li>
      <li *ngIf="form.get('kirche_ort').invalid">Es muss der Ort der Kirche oder Behörde mit max. 100 Zeichen angegeben werden</li>
      <li *ngIf="form.get('marriage_select').invalid">Es muss eine Eheschließung ausgewählt werden</li>
    </ul>
  </div>

</div>


/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kassenbuch-menu-for-main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./kassenbuch-menu-for-main.component";
import * as i4 from "../kassenbuch.service";
var styles_KassenbuchMenuForMainComponent = [i0.styles];
var RenderType_KassenbuchMenuForMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KassenbuchMenuForMainComponent, data: {} });
export { RenderType_KassenbuchMenuForMainComponent as RenderType_KassenbuchMenuForMainComponent };
export function View_KassenbuchMenuForMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "btn btn-sm btn-success m-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate("open-positions") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "btn-outline-success": 0 }), (_l()(), i1.ɵted(-1, null, ["offene Kostenpositionen"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "btn btn-sm btn-success m-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate("stats") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "btn-outline-success": 0 }), (_l()(), i1.ɵted(-1, null, ["Auswertung"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-sm btn-success m-2"; var currVal_1 = _ck(_v, 5, 0, (_co.activeSubpage !== "open-positions")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = "btn btn-sm btn-success m-2"; var currVal_3 = _ck(_v, 10, 0, (_co.activeSubpage !== "stats")); _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_KassenbuchMenuForMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-menu-for-main", [], null, null, null, View_KassenbuchMenuForMainComponent_0, RenderType_KassenbuchMenuForMainComponent)), i1.ɵdid(1, 245760, null, 0, i3.KassenbuchMenuForMainComponent, [i4.KassenbuchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KassenbuchMenuForMainComponentNgFactory = i1.ɵccf("app-kassenbuch-menu-for-main", i3.KassenbuchMenuForMainComponent, View_KassenbuchMenuForMainComponent_Host_0, {}, {}, []);
export { KassenbuchMenuForMainComponentNgFactory as KassenbuchMenuForMainComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-select-edit-marriage.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../tools/show-date-pipe/show-date-pipe";
import * as i4 from "./stammbaum-tree-select-edit-marriage.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_StammbaumTreeSelectEditMarriageComponent = [i0.styles];
var RenderType_StammbaumTreeSelectEditMarriageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeSelectEditMarriageComponent, data: {} });
export { RenderType_StammbaumTreeSelectEditMarriageComponent as RenderType_StammbaumTreeSelectEditMarriageComponent };
function View_StammbaumTreeSelectEditMarriageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-venus-mars fa-sm mx-1"]], null, null, null, null, null))], null, null); }
function View_StammbaumTreeSelectEditMarriageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-heart fa-sm mx-1"]], null, null, null, null, null))], null, null); }
function View_StammbaumTreeSelectEditMarriageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToMarriage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSelectEditMarriageComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSelectEditMarriageComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, [" ", " ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1)], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.aussereheliche_partnerschaft; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.aussereheliche_partnerschaft; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.persons[0].nachname; var currVal_1 = _v.context.$implicit.persons[0].vorname; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.persons[1].nachname; var currVal_5 = _v.context.$implicit.persons[1].vorname; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.date)); _ck(_v, 9, 0, currVal_6); }); }
export function View_StammbaumTreeSelectEditMarriageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.ShowDatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "p-2 m-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ehe zur Bearbeitung ausw\u00E4hlen"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Partner"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Datum"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSelectEditMarriageComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.marriages; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_StammbaumTreeSelectEditMarriageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-tree-select-edit-marriage", [], null, null, null, View_StammbaumTreeSelectEditMarriageComponent_0, RenderType_StammbaumTreeSelectEditMarriageComponent)), i1.ɵdid(1, 49152, null, 0, i4.StammbaumTreeSelectEditMarriageComponent, [i5.NgbActiveModal], null, null)], null, null); }
var StammbaumTreeSelectEditMarriageComponentNgFactory = i1.ɵccf("stammbaum-tree-select-edit-marriage", i4.StammbaumTreeSelectEditMarriageComponent, View_StammbaumTreeSelectEditMarriageComponent_Host_0, {}, {}, []);
export { StammbaumTreeSelectEditMarriageComponentNgFactory as StammbaumTreeSelectEditMarriageComponentNgFactory };

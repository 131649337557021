<div>
  <h4><span class="mr-1" *ngIf="stammbaumPersonsToDisplay">{{stammbaumPersonsToDisplay.length}}</span>Namensträger
  <span *ngIf="createNewInProgress" class="fa fa-spin fa-cog"></span>
  <button [disabled]="createNewInProgress" class="btn btn-success btn-sm mx-2" (click)="createNew()">NT anlegen</button>
  </h4>
</div>

<div class="table-responsive">
  <table class="table table-striped table-sm">
    <tr>
      <th>Personen-ID</th>
      <th>Nachname</th>
      <th>Vornamen</th>
      <th>Geburtsname</th>
      <th>Gruppe</th>
      <th>angeschrieben</th>
      <th>Prozent</th>
      <th>Vollmacht</th>
      <th>angerufen</th>
      <th>Vertrag</th>
      <th>Egebnis</th>
      <th>PA-beglaubigt</th>
      <th>Erbnachweis</th>
    </tr>
    <tr *ngFor="let person of stammbaumPersonsToDisplay" (click)="onSelect(person)">
      <td>{{person.id}}</td>
      <td>{{person.nachname}}</td>
      <td>{{person.vorname}}</td>
      <td>{{person.geburtsname}}</td>
      <td>{{getPersonTypeText(person.person_type)}}</td>
      <td><show-date [value]="person.contract_send_out_date"></show-date></td>
      <td>{{person.contract_send_out_percentage}}</td>
      <td><show-boolean-value [value]="person.mandate"></show-boolean-value></td>
      <td><show-boolean-value [value]="person.phone_call_made"></show-boolean-value></td>
      <td><show-boolean-value [value]="person.signature_date"></show-boolean-value></td>
      <td>{{person.result}}</td>
      <td><show-boolean-value [value]="person.identity_card"></show-boolean-value></td>
      <td><show-boolean-value [value]="person.inheritance_proof"></show-boolean-value></td>
    </tr>
  </table>
</div>

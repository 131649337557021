<h3 class="title">Aufgaben Listenansicht <button class="mx-3 btn btn-primary btn-sm" (click)="showMonth()">zur Kalenderansicht wechseln</button></h3>

<div class="card-deck">
  <div class="card">
    <h4 class="card-header">Eigene Aufgaben <button class="mx-3 btn btn-success btn-sm" (click)="showFormOwn()">neu anlegen</button></h4>
    <ul class="list-group list-group-flush">
      <li *ngFor="let entry of calendarEntriesOwn" (click)="openDetails(entry)" class="list-group-item">{{entry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(entry.deadline_hour, entry.deadline_minute)}} von<span class="mx-2 badge badge-pill badge-warning">{{userService.getAbbreviationForUser(entry.created_by_user_id)}}</span>{{entry.title}}</li>
    </ul>
  </div>
  <div class="card">
    <h4 class="card-header">Für andere erstellte Aufgaben <button class="mx-3 btn btn-success btn-sm" (click)="showForm()">neu anlegen</button></h4>
    <ul class="list-group list-group-flush">
      <li *ngFor="let entry of calendarEntriesOther" (click)="openDetails(entry)" class="list-group-item">{{entry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(entry.deadline_hour, entry.deadline_minute)}} für<span class="mx-2 badge badge-pill badge-success">{{userService.getAbbreviationForUser(entry.for_user_id)}}</span>{{entry.title}}</li>
    </ul>
  </div>
</div>
<div class="card mt-3">
  <h4 class="card-header">Erledigte Aufgaben der letzten 14 Tage</h4>
  <ul class="list-group list-group-flush">
    <ng-container *ngFor="let entry of calendarEntriesDone">
      <li (click)="openDetails(entry)" class="list-group-item">{{entry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(entry.deadline_hour, entry.deadline_minute)}}
        für<span class="mx-2 badge badge-pill badge-success">{{userService.getAbbreviationForUser(entry.for_user_id)}}</span>
        von<span class="mx-2 badge badge-pill badge-warning">{{userService.getAbbreviationForUser(entry.created_by_user_id)}}</span>
        {{entry.title}}</li>
    </ng-container>
  </ul>
</div>



<h3 *ngIf="addressbookEntry">{{addressbookEntry.name}}
  <button tooltip="nur Datensätze, mit denen keine Kontaktdaten, Adressen und Personen mehr verknüpft sind, können gelöscht werden"
                        [disabled]="!deleteable"
                        class="mx-2 btn btn-sm btn-danger pull-right"
                        (click)="delete()">Institution löschen</button>
  <button [disabled]="!addressbookEntryForm.valid" class="mx-2 btn btn-warning btn-sm pull-right" (click)="update()">Änderungen speichern</button>
  <button class="mx-2 btn btn-success btn-sm" (click)="cancel()">zurück zur Suche</button>
</h3>

<form [formGroup]="addressbookEntryForm" class="form-horizontal">
  <div class="form-group row">
    <label for="id" class="col-sm-3 control-label">ID</label>
    <div class="col-sm-9">
      <input formControlName="id" type="text" readonly id="id" class="form-control">
    </div>
  </div>
  <div class="form-group row">
    <label for="name" class="col-sm-3 control-label">Name der Institution</label>
    <div class="col-sm-9">
      <input formControlName="name" type="text" id="name" class="form-control">
      <span *ngIf="addressbookEntryForm.get('name').invalid && addressbookEntryForm.get('name').touched" class="help-block">Bitte einen Namen mit maximal 40 Zeichen angeben!</span>
    </div>
  </div>
  <div class="form-group row">
     <label for="type" class="col-sm-3 control-label">Typ</label>
     <div class="col-sm-9">
       <select formControlName="type" id="type" class="form-control">
         <option *ngFor="let type of institutionTypes"
            [value]="type.id">
            {{type.name}}
          </option>
       </select>
       <span *ngIf="addressbookEntryForm.get('type').invalid && addressbookEntryForm.get('type').touched" class="help-block">Bitte eine Institution auswählen!</span>
     </div>
   </div>
   <div class="form-group row">
    <label for="comment" class="col-sm-3 control-label">Kommentar</label>
    <div class="col-sm-9">
      <textarea formControlName="comment" type="text" id="comment" class="form-control"></textarea>
      <span *ngIf="addressbookEntryForm.get('comment').invalid && addressbookEntryForm.get('comment').touched" class="help-block">Bitte maximal 400 Zeichen angeben!</span>
    </div>
  </div>
</form>

<div class="card my-2">
  <h4 class="my-2">Kontaktmöglichkeiten Institution <button (click)="createContact()" class="mx-2 btn btn-sm btn-warning">neu hinzufügen</button></h4>
  <table class="table table-sm" *ngIf="addressbookEntry.contacts?.length > 0">
    <tr *ngFor="let contact of addressbookEntry.contacts">
      <td>{{printContactType(contact.type)}}</td>
      <td>{{contact.details}}</td>
      <td>{{contact.comment}}</td>
      <td><button class="btn btn-sm btn-danger pull-right" (click)="editContact(contact)">bearbeiten</button></td>
    </tr>
  </table>
</div>


<div class="card my-2">
  <h4 class="my-2">Adressen der Institution <button (click)="createAddress()" class="mx-2 btn btn-sm btn-warning">neu hinzufügen</button></h4>
  <table class="table table-sm" *ngIf="addressbookEntry.addresses?.length > 0">
    <tr *ngFor="let address of addressbookEntry.addresses">
      <td>{{address.street}} {{address.housenumber}}</td>
      <td>{{address.additional_address_information}}</td>
      <td>{{address.zipcode}}</td>
      <td>{{address.town}}</td>
      <td>{{printAddressRegion(address.region)}}</td>
      <td><button class="btn btn-sm btn-danger pull-right" (click)="editAddress(address)">bearbeiten</button></td>
    </tr>
  </table>
</div>

<div class="card my-2">
  <h4 class="my-2">zugehörige Personen <button (click)="createPerson()" class="mx-2 btn btn-sm btn-warning">neu hinzufügen</button></h4>
  <table class="table table-sm" *ngIf="addressbookEntry.persons?.length > 0">
    <tr *ngFor="let person of addressbookEntry.persons">
      <td>{{person.first_name}} {{person.last_name}}</td>
      <td>{{person.function}}</td>
      <td>{{person.comment}}</td>
      <td><button class="btn btn-sm btn-danger pull-right" (click)="editPerson(person)">bearbeiten</button></td>
      <td>
        <h5>Kontakte <button (click)="createContactForPerson(person.id)" class="mx-2 btn btn-sm btn-warning">neuer Kontakt</button></h5>
        <table class="table table-sm">
          <tr *ngFor="let contact of person.contacts">
            <td>{{printContactType(contact.type)}}</td>
            <td>{{contact.details}}</td>
            <td>{{contact.comment}}</td>
            <td><button class="btn btn-sm btn-danger pull-right" (click)="editContact(contact)">bearbeiten</button></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</div>


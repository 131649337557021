<h3>Städte und Kreise
  <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="addStadt()" class="mx-2 btn btn-sm btn-warning">neue Stadt / neuen Kreis anlegen</button>
  <span style="font-size: 45%"><kbd>Einfg</kbd></span>
</h3>
<div *ngIf="nvzService.staedteFirstLoad">
  <abc-choose (chosen)="filterLetter($event)"></abc-choose>
  <filter-field [hint]="'Name, Art, Bundesland, ...'" (searchstring)="filterBy($event)"></filter-field>
  <div *ngIf="!copiedStaedte || copiedStaedte.length == 0">keine Städte / Kreise vorhanden</div>
  <div *ngIf="copiedStaedte && copiedStaedte.length > 0">
    <table class="table table-hover table-striped table-sm">
      <tr>
        <th>Name</th>
        <th>Art</th>
        <th>Bundesland</th>
        <th>AGS</th>
        <th>Kommentar</th>
        <th>Status</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr *ngFor="let stadt of copiedStaedte">
        <td (click)="selectStadt(stadt)">{{stadt.name}}</td>
        <td (click)="selectStadt(stadt)">{{stadt.type}}</td>
        <td (click)="selectStadt(stadt)">{{stadt.bundesland}}</td>
        <td (click)="selectStadt(stadt)">{{stadt.gemeindeschluessel}}</td>
        <td (click)="selectStadt(stadt)">{{stadt.comment}}</td>
        <td (click)="selectStadt(stadt)">{{showStadtStatus(stadt.status)}}</td>
        <td (click)="selectStadt(stadt)"><span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(stadt.owner_id)}}</span></td>
        <td *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || stadt.owner_id == this.user.id" (click)="editStadt(stadt)"><i class="fa fa-edit"></i></td>
        <td *ngIf="!rightsManagementService.isAllowedToEditAllNVZEntries() && stadt.owner_id != this.user.id"></td>
        <td *ngIf="rightsManagementService.isAdmin() || stadt.owner_id == this.user.id" (click)="deleteStadt(stadt)"><i class="fa fa-trash"></i></td>
        <td *ngIf="!rightsManagementService.isAdmin() && stadt.owner_id != this.user.id"></td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!nvzService.staedteFirstLoad">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>


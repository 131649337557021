/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-auskehr-institution.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../addressbook/addressbox-autocomplete/addressbook-autocomplete.component.ngfactory";
import * as i3 from "../../addressbook/addressbox-autocomplete/addressbook-autocomplete.component";
import * as i4 from "../../services/addressbook.service";
import * as i5 from "../../services/rights-management.service";
import * as i6 from "../../routing/routing.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@ngrx/store";
import * as i9 from "./add-auskehr-institution.component";
import * as i10 from "../../services/auskehr.service";
var styles_AddAuskehrInstitutionComponent = [i0.styles];
var RenderType_AddAuskehrInstitutionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddAuskehrInstitutionComponent, data: {} });
export { RenderType_AddAuskehrInstitutionComponent as RenderType_AddAuskehrInstitutionComponent };
export function View_AddAuskehrInstitutionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Institution f\u00FCr Auskehr hinzuf\u00FCgen"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [["class", "control-label"], ["for", "inst"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bezeichnung"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "addressbook-autocomplete", [["id", "inst"]], null, [[null, "fieldValueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fieldValueChange" === en)) {
        var pd_0 = ((_co.institutionId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AddressbookAutocompleteComponent_0, i2.RenderType_AddressbookAutocompleteComponent)), i1.ɵdid(8, 638976, null, 0, i3.AddressbookAutocompleteComponent, [i4.AddressbookService, i5.RightsManagementService, i6.RoutingService, i7.NgbModal, i8.Store], { editMode: [0, "editMode"], fieldValue: [1, "fieldValue"] }, { fieldValueChange: "fieldValueChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "m-2 btn btn-outline-danger pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "m-2 btn btn-success pull-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.institutionId; _ck(_v, 8, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.institutionId || _co.saving); _ck(_v, 12, 0, currVal_2); }); }
export function View_AddAuskehrInstitutionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-auskehr-institution", [], null, null, null, View_AddAuskehrInstitutionComponent_0, RenderType_AddAuskehrInstitutionComponent)), i1.ɵdid(1, 114688, null, 0, i9.AddAuskehrInstitutionComponent, [i7.NgbActiveModal, i10.AuskehrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddAuskehrInstitutionComponentNgFactory = i1.ɵccf("app-add-auskehr-institution", i9.AddAuskehrInstitutionComponent, View_AddAuskehrInstitutionComponent_Host_0, {}, {}, []);
export { AddAuskehrInstitutionComponentNgFactory as AddAuskehrInstitutionComponentNgFactory };

<div class="m-2 p-2">
  <h3 *ngIf="!editMode">NVZ-Eintrag anlegen</h3>
  <h3 *ngIf="editMode">NVZ-Eintrag bearbeiten</h3>

  <form [formGroup]="form" class="form-horizontal">

    <div class="form-group row">
      <label for="urkunden_nr" class="col-sm-2 control-label">Urkunden-Nummer</label>
      <div class="col-sm-10">
        <input #urkunden_nr (focus)="resetExisting()" (blur)="checkExisting()" formControlName="urkunden_nr" type="text" id="urkunden_nr" class="form-control">
        <span *ngIf="form.get('urkunden_nr').invalid && form.get('urkunden_nr').touched" class="help-block">Bitte eine Urkunden-Nummer mit maximal 15 Zeichen angeben!</span>
      </div>
    </div>
    <div *ngIf="alreadyExistingEintraege&&alreadyExistingEintraege.length>0">
      <h4 style="color: red;">Achtung, es gibt bereits folgende Einträge mit dieser Urkunden-Nr.</h4>
      <table class="table table-hover table-striped table-sm">
        <tr *ngFor="let eintrag of alreadyExistingEintraege">
          <td>{{eintrag.urkunden_nr}}</td>
          <td>{{eintrag.nachname}}</td>
          <td>{{eintrag.geburtsname}}</td>
          <td>{{eintrag.fuzzi}}</td>
          <td>{{eintrag.vorname}}</td>
        </tr>
      </table>
    </div>
    <div class="form-group row">
      <label for="nachname" class="col-sm-2 control-label">Nachname</label>
      <div class="col-sm-10">
        <input #nachname formControlName="nachname" type="text" id="nachname" class="form-control">
        <span *ngIf="form.get('nachname').invalid && form.get('nachname').touched" class="help-block">Bitte einen Nachnamen mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="fuzzi" class="col-sm-2 control-label">Fuzzi</label>
      <div class="col-sm-10">
        <input #fuzzi formControlName="fuzzi" type="text" id="fuzzi" class="form-control">
        <span *ngIf="form.get('fuzzi').invalid && form.get('fuzzi').touched" class="help-block">Der Fuzzi darf nicht mehr als 30 Zeichen lang sein!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="geburtsname" class="col-sm-2 control-label">Geburtsname</label>
      <div class="col-sm-10">
        <input #geburtsname formControlName="geburtsname" type="text" id="geburtsname" class="form-control">
        <span *ngIf="form.get('geburtsname').invalid && form.get('geburtsname').touched" class="help-block">Der Geburtsname darf nicht mehr als 30 Zeichen lang sein!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="vorname" class="col-sm-2 control-label">Vornamen</label>
      <div class="col-sm-10">
        <input #vorname formControlName="vorname" type="text" id="vorname" class="form-control">
        <span *ngIf="form.get('vorname').invalid && form.get('vorname').touched" class="help-block">Die Vornamen dürfen nicht mehr als 50 Zeichen lang sein!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="zufallsfund" class="col-sm-2 control-label">Zufallsfund</label>
      <div class="col-sm-10">
        <input type="checkbox" #zufallsfund formControlName="zufallsfund" id="zufallsfund" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label for="juedisch" class="col-sm-2 control-label">jüdisch</label>
      <div class="col-sm-10">
        <input type="checkbox" #juedisch formControlName="juedisch" id="juedisch" class="form-control">
      </div>
    </div>

    <button type="button" (click)="back()" class="btn btn-danger btn-sm m-2">abbrechen</button>
    <span style="font-size: 65%"><kbd>Esc</kbd></span>
    <button *ngIf="form.valid && editMode" type="button" (click)="save()" class="btn btn-success btn-sm m-2">speichern</button>
    <button *ngIf="!form.valid && editMode" type="button" disabled="disabled" class="btn btn-success btn-sm m-2">speichern</button>
    <button *ngIf="form.valid && !editMode" type="button" (click)="save(true)" class="btn btn-success btn-sm m-2">speichern + nächster</button>
    <button *ngIf="!form.valid && !editMode" type="button" disabled="disabled" class="btn btn-success btn-sm m-2">speichern + nächster</button>
    <span style="font-size: 65%"><kbd>Shift</kbd> + <kbd>Enter</kbd></span>
    <button *ngIf="form.valid && !editMode" type="button" (click)="save()" class="btn btn-primary btn-sm m-2">speichern</button>
    <button *ngIf="!form.valid && !editMode" type="button" disabled="disabled" class="btn btn-primary btn-sm m-2">speichern</button>

  </form>
</div>




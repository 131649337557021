<div class="m-2 p-2">
  <h4 *ngIf="!editMode">Eheschließung für {{person?.vorname}} {{person?.nachname}} hinzufügen</h4>
  <h4 *ngIf="editMode">Eheschließung für {{person?.vorname}} {{person?.nachname}} bearbeiten</h4>

  <form [formGroup]="form" class="mt-3">
    <table class="table table-sm">
      <tr>
        <td style="width: 30%;">Ehepartner</td>
        <td>
          <select formControlName="married_to_select" id="married_to_select" class="form-control">
            <option value="0">---bitte auswählen---</option>
            <option *ngFor="let o of partner_options" [value]="o.id">{{o.description}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Datum</td>
        <td><date-input-form formControlName="date" id="date"></date-input-form></td>
      </tr>
      <tr>
        <td>Anmerkungen</td>
        <td><input formControlName="kommentar" type="text" id="kommentar" class="form-control"></td>
      </tr>
      <tr>
        <td>Scheidung</td>
        <td>
          <div class="form">
            <div class="row">
              <input type="checkbox" id="divorced" class="ml-4 m-1" formControlName="divorced">
              <label class="label" for="divorced">Ehe geschieden am</label>
            </div>
            <div class="row">
              <date-input-form id="divorced_date" class="ml-4" formControlName="divorced_date"></date-input-form>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Urkunde</td>
        <td>
          <div class="form-check">
            <div class="row">
              <input type="checkbox" id="needs_hu" formControlName="needs_hu" class="ml-4 m-1">
              <label class="form-check-label" for="needs_hu">HU benötigt</label>
            </div>
            <div class="row">
              <input type="checkbox" id="ordered_hu" formControlName="ordered_hu" class="ml-4 m-1">
              <label class="form-check-label" for="ordered_hu">HU bestellt</label>
            </div>
            <div class="row">
              <input type="checkbox" id="needs_hu_but_do_without" formControlName="needs_hu_but_do_without" class="ml-4 m-1">
              <label class="form-check-label" for="needs_hu_but_do_without">Verzicht auf HU</label>
            </div>

            <div class="row">
              <date-input-form id="ordered_hu_date" class="ml-4" formControlName="ordered_hu_date"></date-input-form>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </form>
  <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
  <button [disabled]="form.invalid" (click)="save()" class="m-2 btn btn-sm btn-success">speichern</button>
  <button *ngIf="editMode" (click)="deleteEhe()" class="m-2 btn btn-sm btn-danger">diesen Eintrag löschen</button>
  <div *ngIf="form.get('married_to_select').invalid">Es muss ein Ehepartner ausgewählt werden</div>
</div>

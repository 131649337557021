<div>
  <table *ngIf="contacts" class="table" style="border: 0 !important;">
    <tr *ngFor="let contact of contacts; let i = index;" style="background-color: transparent !important; border: 0 !important;">
      <td><span class="badge badge-pill badge-primary">{{printableContactTypes[contact.type]}}</span></td>
      <td>{{contact.details}}</td>
      <td *ngIf="editMode"><button class="btn btn-sm btn-danger"(click)="remove(contact, i)">entfernen</button></td>
    </tr>
  </table>

  <p><editfield-select [options]="contactTypes" [editMode]="editMode" [(select)]="type"></editfield-select></p>
  <p><editfield-text [maxLength]="50" [editMode]="editMode" [(text)]="details"></editfield-text></p>
  <p><button *ngIf="editMode" class="btn btn-sm btn-info" (click)="addContact()">hinzufügen</button></p>
</div>

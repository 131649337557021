<div class="row">
  <div class="col-10">
    <input *ngIf="editMode" readonly="readonly" value="{{fieldValueText}}" type="text" class="form-control">
  </div>
  <div class="col-2">
    <button class="btn btn-sm btn-danger" *ngIf="editMode" (click)="resetValue()"><span class="fa fa-trash"></span></button>
  </div>
</div>
<p *ngIf="!editMode" style="margin-left: 0; padding-left: 0;">{{fieldValueText}} <button *ngIf="fieldValueText!=''" class="btn btn-sm btn-primary" (click)="showDetails()"><i class="fa fa-info"></i></button></p>
<span *ngIf="editMode">
  <input *ngIf="showInputField" set-focus type="text" class="form-control" [(ngModel)]="searchValue "(input)="search()" placeholder="mind. 3 Zeichen eingeben">
</span>
<table class="table table-sm table-results" *ngIf="searched">
  <tr *ngIf="isAllowedToCreate"r><td style="background-color: indianred;" (click)="createNew()">neuen Eintrag anlegen</td></tr>
  <tr *ngFor="let obj of searchResultInstitutions" (click)="choose(obj)">
    <td>{{obj.name}}</td>
  </tr>
</table>


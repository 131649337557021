/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-urkunden-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stammbaum-tree-urkunden-popup.component";
import * as i4 from "../../services/file-upload.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/stammbaum.service";
import * as i7 from "../../services/user.service";
import * as i8 from "@ngrx/store";
var styles_StammbaumTreeUrkundenPopupComponent = [i0.styles];
var RenderType_StammbaumTreeUrkundenPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeUrkundenPopupComponent, data: {} });
export { RenderType_StammbaumTreeUrkundenPopupComponent as RenderType_StammbaumTreeUrkundenPopupComponent };
function View_StammbaumTreeUrkundenPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" f\u00FCr ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.person.nachname; var currVal_1 = _co.person.vorname; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_StammbaumTreeUrkundenPopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["title", "klicken um die Urkunde anzuzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumService.getUrkundenArtText(_v.parent.context.$implicit.urkunden_art); _ck(_v, 1, 0, currVal_0); }); }
function View_StammbaumTreeUrkundenPopupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["title", "klicken um die Urkunde anzuzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumService.getDuplicateText(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_StammbaumTreeUrkundenPopupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["title", "klicken um die Urkunde anzuzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumService.getUrkundenUnterartText(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_StammbaumTreeUrkundenPopupComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["title", "klicken um die Urkunde anzuzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumService.getDuplicateUnterartText(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_StammbaumTreeUrkundenPopupComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "mx-2 fa fa-pencil"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editUrkunde(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_StammbaumTreeUrkundenPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵppd(19, 2), (_l()(), i1.ɵeld(20, 0, null, null, 2, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUrkunde(((_v.context.$implicit == null) ? null : _v.context.$implicit.file_ref)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [["class", "badge badge-default badge-pill"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_8)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 0, "span", [["class", "mx-2 fa fa-trash"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteUrkunde(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.refers_to; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.refers_to; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.$implicit.refers_to; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.refers_to; _ck(_v, 8, 0, currVal_3); var currVal_10 = !_v.context.$implicit.refers_to; _ck(_v, 25, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _v.context.$implicit.urkunden_nummer; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.jahr; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.getStaKiBeh(_v.context.$implicit); _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.stammbaumService.getUrkundenStatusText(_v.context.$implicit.urkunden_status); _ck(_v, 16, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.file_ref.created, "dd.MM.y")); _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.userService.getAbbreviationForUser(_v.context.$implicit.file_ref.user_id); _ck(_v, 22, 0, currVal_9); }); }
function View_StammbaumTreeUrkundenPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "table", [["class", "table-responsive table-sm mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kategorie"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Art"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nr."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jahr"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STA / KI / BEH"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Datum"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MA"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_3)), i1.ɵdid(20, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urkunden; _ck(_v, 20, 0, currVal_0); }, null); }
function View_StammbaumTreeUrkundenPopupComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Keine Urkunden vorhanden"]))], null, null); }
export function View_StammbaumTreeUrkundenPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "p-2 m-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h4", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkunden "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeUrkundenPopupComponent_9)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "mt-3 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schlie\u00DFen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.person; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.urkunden && (_co.urkunden.length > 0)); _ck(_v, 7, 0, currVal_1); var currVal_2 = (!_co.urkunden || (_co.urkunden.length === 0)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_StammbaumTreeUrkundenPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-tree-urkunden-popup", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).urkunden($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StammbaumTreeUrkundenPopupComponent_0, RenderType_StammbaumTreeUrkundenPopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.StammbaumTreeUrkundenPopupComponent, [i4.FileUploadService, i5.NgbActiveModal, i5.NgbModal, i6.StammbaumService, i7.UserService, i8.Store], null, null)], null, null); }
var StammbaumTreeUrkundenPopupComponentNgFactory = i1.ɵccf("stammbaum-tree-urkunden-popup", i3.StammbaumTreeUrkundenPopupComponent, View_StammbaumTreeUrkundenPopupComponent_Host_0, {}, {}, []);
export { StammbaumTreeUrkundenPopupComponentNgFactory as StammbaumTreeUrkundenPopupComponentNgFactory };

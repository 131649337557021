
  <h3>Neuen Benutzer anlegen</h3>

  <ngb-alert *ngIf="creatingUser" [dismissible]="false">Der Mitarbeiter wird angelegt</ngb-alert>


  <div>
    <label>Vorname</label>
    <input type="text" [(ngModel)]="newUser.first_name" class="form-control">
  </div>

  <div>
    <label>Nachname</label>
    <input type="text" [(ngModel)]="newUser.last_name" class="form-control">
  </div>

  <div>
    <label>Email</label>
    <input type="text" [(ngModel)]="newUser.email" class="form-control">
  </div>

  <div>
    <label>Kürzel</label>
    <input type="text" [(ngModel)]="newUser.abbreviation" class="form-control">
  </div>

  <div>
    <label>Handynummer</label>
    <input type="text" [(ngModel)]="newUser.mobile" class="form-control" placeholder="bitte mit 49 und ohne führende 0 beginnen, nur Ziffern">
  </div>

  <div>
    <label>Benutzerrechte</label>
    <select [(ngModel)]="newUser.userrole" class="form-control">
      <option *ngFor="let role of userRoles" [value]="role.id">{{role.description}}</option>
    </select>
  </div>

  <p></p>

  <div>
    <button type="cancel" (click)="cancel()" class="btn btn-danger btn-sm mx-2">Abbrechen</button>
    <button type="submit" [disabled]="!newUser.userrole" (click)="save()" class="btn btn-success btn-sm mx-2">Speichern</button>
  </div>


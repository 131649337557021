<div class="modal-header">
  <h4 class="modal-title" *ngIf="contact.id>0">Kontakt bearbeiten</h4>
  <h4 class="modal-title" *ngIf="contact.id==0">Kontakt anlegen</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="subForm" class="form-horizontal">
    <div class="form-group row">
      <label for="type" class="col-sm-3 control-label">Typ</label>
      <div class="col-sm-9">
        <select formControlName="type" id="type" class="form-control">
          <option *ngFor="let type of contactTypes" [value]="type.id">
            {{type.name}}
          </option>
        </select>
        <span *ngIf="subForm.get('type').invalid && subForm.get('type').touched" class="help-block">Bitte einen Kontakttyp auswählen!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="details" class="col-sm-3 control-label">Kontakt</label>
      <div class="col-sm-9">
        <input formControlName="details" type="text" id="details" class="form-control" maxlength="60">
        <span *ngIf="subForm.get('details').invalid && subForm.get('details').touched" class="help-block">Bitte die Kontaktdaten mit maximal 60 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="comment" class="col-sm-3 control-label">Kommentar</label>
      <div class="col-sm-9">
        <textarea formControlName="comment" type="text" id="comment" class="form-control" maxlength="60"></textarea>
        <span *ngIf="subForm.get('comment').invalid && subForm.get('comment').touched" class="help-block">Bitte maximal 60 Zeichen angeben!</span>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="mx-2 btn btn-sm btn-warning pull-left" (click)="cancel()">schliessen</button>
  <button *ngIf="contact.id>0" class="mx-2 btn btn-sm btn-danger pull-right" (click)="delete()">Kontakt löschen</button>
  <button *ngIf="contact.id==0" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="create()">Konatkt anlegen</button>
  <button *ngIf="contact.id>0" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="update()">Änderungen speichern</button>
</div>

export interface IRoutes {
  LOGIN: string,
  DASHBOARD: string,
  CALENDAR: string,
  CASES: string,
  PERSONS: string,
  BUNDESANZEIGER: string,
  NAMENSVERZEICHNISSE: string,
  MAP: string,
  STATISTICS: string,
  ADDRESSBOOK: string,
  KASSENBUCH: string,
  USERS: string,
  GLOBALSEARCH: string,
  ADMINISTRATION: string,
  HELP: string,
  HISTORY: string,
  DOCUMENT_GENERATION: string
}

// also add new routes in module-route-subpage.interface
export interface IModulePages {
  CASES: {
      CASELIST: string,
      CASELISTSCREEN: string,
      AUSKEHRUNGLISTSCREEN: string,
      CASEDETAIL: string,
      CASEDETAILMAX: string,
      CASESEARCH: string,
      PERSONDETAIL: string,
      BOERSE: string,
      POOL: string,
      STAMMBAUM: string
    },
  CALENDAR: {
    MONTH: string,
    LIST: string,
    FORM: string,
    FORM_OWN: string,
    FORM_CHANGE: string
  },
  PERSONS: {
    PERSONLIST: string,
    PERSONDETAIL: string
  },
  ADMINISTRATION: {
    USER: string,
    USER_CREATE: string,
    USER_EDIT: string,
    GENERATED_DOCS: string
  },
  ADDRESSBOOK: {
    SEARCH: string,
    EDIT: string
  },
  NAMENSVERZEICHNISSE: {
    SEARCH: string,
    ZUFALL: string,
    EINTRAG: string,
    STANDESAEMTER: string,
    VERZEICHNISSE: string,
    LAENDER: string,
    STAEDTE: string,
    AUTOPARSER: string
  },
  USERS: {
    USER_LIST: string,
    USER_EDIT: string,
    USER_TREE: string
  },
  DOCUMENT_GENERATION: {
    MAIN: string
  }
}

export const MODULES: IRoutes = {
  LOGIN: 'LOGIN',
  DASHBOARD: 'DASHBOARD',
  CALENDAR: 'CALENDAR',
  CASES: 'CASES',
  PERSONS: 'PERSONS',
  BUNDESANZEIGER: 'BUNDESANZEIGER',
  NAMENSVERZEICHNISSE: 'NAMENSVERZEICHNISSE',
  MAP: 'MAP',
  STATISTICS: 'STATISTICS',
  ADDRESSBOOK: 'ADDRESSBOOK',
  KASSENBUCH: 'KASSENBUCH',
  USERS: 'USERS',
  GLOBALSEARCH: 'GLOBALSEARCH',
  ADMINISTRATION: 'ADMINISTRATION',
  HELP: 'HELP',
  HISTORY: 'HISTORY',
  DOCUMENT_GENERATION: 'DOCUMENT_GENERATION'
};

export const MODULE_PAGES: IModulePages = {
  CASES: {
    CASELIST: 'CASELIST',
    CASELISTSCREEN: 'CASELISTSCREEN',
    AUSKEHRUNGLISTSCREEN: 'AUSKEHRUNGLISTSCREEN',
    CASEDETAIL: 'CASEDETAIL',
    CASEDETAILMAX: 'CASEDETAILMAX',
    CASESEARCH: 'CASESEARCH',
    PERSONDETAIL: 'PERSONDETAIL',
    BOERSE: 'BOERSE',
    POOL: 'POOL',
    STAMMBAUM: 'STAMMBAUM'
  },
  CALENDAR: {
    MONTH: 'MONTH',
    LIST: 'LIST',
    FORM: 'FORM',
    FORM_OWN: 'FORM_OWN',
    FORM_CHANGE: 'FORM_CHANGE'
  },
  PERSONS: {
    PERSONLIST: 'PERSONLIST',
    PERSONDETAIL: 'PERSONDETAIL'
  },
  ADMINISTRATION: {
    USER: 'USER',
    USER_CREATE: 'USER_CREATE',
    USER_EDIT: 'USER_EDIT',
    GENERATED_DOCS: 'GENERATED_DOCS'
  },
  ADDRESSBOOK: {
    SEARCH: 'SEARCH',
    EDIT: 'EDIT'
  },
  NAMENSVERZEICHNISSE: {
    SEARCH: 'SEARCH',
    ZUFALL: 'ZUFALL',
    EINTRAG: 'EINTRAG',
    STANDESAEMTER: 'STANDESAEMTER',
    VERZEICHNISSE: 'VERZEICHNISSE',
    LAENDER: 'LAENDER',
    STAEDTE: 'STAEDTE',
    AUTOPARSER: 'AUTOPARSER'
  },
  USERS: {
    USER_LIST: 'USER_LIST',
    USER_EDIT: 'USER_EDIT',
    USER_TREE: 'USER_TREE'
  },
  DOCUMENT_GENERATION: {
    MAIN: 'MAIN'
  }
};


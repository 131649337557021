/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./network-manager-icon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./network-manager-icon.component";
var styles_NetworkManagerIconComponent = [i0.styles];
var RenderType_NetworkManagerIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetworkManagerIconComponent, data: {} });
export { RenderType_NetworkManagerIconComponent as RenderType_NetworkManagerIconComponent };
export function View_NetworkManagerIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-circle"], ["title", "Netzwerk-Status"]], null, null, null, null, null))], null, null); }
export function View_NetworkManagerIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "network-manager-icon", [], null, null, null, View_NetworkManagerIconComponent_0, RenderType_NetworkManagerIconComponent)), i1.ɵdid(1, 49152, null, 0, i2.NetworkManagerIconComponent, [], null, null)], null, null); }
var NetworkManagerIconComponentNgFactory = i1.ɵccf("network-manager-icon", i2.NetworkManagerIconComponent, View_NetworkManagerIconComponent_Host_0, {}, {}, []);
export { NetworkManagerIconComponentNgFactory as NetworkManagerIconComponentNgFactory };

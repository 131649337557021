/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bundesanzeiger-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i5 from "../../../app-notifications/app-notification.component.ngfactory";
import * as i6 from "../../../app-notifications/app-notification.component";
import * as i7 from "@ngrx/store";
import * as i8 from "./bundesanzeiger-list.component";
import * as i9 from "../../../services/bundesanzeiger.service";
var styles_BundesanzeigerListComponent = [i0.styles];
var RenderType_BundesanzeigerListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BundesanzeigerListComponent, data: {} });
export { RenderType_BundesanzeigerListComponent as RenderType_BundesanzeigerListComponent };
function View_BundesanzeigerListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " ", " ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.bundesanzeiger_date; var currVal_1 = _v.parent.context.$implicit.court; var currVal_2 = _v.parent.context.$implicit.court_reference; var currVal_3 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_BundesanzeigerListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [", geb. ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.birth_name; _ck(_v, 1, 0, currVal_0); }); }
function View_BundesanzeigerListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " ", " ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundesanzeigerListComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCase(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["als Fall \u00FCbernehmen"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["l\u00F6schen"]))], function (_ck, _v) { var currVal_6 = _v.parent.context.$implicit.birth_name; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.bundesanzeiger_date; var currVal_1 = _v.parent.context.$implicit.court; var currVal_2 = _v.parent.context.$implicit.court_reference; var currVal_3 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.first_name; var currVal_5 = _v.parent.context.$implicit.last_name; _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.context.$implicit.text; _ck(_v, 6, 0, currVal_7); }); }
function View_BundesanzeigerListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "ngb-panel", [], null, null, null, null, null)), i1.ɵdid(2, 2113536, [[1, 4]], 3, i3.NgbPanel, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_BundesanzeigerListComponent_3)), i1.ɵdid(7, 16384, [[2, 4]], 0, i3.NgbPanelTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_BundesanzeigerListComponent_4)), i1.ɵdid(9, 16384, [[4, 4]], 0, i3.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = ("ac" + _v.context.$implicit.id); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BundesanzeigerListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-accordion", [["class", "accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i4.View_NgbAccordion_0, i4.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, [["acc", 4]], 1, i3.NgbAccordion, [i3.NgbAccordionConfig], { activeIds: [0, "activeIds"] }, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundesanzeigerListComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openAccordionPanels; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.bas; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
export function View_BundesanzeigerListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, i5.View_AppNotificationComponent_0, i5.RenderType_AppNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppNotificationComponent, [i7.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " neue Bundesanzeiger-F\u00E4lle"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundesanzeigerListComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.bas; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bas.length; _ck(_v, 3, 0, currVal_0); }); }
export function View_BundesanzeigerListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bundesanzeiger-list", [], null, null, null, View_BundesanzeigerListComponent_0, RenderType_BundesanzeigerListComponent)), i1.ɵdid(1, 245760, null, 0, i8.BundesanzeigerListComponent, [i9.BundesanzeigerService, i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BundesanzeigerListComponentNgFactory = i1.ɵccf("app-bundesanzeiger-list", i8.BundesanzeigerListComponent, View_BundesanzeigerListComponent_Host_0, {}, {}, []);
export { BundesanzeigerListComponentNgFactory as BundesanzeigerListComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./history-notes.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../notes/note-single-display/note-single-display.component.ngfactory";
import * as i3 from "../../notes/note-single-display/note-single-display.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../services/case.service";
import * as i6 from "../../services/user.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/common";
import * as i9 from "./history-notes.component";
import * as i10 from "ngx-page-scroll-core";
var styles_HistoryNotesComponent = [i0.styles];
var RenderType_HistoryNotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HistoryNotesComponent, data: {} });
export { RenderType_HistoryNotesComponent as RenderType_HistoryNotesComponent };
function View_HistoryNotesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "note-single-display", [], null, null, null, i2.View_NoteSingleDisplayComponent_0, i2.RenderType_NoteSingleDisplayComponent)), i1.ɵdid(2, 180224, null, 0, i3.NoteSingleDisplayComponent, [i4.NgbModal, i5.CaseService, i6.UserService, i7.Store], { note: [0, "note"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ("note-history-" + _v.context.$implicit.id.toString()); _ck(_v, 0, 0, currVal_0); }); }
function View_HistoryNotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_3)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recentNotes.slice(0, _co.maxResults); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HistoryNotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["letzte Aktivit\u00E4ten (Notizen)"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recentNotes && (_co.recentNotes.length > 0)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_HistoryNotesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "note-single-display", [], null, null, null, i2.View_NoteSingleDisplayComponent_0, i2.RenderType_NoteSingleDisplayComponent)), i1.ɵdid(2, 180224, null, 0, i3.NoteSingleDisplayComponent, [i4.NgbModal, i5.CaseService, i6.UserService, i7.Store], { note: [0, "note"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ("note-history-" + _v.context.$implicit.id.toString()); _ck(_v, 0, 0, currVal_0); }); }
function View_HistoryNotesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_6)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recentNotes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HistoryNotesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_5)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recentNotes && (_co.recentNotes.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HistoryNotesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card my-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoryNotesComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.maxResults > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.maxResults < 1); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HistoryNotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-notes", [], null, null, null, View_HistoryNotesComponent_0, RenderType_HistoryNotesComponent)), i1.ɵdid(1, 180224, null, 0, i9.HistoryNotesComponent, [i7.Store, i10.PageScrollService, i8.DOCUMENT], null, null)], null, null); }
var HistoryNotesComponentNgFactory = i1.ɵccf("history-notes", i9.HistoryNotesComponent, View_HistoryNotesComponent_Host_0, { maxResults: "maxResults" }, {}, []);
export { HistoryNotesComponentNgFactory as HistoryNotesComponentNgFactory };

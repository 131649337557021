<div class="m-2 p-2" style="text-align: center">
  <h5 class="ilatitle">Herzlichen Glückwunsch</h5>
  <div style="text-align: center">
    zum 7. Datenbank-Jubiläum
  </div>
  <div style="text-align: center">
    <img class="m-2" src="assets/7y_2.gif" width="438" height="328">
  </div>
  <div style="text-align: center; font-weight: bold">
    <div>2555 Tage</div>
    <div>6043 Fälle</div>
    <div>100.504 Notizen</div>
    <div>177.050 Notiz-Antworten</div>
    <div>52.449 hochgeladene Dateien</div>
  </div>
  <div style="text-align: center">
    <img class="m-2" src="assets/7y_1.gif" width="438" height="328">
  </div>
  <div>
    <button class="btn btn-sm btn-success" (click)="close()">weiter</button>
  </div>
</div>

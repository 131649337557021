<div class="p-2 m-2">
  <form [formGroup]="form">
    <h5 class="ilatitle">Vollmacht</h5>
    <div class="row">
      <div class="col-6">
        <button class="m-1 btn btn-sm btn-success" (click)="createVollmacht()">Vollmacht weibl.</button>
      </div>
      <div class="col-6">
        <button class="m-1 btn btn-sm btn-success" (click)="createVollmachtM()">Vollmacht männl.</button>
      </div>
    </div>
    <h5 class="ilatitle">Vertrag</h5>
    <div class="row">
      <div class="col-6">
        <button [disabled]="!canCreateVertrag()" class="m-1 btn btn-sm btn-success" (click)="createVertrag()">Vertrag
          weibl.
        </button>
      </div>
      <div class="col-6">
        <button [disabled]="!canCreateVertrag()" class="m-1 btn btn-sm btn-success" (click)="createVertragM()">Vertrag
          männl.
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!canCreateVertrag()">
      <div class="col-6" style="font-weight: bold; font-size: 80%; color: red;">
        Vertrag kann erst nach Eintragung der Prozente erzeugt werden
      </div>
    </div>
    <h5 class="ilatitle">Vertragsbestätigung</h5>
    <div class="row">
      <div class="col-12">
        <table class="table">
          <tr>
            <td><input formControlName="gu_needed" type="checkbox" id="gu_needed" class="mt-2 form-control" /></td>
            <td>GU benötigt</td>
          </tr>
          <tr>
            <td><input formControlName="hu_needed" type="checkbox" id="hu_needed" class="mt-2 form-control form-control-inline" /></td>
            <td>HU benötigt</td>
          </tr>
          <tr>
            <td><input formControlName="pa_needed" type="checkbox" id="pa_needed" class="mt-2 form-control form-control-inline" /></td>
            <td>PA beglaubigt benötigt</td>
          </tr>
          <tr>
            <td><input formControlName="bank_needed" type="checkbox" id="bank_needed" class="mt-2 form-control form-control-inline" /></td>
            <td>Bankverbindung benötigt</td>
          </tr>
          <tr>
            <td><input formControlName="email_needed" type="checkbox" id="email_needed" class="mt-2 form-control form-control-inline" /></td>
            <td>Email-Adresse benötigt</td>
          </tr>
          <tr>
            <td></td>
            <td><textarea [placeholder]="'Freitext-Absatz'" cols="30" rows="6" formControlName="freitext" id="freitext" class="form-control form-control-inline"></textarea></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button class="m-1 btn btn-sm btn-success" (click)="createBestaetigung()">VuV-Bestät. weibl.</button>
      </div>
      <div class="col-6">
        <button class="m-1 btn btn-sm btn-success" (click)="createBestaetigungM()">VuV-Bestät. männl.</button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <button class="m-1 btn btn-sm btn-success" (click)="close()">schliessen</button>
      </div>
    </div>
  </form>
</div>

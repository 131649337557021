<div class="card my-3">
  <div *ngIf="maxResults > 0" class="card-body">
    <h3>letzte Aktivitäten (Notizen)</h3>
    <ul *ngIf="recentNotes && recentNotes.length > 0" class="list-group list-group-flush">
      <li [id]="'note-history-' + note.id.toString()" *ngFor="let note of recentNotes.slice(0, maxResults)" class="list-group-item"><note-single-display [note]="note"></note-single-display></li>
    </ul>
  </div>
  <div *ngIf="maxResults < 1" class="card-body">
    <ul *ngIf="recentNotes && recentNotes.length > 0" class="list-group list-group-flush">
      <li [id]="'note-history-' + note.id.toString()" *ngFor="let note of recentNotes" class="list-group-item"><note-single-display [note]="note"></note-single-display></li>
    </ul>
  </div>
</div>

<div class="modal-header">
  <h4 *ngIf="person.id==0" class="modal-title">Person anlegen</h4>
  <h4 *ngIf="person.id>0" class="modal-title">Person bearbeiten</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="subForm" class="form-horizontal">
    <div class="form-group row">
      <label for="first_name" class="col-sm-3 control-label">Vorname</label>
      <div class="col-sm-9">
        <input formControlName="first_name" type="text" id="first_name" class="form-control">
        <span *ngIf="subForm.get('first_name').invalid && subForm.get('first_name').touched" class="help-block">Bitte den Vornamen mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="last_name" class="col-sm-3 control-label">Nachname</label>
      <div class="col-sm-9">
        <input formControlName="last_name" type="text" id="last_name" class="form-control">
        <span *ngIf="subForm.get('last_name').invalid && subForm.get('last_name').touched" class="help-block">Bitte den Nachnamen mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="function" class="col-sm-3 control-label">Funktion</label>
      <div class="col-sm-9">
        <input formControlName="function" type="text" id="function" class="form-control">
        <span *ngIf="subForm.get('function').invalid && subForm.get('function').touched" class="help-block">Bitte die Funktion mit maximal 50 Zeichen angeben!</span>
      </div>
    </div>
     <div class="form-group row">
      <label for="comment" class="col-sm-3 control-label">Kommentar</label>
      <div class="col-sm-9">
        <textarea formControlName="comment" type="text" id="comment" class="form-control"></textarea>
        <span *ngIf="subForm.get('comment').invalid && subForm.get('comment').touched" class="help-block">Bitte maximal 200 Zeichen angeben!</span>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="mx-2 btn btn-sm btn-warning pull-left" (click)="cancel()">schliessen</button>
  <button *ngIf="person.id>0&&person.contacts&&person.contacts.length<1" class="mx-2 btn btn-sm btn-danger pull-right" (click)="delete()">Person löschen</button>
  <button *ngIf="person.id>0" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="update()">Änderungen speichern</button>
  <button *ngIf="person.id<1" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="create()">Person anlegen</button>
</div>

<div class="m-2 p-2">
  <h4 *ngIf="editMode" class="ilatitle">Beleg bearbeiten <span *ngIf="case_id">für Fall {{case_id}}</span></h4>
  <h4 *ngIf="!editMode" class="ilatitle">Neuen Beleg erfassen <span *ngIf="case_id">für Fall {{case_id}}</span></h4>
  <form [formGroup]="form" class="m-4 form-horizontal">

    <div class="row">
      <div class="col-3">
        <label for="titel" class="custom-control-label">Beleg-Art</label>
      </div>
      <div class="col-3">
        <label for="titel" class="custom-control-label">Beleg-Bezeichnung</label>
      </div>
      <div class="col-3">
        <label for="titel" class="custom-control-label">Beleg-Nummer</label>
      </div>
      <div class="col-3">
        <label for="titel" class="custom-control-label">Beleg-Datum</label>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <select class="form-control" formControlName="art" id="art">
          <option *ngFor="let op of artOptions" [value]="op.id" [title]="op.hint">{{op.description}}</option>
        </select>
        <span *ngIf="form.get('art').invalid && form.get('art').touched" class="help-block">Bitte wählen Sie eine Beleg-Art</span>
      </div>
      <div class="col-3">
        <input type="text" maxlength="50" class="form-control" formControlName="titel" id="titel"
               (blur)="updateFirstPostenTitleOnBlur()">
        <span *ngIf="form.get('titel').invalid && form.get('titel').touched" class="help-block">Bitte eine Bezeichnung mit maximal 50 Zeichen angeben!</span>
      </div>
      <div class="col-3">
        <input type="text" maxlength="50" class="form-control" formControlName="belegnummer" id="belegnummer">
        <span *ngIf="form.get('belegnummer').invalid && form.get('belegnummer').touched" class="help-block">Bitte eine Belegnummer mit maximal 50 Zeichen angeben!</span>
      </div>
      <div class="col-3">
        <input type="date" class="form-control" formControlName="belegdatum" id="belegdatum">
        <span *ngIf="form.get('belegdatum').invalid && form.get('belegdatum').touched" class="help-block">Bitte ein belegdatum angeben!</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label for="erlaeuterung" class="custom-control-label">ggf. Erläuterung</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textarea maxlength="200" rows="3" class="form-control" formControlName="erlaeuterung"
                  id="erlaeuterung"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <table *ngIf="getEinzelpostenFormRow()">
          <tr>
            <td class="custom-control-label">Titel der Kostenposition</td>
            <td class="custom-control-label">Kategorie</td>
            <td class="custom-control-label">Währung</td>
            <td class="custom-control-label">Betrag</td>
            <td></td>
            <td class="custom-control-label">% UST</td>
            <td class="custom-control-label">Rechn.stell.</td>
            <td></td>
            <td></td>
          </tr>

          <ng-container *ngFor="let row of getEinzelpostenFormRow().controls; let i = index;">
            <tr>
              <td>
                <input type="text" class="form-control" [formControl]="row.get('postentitel') | formControl">
              </td>
              <td>
                <select class="form-control" [formControl]="row.get('kostenkategorie') | formControl">
                  <option *ngFor="let op of kostenkategorieOptions" [value]="op.id"
                          [title]="op.externer_alias">{{op.bezeichnung}}</option>
                </select>
              </td>
              <td>
                <select class="form-control" [formControl]="row.get('waehrung') | formControl">
                  <option *ngFor="let op of waehrungOptions" [value]="op.id"
                          [title]="op.hint">{{op.description}}</option>
                </select>
              </td>
              <td>
                <input type="text" class="form-control" [formControl]="row.get('betrag_brutto') | formControl"
                       [ngClass]="{'amount-invalid': row.get('betrag_brutto_invalid').value && row.get('betrag_brutto').touched}">
              </td>
              <td>
                <input type="text" class="form-control" readonly="true"
                       [formControl]="row.get('betrag_brutto_formatted') | formControl">
              </td>
              <td>
                <select class="form-control" [formControl]="row.get('ust_satz') | formControl">
                  <option [value]="0">0</option>
                  <option [value]="7">7</option>
                  <option [value]="19">19</option>
                </select>
              </td>
              <td>
                <select class="form-control" [formControl]="row.get('in_rechnung_stellen') | formControl">
                  <option [value]="1">ja</option>
                  <option [value]="0">nein</option>
                </select>
              </td>
              <td>
                <button *ngIf="i !== 0" class="btn btn-sm btn-danger" (click)="deleteRow(i)"><span
                  class="fa fa-trash"></span></button>
              </td>
              <td>
                <button *ngIf="i === getEinzelpostenFormRow().controls.length - 1" class="btn btn-sm btn-success"
                        (click)="addRow()">neue Zeile
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div><span *ngIf="row.get('postentitel').invalid && row.get('postentitel').touched" class="help-block">Bitte geben Sie einen Titel an</span>
                </div>
                <div><span *ngIf="row.get('kostenkategorie').invalid && row.get('kostenkategorie').touched"
                           class="help-block">Bitte wählen Sie eine Kostenkategorie</span></div>
                <div><span *ngIf="row.get('betrag_brutto_invalid').value && row.get('betrag_brutto').touched"
                           class="help-block">Bitte geben Sie einen gültigen Betrag ein!</span></div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>

    <div class="center mb-3 mt-3">
      <div class="row">
        <div class="col-12 mb-2" *ngIf="editMode">
          <b>vorhandene Dateien:</b>
        </div>
      </div>
      <div class="row" *ngIf="editMode && kostenposition">
        <div *ngFor="let f of kostenposition.dateien" class="col-12">{{f.filename}}
          <button class="btn btn-sm btn-success mx-2" (click)="kassenbuchService.showFile(f.id)">
            <span class="fa fa-eye"></span>
          </button>
        </div>
        <div class="col-12" *ngIf="!kostenposition.dateien || kostenposition.dateien.length === 0">
          keine Dateien vorhanden
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12" *ngIf="editMode"><b>weitere Dateien hinzufügen</b></div>
      </div>
      <ngx-file-drop [showBrowseBtn]="true" browseBtnLabel="Datei auswählen"
                     (onFileDrop)="dropped($event)" multiple="false"
                     (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <span>Datei hierhin schieben</span>
          <span class="ml-2 fa fa-bullseye fa-3x"></span>
          <span class="mx-3">oder</span>
          <button type="button" class="btn btn-primary" (click)="openFileSelector()">Datei auswählen</button>
        </ng-template>
      </ngx-file-drop>
      <div class="upload-table" *ngIf="files && files.length > 0">
        <table class="table mt-2">
          <tbody class="upload-name-style">
          <tr *ngFor="let file of files">
            <td><strong>{{ file.relativePath }}</strong></td>
            <td>
              <button (click)="removeFile(file)" class="btn btn-sm btn-warning"><span class="fa fa-remove"></span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button type="button" (click)="cancel()" class="btn btn-danger btn-sm m-2">abbrechen</button>
    <button [disabled]="form.invalid" type="button" (click)="save()"
            class="btn btn-success btn-sm m-2">speichern
    </button>
  </form>
</div>

<td>{{person_to_display.vorname}} {{person_to_display.nachname}}</td>
<td><span *ngIf="person_to_display.geburtsname">geb. {{person_to_display.geburtsname}}</span></td>
<td><span *ngIf="marriage.date"><editfield-date [date]="marriage.date" [editMode]="false"></editfield-date></span></td>
<td>{{marriage.kommentar}}</td>
<td><span *ngIf="marriage.divorced"><b>geschieden</b></span></td>
<td>
  <form [formGroup]="form">
    <div class="form-check">
      <div class="row">
        <div class="col">
          <input type="checkbox" id="needs_hu" formControlName="needs_hu" class="form-check-input">
          <label class="form-check-label" for="needs_hu">HU benötigt</label>
          <div class="mx-3 px-3"> </div>
        </div>
        <div class="col">
          <input type="checkbox" id="needs_hu_but_do_without" formControlName="needs_hu_but_do_without" class="form-check-input">
          <label class="form-check-label" for="needs_hu_but_do_without">Verzicht auf HU</label>
          <div class="mx-3 px-3"> </div>
        </div>
        <div class="col">
          <input type="checkbox" id="ordered_hu" formControlName="ordered_hu" class="form-check-input">
          <label class="form-check-label" for="ordered_hu">HU bestellt</label>
          <editfield-date *ngIf="marriage.ordered_hu_date" [editMode]="false" [date]="marriage.ordered_hu_date" class="ml-2"></editfield-date>
        </div>
      </div>
    </div>
  </form></td>
<td><button class="btn btn-sm btn-warning" (click)="editMarriage(marriage)">bearbeiten</button></td>

<div class="modal-header">
  <h4 *ngIf="institution" class="modal-title">{{institution.name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="institution" class="card-body">
    <div class="card px-2 mt-3">
      <div class="card-body">
        <p>
          <b>{{printInstitutionType(institution.type)}}</b><br />
          {{institution.comment}}
        </p>
        <p *ngFor="let address of institution.addresses">
          {{address.street}} {{address.housenumber}}<br />
          <span *ngIf="address.additional_address_information!=''">{{address.additional_address_information}}<br /></span>
          {{address.zipcode}} {{address.town}}<br />
          {{printAddressRegion(address.region)}}
        </p>
        <table *ngIf="institution.contacts">
          <tr *ngFor="let contact of institution.contacts">
            <td>{{printContactType(contact.type)}}</td><td>{{contact.details}}</td><td>{{contact.comment}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card px-2 mt-3" *ngIf="institution.persons?.length > 0">
      <div class="card-body" *ngFor="let person of institution.persons">
        <h5 class="my-2">{{person.first_name}} {{person.last_name}}</h5>
        <b>{{person.function}}</b><br />{{person.comment}}
        <table *ngIf="person.contacts">
          <tr *ngFor="let perscont of person.contacts">
            <td>{{printContactType(perscont.type)}}</td><td>{{perscont.details}}</td><td>{{perscont.comment}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-success pull-left">schließen</button>
  <button (click)="edit()" *ngIf="institution" type="button" class="m-2 btn btn-outline-danger pull-right">bearbeiten</button>
</div>

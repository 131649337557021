<div class="card-deck my-3">
  <div class="card">
    <h4>Eigene fällige Aufgaben</h4>
    <ul *ngIf="calendarEntriesOwn?.length > 0" class="list-group list-group-flush">
      <li *ngFor="let entry of calendarEntriesOwn" (click)="openDetails(entry)" class="list-group-item">{{entry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(entry.deadline_hour, entry.deadline_minute)}} von<span class="mx-2 badge badge-pill badge-warning">{{userService.getAbbreviationForUser(entry.created_by_user_id)}}</span>{{entry.title}}</li>
    </ul>
  </div>
  <div class="card">
    <h4>Fällige Aufgaben für andere</h4>
    <ul *ngIf="calendarEntriesOther?.length > 0" class="list-group list-group-flush">
      <li *ngFor="let entry of calendarEntriesOther" (click)="openDetails(entry)" class="list-group-item">{{entry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(entry.deadline_hour, entry.deadline_minute)}} für<span class="mx-2 badge badge-pill badge-success">{{userService.getAbbreviationForUser(entry.for_user_id)}}</span>{{entry.title}}</li>
    </ul>
  </div>
</div>

<div class="container-fluid">
  <app-topmenu *ngIf="(authUser | async).authUser.loggedIn && showTopMenu()" id="topmenu"
               [ui]="ui | async"
               [authUser]="authUser | async"
               [network]="network | async">
  </app-topmenu>

  <div id="wrapper" *ngIf="showLeftMenu()">
    <app-sidemenu *ngIf="(authUser | async).authUser.loggedIn" [ui]="ui | async" id="sidebar-wrapper"></app-sidemenu>

    <main *ngIf="(authUser | async).authUser.loggedIn" id="page-content-wrapper" role="main">
      <app-dashboard *ngIf="(ui | async).ui.routing.activeModule==MODULES.DASHBOARD"
                     [authUser]="(authUser | async)?.authUser"></app-dashboard>

      <case-boerse *ngIf="showCaseBoerse()"></case-boerse>
      <case-pool *ngIf="showCasePool()"></case-pool>
      <case-search *ngIf="showCaseSearch()"></case-search>
      <app-caselist-screen *ngIf="showCaseListScreen()"></app-caselist-screen>
      <app-auskehrunglist-screen *ngIf="showAuskehrungListScreen()"></app-auskehrunglist-screen>
      <calendar-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.CALENDAR" [ui]="ui | async"></calendar-entry>
      <history-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.HISTORY"></history-entry>
      <user-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.USERS" [ui]="ui | async"></user-entry>
      <addressbook-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.ADDRESSBOOK"
                         [ui]="ui | async"></addressbook-entry>
      <app-help *ngIf="(ui | async).ui.routing.activeModule==MODULES.HELP"></app-help>
      <app-changelog *ngIf="(ui | async).ui.routing.activeModule==MODULES.HELP"></app-changelog>
      <app-bundesanzeiger-list
        *ngIf="(ui | async).ui.routing.activeModule==MODULES.BUNDESANZEIGER"></app-bundesanzeiger-list>
      <namensverzeichnisse-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.NAMENSVERZEICHNISSE"
                                 [nvzState]="nvzState | async" [ui]="ui | async"
                                 [authUser]="authUser | async"></namensverzeichnisse-entry>
      <app-map *ngIf="(ui | async).ui.routing.activeModule==MODULES.MAP" id="main"></app-map>
      <administration-entry *ngIf="(ui | async).ui.routing.activeModule==MODULES.ADMINISTRATION"
                            [ui]="ui | async"></administration-entry>
      <globalsearch *ngIf="(ui | async).ui.routing.activeModule==MODULES.GLOBALSEARCH"></globalsearch>
      <app-kassenbuch-for-main-menu *ngIf="(ui | async).ui.routing.activeModule==MODULES.KASSENBUCH"></app-kassenbuch-for-main-menu>
    </main>
  </div>

  <case-detail-max *ngIf="showCaseDetailMax()"></case-detail-max>
  <person-detail *ngIf="showPersonDetail()"></person-detail>
  <app-statistics-main *ngIf="(ui | async).ui.routing.activeModule==MODULES.STATISTICS"></app-statistics-main>


</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-ap-messages.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nvz-ap-messages.component";
import * as i4 from "@ngrx/store";
var styles_NvzApMessagesComponent = [i0.styles];
var RenderType_NvzApMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzApMessagesComponent, data: {} });
export { RenderType_NvzApMessagesComponent as RenderType_NvzApMessagesComponent };
function View_NvzApMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mx-2 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_NvzApMessagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { scrollcontainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-header background-autoparser"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Server-Meldungen"])), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["scrollcontainer", 1]], null, 2, "div", [["class", "card-body"], ["style", "height: 120px; overflow-y: scroll;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApMessagesComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.autoparserState == null) ? null : _co.autoparserState.messages); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_NvzApMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-ap-messages", [], null, null, null, View_NvzApMessagesComponent_0, RenderType_NvzApMessagesComponent)), i1.ɵdid(1, 180224, null, 0, i3.NvzApMessagesComponent, [i4.Store], null, null)], null, null); }
var NvzApMessagesComponentNgFactory = i1.ɵccf("nvz-ap-messages", i3.NvzApMessagesComponent, View_NvzApMessagesComponent_Host_0, {}, {}, []);
export { NvzApMessagesComponentNgFactory as NvzApMessagesComponentNgFactory };

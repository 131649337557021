<ngb-alert *ngIf="pwdDiffer" [dismissible]="false" [type]="'danger'">Die Passwortwiederholung stimmt nicht mit dem Passwort überein</ngb-alert>
<ngb-alert *ngIf="pwdTooEasy" [dismissible]="false" [type]="'danger'">Das Passwort muss aus mindestens 8 Zeichen bestehen, davon mind. 1 Großbuchstabe, mind. 1 Kleinbuchstabe sowie mind. 1 Ziffer</ngb-alert>
<ngb-alert *ngIf="pwdChanging" [dismissible]="false" [type]="'info'">Das Passwort wird gespeichert</ngb-alert>
<ngb-alert *ngIf="pwdChangeError" [dismissible]="false" [type]="'danger'">Beim Speichern des Passworts ist ein Fehler aufgetreten</ngb-alert>
<ngb-alert *ngIf="pwdChangeComplete" [dismissible]="false" [type]="'success'">Das Passwort wurde geändert</ngb-alert>
<ngb-alert *ngIf="imageUploaded" [dismissible]="false" [type]="'success'">Das Bild wurde erfolgreich zum Server übertragen</ngb-alert>
<ngb-alert *ngIf="uploadingImage" [dismissible]="false" [type]="'info'">Das Bild wird zum Server übertragen</ngb-alert>
<ngb-alert *ngIf="imageUploadError" [dismissible]="false" [type]="'danger'">Beim Hochladen des Bildes ist ein Fehler aufgetreten</ngb-alert>
<p></p>
<div *ngIf="copiedUser&&!editMode&&!editPasswordMode">
  <h4>Mitarbeiterprofil {{copiedUser.first_name}} {{copiedUser.last_name}} <button (click)="backToList()" class="btn btn-success btn-sm">Zur Mitarbeiterübersicht</button></h4>
  <p></p>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <tr><td>Name</td><td>{{copiedUser.first_name}} {{copiedUser.last_name}}</td></tr>
      <tr><td><img [src]="profile_path + copiedUser.id" style="max-width: 300px;" alt="kein Bild gefunden"/></td><td class="textfield">{{copiedUser.profile_text}}</td></tr>
      <tr><td>Kürzel</td><td><span class="badge badge-pill badge-default">{{copiedUser.abbreviation}}</span></td></tr>
      <tr><td>Arbeitsorte</td><td>{{copiedUser.location}}</td></tr>
      <tr><td>Fremdsprachen</td><td>{{copiedUser.languages}}</td></tr>
      <tr><td>Beginn bei iLANOT</td><td>{{copiedUser.begin_ilanot}}</td></tr>
      <tr><td>sonstiges</td><td class="textfield">{{copiedUser.additional_information}}</td></tr>
      <tr><td>Telefon</td><td>{{copiedUser.mobile}}</td></tr>
      <tr><td>Email</td><td>{{copiedUser.email}}</td></tr>
      <tr *ngIf="rightsManagementService.isAllowedToAssignSenior()">
        <td>betreut durch</td>
        <td><span class="badge badge-pill badge-info">{{userService.getAbbreviationForUser(copiedUser.senior)}}</span><button (click)="changeSenior()" class="btn btn-sm mx-2 btn-default"><span class="fa fa-sm fa-pencil"></span></button></td></tr>
      <tr *ngIf="copiedUser.id==authUserId"><td><button class="btn btn-warning btn-sm" (click)="editProfile()">Meine Daten bearbeiten</button></td><td></td></tr>
      <tr *ngIf="copiedUser.id==authUserId"><td><button class="btn btn-danger btn-sm" (click)="editPassword()">Mein Passwort ändern</button></td><td></td></tr>
    </table>
  </div>
  <div>
    <h4>Benutzereinstellung</h4>
    <p><b>Hinweis:</b> Änderungen dieser Einstellungen sind erst nach dem nächsten neuen Login wirksam</p>
     <table class="table table-bordered table-striped">
      <tr>
        <td>
          <span *ngIf="copiedUser.note_reply_sort_ascending">Notiz-Antworten als Standard <b>aufsteigend</b> sortieren</span>
          <span *ngIf="!copiedUser.note_reply_sort_ascending">Notiz-Antworten als Standard <b>absteigend</b> sortieren</span>
          <button (click)="changeSorting()" class="btn btn-sm btn-info mx-2">umstellen</button>
        </td>
        <td></td></tr>
     </table>
  </div>
  <div *ngIf="copiedUser.id==authUserId || isAllowedToSeeAllUserData">
    <h4>Verwaltungsdaten</h4>
    <table class="table table-bordered table-striped">
      <tr><td>Geschlecht</td><td><select disabled class="form-control" [(ngModel)]="copiedUser.geschlecht">
        <option *ngFor="let o of genderOptions" [value]="o.value">{{o.description}}</option>
      </select></td></tr>
      <tr><td>Geburtsdatum</td><td>{{copiedUser.birthday}}</td></tr>
      <tr><td>IBAN</td><td>{{copiedUser.account_iban}}</td></tr>
      <tr><td>BIC</td><td>{{copiedUser.account_bic}}</td></tr>
      <tr><td>Name der Bank</td><td>{{copiedUser.account_institute_name}}</td></tr>
      <tr><td>Kontoinhaber</td><td>{{copiedUser.account_holder_name}}</td></tr>
    </table>
  </div>
</div>

<div *ngIf="copiedUser&&editMode">
  <h4>Mitarbeiterprofil {{copiedUser.first_name}} {{copiedUser.last_name}} <button (click)="backToList()" class="btn btn-sm btn-success">Zur Mitarbeiterübersicht</button></h4>
  <p></p>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <tr><td>Name</td><td>{{copiedUser.first_name}} {{copiedUser.last_name}}</td></tr>
      <tr><td><input class="btn btn-sm btn-info" type="file" (change)="imageUpload($event)" placeholder="Foto auswählen zum Hochladen" accept=".jpg" style="display: inline;"></td><td><textarea placeholder="hier bitte einen Text für das Mitarbeiterprofil eingeben" class="form-control" rows="8" [(ngModel)]="copiedUser.profile_text"></textarea></td></tr>
      <tr><td>Kürzel</td><td><span class="badge badge-pill badge-default">{{copiedUser.abbreviation}}</span></td></tr>
      <tr><td>Arbeitsorte</td><td><input class="form-control" [(ngModel)]="copiedUser.location" type="text" maxlength="100"></td></tr>
      <tr><td>Fremdsprachen</td><td><input class="form-control" [(ngModel)]="copiedUser.languages" maxlength="100" type="text"></td></tr>
      <tr><td>Beginn bei iLANOT</td><td><input class="form-control" [(ngModel)]="copiedUser.begin_ilanot" maxlength="20" type="text"></td></tr>
      <tr><td>sonstiges</td><td><textarea class="form-control" rows="5" [(ngModel)]="copiedUser.additional_information"></textarea></td></tr>
      <tr><td>Telefon</td><td><input class="form-control" [(ngModel)]="copiedUser.mobile" maxlength="50" type="text"></td></tr>
      <tr><td>Email</td><td>{{copiedUser.email}}</td></tr>
      <tr *ngIf="copiedUser.id==authUserId"><td></td><td><button class="btn btn-sm btn-info" (click)="cancelEdit()">abbrechen</button> <button class="btn btn-danger btn-sm" (click)="save()">speichern</button></td></tr>
    </table>
  </div>
  <div *ngIf="copiedUser.id==authUserId || isAllowedToSeeAllUserData">
    <h4>Verwaltungsdaten</h4>
    <table class="table table-bordered table-striped">
      <tr><td>Geschlecht</td><td><select class="form-control" [(ngModel)]="copiedUser.geschlecht">
        <option *ngFor="let o of genderOptions" [value]="o.value">{{o.description}}</option>
      </select></td></tr>
      <tr><td>Geburtsdatum</td><td><input class="form-control" [(ngModel)]="copiedUser.birthday" type="text" maxlength="20"></td></tr>
      <tr><td>IBAN</td><td><input class="form-control" [(ngModel)]="copiedUser.account_iban" type="text" maxlength="32"></td></tr>
      <tr><td>BIC</td><td><input class="form-control" [(ngModel)]="copiedUser.account_bic" type="text" maxlength="16"></td></tr>
      <tr><td>Name der Bank</td><td><input class="form-control" [(ngModel)]="copiedUser.account_institute_name" type="text" maxlength="32"></td></tr>
      <tr><td>Kontoinhaber</td><td><input class="form-control" [(ngModel)]="copiedUser.account_holder_name" type="text" maxlength="32"></td></tr>
    </table>
  </div>
</div>

<div *ngIf="copiedUser&&editPasswordMode">
  <h4>Mitarbeiterprofil {{copiedUser.first_name}} {{copiedUser.last_name}} <button (click)="backToList()" class="btn btn-sm btn-success">Zur Mitarbeiterübersicht</button></h4>
  <p></p>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <tr><td>Name</td><td>{{copiedUser.first_name}} {{copiedUser.last_name}}</td></tr>
      <tr><td>altes Passwort</td><td><input type="password" class="form-control" [(ngModel)]="oldPwd"></td></tr>
      <tr><td>neues Passwort</td><td><input type="password" class="form-control" [(ngModel)]="newPwd"></td></tr>
      <tr><td>neues Passwort wiederholen</td><td><input type="password" class="form-control" [(ngModel)]="newPwdRepeat"></td></tr>
      <tr *ngIf="copiedUser.id==authUserId"><td></td><td><button class="btn btn-sm btn-info" (click)="cancelEditPassword()">abbrechen</button> <button class="btn btn-sm btn-danger" (click)="changePassword()">Passwort ändern</button></td></tr>
    </table>
  </div>
</div>



/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calendar-list.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/calendar-entry.service";
import * as i7 from "../../services/user.service";
var styles_CalendarListComponent = [i0.styles];
var RenderType_CalendarListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarListComponent, data: {} });
export { RenderType_CalendarListComponent as RenderType_CalendarListComponent };
function View_CalendarListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " von"])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.deadline_timestamp, "dd.MM.y")); var currVal_1 = _co.calendarEntryService.convertTimeForOutputWithText(_v.context.$implicit.deadline_hour, _v.context.$implicit.deadline_minute); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.userService.getAbbreviationForUser(_v.context.$implicit.created_by_user_id); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); }); }
function View_CalendarListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " f\u00FCr"])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.deadline_timestamp, "dd.MM.y")); var currVal_1 = _co.calendarEntryService.convertTimeForOutputWithText(_v.context.$implicit.deadline_hour, _v.context.$implicit.deadline_minute); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.userService.getAbbreviationForUser(_v.context.$implicit.for_user_id); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); }); }
function View_CalendarListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", " f\u00FCr"])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" von"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(9, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.deadline_timestamp, "dd.MM.y")); var currVal_1 = _co.calendarEntryService.convertTimeForOutputWithText(_v.context.$implicit.deadline_hour, _v.context.$implicit.deadline_minute); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.userService.getAbbreviationForUser(_v.context.$implicit.for_user_id); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.userService.getAbbreviationForUser(_v.context.$implicit.created_by_user_id); _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_4); }); }
export function View_CalendarListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h3", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aufgaben Listenansicht "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "mx-3 btn btn-primary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMonth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur Kalenderansicht wechseln"])), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h4", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Eigene Aufgaben "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "mx-3 btn btn-success btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFormOwn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["neu anlegen"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarListComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "h4", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["F\u00FCr andere erstellte Aufgaben "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "mx-3 btn btn-success btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["neu anlegen"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarListComponent_2)), i1.ɵdid(21, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 5, "div", [["class", "card mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "h4", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erledigte Aufgaben der letzten 14 Tage"])), (_l()(), i1.ɵeld(25, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarListComponent_3)), i1.ɵdid(27, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarEntriesOwn; _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.calendarEntriesOther; _ck(_v, 21, 0, currVal_1); var currVal_2 = _co.calendarEntriesDone; _ck(_v, 27, 0, currVal_2); }, null); }
export function View_CalendarListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "calendar-list", [], null, null, null, View_CalendarListComponent_0, RenderType_CalendarListComponent)), i1.ɵdid(1, 180224, null, 0, i3.CalendarListComponent, [i4.Store, i5.NgbModal, i6.CalendarEntryService, i7.UserService], null, null)], null, null); }
var CalendarListComponentNgFactory = i1.ɵccf("calendar-list", i3.CalendarListComponent, View_CalendarListComponent_Host_0, {}, {}, []);
export { CalendarListComponentNgFactory as CalendarListComponentNgFactory };

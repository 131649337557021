/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-list/user-list.component.ngfactory";
import * as i3 from "../user-list/user-list.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/user.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../services/rights-management.service";
import * as i8 from "../user-profile-show/user-profile-show.component.ngfactory";
import * as i9 from "../user-profile-show/user-profile-show.component";
import * as i10 from "../../services/constants.service";
import * as i11 from "../user-tree/user-tree.component.ngfactory";
import * as i12 from "../user-tree/user-tree.component";
import * as i13 from "../../app-notifications/app-notification.component.ngfactory";
import * as i14 from "../../app-notifications/app-notification.component";
import * as i15 from "@angular/common";
import * as i16 from "./user-entry.component";
var styles_UserEntryComponent = [i0.styles];
var RenderType_UserEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserEntryComponent, data: {} });
export { RenderType_UserEntryComponent as RenderType_UserEntryComponent };
function View_UserEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-list", [], null, null, null, i2.View_UserListComponent_0, i2.RenderType_UserListComponent)), i1.ɵdid(1, 245760, null, 0, i3.UserListComponent, [i4.Store, i5.UserService, i6.NgbModal, i7.RightsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_UserEntryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-profile-show", [], null, null, null, i8.View_UserProfileShowComponent_0, i8.RenderType_UserProfileShowComponent)), i1.ɵdid(1, 245760, null, 0, i9.UserProfileShowComponent, [i5.UserService, i7.RightsManagementService, i10.ConstantsService, i6.NgbModal, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_UserEntryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-tree", [], null, null, null, i11.View_UserTreeComponent_0, i11.RenderType_UserTreeComponent)), i1.ɵdid(1, 245760, null, 0, i12.UserTreeComponent, [i4.Store, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UserEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, i13.View_AppNotificationComponent_0, i13.RenderType_AppNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i14.AppNotificationComponent, [i4.Store], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserEntryComponent_1)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserEntryComponent_2)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserEntryComponent_3)), i1.ɵdid(7, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ui.ui.routing.activePageInModule.USERS == _co.MODULE_PAGES.USERS.USER_LIST); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.ui.ui.routing.activePageInModule.USERS == _co.MODULE_PAGES.USERS.USER_EDIT); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.ui.ui.routing.activePageInModule.USERS === _co.MODULE_PAGES.USERS.USER_TREE); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_UserEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-entry", [], null, null, null, View_UserEntryComponent_0, RenderType_UserEntryComponent)), i1.ɵdid(1, 114688, null, 0, i16.UserEntryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserEntryComponentNgFactory = i1.ɵccf("user-entry", i16.UserEntryComponent, View_UserEntryComponent_Host_0, { ui: "ui" }, {}, []);
export { UserEntryComponentNgFactory as UserEntryComponentNgFactory };

<div class="card my-3">
  <div class="card-text">
    <div class="table-responsive">
      <h3 *ngIf="headline!=''">{{headline}}</h3>
      <h5 *ngIf="!copiedCases">0 Datensätze gefunden</h5>
      <h5 *ngIf="copiedCases && resultCount > 100">{{resultCount}} Datensätze gefunden, zeige {{this.pagination * 100 +1}} - {{Math.min((this.pagination + 1) * 100, resultCount)}}
        <button class="pull-right btn btn-sm btn-link forward" (click)="paginationUp()"><i class="fa fa-lg fa-step-forward"></i></button>
        <button class="pull-right btn btn-sm btn-link backward" (click)="paginationDown()"><i class="fa fa-lg fa-step-backward"></i></button>
      </h5>
      <h5 *ngIf="copiedCases && resultCount <= 100">{{resultCount}} Datensätze gefunden</h5>
      <table *ngIf="copiedCases && !recalculating" class="table table-hover table-striped table-sm">
        <tr>
          <th>Fall-ID</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Geburtsname</th>
          <th>Gericht</th>
          <th *ngIf="mode === 6 || mode === 7">Geburtsort</th>
          <th>Wert</th>
          <th>Immob.</th>
          <th>Fisk.</th>
          <th>Team</th>
          <th>Quelle</th>
          <th *ngIf="mode !== 6 && mode !== 7">Status</th>
        </tr>
        <tr (click)="onTableClicked(currentCase)" *ngFor="let currentCase of copiedCases.cases">
          <td>{{currentCase.id}}</td>
          <td>{{currentCase.first_name}}</td>
          <td>{{currentCase.last_name}}</td>
          <td>{{currentCase.birth_name}}</td>
          <td>{{currentCase.court}}</td>
          <td *ngIf="mode === 6 || mode === 7">{{currentCase.birth_place}}</td>
          <td>{{currentCase.value}}</td>
          <td>
            <i *ngIf="!currentCase.real_estate||currentCase.real_estate=='false'" class="fa fa-remove" style="color: red;"></i>
            <i *ngIf="currentCase.real_estate&&currentCase.real_estate!='false'&&currentCase.real_estate!=''" class="fa fa-check" style="color: green;"></i>
          </td>
          <td>
            <i *ngIf="!currentCase.fiscal||currentCase.fiscal=='false'" class="fa fa-remove" style="color: red;"></i>
            <i *ngIf="currentCase.fiscal&&currentCase.fiscal!='false'&&currentCase.fiscal!=''" class="fa fa-check" style="color: green;"></i>
          </td>
          <td><ng-container *ngFor="let member of currentCase.team_member_details">
            <span *ngIf="!member.deleted && member.leader" class="badge badge-pill badge-success">{{userService.getAbbreviationForUser(member.user_id)}}</span>
            <span *ngIf="!member.deleted && !member.leader && member.ebs_doku"
                  class="badge badge-pill badge-danger">{{userService.getAbbreviationForUser(member.user_id)}}</span>
            <span *ngIf="!member.deleted && !member.leader && !member.ebs_doku"
                  class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
            <span *ngIf="false && member.deleted" style="background-color: lightgray;" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
          </ng-container></td>
          <td>{{currentCase.source}}</td>
          <td *ngIf="mode !== 6 && mode !== 7">{{currentCase.status}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-input-form.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./date-input-form.component";
var styles_DateInputFormComponent = [i0.styles];
var RenderType_DateInputFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateInputFormComponent, data: {} });
export { RenderType_DateInputFormComponent as RenderType_DateInputFormComponent };
export function View_DateInputFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "table", [["class", "table no-marg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [["style", "width: 50%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "form-control col-sm-8"], ["maxlength", "20"], ["placeholder", "ttmmjjjj ?-+#"], ["size", "20"]], [[8, "disabled", 0], [8, "value", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setValue($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.text; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.cssClass; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.dateDisplay; _ck(_v, 7, 0, currVal_3); }); }
export function View_DateInputFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "date-input-form", [], null, null, null, View_DateInputFormComponent_0, RenderType_DateInputFormComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DateInputFormComponent]), i1.ɵdid(2, 49152, null, 0, i3.DateInputFormComponent, [], null, null)], null, null); }
var DateInputFormComponentNgFactory = i1.ɵccf("date-input-form", i3.DateInputFormComponent, View_DateInputFormComponent_Host_0, { displayOnly: "displayOnly" }, {}, []);
export { DateInputFormComponentNgFactory as DateInputFormComponentNgFactory };

<div class="p-2 m-2">
  <h4 class="ilatitle">Erbe <ng-container *ngIf="erbe">{{ erbe.vorname }} {{ erbe.nachname }}</ng-container> bearbeiten</h4>
  <table class="table table-sm">
    <tr><td>Erbschaftssteuer-Erklärung gewünscht</td><td><editfield-checkbox [(checkbox)]="erbe.steuererklaerung_needed" [editMode]="true"></editfield-checkbox></td></tr>
    <tr><td>Erbschaftssteuer-Erklärung erledigt</td><td><editfield-checkbox [(checkbox)]="erbe.steuererklaerung_done" [editMode]="true"></editfield-checkbox></td></tr>
    <tr><td>Unbedenklichkeitsbescheinigung benötigt</td><td><editfield-checkbox [(checkbox)]="erbe.unbedenklichkeit_needed" [editMode]="true"></editfield-checkbox></td></tr>
    <tr><td>Unbedenklichkeitsbescheinigung vorhanden</td><td><editfield-checkbox [(checkbox)]="erbe.unbedenklichkeit_done" [editMode]="true"></editfield-checkbox></td></tr>
  </table>
  <div>
    <button class="btn btn-sm btn-danger mx-2" (click)="close()">abbrechen</button>
    <button class="btn btn-sm btn-success mx-2" (click)="save()">speichern</button>
  </div>
</div>

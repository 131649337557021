<div class="row">
  <h3>Globale Suchfunktionen</h3>
</div>
<app-notification [limitToSlot]="slot"></app-notification>

<h4>Notizen</h4>
<div class="form-group row">
  <div class="col-md-6 col-sm-6">
    <input [(ngModel)]="go_to_note_id" class="form-control form-inline" placeholder="Notiz-ID (ohne #)">
  </div>
  <div class="col-md-6 col-sm-6">
    <button (click)="goToNote()" class="btn btn-success">direkt zu dieser Notiz</button>
  </div>
</div>
<div class="form-group row">
  <div class="col-md-6 col-sm-6">
    <input [(ngModel)]="note_search" class="form-control form-inline" placeholder="(UND-verknüpfte) Suchbegriffe durch Leerzeichen getrennt eingeben">
  </div>
  <div class="col-md-6 col-sm-6">
    <button (click)="searchNotes()" class="btn btn-success">Notizen durchsuchen</button>
  </div>
</div>

<div class="my-3">
  <h4>{{search_results?.length}} Suchergebnisse (max. 200 Treffer werden angezeigt)</h4>
  <ul *ngIf="search_results" class="list-group list-group-flush">
    <li *ngFor="let note of search_results" class="list-group-item"><note-single-display [note]="note"></note-single-display></li>
  </ul>
  <div *ngIf="searching"><i class="fa fa-cog fa-spin"></i> Notizen werden durchsucht, bitte warten...</div>
  <div *ngIf="!searching && search_results && (search_results.length == 0)">keine Treffer gefunden</div>
</div>

<h4>Telefonnummern</h4>
<div class="form-group row">
  <div class="col-md-6 col-sm-6">
    <input [(ngModel)]="tel_search" class="form-control form-inline" placeholder="Telefonnummer bzw. Telefonnummer-Teil">
  </div>
  <div class="col-md-6 col-sm-6">
    <button (click)="searchTel()" class="btn btn-success">Telefonnummer suchen</button>
  </div>
</div>

<div class="my-3">
  <h4>{{search_results_tel?.length}} Suchergebnisse</h4>
  <table *ngIf="search_results_tel && search_results_tel.length > 0" class="table table-sm">
    <tr><th>Treffer</th><th>Typ</th><th>Nummer</th><th></th></tr>
    <tr *ngFor="let res of search_results_tel">
      <td>{{res.text}}</td>
      <td>{{printType(res.type)}}</td>
      <td>{{res.number}}</td>
      <td><button *ngIf="res.type === 'person'" (click)="goToPerson(res.id)" class="btn btn-sm btn-success">zur Person</button></td>
    </tr>
  </table>
  <div *ngIf="searchingTel"><i class="fa fa-cog fa-spin"></i> Telefonnummer wird gesucht, bitte warten...</div>
  <div *ngIf="!searchingTel && search_results_tel && (search_results_tel.length == 0)">Telefonnummer nicht gefunden</div>
</div>





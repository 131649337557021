<svg *ngIf="width > 0 && height > 0 && original_height && original_width" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/1999/XSL/Transform" class="stb-main"
     [attr.width]="width" [attr.height]="height" [attr.viewBox]="getViewBox()" (click)="goTo($event)">

  <ng-container *ngFor="let c of connections_m">
    <svg:g stammbaum-tree-marriage-connection [mapOnly]="true" [version]="_stammbaumState?.stammbaum.version" [treeViewMode]="_stammbaumState.treeViewMode" [p1]="c.p1" [p2]="c.p2" [m]="c.m"></svg:g>
  </ng-container>

  <ng-container *ngFor="let c of connections_c">
    <svg:g stammbaum-tree-children-connection [mapOnly]="true" [version]="_stammbaumState?.stammbaum.version" [treeViewMode]="_stammbaumState.treeViewMode" [p]="c.p" [c]="c.c"></svg:g>
  </ng-container>

  <ng-container *ngFor="let p of _stammbaumState?.stammbaum.persons; let i = index;">
    <svg:g stammbaum-tree-person [mapOnly]="true" [person]="p" [version]="_stammbaumState?.stammbaum.version" [treeViewMode]="_stammbaumState.treeViewMode"></svg:g>
  </ng-container>

  <rect [attr.width]="scrollPos.width" [attr.height]="scrollPos.height" [attr.x]="scrollPos.x" [attr.y]="scrollPos.y" style="fill:rgb(255,255,0);fill-opacity:0.35;stroke-width:4;stroke-opacity:0.9;stroke:rgb(255,0,0)" />

</svg>

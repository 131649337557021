<div class="m-2 p-2">
  <h3>Verzeichnis überprüfen</h3>
  <table class="table table-striped table-sm">
    <tr><td>Einträge gesamt</td><td>{{total_number}}</td></tr>
    <tr><td>erste Urkundennummer</td><td>{{first_number}}</td></tr>
    <tr><td>letzte Urkundennummer</td><td>{{last_number}}</td></tr>
    <tr><td>fehlende Urkundennummern</td><td><span *ngFor="let number of missing_numbers">{{number}} </span></td></tr>
    <tr><td>doppelt auftretende Urkundennummern</td><td><span *ngFor="let number of double_numbers">{{number}} </span></td></tr>
    <tr><td>mehr als doppelt auftretende Urkundennummern</td><td><span *ngFor="let number of multiple_numbers">{{number}} </span></td></tr>
  </table>
  <button type="button" (click)="back()" class="btn btn-success btn-sm m-2">schliessen</button>
</div>

<div class="m-2 p-2">
  <h4 *ngIf="!editMode">Person hinzufügen</h4>
  <h4 *ngIf="editMode">Person bearbeiten</h4>

  <form [formGroup]="form" class="mt-3">
    <table class="table table-sm">
      <tr>
        <td style="width: 30%;">Personen-Typ</td>
        <td style="width: 70%;">
          <select formControlName="person_type" type="text" id="person_type" class="form-control">
            <option *ngFor="let opt of getPersonTypeOptions()" [value]="opt.id">{{opt.description}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="form-check">
            <div class="row">
              <div class="col">
                &nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="wird_gesucht" formControlName="wird_gesucht" class="form-check-input">
                <label class="form-check-label" for="wird_gesucht">wird gesucht</label>
              </div>
              <div class="col">
                <input type="checkbox" id="sicher_keine_nachkommen" formControlName="sicher_keine_nachkommen" class="form-check-input">
                <label class="form-check-label" for="sicher_keine_nachkommen">hat sicher keine Nachkommen</label>
              </div>
              <div class="col">
                <input type="checkbox" id="nk_in_vierter_eo_nicht_erbberechtigt" formControlName="nk_in_vierter_eo_nicht_erbberechtigt" class="form-check-input">
                <label class="form-check-label" for="nk_in_vierter_eo_nicht_erbberechtigt">Nachkommen in 4. EO nicht erbberechtigt</label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Nachname</td>
        <td><input formControlName="nachname" type="text" id="nachname" class="form-control"></td>
      </tr>
      <tr>
        <td>Vornamen</td>
        <td><input formControlName="vorname" type="text" id="vorname" class="form-control"></td>
      </tr>
      <tr>
        <td>Geburtsname</td>
        <td><input formControlName="geburtsname" type="text" id="geburtsname" class="form-control"></td>
      </tr>
      <tr>
        <td>Geburtsdatum</td>
        <td><date-input-form formControlName="geburtsdatum" type="text" id="geburtsdatum" class="form-control"></date-input-form></td>
      </tr>
      <tr>
        <td>Geburtsort</td>
        <td><input formControlName="geburtsinfo" type="text" id="geburtsinfo" class="form-control"></td>
      </tr>
      <tr>
        <td>Geburtsland (wenn nicht Deutschland)</td>
        <td><input formControlName="geburtsland" type="text" id="geburtsland" class="form-control"></td>
      </tr>
      <tr>
        <td>Sterbedatum</td>
        <td><date-input-form formControlName="sterbedatum" type="text" id="sterbedatum" class="form-control"></date-input-form></td>
      </tr>
      <tr>
        <td>Sterbeort</td>
        <td><input formControlName="sterbeinfo" type="text" id="sterbeinfo" class="form-control"></td>
      </tr>
      <tr>
        <td>Sterbeland (wenn nicht Deutschland)</td>
        <td><input formControlName="sterbeland" type="text" id="sterbeland" class="form-control"></td>
      </tr>
      <tr *ngIf="editMode">
        <td>Eheschließungen <button (click)="addEhe()" class="btn btn-sm btn-success mx-2">Hinzufügen</button></td>
        <td>
          <table *ngIf="marriages && marriages.length > 0" class="table table-sm">
            <tr display-marriage *ngFor="let mar of marriages" [marriage]="mar" [person_id]="person_id" (editMarriageSignal)="editEhe($event)"></tr>
          </table>
        </td>
      </tr>
      <tr>
        <td>Kommentar</td>
        <td><input [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" formControlName="kommentar" type="text" id="kommentar" class="form-control"></td>
      </tr>
      <tr>
        <td>Urkunden</td>
        <td>
          <div class="form-check">
            <div class="row">
              <div class="col">
                &nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="needs_gu" formControlName="needs_gu" class="form-check-input">
                <label class="form-check-label" for="needs_gu">GU benötigt</label>
              </div>
              <div class="col">
                <input type="checkbox" id="ordered_gu" formControlName="ordered_gu" class="form-check-input">
                <label class="form-check-label" for="needs_gu">GU bestellt</label>
              </div>
              <div class="col">
                <input type="checkbox" id="needs_gu_but_do_without" formControlName="needs_gu_but_do_without" class="form-check-input">
                <label class="form-check-label" for="needs_gu_but_do_without">Verzicht auf GU</label>
              </div>
              <div class="col">
                <date-input-form formControlName="ordered_gu_date" type="text" id="ordered_gu_date" placeholder="Bestelldatum"></date-input-form>
              </div>
            </div>
          </div>
          <div class="form-check">
            <div class="row">
              <div class="col">
                &nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="needs_su" formControlName="needs_su" class="form-check-input">
                <label class="form-check-label" for="needs_su">SU benötigt</label>
              </div>
              <div class="col">
                <input type="checkbox" id="ordered_su" formControlName="ordered_su" class="form-check-input">
                <label class="form-check-label" for="ordered_su">SU bestellt</label>
              </div>
              <div class="col">
                <input type="checkbox" id="needs_su_but_do_without" formControlName="needs_su_but_do_without" class="form-check-input">
                <label class="form-check-label" for="needs_su_but_do_without">Verzicht auf SU</label>
              </div>
              <div class="col">
                <date-input-form formControlName="ordered_su_date" type="text" id="ordered_su_date" placeholder="Bestelldatum"></date-input-form>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </form>
  <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
  <button (click)="check()" [disabled]="person_with_status_erblasser_exists && (+current_person_type === 1)" class="m-2 btn btn-sm btn-success">speichern</button>
  <p class="form-error" *ngIf="person_with_status_erblasser_exists && (+current_person_type === 1)">Es darf nur maximal eine Person als Erblasser festgelegt werden.</p>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editfield-address.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../editfield-text/editfield-text.component.ngfactory";
import * as i3 from "../editfield-text/editfield-text.component";
import * as i4 from "@angular/common";
import * as i5 from "./editfield-address.component";
var styles_EditfieldAddressComponent = [i0.styles];
var RenderType_EditfieldAddressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditfieldAddressComponent, data: {} });
export { RenderType_EditfieldAddressComponent as RenderType_EditfieldAddressComponent };
function View_EditfieldAddressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_EditfieldAddressComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_EditfieldAddressComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_EditfieldAddressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.street = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(2, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "editfield-text", [["class", "ml-2"]], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.house_number = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(4, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldAddressComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.additional_information = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(8, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldAddressComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.zipcode = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(12, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "editfield-text", [["class", "ml-2"]], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.town = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(14, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldAddressComponent_4)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.address.country = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(18, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.street; var currVal_1 = _co.editMode; var currVal_2 = "Stra\u00DFe"; var currVal_3 = 50; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.address.house_number; var currVal_5 = _co.editMode; var currVal_6 = "Nummer"; var currVal_7 = 20; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = !_co.editMode; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.address.additional_information; var currVal_10 = _co.editMode; var currVal_11 = "Zusatzangaben"; var currVal_12 = 50; _ck(_v, 8, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = (!_co.editMode && (_co.address.additional_information != "")); _ck(_v, 10, 0, currVal_13); var currVal_14 = _co.address.zipcode; var currVal_15 = _co.editMode; var currVal_16 = "PLZ"; var currVal_17 = 10; _ck(_v, 12, 0, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.address.town; var currVal_19 = _co.editMode; var currVal_20 = "Ort"; var currVal_21 = 50; _ck(_v, 14, 0, currVal_18, currVal_19, currVal_20, currVal_21); var currVal_22 = !_co.editMode; _ck(_v, 16, 0, currVal_22); var currVal_23 = _co.address.country; var currVal_24 = _co.editMode; var currVal_25 = "Land"; var currVal_26 = 50; _ck(_v, 18, 0, currVal_23, currVal_24, currVal_25, currVal_26); }, null); }
export function View_EditfieldAddressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldAddressComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditfieldAddressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "editfield-address", [], null, null, null, View_EditfieldAddressComponent_0, RenderType_EditfieldAddressComponent)), i1.ɵdid(1, 638976, null, 0, i5.EditfieldAddressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditfieldAddressComponentNgFactory = i1.ɵccf("editfield-address", i5.EditfieldAddressComponent, View_EditfieldAddressComponent_Host_0, { address: "address", editMode: "editMode" }, { addressChange: "addressChange" }, []);
export { EditfieldAddressComponentNgFactory as EditfieldAddressComponentNgFactory };

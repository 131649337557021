import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Observable, Subscription} from 'rxjs';
import {CaseService} from '../../services/case.service';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {MODULE_PAGES, MODULES} from '../../routing/routes.model';
import {ICaseState} from '../../store/case-state.interface';
import {INetworkState} from '../../store/network-state.interface';
import {NetworkService} from '../../services/network.service';
import {IUserRole} from '../../models/userrole.interface';
import {INVZState} from '../../store/nvz-state.interface';
import {AutoparserWebsocketService} from '../../services/autoparser-websocket.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {UserNotificationPopupComponent} from '../../tools/user-notification-popup/user-notification-popup.component';
import {WelcomePopupComponent} from "../../tools/welcome-popup/welcome-popup.component";

@Component({
  selector: 'app-entry',
  templateUrl: './app-entry.component.html',
  styleUrls: ['./app-entry.component.css']
})

export class AppEntryComponent implements OnInit, OnDestroy {
  MODULES;
  cases: Observable<ICaseState>;
  nvzState: Observable<INVZState>;
  ui: Observable<{ui: IUi}>;
  uiState: IUi;
  uiStateSub: Subscription;
  authUser: Observable<{authUser: IAuthUser}>;
  network: Observable<INetworkState>;
  userRoles: Observable<IUserRole[]>;
  notifications: string[];
  shown = false;

  constructor(private authenticationService: AuthenticationService,
              private networkService: NetworkService, // do not remove, must be provided to run constructor even if not used here
              private caseService: CaseService,
              private ngbModal: NgbModal,
              private autoparserWebsocketService: AutoparserWebsocketService,
              private store: Store<{ui: {ui: IUi},
                authUser: {authUser: IAuthUser},
                network: INetworkState,
                userRoles: IUserRole[],
                cases: ICaseState,
                nvz: INVZState}>)  {
    this.nvzState = this.store.select('nvz');
    this.cases = this.store.select('cases');
    this.ui = this.store.select('ui');
    this.uiStateSub = this.ui.subscribe(
      (uiState) => {
        this.uiState = uiState.ui;
        if (!this.shown) {
          this.shown = true;
          this.ngbModal.open(UserNotificationPopupComponent)
          // this.ngbModal.open(WelcomePopupComponent); // Jubiläums-Fenster :-)
        }
      }
    );
    this.authUser = this.store.select('authUser');
    this.network = this.store.select('network');
    this.userRoles = this.store.select('userRoles');
  }

  ngOnInit() {
    this.autoparserWebsocketService.connect(); // TODO REFACTOR TO CENTRAL WEBSOCKET CHANNEL
    this.MODULES = MODULES;
  }

  showTopMenu() {
    if (this.uiState.routing.activeModule === MODULES.STATISTICS) { return false; }
    return (this.uiState.routing.activeModule !== MODULES.CASES ||
      (this.uiState.routing.activePageInModule.CASES !== MODULE_PAGES.CASES.CASEDETAILMAX) &&
      (this.uiState.routing.activePageInModule.CASES !== MODULE_PAGES.CASES.PERSONDETAIL));
  }

  showLeftMenu() {
    if (this.uiState.routing.activeModule === MODULES.STATISTICS) { return false; }
    return (this.uiState.routing.activeModule !== MODULES.CASES ||
      (this.uiState.routing.activePageInModule.CASES !== MODULE_PAGES.CASES.CASEDETAILMAX) &&
      (this.uiState.routing.activePageInModule.CASES !== MODULE_PAGES.CASES.PERSONDETAIL));
  }

  showCaseDetailMax() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.CASEDETAILMAX);
  }

  showPersonDetail() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.PERSONDETAIL);
  }

  showCaseBoerse() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.BOERSE);
  }

  showCasePool() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.POOL);
  }

  showCaseSearch() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.CASESEARCH);
  }

  showCaseListScreen() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.CASELISTSCREEN);
  }

  showAuskehrungListScreen() {
    return (this.uiState.routing.activeModule === MODULES.CASES && this.uiState.routing.activePageInModule.CASES === MODULE_PAGES.CASES.AUSKEHRUNGLISTSCREEN);
  }

  ngOnDestroy() {
    this.uiStateSub.unsubscribe();
  }
}



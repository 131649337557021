<div class="modal-header">
  <h4 *ngIf="!editMode" class="modal-title ilatitle">Neue Auskehr-Zahlung anlegen für {{institution.name}}</h4>
  <h4 *ngIf="editMode" class="modal-title ilatitle">Auskehr-Zahlung {{zahlung?.text}} für {{institution.name}} bearbeiten</h4>
</div>

<form [formGroup]="form" class="form">
  <div class="form-group mx-3">
   <label for="text" class="control-label">Beschreibung</label>
   <input formControlName="text" type="text" id="text" class="form-control">
   <span *ngIf="form.get('text').invalid && form.get('text').touched" class="help-block">Die Beschreibung muss angegeben werden und darf nicht mehr als 300 Zeichen lang sein!</span>
 </div>
  <div class="form-group mx-3">
   <label for="sum" class="control-label">tatsächlich erhaltener Betrag</label>
   <input formControlName="sum" type="text" id="sum" class="form-control">
   <input formControlName="sum_formatted" type="text" id="sum_formatted" readonly="true" class="form-control">
   <span *ngIf="form.get('sum').invalid && form.get('sum').touched" class="help-block">Die Summe muss eine Zahl >=0 sein</span>
  </div>
  <div class="form-group mx-3">
   <label for="sum_at_death" class="control-label">Wert zum Todeszeitpunkt</label>
   <input formControlName="sum_at_death" type="text" id="sum_at_death" class="form-control">
   <input formControlName="sum_at_death_formatted" type="text" id="sum_at_death_formatted" readonly="true" class="form-control">
   <span *ngIf="form.get('sum_at_death').invalid && form.get('sum_at_death').touched" class="help-block">Die Summe muss eine Zahl >=0 sein</span>
  </div>
  <div class="form-group mx-3">
    <label for="date" class="control-label">Datum</label>
    <input type="date" class="form-control" formControlName="date" id="date">
    <span *ngIf="form.get('date').invalid && form.get('sum').touched" class="help-block">Es muss ein gültiges Datum angegeben werden</span>
  </div>
  <div class="form-group mx-3">
   <label for="item_received" class="control-label">Betrag/Gegenstand erhalten</label>
   <select formControlName="item_received" id="item_received" class="form-control">
     <option value="0">nein</option>
     <option value="1">ja</option>
   </select>
 </div>
</form>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="save()" [disabled]="!form.valid || saving" type="button" class="m-2 btn btn-success pull-right">speichern</button>
</div>


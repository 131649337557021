import { ConstantsService } from '../services/constants.service';
import { HttpClient } from '@angular/common/http';
import { captureMessage } from '@sentry/browser';
import { RightsManagementService } from '../services/rights-management.service';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { FileUploader } from 'ng2-file-upload';
import * as i0 from "@angular/core";
import * as i1 from "../services/constants.service";
import * as i2 from "../services/rights-management.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@ngrx/store";
export class KassenbuchService {
    constructor(constantsService, rightsManagementService, http, store) {
        this.constantsService = constantsService;
        this.rightsManagementService = rightsManagementService;
        this.http = http;
        this.store = store;
        this.kostenpositionenListForCaseSub = new BehaviorSubject([]);
        this.kostenpositionenListOpenSub = new BehaviorSubject([]);
        this.kostenpositionenStatsSub = new BehaviorSubject([]);
        this.kassenbuchMainSubpageSub = new BehaviorSubject('open-positions');
        this.artOptions = [
            { id: 0, description: 'RG', hint: 'Rechnung' },
            { id: 1, description: 'VK', hint: 'Vorkassen-Rechnung' },
            { id: 2, description: 'KE', hint: 'Kostenerstattung' },
        ];
        this.paymentOptions = [
            { id: 0, description: 'ÜW', hint: 'Überweisung', short: 'Ü' },
            { id: 1, description: 'VK-Ü', hint: 'Vorkassen-Überweisung', short: 'V' },
            { id: 2, description: 'KK', hint: 'Kreditkarte', short: 'K' },
            { id: 3, description: 'LS/EC', hint: 'Lastschrift / EC-Karte', short: 'L' },
        ];
        this.waehrungOptions = [
            { id: 0, description: '€', hint: 'Euro' },
            { id: 1, description: '$US', hint: 'US-Dollar' },
            { id: 2, description: '$CAN', hint: 'kanad. Dollar' },
            { id: 3, description: '£', hint: 'brit. Pfund' },
            { id: 4, description: 'CHF', hint: 'Schweizer Franken' },
            { id: 5, description: 'NOK', hint: 'norweg. Krone' },
            { id: 6, description: 'SEK', hint: 'schwed. Krone' },
            { id: 7, description: 'DKK', hint: 'dän. Krone' },
            { id: 8, description: 'PLN', hint: 'poln. Zloty' },
            { id: 9, description: 'CZK', hint: 'tschech. Krone' },
            { id: 10, description: 'HUF', hint: 'ungar. Forint' },
            { id: 11, description: 'HRK', hint: 'kroat. Kuna' },
            { id: 12, description: 'BAM', hint: 'bosnische konvert. Mark' },
            { id: 12, description: 'RSD', hint: 'serb. Dinar' },
            { id: 13, description: 'RON', hint: 'neuer rumän. Leu' },
            { id: 14, description: 'BGN', hint: 'bulgar. Lew' },
            { id: 15, description: 'MKD', hint: 'mazedonischer Denar' },
            { id: 16, description: 'TRL', hint: 'türkische Lira' },
        ];
        this.activeCaseId = null;
        this.kostenkategorienSub = new BehaviorSubject([]);
        // only load kassenbuch data if the user is allowed to use it
        if (this.rightsManagementService.isAllowedToViewKassenbuchTabInCase() || this.rightsManagementService.isAllowedToUseModule('kassenbuch')) {
            this.loadKostenkategorien();
            this.loadKostenpositionStats();
        }
        this.caseSub = this.store.select('cases').subscribe((cases) => {
            if (+this.activeCaseId !== +cases.activeCaseId) {
                if (this.rightsManagementService.isAllowedToViewKassenbuchTabInCase()) {
                    this.loadKostenpositionListForCase(+cases.activeCaseId);
                }
            }
            this.activeCaseId = cases.activeCaseId;
        });
    }
    getDefaultPaymentOptionForBelegArt(belegartId) {
        if (belegartId === 1) {
            return 1;
        }
        return 0;
    }
    getDefaultSetDoneOptionForPaymentOption(paymentOptionId) {
        if (paymentOptionId === 0) {
            return 1;
        }
        return 0;
    }
    getKuerzelForPaymentOption(id) {
        const pO = this.paymentOptions.find(x => x.id === id);
        if (pO === null) {
            return '';
        }
        return pO.short;
    }
    getHintForPaymentOption(id) {
        const pO = this.paymentOptions.find(x => x.id === id);
        if (pO === null) {
            return '';
        }
        return pO.hint;
    }
    loadKostenkategorien() {
        this.http.get(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenkategorie/`, { headers: this.constantsService.getHttpOptions() }).subscribe((result) => {
            this.kostenkategorienSub.next(result);
        }, (error) => {
            captureMessage('error loading kostenkategorie');
            setTimeout(() => {
                this.loadKostenkategorien();
            }, 60000);
        }, () => {
        });
    }
    loadKostenpositionListForCase(caseId) {
        if (+caseId < 1) {
            return;
        }
        this.http.get(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition/?case_id=${caseId}`, { headers: this.constantsService.getHttpOptions() }).subscribe((result) => {
            this.kostenpositionenListForCaseSub.next(result);
        }, (error) => {
            captureMessage('error loading kassenbuch kostenpositionen');
            setTimeout(() => {
                this.loadKostenpositionListForCase(caseId);
            }, 60000);
        }, () => {
        });
    }
    loadKostenpositionStats() {
        this.http.get(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-stats/`, { headers: this.constantsService.getHttpOptions() }).subscribe((result) => {
            this.kostenpositionenStatsSub.next(result);
        }, (error) => {
            captureMessage('error loading kassenbuch kostenpositionen stats');
            setTimeout(() => {
            }, 60000);
        }, () => {
        });
    }
    setKassenbuchSubpage(subpage) {
        this.kassenbuchMainSubpageSub.next(subpage);
    }
    loadOpenKostenpositionList() {
        this.http.get(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition/?limit_to_open=1`, { headers: this.constantsService.getHttpOptions() }).subscribe((result) => {
            this.kostenpositionenListOpenSub.next(result);
        }, (error) => {
            captureMessage('error loading kassenbuch open kostenpositionen');
            console.log(error);
            setTimeout(() => {
            }, 60000);
        }, () => {
        });
    }
    createKostenposition(kostenposition) {
        const body = kostenposition;
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    setPaid(postenId, datum, done, method) {
        const body = { posten_id: postenId, datum: datum, done: done, method: method };
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-set-paid/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    umrechnen(einzelpostenId, data) {
        const body = { id: einzelpostenId, betrag: data.betrag, waehrung: data.waehrung };
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-einzelposten-umrechnen/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    deleteKostenposition(postenId, deleted_reason) {
        const body = { posten_id: postenId, deleted_reason: deleted_reason };
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-delete/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    undoDeleteKostenposition(postenId) {
        const body = { posten_id: postenId };
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-undo-delete/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    editKostenpositionStatus(postenId, res) {
        const body = { posten_id: postenId, method: res };
        return this.http.post(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition-edit-status/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    addKostenpositionLocally(kospos) {
        const koposes = this.kostenpositionenListForCaseSub.getValue();
        this.kostenpositionenListForCaseSub.next([...koposes, kospos]);
    }
    updateKostenposition(kostenposition) {
        const body = kostenposition;
        return this.http.patch(`${this.constantsService.getApiEndpoint()}/kassenbuch-kostenposition/${kostenposition.id}/`, body, { headers: this.constantsService.getHttpOptions() });
    }
    updateKostenpositionLocally(kospos, edit_list_for_case = false) {
        if (!edit_list_for_case) {
            const koposes = this.kostenpositionenListForCaseSub.getValue();
            const koposesWithoutOld = koposes.filter(x => x.id !== kospos.id);
            this.kostenpositionenListForCaseSub.next([...koposesWithoutOld, kospos]);
        }
        else {
            const koposes = this.kostenpositionenListOpenSub.getValue();
            const koposesWithoutOld = koposes.filter(x => x.id !== kospos.id);
            this.kostenpositionenListOpenSub.next([...koposesWithoutOld, kospos]);
        }
    }
    getKostenkategorieName(kostenkategorieID) {
        const kat = this.kostenkategorienSub.getValue().find(x => x.id === kostenkategorieID);
        if (!kat) {
            return '';
        }
        return kat.bezeichnung;
    }
    getKostenkategorieById(kostenkategorieID) {
        return this.kostenkategorienSub.getValue().find(x => x.id === kostenkategorieID);
    }
    loadAbrechnung(caseId, mode, includeEPs) {
        const pdf_name = 'Kostenabrechnung_Fall_' + caseId;
        const token = localStorage.getItem('token');
        const submitForm = document.createElement('form');
        submitForm.style.display = 'none';
        submitForm.target = pdf_name;
        submitForm.method = 'POST';
        submitForm.action = this.constantsService.getApiEndpoint() + '/kostenabrechnung/';
        const formInput = document.createElement('input');
        formInput.type = 'text';
        formInput.name = 'api_token';
        formInput.value = token;
        const modeInput = document.createElement('input');
        modeInput.type = 'text';
        modeInput.name = 'mode';
        modeInput.value = mode;
        const epInput = document.createElement('input');
        epInput.type = 'text';
        epInput.name = 'include_ep';
        epInput.value = includeEPs;
        const numberInput = document.createElement('input');
        numberInput.type = 'text';
        numberInput.name = 'case_id';
        numberInput.value = caseId;
        submitForm.appendChild(formInput);
        submitForm.appendChild(numberInput);
        submitForm.appendChild(modeInput);
        submitForm.appendChild(epInput);
        document.body.appendChild(submitForm);
        const wind = window.open('', pdf_name);
        if (wind) {
            submitForm.submit();
        }
    }
    getArtOptions() {
        return this.artOptions;
    }
    getPaymentOptions() {
        return this.paymentOptions;
    }
    getKostenkategorieOptions() {
        return this.kostenkategorienSub.getValue();
    }
    getWaehrungOptions() {
        return this.waehrungOptions;
    }
    getWaehrungOptionStringById(id) {
        const opt = this.waehrungOptions.find(x => x.id === id);
        if (!opt) {
            return '';
        }
        return opt.description;
    }
    getArtOptionTextById(id) {
        const opt = this.artOptions.find(x => x.id === id);
        if (opt) {
            return opt.description;
        }
        return '';
    }
    uploadFilesForAbrechnung(files, kostenpositionId) {
        const headers = [this.constantsService.getHeadersForFileUpload()];
        const uploader = new FileUploader({
            url: `${this.constantsService.getApiEndpoint()}/kassenbuch-file-upload/${kostenpositionId}/`,
            headers: headers
        });
        const totalFiles = files.filter(x => x && x.fileEntry && x.fileEntry.isFile).length;
        let count = 0;
        for (const singleFile of files) {
            if (singleFile && singleFile.fileEntry && singleFile.fileEntry.isFile) {
                count += 1;
                const fileEntry = singleFile.fileEntry;
                fileEntry.file((file) => {
                    uploader.addToQueue([...[file]]);
                    console.log(count, totalFiles);
                    if (count === totalFiles) {
                        // callback from all files are done, so start upload
                        uploader.uploadAll();
                        uploader.onCompleteAll = () => { this.loadKostenpositionListForCase(this.activeCaseId); };
                    }
                });
            }
        }
    }
    showFile(fileId) {
        const token = localStorage.getItem('token');
        const mapForm = document.createElement('form');
        mapForm.style.display = 'none';
        mapForm.target = 'Datei' + fileId;
        mapForm.method = 'POST';
        mapForm.action = this.constantsService.getApiEndpoint() + '/kassenbuch-get-file/';
        const mapInput = document.createElement('input');
        mapInput.type = 'text';
        mapInput.name = 'api_token';
        mapInput.value = token;
        const file_id = document.createElement('input');
        file_id.type = 'text';
        file_id.name = 'file_id';
        file_id.value = fileId.toString();
        mapForm.appendChild(mapInput);
        mapForm.appendChild(file_id);
        document.body.appendChild(mapForm);
        const map = window.open('', 'Datei' + fileId);
        if (map) {
            mapForm.submit();
        }
    }
}
KassenbuchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KassenbuchService_Factory() { return new KassenbuchService(i0.ɵɵinject(i1.ConstantsService), i0.ɵɵinject(i2.RightsManagementService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.Store)); }, token: KassenbuchService, providedIn: "root" });

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kassenbuch-stats.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@swimlane/ngx-charts/release/pie-chart/pie-chart.component.ngfactory";
import * as i3 from "@swimlane/ngx-charts/release/pie-chart/pie-chart.component";
import * as i4 from "@angular/common";
import * as i5 from "./kassenbuch-stats.component";
import * as i6 from "../kassenbuch.service";
var styles_KassenbuchStatsComponent = [i0.styles];
var RenderType_KassenbuchStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KassenbuchStatsComponent, data: {} });
export { RenderType_KassenbuchStatsComponent as RenderType_KassenbuchStatsComponent };
function View_KassenbuchStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ngx-charts-pie-chart", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PieChartComponent_0, i2.RenderType_PieChartComponent)), i1.ɵdid(2, 4898816, null, 1, i3.PieChartComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"], labels: [1, "labels"], legend: [2, "legend"], doughnut: [3, "doughnut"], gradient: [4, "gradient"] }, { select: "select" }), i1.ɵqud(603979776, 1, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statsAggregated; var currVal_1 = _co.labels; var currVal_2 = _co.showLegend; var currVal_3 = _co.doughnut; var currVal_4 = _co.gradient; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_KassenbuchStatsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\u20AC ", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " %"])), i1.ɵppd(8, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.value, "1.2-2")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), ((_v.context.$implicit.value * 100) / _co.total), "1.2-2")); _ck(_v, 7, 0, currVal_2); }); }
export function View_KassenbuchStatsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Statistische Auswertung"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchStatsComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchStatsComponent_2)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Gesamtbetrag"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["\u20AC ", ""])), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 0, "th", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statsAggregated; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.stats; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.total, "1.2-2")); _ck(_v, 12, 0, currVal_2); }); }
export function View_KassenbuchStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-stats", [], null, null, null, View_KassenbuchStatsComponent_0, RenderType_KassenbuchStatsComponent)), i1.ɵdid(1, 245760, null, 0, i5.KassenbuchStatsComponent, [i6.KassenbuchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KassenbuchStatsComponentNgFactory = i1.ɵccf("app-kassenbuch-stats", i5.KassenbuchStatsComponent, View_KassenbuchStatsComponent_Host_0, {}, {}, []);
export { KassenbuchStatsComponentNgFactory as KassenbuchStatsComponentNgFactory };

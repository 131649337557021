/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./team-icons.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./team-icons.component";
import * as i4 from "../../../services/team-member.service";
import * as i5 from "../../../services/user.service";
import * as i6 from "@ngrx/store";
var styles_TeamIconsComponent = [i0.styles];
var RenderType_TeamIconsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeamIconsComponent, data: {} });
export { RenderType_TeamIconsComponent as RenderType_TeamIconsComponent };
function View_TeamIconsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-success"], ["title", "Fallf\u00FChrer"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.parent.context.$implicit.user_id); _ck(_v, 1, 0, currVal_0); }); }
function View_TeamIconsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-danger"], ["title", "EBS-Doku"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.parent.context.$implicit.user_id); _ck(_v, 1, 0, currVal_0); }); }
function View_TeamIconsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-default"], ["title", "Mitarbeiter"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.parent.context.$implicit.user_id); _ck(_v, 1, 0, currVal_0); }); }
function View_TeamIconsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamIconsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamIconsComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamIconsComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!_v.context.$implicit.deleted && _v.context.$implicit.leader); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_v.context.$implicit.deleted && !_v.context.$implicit.leader) && _v.context.$implicit.ebs_doku); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((!_v.context.$implicit.deleted && !_v.context.$implicit.leader) && !_v.context.$implicit.ebs_doku); _ck(_v, 6, 0, currVal_2); }, null); }
function View_TeamIconsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamIconsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teamMembers; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TeamIconsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamIconsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.teamMembers && (_co.teamMembers.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TeamIconsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "team-icons", [], null, null, null, View_TeamIconsComponent_0, RenderType_TeamIconsComponent)), i1.ɵdid(1, 245760, null, 0, i3.TeamIconsComponent, [i4.TeamMemberService, i5.UserService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeamIconsComponentNgFactory = i1.ɵccf("team-icons", i3.TeamIconsComponent, View_TeamIconsComponent_Host_0, {}, {}, []);
export { TeamIconsComponentNgFactory as TeamIconsComponentNgFactory };

<div class="modal-header">
  <h5 class="modal-title">Verbundene Clients <span *ngIf="ccon">({{ccon?.length}})</span></h5>
</div>

<div class="modal-body">
  <table class="table-responsive">
    <tr><th></th><th>User-ID</th><th>User</th><th>IP</th><th>Socket</th><th>Kanal</th><th>verbunden seit</th><th>letzter Beat</th><th>Client-Version</th></tr>
    <tr *ngFor="let cc of ccon">
      <td>
        <span *ngIf="cc.alive" class="green fa fa-circle"></span>
        <span *ngIf="!cc.alive" class="orange fa fa-circle"></span>
      </td>
      <td>{{cc.user_id}}</td>
      <td>{{cc.user_abbreviation}}</td>
      <td>{{cc.ip}}</td>
      <td>{{cc.socket_name}}</td>
      <td>{{cc.communication_channel}}</td>
      <td>{{cc.connected_at | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
      <td>{{cc.heartbeat_at | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
      <td><span *ngIf="cc.version">{{cc.version}}</span></td>
    </tr>
  </table>
</div>

<div class="modal-footer">
  <button (click)="confirm()" type="button" class="m-2 btn btn-success pull-right">ok</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addressbook-search.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./addressbook-search.component";
import * as i4 from "../../services/addressbook.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@ngrx/store";
var styles_AddressbookSearchComponent = [i0.styles];
var RenderType_AddressbookSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressbookSearchComponent, data: {} });
export { RenderType_AddressbookSearchComponent as RenderType_AddressbookSearchComponent };
function View_AddressbookSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showInstitution(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.shortname; _ck(_v, 4, 0, currVal_1); }); }
function View_AddressbookSearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showInstitution(_v.context.$implicit.institution) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.last_name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.first_name; _ck(_v, 4, 0, currVal_1); }); }
function View_AddressbookSearchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.street; var currVal_1 = _v.context.$implicit.housenumber; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.zipcode; var currVal_3 = _v.context.$implicit.town; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.printAddressRegion(_v.context.$implicit.region); _ck(_v, 5, 0, currVal_4); }); }
function View_AddressbookSearchComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printContactType(_v.context.$implicit.type); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_2); }); }
function View_AddressbookSearchComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeAddressbookEntry.contacts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookSearchComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printContactType(_v.context.$implicit.type); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_2); }); }
function View_AddressbookSearchComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_10)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.contacts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookSearchComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.context.$implicit.contacts; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.first_name; var currVal_1 = _v.context.$implicit.last_name; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.function; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.comment; _ck(_v, 6, 0, currVal_3); }); }
function View_AddressbookSearchComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card px-2 my-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeAddressbookEntry.persons; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookSearchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "card px-2 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger pull-right mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editActiveEntry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["bearbeiten"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_4)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_5)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_7)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.activeAddressbookEntry.addresses; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.activeAddressbookEntry.contacts; _ck(_v, 15, 0, currVal_4); var currVal_5 = (((_co.activeAddressbookEntry.persons == null) ? null : _co.activeAddressbookEntry.persons.length) > 0); _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeAddressbookEntry.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.printInstitutionType(_co.activeAddressbookEntry.type); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.activeAddressbookEntry.comment; _ck(_v, 11, 0, currVal_2); }); }
export function View_AddressbookSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Adressbuch-Suche "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewEntry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["neue Institution anlegen"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "input", [["autofocus", ""], ["class", "form-control"], ["placeholder", "Institutionen und Personen, mindestens 3 Zeichen eintippen..."], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.search($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "card-deck mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "card col-md-4 results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suchergebnisse"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "table", [["class", "table table-bordered table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "table", [["class", "table table-bordered table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_2)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "card col-md-8"], ["style", "border: 0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookSearchComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchResultInstitutions; _ck(_v, 12, 0, currVal_0); var currVal_1 = _co.searchResultPersons; _ck(_v, 15, 0, currVal_1); var currVal_2 = _co.activeAddressbookEntry; _ck(_v, 18, 0, currVal_2); }, null); }
export function View_AddressbookSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-search", [], null, null, null, View_AddressbookSearchComponent_0, RenderType_AddressbookSearchComponent)), i1.ɵdid(1, 245760, null, 0, i3.AddressbookSearchComponent, [i4.AddressbookService, i5.NgbModal, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressbookSearchComponentNgFactory = i1.ɵccf("addressbook-search", i3.AddressbookSearchComponent, View_AddressbookSearchComponent_Host_0, {}, {}, []);
export { AddressbookSearchComponentNgFactory as AddressbookSearchComponentNgFactory };

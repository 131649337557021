/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choose-user-reactive-forms.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./choose-user-reactive-forms.component";
import * as i5 from "@ngrx/store";
var styles_ChooseUserReactiveFormsComponent = [i0.styles];
var RenderType_ChooseUserReactiveFormsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChooseUserReactiveFormsComponent, data: {} });
export { RenderType_ChooseUserReactiveFormsComponent as RenderType_ChooseUserReactiveFormsComponent };
function View_ChooseUserReactiveFormsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "col-sm-2 control-label"], ["for", "forUserId"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ChooseUserReactiveFormsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.abbreviation; _ck(_v, 3, 0, currVal_2); }); }
export function View_ChooseUserReactiveFormsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChooseUserReactiveFormsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-sm-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "select", [["class", "form-control"], ["id", "forUserId"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.setValue($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChooseUserReactiveFormsComponent_2)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.disabled; var currVal_9 = _co.value; _ck(_v, 7, 0, currVal_8, currVal_9); var currVal_10 = _co.users; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ChooseUserReactiveFormsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "choose-user-reactive-forms", [], null, null, null, View_ChooseUserReactiveFormsComponent_0, RenderType_ChooseUserReactiveFormsComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.ChooseUserReactiveFormsComponent]), i1.ɵdid(2, 180224, null, 0, i4.ChooseUserReactiveFormsComponent, [i5.Store], null, null)], null, null); }
var ChooseUserReactiveFormsComponentNgFactory = i1.ɵccf("choose-user-reactive-forms", i4.ChooseUserReactiveFormsComponent, View_ChooseUserReactiveFormsComponent_Host_0, { title: "title", userId: "userId", inactive: "inactive" }, {}, []);
export { ChooseUserReactiveFormsComponentNgFactory as ChooseUserReactiveFormsComponentNgFactory };

<div class="p-2 m-2">
  <div class="card m-2">
    <div class="card-header">
      <h4>
        Vorhandene Speicherpunkte
        <button class="ml-3 btn btn-sm btn-info" (click)="toggleShowAuto()">
          <span *ngIf="showAuto">nur manuelle anzeigen</span>
          <span *ngIf="!showAuto">auch automatisch erstellte anzeigen</span>
        </button>
      </h4>
    </div>
    <table *ngIf="checkpointsToShow.length > 0" class="table-responsive table-sm">
      <tr>
        <th>Bezeichnung</th>
        <th>Erstellungsdatum</th>
        <th>Kommentar</th>
        <th>Benutzer</th>
        <th></th>
      </tr>
      <tr *ngFor="let cp of checkpointsToShow">
        <td>{{cp.title}}</td>
        <td>{{cp.created | date: 'dd.MM.y HH:mm:ss'}}</td>
        <td>{{cp.comment}}</td>
        <td>
          <span *ngIf="!cp.autosave" [innerHTML]="">
            <span class="badge badge-pill badge-info">{{userService.getAbbreviationForUser(cp.created_by)}}</span>
          </span>
          <span *ngIf="cp.autosave">
            <span class="badge badge-pill badge-default">System</span>
          </span>
        </td>
        <td>
          <button class="btn btn-sm btn-primary" (click)="loadCheckpoint(cp.id)">laden</button>
        </td>
      </tr>
    </table>
    <p
      *ngIf="!stammbaumState || !stammbaumState.stammbaum || !stammbaumState.stammbaum.version || ! stammbaumState.stammbaum.version.checkpoints || stammbaumState.stammbaum.version.checkpoints.length === 0">
      Keine gespeicherten Zwischenstände vorhanden
    </p>
  </div>

  <div class="card m-2 mt-4">
    <div class="card-header">
      <h4>Neuen Speicherpunkt anlegen</h4>
    </div>
    <form [formGroup]="form">
      <div class="row px-2 my-2">
        <div class="col-6">
          Titel (max. 40 Zeichen)
        </div>
        <div class="col-6">
          <input maxlength="40" type="text" formControlName="title" id="title" class="form-control">
        </div>
      </div>
      <div class="row px-2 my-2">
        <div class="col-6">
          Kommentar
        </div>
        <div class="col-6">
          <input type="text" formControlName="comment" id="comment" class="form-control">
        </div>
      </div>
      <div class="row px-2 my-2">
        <div class="col-12">
          <button class="btn btn-sm btn-warning" (click)="saveCheckpoint()">Speicherpunkt anlegen</button>
        </div>
      </div>
    </form>
  </div>
  <div class="m-2">
    <button class="btn btn-sm btn-success" (click)="cancel()">Fenster schließen</button>
  </div>
</div>

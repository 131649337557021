<div class="m-2 p-2">
  <h3 *ngIf="!editMode">Standesamt anlegen</h3>
  <h3 *ngIf="editMode">Standesamt bearbeiten</h3>

  <form [formGroup]="form" class="form-horizontal">

    <div class="form-group row">
      <label for="name" class="col-sm-3 control-label">Name des Standesamts</label>
      <div class="col-sm-9">
        <input #name formControlName="name" type="text" id="name" class="form-control">
        <span *ngIf="form.get('name').invalid && form.get('name').touched" class="help-block">Bitte einen Standesamt-Namen mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="schluessel" class="col-sm-3 control-label">Standesamts-Nummer</label>
      <div class="col-sm-9">
        <input #schluessel formControlName="schluessel" type="text" id="schluessel" class="form-control">
        <span *ngIf="form.get('schluessel').invalid && form.get('schluessel').touched" class="help-block">Bitte die Standesamts-Nummer mit maximal 15 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="comment" class="col-sm-3 control-label">Kommentar</label>
      <div class="col-sm-9">
        <input #comment formControlName="comment" type="text" id="comment" class="form-control">
        <span *ngIf="form.get('comment').invalid && form.get('comment').touched" class="help-block">Bitte einen Kommentar mit maximal 100 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="status" class="col-sm-3 control-label">Status</label>
      <div class="col-sm-9">
        <select #status formControlName="status" id="status" class="form-control">
          <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
        </select>
        <span *ngIf="form.get('status').invalid && form.get('status').touched" class="help-block">Bitte den Status für dieses Standesamt auswählen!</span>
      </div>
    </div>

    <button type="button" (click)="back()" class="btn btn-danger btn-sm m-2">abbrechen</button>
    <span style="font-size: 65%"><kbd>Esc</kbd></span>
    <button *ngIf="form.valid" type="button" (click)="save()" class="btn btn-success btn-sm m-2">speichern</button>
    <button *ngIf="!form.valid" type="button" disabled="disabled" class="btn btn-success btn-sm m-2">speichern</button>
    <span style="font-size: 65%"><kbd>Shift</kbd> + <kbd>Enter</kbd></span>
  </form>
</div>

import {Action} from '@ngrx/store';
import {ICase} from '../cases/case.interface';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';

export const REPLACE_CASES_IN_CASELIST = 'REPLACE_CASES_IN_CASELIST';
export const REPLACE_CASE_IN_CASELIST = 'REPLACE_CASE_IN_CASELIST';
export const ADD_CASE_TO_CASELIST = 'ADD_CASE_TO_CASELIST';
export const SET_ACTIVE_CASE = 'SET_ACTIVE_CASE';
export const SET_ACTIVE_CASE_DETAIL_TAB = 'SET_ACTIVE_CASE_DETAIL_TAB';
export const SET_ACTIVE_PERSON = 'SET_ACTIVE_PERSON';
export const EXPAND_CASE_IN_CASELIST = 'EXPAND_CASE_IN_CASELIST';
export const REPLACE_EXPANDED_HEIRS = 'REPLACE_EXPANDED_HEIRS';
export const DELETE_ALL_CASE_DATA = 'DELETE_ALL_CASE_DATA';
export const ADD_CASE_ID_TO_HISTORY = 'ADD_CASE_ID_TO_HISTORY';
export const ADD_PERSON_ID_TO_HISTORY = 'ADD_PERSON_ID_TO_HISTORY';
export const SET_CASE_MODULE_HEADLINE = 'SET_CASE_MODULE_HEADLINE';
export const SET_CASE_FILTERS = 'SET_CASE_FILTERS';
export const CLEAR_CASE_FILTERS = 'CLEAR_CASE_FILTERS';
export const TRIGGER_RELOAD_ACTIVE_CASE = 'TRIGGER_RELOAD_ACTIVE_CASE';
export const SET_LOAD_ALL_CASES_FLAG = 'SET_LOAD_ALL_CASES_FLAG';
export const SET_LOAD_ALL_CASES_EXECUTED_FLAG = 'SET_LOAD_ALL_CASES_EXECUTED_FLAG';
export const REPLACE_CASE_SEARCH_RESULTS = 'REPLACE_CASE_SEARCH_RESULTS';
export const REPLACE_PERSON_SEARCH_RESULTS = 'REPLACE_PERSON_SEARCH_RESULTS';
export const REPLACE_SEARCH_RESULT_MODE = 'REPLACE_SEARCH_RESULT_MODE';
export const SET_STATUS_FOR_CASELIST_SCREEN = 'SET_STATUS_FOR_CASELIST_SCREEN';
export const SET_DATES_FOR_AUSZAHLUNGSLISTE_SCREEN = 'SET_DATES_FOR_AUSZAHLUNGSLISTE_SCREEN';

export class ReplaceCasesInCaselist implements Action {
  readonly type = REPLACE_CASES_IN_CASELIST;
  constructor(public payload: ICase[]) {}
}

export class ReplaceCaseInCaselist implements Action {
  readonly type = REPLACE_CASE_IN_CASELIST;
  constructor(public payload: ICase) {}
}

export class AddCaseToCaselist implements Action {
  readonly type = ADD_CASE_TO_CASELIST;
  constructor(public payload: ICase) {}
}

export class SetActiveCase implements Action {
  readonly type = SET_ACTIVE_CASE;
  constructor(public payload: {id: number, caseData: ICase}) {}
}

export class SetActiveCaseDetailTab implements Action {
  readonly type = SET_ACTIVE_CASE_DETAIL_TAB;
  constructor(public payload: number) {}
}

export class SetActivePerson implements Action {
  readonly type = SET_ACTIVE_PERSON;
  constructor(public payload: {id: number, personData: IStammbaumPerson}) {}
}

export class ExpandCaseInCaselist implements Action {
  readonly type = EXPAND_CASE_IN_CASELIST;
  constructor(public payload: number) {}
}

export class ReplaceExpandedHeirs implements Action {
  readonly type = REPLACE_EXPANDED_HEIRS;
  constructor(public payload: IStammbaumPerson[]) {}
}

export class DeleteAllCaseData implements Action {
  readonly type = DELETE_ALL_CASE_DATA;
  constructor() {}
}

export class SetCaseModuleHeadline implements Action {
  readonly type = SET_CASE_MODULE_HEADLINE;
  constructor(public payload: string) {}
}

export class AddCaseIdToHistory implements Action {
  readonly type = ADD_CASE_ID_TO_HISTORY;
  constructor(public payload: {id: number, text: string}) {}
}

export class AddPersonIdToHistory implements Action {
  readonly type = ADD_PERSON_ID_TO_HISTORY;
  constructor(public payload: {id: number, text: string}) {}
}

export class SetCaseFilters implements Action {
  readonly type = SET_CASE_FILTERS;
  constructor(public payload: {searchString: string, groupString: string, limitResultsToUser: number, personState: string, fiscalFilter: string, pagination: number}) {}
}

export class ClearCaseFilters implements Action {
  readonly type = CLEAR_CASE_FILTERS;
  constructor() {}
}

export class TriggerReloadActiveCase implements Action {
  readonly type = TRIGGER_RELOAD_ACTIVE_CASE;
  constructor() {}
}

export class SetLoadAllCasesFlag implements Action {
  readonly type = SET_LOAD_ALL_CASES_FLAG;
  constructor(public payload: boolean) {}
}

export class SetLoadAllCasesExecutedFlag implements Action {
  readonly type = SET_LOAD_ALL_CASES_EXECUTED_FLAG;
  constructor(public payload: boolean) {}
}

export class ReplaceCaseSearchResults implements Action {
  readonly type = REPLACE_CASE_SEARCH_RESULTS;
  constructor(public payload: ICase[]) {}
}

export class ReplacePersonSearchResults implements Action {
  readonly type = REPLACE_PERSON_SEARCH_RESULTS;
  constructor(public payload: IStammbaumPerson[]) {}
}

export class ReplaceSearchResultMode implements Action {
  readonly type = REPLACE_SEARCH_RESULT_MODE;
  constructor(public payload: string) {}
}

export class SetStatusForCaselistScreen implements Action {
  readonly type = SET_STATUS_FOR_CASELIST_SCREEN;
  constructor(public payload: number) {}
}

export class SetDatesForAuszahlunglistScreen implements Action {
  readonly type = SET_DATES_FOR_AUSZAHLUNGSLISTE_SCREEN;
  constructor(public payload: { startDate: string; endDate: string, modeForAuszahlungslisteScreen: string }) {}
}

export type CaseActions = ReplaceCasesInCaselist | AddCaseToCaselist | SetActiveCase | SetActivePerson |
  DeleteAllCaseData | AddCaseIdToHistory | AddPersonIdToHistory | SetCaseModuleHeadline | SetCaseFilters |
  ExpandCaseInCaselist | ReplaceExpandedHeirs | ClearCaseFilters | ReplaceCaseInCaselist |
  TriggerReloadActiveCase | SetLoadAllCasesFlag | SetLoadAllCasesExecutedFlag | SetActiveCaseDetailTab |
  ReplaceCaseSearchResults | ReplacePersonSearchResults | ReplaceSearchResultMode | SetStatusForCaselistScreen |
  SetDatesForAuszahlunglistScreen;

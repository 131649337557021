/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-sort-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./table-sort-header.component";
var styles_TableSortHeaderComponent = [i0.styles];
var RenderType_TableSortHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableSortHeaderComponent, data: {} });
export { RenderType_TableSortHeaderComponent as RenderType_TableSortHeaderComponent };
function View_TableSortHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-caret-down"]], null, null, null, null, null))], null, null); }
function View_TableSortHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-caret-up"]], null, null, null, null, null))], null, null); }
export function View_TableSortHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "headering"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "is_active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSortHeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSortHeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "headering"; var currVal_1 = _ck(_v, 3, 0, _co.isActive); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.isActive && !_co.isInverse); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.isActive && _co.isInverse); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.title; _ck(_v, 8, 0, currVal_4); }); }
export function View_TableSortHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-sort-header", [], null, null, null, View_TableSortHeaderComponent_0, RenderType_TableSortHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableSortHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableSortHeaderComponentNgFactory = i1.ɵccf("app-table-sort-header", i3.TableSortHeaderComponent, View_TableSortHeaderComponent_Host_0, { title: "title", isActive: "isActive", isInverse: "isInverse" }, {}, []);
export { TableSortHeaderComponentNgFactory as TableSortHeaderComponentNgFactory };

<td>
  <span [ngClass]="stammbaumService.getPersonTypeClass(person)" class="fa fa-circle" [title]="stammbaumService.getPersonTypeTooltip(person)"></span>
</td>
<td>{{person.id}}</td>
<td>
  {{person.nachname}}
</td>
<td>
  {{person.vorname}}
</td>
<td>
  <span *ngIf="person.geburtsname"> geb. {{person.geburtsname}}</span>
</td>
<td>
  <editfield-date [date]="person.geburtsdatum" [editMode]="false"></editfield-date>
</td>
<td>
  {{person.geburtsinfo}} <span *ngIf="person.geburtsland">({{person.geburtsland}})</span>
</td>
<td>
  <editfield-date [date]="person.sterbedatum" [editMode]="false"></editfield-date>
</td>
<td>
  {{person.sterbeinfo}} <span *ngIf="person.sterbeland">({{person.sterbeland}})</span>
</td>
<td>
  <span *ngFor="let m of person.marriages_for_stammbaum_person" class="mx-1 badge badge-pill badge-default" [title]="getMarriageInfo(m)">OO</span>
</td>
<td>
  <span (click)="showUrkunden()" *ngIf="person.needs_gu && !person.needs_gu_but_do_without && !person.urkunden_vorhanden?.has_gu && !person.ordered_gu" title="GU benötigt" class="mx-1 badge badge-pill badge-danger">GU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_gu && person.needs_gu_but_do_without && !person.urkunden_vorhanden?.has_gu && !person.ordered_gu" title="auf GU wird verzichtet" class="mx-1 badge badge-pill badge-grey">GU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_gu && !person.urkunden_vorhanden?.has_gu && person.ordered_gu" title="GU bestellt" class="mx-1 badge badge-pill badge-ordered">GU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_gu && person.urkunden_vorhanden?.has_gu" title="GU benötigt und vorhanden" class="mx-1 badge badge-pill badge-success">GU</span>
  <span (click)="showUrkunden()" *ngIf="!person.needs_gu && person.urkunden_vorhanden?.has_gu" title="GU nicht benötigt, aber vorhanden" class="mx-1 badge badge-pill badge-primary">GU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_su && !person.needs_su_but_do_without && !person.urkunden_vorhanden?.has_su && !person.ordered_su" title="SU benötigt" class="mx-1 badge badge-pill badge-danger">SU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_su && person.needs_su_but_do_without && !person.urkunden_vorhanden?.has_su && !person.ordered_su" title="auf SU wird verzichtet" class="mx-1 badge badge-pill badge-grey">SU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_su && !person.urkunden_vorhanden?.has_su && person.ordered_su" title="SU bestellt" class="mx-1 badge badge-pill badge-ordered">SU</span>
  <span (click)="showUrkunden()" *ngIf="person.needs_su && person.urkunden_vorhanden?.has_su" title="SU benötigt und vorhanden" class="mx-1 badge badge-pill badge-success">SU</span>
  <span (click)="showUrkunden()" *ngIf="!person.needs_su && person.urkunden_vorhanden?.has_su" title="SU nicht benötigt, aber vorhanden" class="mx-1 badge badge-pill badge-primary">SU</span>
  <ng-container *ngFor="let mr of person.urkunden_vorhanden?.has_hu">
    <span (click)="showUrkunden()" *ngIf="mr.needs_hu && !mr.needs_hu_but_do_without && !mr.has_hu && !mr.ordered_hu" title="HU benötigt" class="mx-1 badge badge-pill badge-danger">HU</span>
    <span (click)="showUrkunden()" *ngIf="mr.needs_hu && mr.needs_hu_but_do_without && !mr.has_hu && !mr.ordered_hu" title="auf HU wird verzichtet" class="mx-1 badge badge-pill badge-grey">HU</span>
    <span (click)="showUrkunden()" *ngIf="mr.needs_hu && !mr.has_hu && mr.ordered_hu" title="HU bestellt" class="mx-1 badge badge-pill badge-ordered">HU</span>
    <span (click)="showUrkunden()" *ngIf="mr.needs_hu && mr.has_hu" title="HU benötigt und vorhanden" class="mx-1 badge badge-pill badge-success">HU</span>
    <span (click)="showUrkunden()" *ngIf="!mr.needs_hu && mr.has_hu" title="HU nicht benötigt, aber vorhanden" class="mx-1 badge badge-pill badge-primary">HU</span>
  </ng-container>
  <span (click)="showUrkunden()" *ngIf="person.urkunden_vorhanden?.urkunden_count && person.urkunden_vorhanden?.urkunden_count > 0" [title]="'Liste der vorhandenenen ' + person.urkunden_vorhanden?.urkunden_count + ' Urkunden anzeigen'" class="mx-1 badge badge-pill badge-primary"><span class="fa fa-search"></span> {{person.urkunden_vorhanden.urkunden_count}}</span>
</td>
<td>
  <span (click)="toggleActive()" *ngIf="false && !person.is_active" title="Person aktiv setzen" class="mx-1 badge badge-pill badge-success">
    <span class="fa fa-lightbulb"></span>
  </span>
  <span (click)="deletePerson()" *ngIf="false && !person.is_active" title="Person löschen" class="mx-1 badge badge-pill badge-danger">
    <span class="fa fa-trash"></span>
  </span>
  <span (click)="toggleActive()" *ngIf="false && person.is_active" title="Person inaktiv setzen" class="mx-1 badge badge-pill badge-danger">
    <span class="fa fa-lightbulb"></span>
  </span>
  <span (click)="editPerson()" title="Person bearbeiten" class="mx-1 badge badge-pill badge-success">
    <span class="fa fa-pencil"></span>
  </span>
  <span (click)="addDocument()" title="Urkunde hinzufügen" class="mx-1 badge badge-pill badge-success">
    <span class="fa fa-file"></span>
  </span>
  <span *ngIf="person.extended_person_data > 0" title="zu den Zusatzdaten für Erben" class="mx-1 badge badge-pill badge-success" (click)="goToHeirViewOfPerson()">
    <span class="fa fa-link"></span>
  </span>
</td>

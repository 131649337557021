<div *ngIf="!docs || docs.length == 0">
  keine Dokumente in diesem Ordner
</div>
<table *ngIf="docs.length > 0" class="table table-striped table-sm">
  <tr *ngFor="let doc of docs">

    <td title="Dokument" *ngIf="(doc.document_type !== 1) && (doc.document_type !== 2)"><i class="fa fa-envelope"></i></td>
    <td title="Email" *ngIf="doc.document_type === 1"><i class="fa fa-at"></i></td>
    <td title="Fax" *ngIf="doc.document_type === 2"><i class="fa fa-fax"></i></td>

    <td title="Sendevorgang abgeschlossen" *ngIf="(doc.document_send_status !== 1) && (doc.document_send_status !== 2)"><i class="green fa fa-check"></i></td>
    <td title="Sendevorgang läuft" *ngIf="doc.document_send_status === 1"><i class="orange fa fa-cog fa-spin"></i></td>
    <td title="Sendevorgang fehlgeschlagen" *ngIf="doc.document_send_status === 2"><i class="red fa fa-times"></i></td>

    <td>{{doc.title}}</td>
    <td>{{doc.file_name}}</td>
    <td>{{doc.created | date: 'dd.MM.yyyy'}}</td>
    <td>{{doc.comment}}</td>
    <td><span class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(doc.user)}}</span></td>


  </tr>
</table>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./age-check.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./age-check.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_AgeCheckComponent = [i0.styles];
var RenderType_AgeCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgeCheckComponent, data: {} });
export { RenderType_AgeCheckComponent as RenderType_AgeCheckComponent };
function View_AgeCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Person zu jung f\u00FCr Nachkommen"]))], null, null); }
function View_AgeCheckComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Person nach dem Erblasser verstorben"]))], null, null); }
function View_AgeCheckComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Das Sterbe-Alter der bearbeiteten Person betr\u00E4gt 12 oder kleiner. Soll die Option \"Sicher keine Nachkommen\" aktiviert werden?"]))], null, null); }
function View_AgeCheckComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Das Sterbe-Alter der bearbeiteten Person liegt nach dem Sterbedatum des Erblassers. Soll die Person als nachverstorbener Erbe gekennzeichnet werden?"]))], null, null); }
export function View_AgeCheckComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgeCheckComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgeCheckComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgeCheckComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgeCheckComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "m-2 btn btn-success pull-right"], ["id", "confirm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ja"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "m-2 btn btn-danger pull-right"], ["id", "deny"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deny() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Nein"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "below12"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.mode === "nachverstorben"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.mode === "below12"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.mode === "nachverstorben"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_AgeCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-age-check-popup", [], null, null, null, View_AgeCheckComponent_0, RenderType_AgeCheckComponent)), i1.ɵdid(1, 114688, null, 0, i3.AgeCheckComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgeCheckComponentNgFactory = i1.ɵccf("app-age-check-popup", i3.AgeCheckComponent, View_AgeCheckComponent_Host_0, {}, {}, []);
export { AgeCheckComponentNgFactory as AgeCheckComponentNgFactory };

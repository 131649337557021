/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addressbook-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../addressbook-search/addressbook-search.component.ngfactory";
import * as i3 from "../addressbook-search/addressbook-search.component";
import * as i4 from "../../services/addressbook.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@ngrx/store";
import * as i7 from "../addressbook-form/addressbook-form.component.ngfactory";
import * as i8 from "../addressbook-form/addressbook-form.component";
import * as i9 from "@angular/common";
import * as i10 from "./addressbook-entry.component";
var styles_AddressbookEntryComponent = [i0.styles];
var RenderType_AddressbookEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressbookEntryComponent, data: {} });
export { RenderType_AddressbookEntryComponent as RenderType_AddressbookEntryComponent };
function View_AddressbookEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-search", [], null, null, null, i2.View_AddressbookSearchComponent_0, i2.RenderType_AddressbookSearchComponent)), i1.ɵdid(1, 245760, null, 0, i3.AddressbookSearchComponent, [i4.AddressbookService, i5.NgbModal, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AddressbookEntryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-form", [], null, null, null, i7.View_AddressbookFormComponent_0, i7.RenderType_AddressbookFormComponent)), i1.ɵdid(1, 245760, null, 0, i8.AddressbookFormComponent, [i6.Store, i4.AddressbookService, i5.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AddressbookEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookEntryComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookEntryComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ui.ui.routing.activePageInModule.ADDRESSBOOK == _co.MODULE_PAGES.ADDRESSBOOK.SEARCH); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.ui.ui.routing.activePageInModule.ADDRESSBOOK == _co.MODULE_PAGES.ADDRESSBOOK.EDIT); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AddressbookEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-entry", [], null, null, null, View_AddressbookEntryComponent_0, RenderType_AddressbookEntryComponent)), i1.ɵdid(1, 114688, null, 0, i10.AddressbookEntryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressbookEntryComponentNgFactory = i1.ɵccf("addressbook-entry", i10.AddressbookEntryComponent, View_AddressbookEntryComponent_Host_0, { ui: "ui" }, {}, []);
export { AddressbookEntryComponentNgFactory as AddressbookEntryComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remove-auskehr-institution.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./remove-auskehr-institution.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../services/auskehr.service";
var styles_RemoveAuskehrInstitutionComponent = [i0.styles];
var RenderType_RemoveAuskehrInstitutionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemoveAuskehrInstitutionComponent, data: {} });
export { RenderType_RemoveAuskehrInstitutionComponent as RenderType_RemoveAuskehrInstitutionComponent };
export function View_RemoveAuskehrInstitutionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Institution vom Fall entfernen"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Wollen Sie "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" wirklich aus der Auskehr des Falles entfernen?\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "m-2 btn btn-outline-danger pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "m-2 btn btn-success pull-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["entfernen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institution.name; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.saving; _ck(_v, 11, 0, currVal_1); }); }
export function View_RemoveAuskehrInstitutionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remove-add-auskehr-institution", [], null, null, null, View_RemoveAuskehrInstitutionComponent_0, RenderType_RemoveAuskehrInstitutionComponent)), i1.ɵdid(1, 49152, null, 0, i2.RemoveAuskehrInstitutionComponent, [i3.NgbActiveModal, i4.AuskehrService], null, null)], null, null); }
var RemoveAuskehrInstitutionComponentNgFactory = i1.ɵccf("app-remove-add-auskehr-institution", i2.RemoveAuskehrInstitutionComponent, View_RemoveAuskehrInstitutionComponent_Host_0, {}, {}, []);
export { RemoveAuskehrInstitutionComponentNgFactory as RemoveAuskehrInstitutionComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../stammbaum-version-display/stammbaum-version-display.component.ngfactory";
import * as i3 from "../stammbaum-version-display/stammbaum-version-display.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "../stammbaum-list-persons/stammbaum-list-persons.component.ngfactory";
import * as i7 from "../stammbaum-list-persons/stammbaum-list-persons.component";
import * as i8 from "../../services/case.service";
import * as i9 from "../../services/stammbaum.service";
import * as i10 from "@ngrx/store";
import * as i11 from "../../services/constants.service";
import * as i12 from "../stammbaum-tree-svg/stammbaum-tree-svg.component.ngfactory";
import * as i13 from "../stammbaum-tree-svg/stammbaum-tree-svg.component";
import * as i14 from "../../services/stammbaum-display.service";
import * as i15 from "../stammbaum-controls/stammbaum-controls.component.ngfactory";
import * as i16 from "../stammbaum-controls/stammbaum-controls.component";
import * as i17 from "../../services/rights-management.service";
import * as i18 from "../stammbaum-urkunden-upload/stammbaum-urkunden-upload.component.ngfactory";
import * as i19 from "../stammbaum-urkunden-upload/stammbaum-urkunden-upload.component";
import * as i20 from "../../services/file-upload.service";
import * as i21 from "./stammbaum-entry.component";
import * as i22 from "../../routing/routing.service";
import * as i23 from "@angular/router";
var styles_StammbaumEntryComponent = [i0.styles];
var RenderType_StammbaumEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumEntryComponent, data: {} });
export { RenderType_StammbaumEntryComponent as RenderType_StammbaumEntryComponent };
function View_StammbaumEntryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toTreeView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur Baumansicht wechseln"]))], null, null); }
function View_StammbaumEntryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toCheckpoints() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Speicherpunkte"]))], null, null); }
function View_StammbaumEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "card m-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "color: #428bca; font-weight: bold; font-size: 120%;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToCaseViaRouter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "fa fa-lg fa-window-close"], ["title", "Stammbaum-Modus beenden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "stammbaum-version-display", [], null, null, null, i2.View_StammbaumVersionDisplayComponent_0, i2.RenderType_StammbaumVersionDisplayComponent)), i1.ɵdid(7, 573440, null, 0, i3.StammbaumVersionDisplayComponent, [i4.NgbModal], { stammbaumState: [0, "stammbaumState"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StammbaumEntryComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StammbaumEntryComponent_3)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.stammbaumStateObs)); _ck(_v, 7, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = ((((tmp_2_0 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.stammbaumStateObs))) == null) ? null : ((tmp_2_0.stammbaumVersions == null) ? null : tmp_2_0.stammbaumVersions.length)) > 0); _ck(_v, 10, 0, currVal_2); var tmp_3_0 = null; var currVal_3 = ((((tmp_3_0 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.stammbaumStateObs))) == null) ? null : ((tmp_3_0.stammbaumVersions == null) ? null : tmp_3_0.stammbaumVersions.length)) > 0); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.caseInfo(); _ck(_v, 3, 0, currVal_0); }); }
function View_StammbaumEntryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "stammbaum-list-persons", [], null, null, null, i6.View_StammbaumListPersonsComponent_0, i6.RenderType_StammbaumListPersonsComponent)), i1.ɵdid(1, 573440, null, 0, i7.StammbaumListPersonsComponent, [i4.NgbModal, i8.CaseService, i9.StammbaumService, i10.Store, i11.ConstantsService], { stammbaumState: [0, "stammbaumState"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.stammbaumStateObs)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StammbaumEntryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "stammbaum-tree-svg", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).updateScrollPosition($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_StammbaumTreeSvgComponent_0, i12.RenderType_StammbaumTreeSvgComponent)), i1.ɵdid(1, 12828672, null, 0, i13.StammbaumTreeSvgComponent, [i14.StammbaumDisplayService, i4.NgbModal, i10.Store], { stammbaumState: [0, "stammbaumState"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.stammbaumStateObs)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StammbaumEntryComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "stammbaum-controls", [["class", "card fixed-pos"]], null, null, null, i15.View_StammbaumControlsComponent_0, i15.RenderType_StammbaumControlsComponent)), i1.ɵdid(1, 114688, null, 0, i16.StammbaumControlsComponent, [i10.Store, i4.NgbModal, i11.ConstantsService, i17.RightsManagementService, i8.CaseService, i9.StammbaumService], { stammbaumState: [0, "stammbaumState"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.stammbaumStateObs)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StammbaumEntryComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "stammbaum-urkunden-upload", [], null, null, null, i18.View_StammbaumUrkundenUploadComponent_0, i18.RenderType_StammbaumUrkundenUploadComponent)), i1.ɵdid(1, 4440064, null, 0, i19.StammbaumUrkundenUploadComponent, [i10.Store, i4.NgbModal, i8.CaseService, i20.FileUploadService, i9.StammbaumService], { stammbaumState: [0, "stammbaumState"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.stammbaumStateObs)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StammbaumEntryComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "stammbaum-urkunden-upload", [], null, null, null, i18.View_StammbaumUrkundenUploadComponent_0, i18.RenderType_StammbaumUrkundenUploadComponent)), i1.ɵdid(1, 4440064, null, 0, i19.StammbaumUrkundenUploadComponent, [i10.Store, i4.NgbModal, i8.CaseService, i20.FileUploadService, i9.StammbaumService], { stammbaumState: [0, "stammbaumState"], editMode: [1, "editMode"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.stammbaumStateObs)); var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumEntryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_5)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_6)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_7)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_8)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_9)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.stammbaumMode === "person-list"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.stammbaumMode === "tree-view"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.stammbaumMode === "tree-view"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.stammbaumMode === "urkunden-upload-list"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.stammbaumMode === "urkunden-edit-list"); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_StammbaumEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumEntryComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StammbaumEntryComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.stammbaumMode !== "tree-view"); _ck(_v, 1, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = ((((tmp_1_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.stammbaumStateObs))) == null) ? null : ((tmp_1_0.stammbaumVersions == null) ? null : tmp_1_0.stammbaumVersions.length)) > 0); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StammbaumEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum", [], null, null, null, View_StammbaumEntryComponent_0, RenderType_StammbaumEntryComponent)), i1.ɵdid(1, 245760, null, 0, i21.StammbaumEntryComponent, [i22.RoutingService, i9.StammbaumService, i23.Router, i8.CaseService, i4.NgbModal, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumEntryComponentNgFactory = i1.ɵccf("stammbaum", i21.StammbaumEntryComponent, View_StammbaumEntryComponent_Host_0, {}, {}, []);
export { StammbaumEntryComponentNgFactory as StammbaumEntryComponentNgFactory };

<div>
  <aside id="sidebar">
    <ul id="sidemenu" class="sidebar-nav">
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.DASHBOARD)" (click)="navigateToModule(MODULES.DASHBOARD)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.DASHBOARD">
          <span class="sidebar-icon"><i class="fa fa-dashboard"></i></span>
          <span class="sidebar-title">Dashboard</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.CALENDAR)" (click)="navigateToModule(MODULES.CALENDAR)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.CALENDAR">
          <span class="sidebar-icon"><i class="fa fa-calendar"></i></span>
          <span class="sidebar-title">Aufgaben</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.CASES)" (click)="navigateToModule(MODULES.CASES)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.CASES">
          <span class="sidebar-icon"><i class="fa fa-tasks"></i></span>
          <span class="sidebar-title">Fälle/Personen</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.BUNDESANZEIGER)" (click)="navigateToModule(MODULES.BUNDESANZEIGER)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.BUNDESANZEIGER">
          <span class="sidebar-icon"><i class="fa fa-list"></i></span>
          <span class="sidebar-title">Bundesanzeiger</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.NAMENSVERZEICHNISSE)" (click)="navigateToModule(MODULES.NAMENSVERZEICHNISSE)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.NAMENSVERZEICHNISSE">
          <span class="sidebar-icon"><i class="fa fa-address-book-o"></i></span>
          <span class="sidebar-title">NVZs</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.MAP)" (click)="navigateToModule(MODULES.MAP)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.MAP">
          <span class="sidebar-icon"><i class="fa fa-map-marker"></i></span>
          <span class="sidebar-title">Karte</span>
        </a>
      </li>
      <li *ngIf="false && rightsManagementService.isAllowedToUseModule(MODULES.STATISTICS)" (click)="navigateToModule(MODULES.STATISTICS)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.STATISTICS">
          <span class="sidebar-icon"><i class="fa fa-chart-line"></i></span>
          <span class="sidebar-title">TEST</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.ADDRESSBOOK)"(click)="navigateToModule(MODULES.ADDRESSBOOK)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.ADDRESSBOOK">
          <span class="sidebar-icon"><i class="fa fa-list-alt"></i></span>
          <span class="sidebar-title">Adressbuch</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.KASSENBUCH)"(click)="navigateToModule(MODULES.KASSENBUCH)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.KASSENBUCH">
          <span class="sidebar-icon"><i class="fa fa-money"></i></span>
          <span class="sidebar-title">Kassenbuch</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.USERS)" (click)="navigateToModule(MODULES.USERS)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.USERS">
          <span class="sidebar-icon"><i class="fa fa-graduation-cap"></i></span>
          <span class="sidebar-title">Mitarbeiter</span>
        </a>
      </li>
       <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.GLOBALSEARCH)" (click)="navigateToModule(MODULES.GLOBALSEARCH)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.GLOBALSEARCH">
          <span class="sidebar-icon"><i class="fa fa-search"></i></span>
          <span class="sidebar-title">Suchfunktionen</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.ADMINISTRATION)" (click)="navigateToModule(MODULES.ADMINISTRATION)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.ADMINISTRATION">
          <span class="sidebar-icon"><i class="fa fa-cog"></i></span>
          <span class="sidebar-title">Administration</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.HELP)" (click)="navigateToModule(MODULES.HELP)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.HELP">
          <span class="sidebar-icon"><i class="fa fa-info-circle"></i></span>
          <span class="sidebar-title">Hilfe/Changelog</span>
        </a>
      </li>
      <li *ngIf="rightsManagementService.isAllowedToUseModule(MODULES.HISTORY)" (click)="navigateToModule(MODULES.HISTORY)">
        <a [class.activeModule]="ui.ui.routing.activeModule==MODULES.HISTORY">
          <span class="sidebar-icon"><i class="fa fa-history"></i></span>
          <span class="sidebar-title">Verlauf</span>
        </a>
      </li>
      <li (click)="logout()">
        <a>
          <span class="sidebar-icon"><i class="fa fa-sign-out"></i></span>
          <span class="sidebar-title">Logout</span>
        </a>
      </li>
    </ul>
  </aside>
</div>




<h3>Verzeichnisse
  <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="addVerzeichnis()" class="mx-2 btn btn-sm btn-warning">neues Verzeichnis anlegen</button>
  <span style="font-size: 45%"><kbd>Einfg</kbd></span>
</h3>
<div *ngIf="nvzService.verzeichnisseLoaded">
  <h4 *ngIf="nvzState && nvzState.activeStandesamt">für Standesamt {{nvzState.activeStandesamt.name}}</h4>
  <div *ngIf="!nvzState || !nvzState.verzeichnisse || nvzState.verzeichnisse.length == 0">keine Verzeichnisse vorhanden</div>
  <div *ngIf="nvzState && nvzState.verzeichnisse && nvzState.verzeichnisse.length > 0">
    <table class="table table-hover table-striped table-sm">
      <tr>
        <th>Art</th>
        <th>Jahrgang</th>
        <th>Kommentar</th>
        <th>Status</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <ng-container *ngFor="let verzeichnis of nvzState.verzeichnisse">
        <tr *ngIf="verzeichnis.standesamt_id === nvzState.activeStandesamt.id">
          <td (click)="selectVerzeichnis(verzeichnis)">{{showVerzeichnisArt(verzeichnis.art)}}</td>
          <td (click)="selectVerzeichnis(verzeichnis)">{{verzeichnis.jahrgang}}</td>
          <td (click)="selectVerzeichnis(verzeichnis)">{{verzeichnis.comment}}</td>
          <td (click)="selectVerzeichnis(verzeichnis)">{{showVerzeichnisStatus(verzeichnis.status)}}</td>
          <td (click)="selectVerzeichnis(verzeichnis)">
            <span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(verzeichnis.owner_id)}}</span>
            <span *ngFor="let user of verzeichnis.users_allowed_to_edit" class="badge badge-pill badge-default mx-1" style="background-color: lightgreen">{{userService.getAbbreviationForUser(user)}}</span>
          </td>
          <td *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || hasUserEditRights(verzeichnis) || verzeichnis.owner_id == this.user.id" (click)="checkVerzeichnis(verzeichnis)"><i class="fa fa-cog"></i></td>
          <td *ngIf="!rightsManagementService.isAllowedToEditAllNVZEntries() && !hasUserEditRights(verzeichnis) && verzeichnis.owner_id != this.user.id"></td>
          <td *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || hasUserEditRights(verzeichnis) || verzeichnis.owner_id == this.user.id" (click)="editVerzeichnis(verzeichnis)"><i class="fa fa-edit"></i></td>
          <td *ngIf="!rightsManagementService.isAllowedToEditAllNVZEntries() && !hasUserEditRights(verzeichnis) && verzeichnis.owner_id != this.user.id"></td>
          <td *ngIf="rightsManagementService.isAdmin() || verzeichnis.owner_id == this.user.id" (click)="deleteVerzeichnis(verzeichnis)"><i class="fa fa-trash"></i></td>
          <td *ngIf="!rightsManagementService.isAdmin() && verzeichnis.owner_id != this.user.id"></td>
          <td *ngIf="verzeichnis.owner_id == this.user.id || rightsManagementService.isAllowedToManageNVZEditRights()" (click)="editUserRights(verzeichnis)"><i class="fa fa-user"></i></td>
          <td *ngIf="verzeichnis.owner_id != this.user.id && !rightsManagementService.isAllowedToManageNVZEditRights()"></td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
<div *ngIf="!nvzService.verzeichnisseLoaded">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>

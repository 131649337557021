<div class="modal-header">
  <h4 class="modal-title">neue Institution anlegen</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="addressbookEntryForm" class="form" (ngSubmit)="save()">
 <div class="form-group mx-3">
   <label for="institution_name" class="control-label">Institution (Bezeichnung inkl. Ort, z.B. "Amtsgericht Erfurt", "Stadtarchiv Stuttgart" etc.)</label>
   <input formControlName="institution_name" type="text" id="institution_name" class="form-control">
   <span *ngIf="addressbookEntryForm.get('institution_name').invalid && addressbookEntryForm.get('institution_name').touched" class="help-block">Bitte einen Namen mit maximal 40 Zeichen angeben!</span>
 </div>
 <div class="form-group mx-3">
   <label for="type" class="control-label">Typ</label>
   <select formControlName="type" id="type" class="form-control">
     <option *ngFor="let type of institutionTypes" [value]="type.id">{{type.name}}</option>
   </select>
   <span *ngIf="addressbookEntryForm.get('type').invalid && addressbookEntryForm.get('type').touched" class="help-block">Bitte eine Institution auswählen!</span>
 </div>
</form>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="save()" [disabled]="!addressbookEntryForm.valid || saving" type="button" class="m-2 btn btn-success pull-right">speichern</button>
</div>

<div class="m-2 p-2">
  <h4>Senior zuweisen</h4>
  <div *ngIf="user">für {{user.first_name}} {{user.last_name}} <span class="badge badge-pill badge-success">{{user.abbreviation}}</span></div>
  <form class="mt-4" [formGroup]="form">
    <select class="form-control" formControlName="senioruser">
      <option value="0">--- keinen Senior zuweisen ---</option>
      <option [value]="op.id" *ngFor="let op of user_selection">{{op.first_name}} {{op.last_name}} ({{op.abbreviation}})</option>
    </select>
  </form>
  <div class="mt-3">
    <button class="mx-2 btn btn-sm btn-danger" (click)="cancel()">abbrechen</button>
    <button class="mx-2 btn btn-sm btn-success" (click)="save()">speichern</button>
  </div>
</div>

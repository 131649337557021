<div class="card my-2">

  <h3 style="display: none">Keyboard Shortcuts</h3>
  <table class="table table-striped" style="display: none;">
    <tr><td class="col-lg-2 col-md-4"><kbd>Einfügen</kbd></td><td>neuen Datensatz eingeben</td></tr>
    <tr><td><kbd>Esc</kbd></td><td>Fenster schliessen</td></tr>
    <tr><td><kbd>Enter</kbd></td><td>bestätigen / abschliessen</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>D</kbd></td><td>Dashboard</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>F</kbd></td><td>Fälle</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>T</kbd></td><td>Termine</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>P</kbd></td><td>Personen</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>K</kbd></td><td>Kontakte</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>M</kbd></td><td>Mitarbeiter</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>A</kbd></td><td>Archiv</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>P</kbd></td><td>Papierkorb</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>H</kbd></td><td>Hilfe</td></tr>
    <tr><td><kbd>Shift</kbd> + <kbd>Alt</kbd> + <kbd>L</kbd></td><td>Logout</td></tr>
  </table>

  <h3>Benutzungshinweise</h3>
  <h4>Dateneingabe</h4>
  <h5>einzeilige Textfelder</h5>
  <ul>
    <li>Bei einzeiligen Textfeldern gibt es die Optionen ! und ? (jeweils als erstes Zeichen des Feldes)</li>
    <li>! kennzeichnet eine Angabe, die hervorgehoben dargestellt werden soll, z.B. !Urkunde anfordern</li>
    <li>? kennzeichnet eine unsichere Angabe, z.B. ?Dortmund</li>
  </ul>
  <h5>Datumsfelder</h5>
  <ul>
    <li>Die Eingabe von Datumsangaben erfolgt grundsätzlich 8-stellig im Format ttmmyyyy (ohne Punkte)</li>
    <li>Beispiel: 01012012 für 01.01.2012</li>
    <li>Folgende Zusatzzeichen sind möglich: ? + - #</li>
    <li>- kennzeichnet einen Zeitraum, also 01012012-31122012 für den Zeitraum vom 01.01.2012 bis zum 31.12.2012</li>
    <li>+ kennzeichnet zwei alternative Datumsangaben, also 01012012+31122012 für entweder 01.01.2012 oder 31.12.2012</li>
    <li>? kennzeichnet ein unsicheres Datum, unsichere Datums-Alternativen oder unsichere Zeiträume, z.B. ?01012012, ?02032001-04032001 oder ?02032012+20032012</li>
    <li># ermöglicht eine Freitext-Eingabe (max. 19 Zeichen), z.B. #siehe Kommentar</li>
  </ul>
  <h5>Farbcodes für Erben</h5>
  <ul>
    <li><span style="background-color: #00ee00;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Erbe angelegt, aber V+V noch nicht verschickt</li>
    <li><span style="background-color: #004000;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> V+V verschickt</li>
    <li><span style="background-color: #b88428;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> V+V unterschrieben zurück</li>
    <li><span style="background-color: #ff0000;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> unwilliger Erbe</li>
  </ul>

</div>

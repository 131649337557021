/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-exchange-person.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stammbaum-exchange-person.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/case.service";
import * as i7 from "../../tools/show-date-pipe/show-date-pipe";
import * as i8 from "../../services/stammbaum.service";
var styles_StammbaumExchangePersonComponent = [i0.styles];
var RenderType_StammbaumExchangePersonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumExchangePersonComponent, data: {} });
export { RenderType_StammbaumExchangePersonComponent as RenderType_StammbaumExchangePersonComponent };
function View_StammbaumExchangePersonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keine Personen gefunden"]))], null, null); }
function View_StammbaumExchangePersonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.geburtsland; _ck(_v, 1, 0, currVal_0); }); }
function View_StammbaumExchangePersonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangePersonComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exchange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["austauschen"]))], function (_ck, _v) { var currVal_5 = _v.context.$implicit.geburtsland; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.nachname; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.vorname; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.geburtsname; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.printDate(_v.context.$implicit.geburtsdatum); _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.geburtsinfo; _ck(_v, 10, 0, currVal_4); var currVal_6 = _co.printPersonType(_v.context.$implicit); _ck(_v, 14, 0, currVal_6); }); }
function View_StammbaumExchangePersonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "table", [["class", "table-responsive table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachname"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vorname"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsname"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsdatum"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsinfo (Ort)"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personen-Typ"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangePersonComponent_3)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.persons; _ck(_v, 16, 0, currVal_0); }, null); }
export function View_StammbaumExchangePersonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " austauschen gegen bestehenden Person-Datensatz"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangePersonComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangePersonComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.persons || (_co.persons.length === 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.persons && (_co.persons.length > 0)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPersonName(); _ck(_v, 2, 0, currVal_0); }); }
export function View_StammbaumExchangePersonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-select-person", [], null, null, null, View_StammbaumExchangePersonComponent_0, RenderType_StammbaumExchangePersonComponent)), i1.ɵdid(1, 245760, null, 0, i3.StammbaumExchangePersonComponent, [i4.NgbActiveModal, i5.Store, i6.CaseService, i7.ShowDatePipe, i8.StammbaumService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumExchangePersonComponentNgFactory = i1.ɵccf("stammbaum-select-person", i3.StammbaumExchangePersonComponent, View_StammbaumExchangePersonComponent_Host_0, {}, {}, []);
export { StammbaumExchangePersonComponentNgFactory as StammbaumExchangePersonComponentNgFactory };

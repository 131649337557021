<div class="modal-header">
  <h2 class="ilatitle">Email an Mitarbeiter senden</h2>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <h6><b>Empfänger manuell hinzufügen</b></h6>
    </div>
    <div class="col-6">
      <h6><b>Mitarbeiter auswählen:</b></h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="input-group">
        <input class="add-btn form-control-sm form-control" autocomplete="off" id="mail-add" [(ngModel)]="single_mail" placeholder="Email hinzufügen..." type="text"> <button class="btn btn-sm btn-primary add-btn" type="button" (click)="addToList()" title="Email-Adresse zur Empfängerliste hinzufügen"><span class="fas fa-user-plus"></span> </button>
      </div>
      <h6><b>Empfänger-Liste:</b></h6>
      <div *ngFor="let mail of manual"><span class="list-item-manual">{{mail}}</span><i (click)="deleteFromList(mail)" class="fas fa-minus-circle" title="Email-Adresse löschen"></i></div>
    </div>
    <div class="col-6">
      <button class="btn btn-sm mitarb-btn" title="Administratoren ein/ausblenden" [ngClass]="admin ? 'btn-success' : 'btn-outline-success'" (click)="switch('admin')">Admin</button>
      <button class="btn btn-sm mitarb-btn" title="Ermittlungsleitung ein/ausblenden" [ngClass]="leitung ? 'btn-success' : 'btn-outline-success'" (click)="switch('leitung')">Leit.(Erm.)</button>
      <button class="btn btn-sm mitarb-btn" title="Verwaltungs-Mitarbeiter ein/ausblenden" [ngClass]="verwaltung ? 'btn-success' : 'btn-outline-success'" (click)="switch('verwaltung')">Verwa.</button>
      <button class="btn btn-sm mitarb-btn" title="Ermittler ein/ausblenden" [ngClass]="ermittler ? 'btn-success' : 'btn-outline-success'" (click)="switch('ermittler')">Erm.</button>
      <button class="btn btn-sm mitarb-btn" title="NVZ-Mitarbeiter ein/ausblenden" [ngClass]="nvz ? 'btn-success' : 'btn-outline-success'" (click)="switch('nvz')">NVZ</button>
      <button class="btn btn-sm mitarb-btn" title="Archiv-Mitarbeiter ein/ausblenden" [ngClass]="archiv ? 'btn-success' : 'btn-outline-success'" (click)="switch('archiv')">Archiv</button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div *ngFor="let send of send_to"><span title="{{send.first_name}} {{send.last_name}} von Empfänger-Liste entfernen" (click)="switchList(send, 1)" class="list-item"><span [ngStyle]="{'background': pillColor(send)}" class="badge badge-pill">{{pillLabel(send)}}</span>{{send.first_name}} {{send.last_name}}</span></div>
    </div>
    <div class="col-6">
      <div *ngFor="let not_send of not_send_to">
        <span title="{{not_send.first_name}} {{not_send.last_name}} zur Empfänger-Liste hinzufügen" (click)="switchList(not_send,2)" class="list-item"><span [ngStyle]="{'background': pillColor(not_send)}" class="badge badge-pill">{{pillLabel(not_send)}}</span>{{not_send.first_name}} {{not_send.last_name}}</span></div>
    </div>
  </div>
  <div class="row button-row">
    <div class="col-6">
      <button title="Alle Mitarbeiter von Empfängerliste löschen" (click)="allToNotSend()" id="btn-send-to-not" class="btn btn-warning btn-sm pull-left">Alle löschen</button>
    </div>
    <div class="col-6">
      <button title="Alle angezeigten Mitarbeiter zur Empfängerliste hinzufügen." (click)="allToSend()" id="btn-not-to-send" class="btn btn-warning btn-sm pull-right">An Alle</button>
    </div>
  </div>
  <div><b>Betreff:</b></div>
  <input autocomplete="off" class="input col-11" type="text" [(ngModel)]="header" id="betreff" placeholder="Betreff..." name="betreff">
  <div><b>Nachricht:</b></div>
  <div>
    <quill-editor class="ql-editor" [(ngModel)]="message" [modules]="quillToolbar" placeholder="Mail..."></quill-editor>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-sm btn-success" id="btn-close" (click)="send()">Senden</button>
  <button class="btn btn-sm btn-danger" id="btn-dismiss" (click)="dismiss()">Abbrechen</button>
</div>

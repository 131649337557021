<div class="m-2 p-2">
  <h4>Bestehende Urkunde wiederverwenden <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">Schließen</button></h4>
  <div *ngIf="loading">
    <i class="fa fa-cog fa-spin"></i> wird geladen....
  </div>
  <ngb-accordion #accordion="ngbAccordion" *ngIf="persons && !loading && mode==='list'">
      <ngb-panel *ngFor="let person of persons | keyvalue: order" id="person-{{person.key}}">
        <ng-template ngbPanelHeader>
          <div class="custom-header justify-content-between">
            <button class="accordion-button" ngbPanelToggle><b>{{person.value.person.nachname}} {{person.value.person.geburtsname}}, {{person.value.person.vorname}} </b></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="urkunden" *ngFor="let urk of person.value.urkunden">
            <button class="m-2 btn btn-sm btn-success" (click)="selectUrkunde(urk)">auswählen</button>
            <b>{{getUrkundenUnterart(urk)}} {{urk.urkunden_nummer}}/{{urk.jahr}}</b> -- {{getUrkundenStatus(urk)}}
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  <div *ngIf="mode==='single' && choosenUrkunde && !loading">
    <h5>{{getUrkundenUnterart(choosenUrkunde)}} {{choosenUrkunde.urkunden_nummer}}/{{choosenUrkunde.jahr}} ({{choosenUrkunde.person.vorname}} {{choosenUrkunde.person.nachname}} {{choosenUrkunde.person.geburtsname}}) für {{personToUploadFor.vorname}} {{personToUploadFor.nachname}} verwenden</h5>
    <div class="reuse-options">
      Zählt für <b>{{personToUploadFor.vorname}} {{personToUploadFor.nachname}}</b> als:
      <select [(ngModel)]="countsAs" (ngModelChange)="onTypeChange($event)" id="urkunden-art" class="form-control">
        <option [ngValue]="0">----Bitte Wählen----</option>
        <option [ngValue]="1">Geburtsurkunde</option>
        <option [ngValue]="2">Sterbeurkunde</option>
        <option [ngValue]="3">Heiratsurkunde</option>
      </select>
    </div>
  </div>
  <div *ngIf="showMarriageSelect" class="reuse-options">
    <h5>Eheschließung auswählen</h5>
    <select [(ngModel)]="marriageID" id="marriage_select" class="form-control">
      <option value="0">---bitte auswählen---</option>
      <option *ngFor="let m of personToUploadFor.marriages_for_stammbaum_person" [ngValue]="m.id">
        {{display_marriage(m)}}
      </option>
    </select>
    <td></td>
  </div>
  <div *ngIf="mode === 'error'">
    Fehler beim Erstellen der Referenz. Bitte versuchen Sie es erneut!
  </div>
  <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">Schließen</button>
  <button (click)="uploadDuplicate()" class="m-2 btn btn-sm btn-success">Verwenden</button>
</div>

<div class="card m-2" *ngIf="stammbaumMode !== 'tree-view'">
  <div class="card-body">
    <span style="color: #428bca; font-weight: bold; font-size: 120%;">{{caseInfo()}}</span>
    <button  (click)="backToCaseViaRouter()" class="m-2 btn btn-sm btn-danger"><span title="Stammbaum-Modus beenden" class="fa fa-lg fa-window-close"></span></button>
    <stammbaum-version-display [stammbaumState]="stammbaumStateObs | async"></stammbaum-version-display>
    <button *ngIf="(stammbaumStateObs | async )?.stammbaumVersions?.length > 0" class="m-2 btn btn-sm btn-success" (click)="toTreeView()">zur Baumansicht wechseln</button>
    <button *ngIf="(stammbaumStateObs | async )?.stammbaumVersions?.length > 0" class="m-2 btn btn-sm btn-info" (click)="toCheckpoints()">Speicherpunkte</button>
  </div>
</div>

<div *ngIf="(stammbaumStateObs | async )?.stammbaumVersions?.length > 0">
  <stammbaum-list-persons *ngIf="stammbaumMode === 'person-list'" [stammbaumState]="stammbaumStateObs | async"></stammbaum-list-persons>
  <stammbaum-tree-svg *ngIf="stammbaumMode === 'tree-view'" [stammbaumState]="stammbaumStateObs | async"></stammbaum-tree-svg>
  <stammbaum-controls *ngIf="stammbaumMode === 'tree-view'" [stammbaumState]="stammbaumStateObs | async" class="card fixed-pos"></stammbaum-controls>
  <stammbaum-urkunden-upload *ngIf="stammbaumMode === 'urkunden-upload-list'" [stammbaumState]="stammbaumStateObs | async"></stammbaum-urkunden-upload>
  <stammbaum-urkunden-upload [editMode]="true" *ngIf="stammbaumMode === 'urkunden-edit-list'" [stammbaumState]="stammbaumStateObs | async"></stammbaum-urkunden-upload>
</div>


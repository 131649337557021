<div class="m-2 p-2">
  <h4>{{person.nachname}} {{person.vorname}} bearbeiten</h4>
  <form [formGroup]="form" class="mt-1">
    <div class="row">

      <div class="col-md-9">
        <table class="table-responsive table-sm display-table">
          <tr>
            <td>Status der Person</td>
            <td colspan="3">
              <select *ngIf="isAvailableOption()" formControlName="person_type" type="text" id="person_type"
                      class="form-control">
                <option *ngFor="let opt of availableOptions" [value]="opt.id">{{opt.description}}</option>
              </select>
              <select *ngIf="!isAvailableOption()" formControlName="person_type" type="text" id="person_type_2"
                      class="form-control">
                <option *ngFor="let opt of this.selectedOption" [value]="opt.id">{{opt.description}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <div class="form-check">
                <div class="row">
                  <div class="col-4">
                    <input type="checkbox" id="adoptiert" formControlName="adoptiert" class="form-check-input">
                    <label class="form-check-label" for="adoptiert">adoptiert</label>
                  </div>
                  <div class="col-4">
                    <input type="checkbox" id="gefallen" formControlName="gefallen" class="form-check-input">
                    <label class="form-check-label" for="gefallen">gefallen</label>
                  </div>
                  <div class="col-4">
                    <input type="checkbox" id="unbekannt" formControlName="unbekannt" class="form-check-input">
                    <label class="form-check-label" for="unbekannt">Person unbekannt</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td colspan="3">
              <div class="form-check">
                <div class="row">
                  <div class="col">
                    <input type="checkbox" id="wird_gesucht" formControlName="wird_gesucht" class="form-check-input">
                    <label class="form-check-label" for="wird_gesucht">wird gesucht</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="sicher_keine_nachkommen" formControlName="sicher_keine_nachkommen"
                           class="form-check-input">
                    <label class="form-check-label" for="sicher_keine_nachkommen">sicher keine Nachkommen</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="nk_in_vierter_eo_nicht_erbberechtigt"
                           formControlName="nk_in_vierter_eo_nicht_erbberechtigt" class="form-check-input">
                    <label class="form-check-label" for="nk_in_vierter_eo_nicht_erbberechtigt">NK in 4. EO nicht
                      erbberechtigt</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <div class="form-check">
                <div class="row">
                  <div class="col">
                    <input type="checkbox" id="berliner_testament" formControlName="berliner_testament" class="form-check-input">
                    <label class="form-check-label" for="berliner_testament">Berliner Testament</label>
                  </div>
                  <div class="col-md-4" *ngIf="displayUnwillig">
                    <input type="checkbox" id="unwillig_include" formControlName="unwillig_still_include_ebs" class="form-check-input">
                    <label class="form-check-label" for="unwillig_include">trotzdem in ESA aufnehmen</label>
                  </div>
                  <div class="col-md-4" *ngIf="displayESAStopField">
                    <input type="checkbox" id="esa_stop" formControlName="nachverstorben_stop_esa"
                           class="form-check-input">
                    <label class="form-check-label" for="esa_stop">ESA-Zweig NICHT ausformulieren</label>
                  </div>
                  <div class="col-md-4">
                    <input type="checkbox" id="sicherungsaufforderung" formControlName="sicherungsaufforderung" class="form-check-input">
                    <label class="form-check-label" for="sicherungsaufforderung">Sicherungsaufforderung</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="displayAusgeschlagen">
            <td>Ausschlagung</td>
            <td colspan="3">
              <div class="row place-items-center">
                <div class="col-md-2" *ngIf="displayAusgeschlagen">
                  <input type="checkbox" id="ausschlagung_belegt" formControlName="ausschlagung_belegt" class="form-check-input">
                  <label class="form-check-label" for="ausschlagung_belegt">belegt</label>
                </div>
                <div class="col-md-2" *ngIf="displayAusgeschlagen">
                  <input type="checkbox" id="ausschlagung_for_minors" formControlName="ausschlagung_for_minors" class="form-check-input">
                  <label class="form-check-label" for="ausschlagung_for_minors">für Nachkommen</label>
                </div>
                <div class="col" *ngIf="displayAusgeschlagen">
                  <label class="form-check-label" for="ausschlagung_aktenbezug">Aktenbezug</label>
                  <input formControlName="ausschlagung_aktenbezug" type="text" id="ausschlagung_aktenbezug" class="form-control">
                </div>
                <div title="Ausschlagungsort" class="col" *ngIf="displayAusgeschlagen">
                  <label class="form-check-label" for="ausschlagung_ort">Ort</label>
                  <input formControlName="ausschlagung_ort" type="text" id="ausschlagung_ort"
                         class="form-control">
                </div>
                <div title="Ausschlagungsdatum" class="col" *ngIf="displayAusgeschlagen">
                  <date-input-form formControlName="ausschlagung_datum" id="ausschlagung_datum"
                                   class="form-control"></date-input-form>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="displayAdoption">
            <td>Adoptionsdatum</td>
            <td colspan="3">
              <div class="row space-around place-items-center">
                <div>
                  <date-input-form formControlName="adoptiert_jahr" type="text" id="adoptiert_jahr"
                               class="form-control"></date-input-form>
                </div>
                <div>Adoptionsland</div>
                <div><select formControlName="adoptiert_land" type="text" id="adoptiert_land" class="form-control">
                  <option *ngFor="let option of stammbaumService.adoptionsland_options" [value]="option.id">{{option.description}}</option>
                </select></div>
                <div>Erbrecht geht auf Adoptionseltern über</div>
                <div><input type="checkbox" formControlName="adoptiert_erbrecht_auf_adoptionseltern" class="form-control" id="adoptiert_erbrecht_auf_adoptionseltern"></div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Nachname</td>
            <td><input formControlName="nachname" type="text" id="nachname"
                       class="form-control"></td>
            <td>Geburtsname</td>
            <td><input formControlName="geburtsname" type="text" id="geburtsname" class="form-control"></td>
          </tr>
          <tr>
            <td>Vornamen</td>
            <td colspan="3"><input formControlName="vorname" type="text" id="vorname" class="form-control"></td>
          </tr>
          <tr>
            <td>Geburtsdatum</td>
            <td>
              <date-input-form formControlName="geburtsdatum" type="text" id="geburtsdatum"
                               class="form-control"></date-input-form>
            </td>
            <td>Geburtsort</td>
            <td>
              <input formControlName="geburtsinfo" type="text" id="geburtsinfo" class="form-control">
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Geburtsland</td>
            <td>
              <input formControlName="geburtsland" type="text" id="geburtsland" class="form-control"
                     placeholder="(wenn nicht Deutschland)">
            </td>
          </tr>
          <tr>
            <td>Taufdatum</td>
            <td>
              <date-input-form formControlName="taufdatum" type="text" id="taufdatum"
                               class="form-control"></date-input-form>
            </td>
            <td>Taufinfos</td>
            <td><input formControlName="taufinfo" type="text" id="taufinfo" class="form-control"></td>
          </tr>
          <tr>
            <td>Sterbedatum</td>
            <td>
              <date-input-form formControlName="sterbedatum" type="text" id="sterbedatum"
                               class="form-control"></date-input-form>
            </td>
            <td>Sterbeort</td>
            <td>
              <input formControlName="sterbeinfo" type="text" id="sterbeinfo" class="form-control">
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Sterbeland</td>
            <td>
              <input formControlName="sterbeland" type="text" id="sterbeland" class="form-control" placeholder="(wenn nicht Deutschland)">
            </td>
          </tr>
          <tr>
            <td>Begräbnisdatum</td>
            <td><date-input-form formControlName="begraebnisdatum" type="text" id="begraebnisdatum"
                               class="form-control"></date-input-form></td>
            <td>Begräbnisinfos</td>
            <td><input formControlName="begraebnisinfo" type="text" id="begraebnisinfo" class="form-control"></td>
          </tr>
          <tr>
            <td>Religion</td>
            <td><input formControlName="religion" type="text" id="religion" class="form-control"></td>
            <td>Geschlecht</td>
            <td><select formControlName="geschlecht" type="text" id="geschlecht" class="form-control">
              <option value="1">weiblich</option>
              <option value="2">männlich</option>
              <option value="0">unbekannt</option>
            </select></td>
          </tr>
          <tr>
            <td>Staatsbürgerschaft</td>
            <td><select formControlName="staatsbuergerschaft" type="text" id="staatsbuergerschaft" class="form-control">
              <option *ngFor="let option of stammbaumService.staatsbuergerschaft_options" [value]="option.id">{{option.description}}</option>
            </select></td>
          </tr>
          <tr>
            <td>Urkunden</td>
            <td colspan="3">
              <div class="form-check">
                <div class="row">
                  <div class="col">
                    &nbsp;&nbsp;&nbsp;
                    <input type="checkbox" id="needs_gu" formControlName="needs_gu" class="form-check-input">
                    <label class="form-check-label" for="needs_gu">GU benötigt</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="ordered_gu" formControlName="ordered_gu" class="form-check-input">
                    <label class="form-check-label" for="ordered_gu">GU bestellt</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="needs_gu_but_do_without" formControlName="needs_gu_but_do_without" class="form-check-input">
                    <label class="form-check-label" for="needs_gu_but_do_without">Verzicht auf GU</label>
                  </div>
                  <div class="col">
                    <choose-user-reactive-forms id="ordered_gu_user" formControlName="ordered_gu_user"></choose-user-reactive-forms>
                  </div>
                  <div class="col">
                    <date-input-form formControlName="ordered_gu_date" type="text" id="ordered_gu_date"
                                     placeholder="Datum"></date-input-form>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <div class="row">
                  <div class="col">
                    &nbsp;&nbsp;&nbsp;
                    <input type="checkbox" id="needs_su" formControlName="needs_su" class="form-check-input">
                    <label class="form-check-label" for="needs_su">SU benötigt</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="ordered_su" formControlName="ordered_su" class="form-check-input">
                    <label class="form-check-label" for="ordered_su">SU bestellt</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="needs_su_but_do_without" formControlName="needs_su_but_do_without" class="form-check-input">
                    <label class="form-check-label" for="needs_su_but_do_without">Verzicht auf SU</label>
                  </div>
                  <div class="col">
                    <choose-user-reactive-forms id="ordered_su_user" formControlName="ordered_su_user"></choose-user-reactive-forms>
                  </div>
                  <div class="col">
                    <date-input-form formControlName="ordered_su_date" type="text" id="ordered_su_date"
                                     placeholder="Datum"></date-input-form>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="col-md-3">
        <div>
          <p bol>Kommentar Beruf</p>
          <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autofocus autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_beruf" id="kommentar_beruf"
                  class="keepnl form-control"></textarea>
        </div>
        <div>
          <p bol>Kommentar AB-Recherche</p>
          <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_ab_recherche" id="kommentar_ab_recherche"
                  class="keepnl form-control"></textarea>
        </div>
        <div>
          <p bol>Kommentar Urkunden</p>
          <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_urkunden" id="kommentar_urkunden"
                  class="keepnl form-control"></textarea>
        </div>
        <div>
          <p bol>Kommentar sonstiges</p>
          <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autosize [minRows]="6" [maxRows]="30" formControlName="kommentar" id="kommentar"
                  class="keepnl form-control"></textarea>
        </div>
      </div>

    </div>
  </form>
  <button (click)="cancel()" class="mx-2 btn btn-sm btn-warning">abbrechen</button>
  <button (click)="check()" class="mx-2 btn btn-sm btn-success">speichern</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-marriage-connection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stammbaum-tree-marriage-connection.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/stammbaum-display.service";
var styles_StammbaumTreeMarriageConnectionComponent = [i0.styles];
var RenderType_StammbaumTreeMarriageConnectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeMarriageConnectionComponent, data: {} });
export { RenderType_StammbaumTreeMarriageConnectionComponent as RenderType_StammbaumTreeMarriageConnectionComponent };
function View_StammbaumTreeMarriageConnectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x1; var currVal_1 = _co.y1; var currVal_2 = _co.x2; var currVal_3 = _co.y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeMarriageConnectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "#f0f0f0"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x1; var currVal_1 = _co.y1; var currVal_2 = _co.x2; var currVal_3 = _co.y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeMarriageConnectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-dasharray", "4 3"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x1; var currVal_1 = _co.y1; var currVal_2 = _co.x2; var currVal_3 = _co.y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_StammbaumTreeMarriageConnectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:line", [["stroke", "#f0f0f0"], ["stroke-dasharray", "4 3"], ["stroke-width", "3"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x1; var currVal_1 = _co.y1; var currVal_2 = _co.x2; var currVal_3 = _co.y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_StammbaumTreeMarriageConnectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMarriageConnectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMarriageConnectionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMarriageConnectionComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMarriageConnectionComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.p1_is_secondary_marriage && !_co.p2_is_secondary_marriage) && !_co.hideConnection()); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.p1_is_secondary_marriage && !_co.p2_is_secondary_marriage) && _co.hideConnection()); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.hideConnection() && (_co.p1_is_secondary_marriage || _co.p2_is_secondary_marriage)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.hideConnection() && (_co.p1_is_secondary_marriage || _co.p2_is_secondary_marriage)); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_StammbaumTreeMarriageConnectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["stammbaum-tree-marriage-connection", ""]], null, null, null, View_StammbaumTreeMarriageConnectionComponent_0, RenderType_StammbaumTreeMarriageConnectionComponent)), i1.ɵdid(1, 180224, null, 0, i3.StammbaumTreeMarriageConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], null, null)], null, null); }
var StammbaumTreeMarriageConnectionComponentNgFactory = i1.ɵccf("[stammbaum-tree-marriage-connection]", i3.StammbaumTreeMarriageConnectionComponent, View_StammbaumTreeMarriageConnectionComponent_Host_0, { treeViewMode: "treeViewMode", m: "m", version: "version", mapOnly: "mapOnly", p1: "p1", p2: "p2" }, {}, []);
export { StammbaumTreeMarriageConnectionComponentNgFactory as StammbaumTreeMarriageConnectionComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./deactivated-modal-component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var styles_DeactivatedModalComponent = [];
var RenderType_DeactivatedModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeactivatedModalComponent, data: {} });
export { RenderType_DeactivatedModalComponent as RenderType_DeactivatedModalComponent };
export function View_DeactivatedModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Diese Funktion ist momentan deaktiviert"]))], null, null); }
export function View_DeactivatedModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deactivated-modal", [], null, null, null, View_DeactivatedModalComponent_0, RenderType_DeactivatedModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.DeactivatedModalComponent, [i2.NgbActiveModal], null, null)], null, null); }
var DeactivatedModalComponentNgFactory = i0.ɵccf("deactivated-modal", i1.DeactivatedModalComponent, View_DeactivatedModalComponent_Host_0, {}, {}, []);
export { DeactivatedModalComponentNgFactory as DeactivatedModalComponentNgFactory };

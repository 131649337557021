<span *ngIf="note" class="singleNote" (click)="openDetails()">
  {{note.updated | date:'dd.MM.yyyy H:mm'}} Uhr
  <span class="case-badge badge badge-pill badge-success">{{getNoteCaseText(note)}}</span>
  <span *ngIf="note.person_id > 0" class="case-badge badge badge-pill badge-warning">{{getNotePersonText(note)}}</span>
  <span class="badge badge-pill badge-danger" *ngIf="note.important"> ! </span>
  <span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.user_id)}}</span>
  <span *ngIf="note.result_user_id" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.result_user_id)}}</span>
  <br class="only-small">
 {{note.title}}
  <span class="ml-3 noteid">#{{note.id}}</span>
</span>

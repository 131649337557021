<div class="m-2 p-2" *ngIf="activeCase">
  <h5 class="ilatitle">Status ändern</h5>
  <div>
    momentaner Status: {{getCaseStatusName(activeCase.status)}}
  </div>
  <div style="margin-bottom: 30px">
    neuer Status:
    <select class="form-control" *ngIf="caseStatusOptions.length !== 0" [(ngModel)]="caseStatusSelected">
      <option *ngFor="let opt of caseStatusOptions" [value]="opt.to_status">{{opt.name}}</option>
    </select>
    <p *ngIf="caseStatusOptions.length === 0">Sie haben nicht die Berechtigung den Status dieses Falls zu ändern!</p>
  </div>
  <div *ngIf="ablageSelected()">
    Bitte wählen Sie einen Ablagegrund aus und geben Sie ggf. eine genauere Erläuterung an.<br /><br />
    <select class="form-control" *ngIf="ablagegrundOptions.length !== 0" [(ngModel)]="grund">
      <option *ngFor="let opt of ablagegrundOptions" [value]="opt.id">{{opt.description}}</option>
    </select>
    Erläuterung: <input class="form-control" type="text" maxlength="80" [(ngModel)]="erlaeuterung">
  </div>
  <div class="mt-2">
    <button *ngIf="caseStatusOptions.length !== 0 && (grund > 0 || !ablageSelected())" (click)="close()" class="mx-2 btn btn-sm btn-info">ändern</button>
    <button (click)="dismiss()" class="mx-2 btn btn-sm btn-warning">schließen</button>
  </div>
</div>

<div class="modal-header">
  <h5 class="modal-title">Status des Datenbank-Updates</h5>
</div>

<div class="modal-body">
  Letzte auf Hidrive gesicherte MySQL-Datenbank-Backups
  <table class="table">
    <tr><th>Datum</th><th>Datei</th></tr>
    <tr *ngFor="let bk of bks"><td>{{bk.created | date: 'dd.MM.yyyy HH:mm:ss'}}</td><td>{{bk.filename}}</td></tr>
  </table>
</div>

<div class="modal-footer">
  <button (click)="confirm()" type="button" class="m-2 btn btn-success pull-right">ok</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./line-image-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-image-viewer/ngx-image-viewer.ngfactory";
import * as i3 from "ngx-image-viewer";
import * as i4 from "./line-image-popup.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_LineImagePopupComponent = [i0.styles];
var RenderType_LineImagePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LineImagePopupComponent, data: {} });
export { RenderType_LineImagePopupComponent as RenderType_LineImagePopupComponent };
export function View_LineImagePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success m-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ngx-image-viewer", [], null, [["window", "keyup.ArrowRight"], ["window", "keyup.ArrowLeft"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("window:keyup.ArrowRight" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).nextImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keyup.ArrowLeft" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).prevImage($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onMouseOver() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_ImageViewerComponent_0, i2.RenderType_ImageViewerComponent)), i1.ɵdid(3, 114688, null, 0, i3.ImageViewerComponent, [[2, "config"]], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success m-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LineImagePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "line-image-popup", [], null, null, null, View_LineImagePopupComponent_0, RenderType_LineImagePopupComponent)), i1.ɵdid(1, 49152, null, 0, i4.LineImagePopupComponent, [i5.NgbActiveModal], null, null)], null, null); }
var LineImagePopupComponentNgFactory = i1.ɵccf("line-image-popup", i4.LineImagePopupComponent, View_LineImagePopupComponent_Host_0, {}, {}, []);
export { LineImagePopupComponentNgFactory as LineImagePopupComponentNgFactory };

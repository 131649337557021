/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./person-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../tools/editfield-name/editfield-name.component.ngfactory";
import * as i4 from "../../tools/editfield-name/editfield-name.component";
import * as i5 from "../../tools/custom-uppercase-pipe/custom-uppercase-pipe";
import * as i6 from "../../tools/editfield-address/editfield-address.component.ngfactory";
import * as i7 from "../../tools/editfield-address/editfield-address.component";
import * as i8 from "../../tools/editfield-contact/editfield-contact.component.ngfactory";
import * as i9 from "../../tools/editfield-contact/editfield-contact.component";
import * as i10 from "../../services/communication-detail.service";
import * as i11 from "../../tools/editfield-date/editfield-date.component.ngfactory";
import * as i12 from "../../tools/editfield-date/editfield-date.component";
import * as i13 from "./person-popup.component";
import * as i14 from "@ng-bootstrap/ng-bootstrap";
import * as i15 from "../../services/stammbaum.service";
var styles_PersonPopupComponent = [i0.styles];
var RenderType_PersonPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonPopupComponent, data: {} });
export { RenderType_PersonPopupComponent as RenderType_PersonPopupComponent };
function View_PersonPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (geb. ", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heir.geburtsname; _ck(_v, 1, 0, currVal_0); }); }
function View_PersonPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "mx-2 btn btn-warning btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToHeir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur Person wechseln"]))], null, null); }
function View_PersonPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ", ", " "])), i1.ɵppd(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonPopupComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 29, "table", [["class", "table table-responsive table-bordered table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "editfield-name", [], null, [[null, "first_nameChange"], [null, "last_nameChange"], [null, "birth_nameChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("first_nameChange" === en)) {
        var pd_0 = ((_co.heir.vorname = $event) !== false);
        ad = (pd_0 && ad);
    } if (("last_nameChange" === en)) {
        var pd_1 = ((_co.heir.nachname = $event) !== false);
        ad = (pd_1 && ad);
    } if (("birth_nameChange" === en)) {
        var pd_2 = ((_co.heir.geburtsname = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_EditfieldNameComponent_0, i3.RenderType_EditfieldNameComponent)), i1.ɵdid(12, 638976, null, 0, i4.EditfieldNameComponent, [i5.CustomUppercasePipe], { first_name: [0, "first_name"], last_name: [1, "last_name"], birth_name: [2, "birth_name"], editMode: [3, "editMode"] }, { first_nameChange: "first_nameChange", last_nameChange: "last_nameChange", birth_nameChange: "birth_nameChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Typ"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Anschrift"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "editfield-address", [], null, [[null, "addressChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addressChange" === en)) {
        var pd_0 = ((_co.heir.address = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_EditfieldAddressComponent_0, i6.RenderType_EditfieldAddressComponent)), i1.ɵdid(23, 638976, null, 0, i7.EditfieldAddressComponent, [], { address: [0, "address"], editMode: [1, "editMode"] }, { addressChange: "addressChange" }), (_l()(), i1.ɵeld(24, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kontakt"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "editfield-contact", [], null, null, null, i8.View_EditfieldContactComponent_0, i8.RenderType_EditfieldContactComponent)), i1.ɵdid(29, 638976, null, 0, i9.EditfieldContactComponent, [i10.CommunicationDetailService], { editMode: [0, "editMode"], personId: [1, "personId"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsdatum"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "editfield-date", [], null, [[null, "dateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateChange" === en)) {
        var pd_0 = ((_co.heir.geburtsdatum = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_EditfieldDateComponent_0, i11.RenderType_EditfieldDateComponent)), i1.ɵdid(35, 638976, null, 0, i12.EditfieldDateComponent, [], { date: [0, "date"], editMode: [1, "editMode"] }, { dateChange: "dateChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonPopupComponent_3)), i1.ɵdid(37, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(38, 0, null, null, 1, "button", [["class", "mx-2 btn btn-success btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.heir.geburtsname; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.heir.vorname; var currVal_4 = _co.heir.nachname; var currVal_5 = _co.heir.geburtsname; var currVal_6 = false; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.heir.address; var currVal_9 = false; _ck(_v, 23, 0, currVal_8, currVal_9); var currVal_10 = false; var currVal_11 = _co.heir.id; _ck(_v, 29, 0, currVal_10, currVal_11); var currVal_12 = _co.heir.geburtsdatum; var currVal_13 = false; _ck(_v, 35, 0, currVal_12, currVal_13); var currVal_14 = _co.canDisplayDetails(); _ck(_v, 37, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.heir.nachname)); var currVal_1 = _co.heir.vorname; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_7 = _co.getPersonTypeText(); _ck(_v, 17, 0, currVal_7); }); }
export function View_PersonPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonPopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heir; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PersonPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-person-popup", [], null, null, null, View_PersonPopupComponent_0, RenderType_PersonPopupComponent)), i1.ɵdid(1, 114688, null, 0, i13.PersonPopupComponent, [i14.NgbActiveModal, i15.StammbaumService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonPopupComponentNgFactory = i1.ɵccf("app-person-popup", i13.PersonPopupComponent, View_PersonPopupComponent_Host_0, {}, {}, []);
export { PersonPopupComponentNgFactory as PersonPopupComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-notification.component";
import * as i4 from "@ngrx/store";
var styles_AppNotificationComponent = [i0.styles];
var RenderType_AppNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppNotificationComponent, data: {} });
export { RenderType_AppNotificationComponent as RenderType_AppNotificationComponent };
function View_AppNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.appNotifications[0].icon_class, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_AppNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.appNotifications[0].icon_class; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "alert ", _co.appNotifications[0].css_class, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.appNotifications[0].text; _ck(_v, 4, 0, currVal_2); }); }
function View_AppNotificationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.icon_class, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_AppNotificationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.icon_class; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "alert ", _v.parent.context.$implicit.css_class, ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.text; _ck(_v, 3, 0, currVal_2); }); }
function View_AppNotificationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.limitToSlot == _v.context.$implicit.slot) || (_v.context.$implicit.slot == _co.SLOT_GLOBAL)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appNotifications; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppNotificationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Die lokalen Daten sind auf aktuellem Stand "]))], null, null); }
function View_AppNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.appNotifications == null) ? null : _co.appNotifications.length) > 0) && !_co.limitToSlot); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((((_co.appNotifications == null) ? null : _co.appNotifications.length) > 0) && _co.limitToSlot); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!(((_co.appNotifications == null) ? null : _co.appNotifications.length) > 0) && !_co.limitToSlot); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AppNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appNotifications; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_AppNotificationComponent_0, RenderType_AppNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i3.AppNotificationComponent, [i4.Store], null, null)], null, null); }
var AppNotificationComponentNgFactory = i1.ɵccf("app-notification", i3.AppNotificationComponent, View_AppNotificationComponent_Host_0, { limitToSlot: "limitToSlot" }, {}, []);
export { AppNotificationComponentNgFactory as AppNotificationComponentNgFactory };

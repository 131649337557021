/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kassenbuch-delete-kostenposition-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./kassenbuch-delete-kostenposition-popup.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_KassenbuchDeleteKostenpositionPopupComponent = [i0.styles];
var RenderType_KassenbuchDeleteKostenpositionPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KassenbuchDeleteKostenpositionPopupComponent, data: {} });
export { RenderType_KassenbuchDeleteKostenpositionPopupComponent as RenderType_KassenbuchDeleteKostenpositionPopupComponent };
function View_KassenbuchDeleteKostenpositionPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-12 help-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bitte geben Sie einen Grund f\u00FCr die L\u00F6schung an"]))], null, null); }
export function View_KassenbuchDeleteKostenpositionPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Kostenposition l\u00F6schen "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 19, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(9, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(11, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["Wollen Sie den Beleg ", " wirklich l\u00F6schen?"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "col-12 custom-control-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Grund f\u00FCr L\u00F6schung"])), (_l()(), i1.ɵeld(18, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 7, "input", [["class", "form-control"], ["formControlName", "deleted_reason"], ["id", "deleted_reason"], ["maxlength", "50"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(21, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(26, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KassenbuchDeleteKostenpositionPopupComponent_1)), i1.ɵdid(29, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-danger"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["l\u00F6schen"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 9, 0, currVal_7); var currVal_17 = "50"; _ck(_v, 21, 0, currVal_17); var currVal_18 = "deleted_reason"; _ck(_v, 24, 0, currVal_18); var currVal_19 = (_co.form.invalid && _co.form.controls.deleted_reason.touched); _ck(_v, 29, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 11).ngClassValid; var currVal_5 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.kostenposition.belegtitel; _ck(_v, 15, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 21).maxlength ? i1.ɵnov(_v, 21).maxlength : null); var currVal_10 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 26).ngClassValid; var currVal_15 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 19, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_20 = _co.form.invalid; _ck(_v, 33, 0, currVal_20); }); }
export function View_KassenbuchDeleteKostenpositionPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kassenbuch-delete-kostenposition-popup", [], null, null, null, View_KassenbuchDeleteKostenpositionPopupComponent_0, RenderType_KassenbuchDeleteKostenpositionPopupComponent)), i1.ɵdid(1, 114688, null, 0, i4.KassenbuchDeleteKostenpositionPopupComponent, [i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KassenbuchDeleteKostenpositionPopupComponentNgFactory = i1.ɵccf("app-kassenbuch-delete-kostenposition-popup", i4.KassenbuchDeleteKostenpositionPopupComponent, View_KassenbuchDeleteKostenpositionPopupComponent_Host_0, {}, {}, []);
export { KassenbuchDeleteKostenpositionPopupComponentNgFactory as KassenbuchDeleteKostenpositionPopupComponentNgFactory };

<div class="m-2 p-2">
  <h4>
    Netzwerk-Nachrichten
  </h4>
  <table class="table table-sm">
    <tr *ngFor="let mess of messages" [ngClass]="{'error': mess.level === 2, 'ok': mess.level === 0, 'warning' : mess.level === 1}">
      <td>{{mess.event_timestamp | date: 'dd.MM.y HH:mm:ss'}}</td><td>{{mess.text}}</td><td>{{displayLevel(mess.level)}}</td>
    </tr>
  </table>
  <button class="btn btn-sm btn-success" (click)="close()">schließen</button>
</div>



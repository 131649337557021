<div class="container">
  <nav class="navbar fixed-top navbar-light bg-light navbar-top-custom flex-row">
    <a class="navbar-brand" (click)="goToDashboard()"><img class="logo" src="assets/ilanot-logo.png" alt="iLANOT"/></a>

    <!--Navigation Buttons for Large Screens-->
    <form class="form-inline d-none d-md-block">

      <!-- Modul Namensverzeichnisse -->
      <ng-container *ngIf="
      ui.ui.routing.activeModule === MODULES.NAMENSVERZEICHNISSE &&
      (ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG)">
        <button (click)="nvzBackToSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>NVZ-Suche</button>
        <button (click)="standesamtSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-search"></span>Standesamt-Direktsuche</button>
        <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="standesamtSort()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-search"></span>Standesamt-Zuordnung</button>
      </ng-container>

      <!-- Modul USER -->
      <ng-container *ngIf="ui.ui.routing.activeModule === MODULES.USERS && (ui.ui.routing.activePageInModule.USERS === MODULE_PAGES.USERS.USER_TREE)">
        <button (click)="userList()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Mitarbeiter-Liste</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule === MODULES.USERS && (ui.ui.routing.activePageInModule.USERS === MODULE_PAGES.USERS.USER_LIST)">
        <button (click)="userTree()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Mitarbeiter-Hierarchie</button>
      </ng-container>

      <!-- Modul FALL -->
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.CASESEARCH">
        <button *ngIf="rightsManagementService.isAllowedToSeePool()" (click)="casePool()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Fall-Pool</button>
        <button *ngIf="rightsManagementService.isAllowedToSeeBoerse()" (click)="caseBoerse()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Fall-Börse</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.BOERSE">
        <button (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Suche/Listen</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.POOL">
        <button (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Suche/Listen</button>
      </ng-container>

      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.CASEDETAIL">
        <button *ngIf="rightsManagementService.isAllowedToSearchCases()" (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Fall-Suche</button>
        <button (click)="scrollToTop()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-double-up"></span> Oben</button>
        <button (click)="scrollToNote()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span> Notizen</button>
        <button (click)="scrollToPersons()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span> Personen</button>
        <button (click)="scrollToFiles()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span> Dokumente</button>
      </ng-container>
    </form>

    <!--Navigation Buttons for Small Screens-->
    <form class="form-inline d-md-none">

      <!-- Modul Namensverzeichnisse -->
      <ng-container *ngIf="
      ui.ui.routing.activeModule === MODULES.NAMENSVERZEICHNISSE &&
      (ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE ||
      ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG)">
        <button (click)="nvzBackToSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Suche</button>
        <button (click)="standesamtSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-search"></span>Amt-Suche</button>
        <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="standesamtSort()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-search"></span>Amt-Zuord</button>
      </ng-container>

      <!-- Modul USER -->
      <ng-container *ngIf="ui.ui.routing.activeModule === MODULES.USERS && (ui.ui.routing.activePageInModule.USERS === MODULE_PAGES.USERS.USER_TREE)">
        <button (click)="userList()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Liste</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule === MODULES.USERS && (ui.ui.routing.activePageInModule.USERS === MODULE_PAGES.USERS.USER_LIST)">
        <button (click)="userTree()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Hierarchie</button>
      </ng-container>

      <!-- Modul FALL -->
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.CASESEARCH">
        <button *ngIf="rightsManagementService.isAllowedToSeePool()" (click)="casePool()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Pool</button>
        <button *ngIf="rightsManagementService.isAllowedToSeeBoerse()" (click)="caseBoerse()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Börse</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.BOERSE">
        <button (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Suche</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.POOL">
        <button (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Suche</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.CASEDETAIL">
        <button *ngIf="rightsManagementService.isAllowedToSearchCases()" (click)="caseSearch()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-right"></span>Suche</button>
        <button (click)="scrollToTop()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-double-up"></span>O</button>
        <button (click)="scrollToNote()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span>N</button>
        <button (click)="scrollToPersons()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span>P</button>
        <button (click)="scrollToFiles()" class="mx-1 btn btn-sm btn-primary"><span class="fa fa-angle-right"></span>D</button>
      </ng-container>
      <ng-container *ngIf="ui.ui.routing.activeModule==MODULES.CASES && ui.ui.routing.activePageInModule.CASES==MODULE_PAGES.CASES.STAMMBAUM">
        <button (click)="goBackToCaseFromStammbaum()" class="mx-1 btn btn-sm btn-success"><span class="fa fa-angle-double-left"></span>Fall</button>
      </ng-container>

    </form>
    <ul class="navbar-nav flex-row ml-auto">
      <li *ngIf="constantsService.isTest()" class="mx-1 nav-item red-bold">TEST</li>
      <li class="nav-item"><span class="badge badge-pill badge-primary">{{authUser.authUser?.user?.abbreviation}}</span></li>
      <li *ngIf="userRole!=''" class="nav-item ml-2"> ({{userRole}})</li>
      <li [ngClass]="{'ok': network?.isConnectedToServer, 'error': !network?.isConnectedToServer}" class="nav-item ml-3"><network-manager-icon (click)="showNetworkManager()"></network-manager-icon></li>
      <li (click)="showUploadManager()" *ngIf="fileUploadService.uploading" title="Datei-Upload läuft" class="nav-item ml-3"><span class="fa fa-upload fa-spin"></span></li>
      <li (click)="showUploadManager()" *ngIf="!fileUploadService.uploading && !fileUploadService.upload_error" title="keine Fehler beim Datei-Upload" class="nav-item ok ml-3"><span class="fa fa-upload"></span></li>
      <li (click)="showUploadManager()" *ngIf="fileUploadService.upload_error && !fileUploadService.uploading" title="Fehler beim Datei-Upload" class="nav-item error ml-3"><span class="fa fa-upload"></span></li>
      <li (click)="showUploadManager()" class="nav-item ml-1" title="Größe der Datei-Upload-Warteschlange">{{fileUploadService.uploader?.queue?.length}}</li>
    </ul>
  </nav>
</div>

<div class="p-3 m-3 main" *ngIf="!recommend_only">
  <h3>Veraltete Software-Version</h3>
  <p>
    Sie verwenden die veraltete Software-Version {{version}}, die nicht mehr zugelassen ist.
    Bitte aktualisieren Sie Ihre Version durch Leeren des Browser-Caches und anschließendem Reload.
  </p>
  <p>
    In den meisten Browsern kann dies durch Drücken von CTRL + F5 bzw. durch Halten von CTRL oder Shift und gleichzeitiges Anklicken des Reload-Buttons erreicht werden.
  </p>
</div>

<div class="p-3 m-3 main-rec" *ngIf="recommend_only">
  <h3>Neue Software-Version verfügbar</h3>
  <p>
    Es ist eine aktuellere Software-Version als die von Ihnen verwendete Version {{version}} verfügbar.
    Bitte aktualisieren Sie bei Gelegenheit Ihre Version durch Leeren des Browser-Caches und anschließendem Reload.
  </p>
  <p>
    In den meisten Browsern kann dies durch Drücken von CTRL + F5 bzw. durch Halten von CTRL oder Shift und gleichzeitiges Anklicken des Reload-Buttons erreicht werden.
  </p>
  <button (click)="close()" class="btn btn-sm btn-success m-2">OK</button>
</div>

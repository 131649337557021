<div class="m-2 p-2" *ngIf="heir">
  <h3>{{heir.nachname | uppercase}}, {{heir.vorname}} <span *ngIf="heir.geburtsname"> (geb. {{heir.geburtsname}})</span></h3>
  <table class="table table-responsive table-bordered table-striped">
    <tr><td>Name</td><td><editfield-name [editMode]="false" [(first_name)]="heir.vorname" [(last_name)]="heir.nachname" [(birth_name)]="heir.geburtsname"></editfield-name></td></tr>
    <tr><td>Typ</td><td>{{getPersonTypeText()}}</td></tr>
    <tr><td>Anschrift</td><td><editfield-address [editMode]="false" [(address)]="heir.address"></editfield-address></td></tr>
    <tr><td>Kontakt</td><td><editfield-contact [personId]="heir.id" [editMode]="false"></editfield-contact></td></tr>
    <tr><td>Geburtsdatum</td><td><editfield-date [(date)]="heir.geburtsdatum" [editMode]="false"></editfield-date></td></tr>
  </table>
  <button *ngIf="canDisplayDetails()" (click)="goToHeir()" class="mx-2 btn btn-warning btn-sm">zur Person wechseln</button>
  <button (click)="close()" class="mx-2 btn btn-success btn-sm">schliessen</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-tree.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-tree.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/user.service";
var styles_UserTreeComponent = [i0.styles];
var RenderType_UserTreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserTreeComponent, data: {} });
export { RenderType_UserTreeComponent as RenderType_UserTreeComponent };
function View_UserTreeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "card-body px-3 py-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_UserTreeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTreeComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.juniors; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.senior; _ck(_v, 2, 0, currVal_0); }); }
export function View_UserTreeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mitarbeiter-Struktur"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Senior- und Junior-Ermittler"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTreeComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user_hierarchy; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_UserTreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-tree", [], null, null, null, View_UserTreeComponent_0, RenderType_UserTreeComponent)), i1.ɵdid(1, 245760, null, 0, i3.UserTreeComponent, [i4.Store, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserTreeComponentNgFactory = i1.ɵccf("user-tree", i3.UserTreeComponent, View_UserTreeComponent_Host_0, {}, {}, []);
export { UserTreeComponentNgFactory as UserTreeComponentNgFactory };

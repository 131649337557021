/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-list.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/user.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../services/rights-management.service";
var styles_UserListComponent = [i0.styles];
var RenderType_UserListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserListComponent, data: {} });
export { RenderType_UserListComponent as RenderType_UserListComponent };
function View_UserListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "my-2 mx-2 btn btn-sm btn-warning pull-right"], ["id", "btn-open-mail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Email an Mitarbeiter schreiben"]))], null, null); }
function View_UserListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keine Datens\u00E4tze gefunden"]))], null, null); }
function View_UserListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " Datens\u00E4tze gefunden"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultCount; _ck(_v, 1, 0, currVal_0); }); }
function View_UserListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Betreuer"]))], null, null); }
function View_UserListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.parent.parent.context.$implicit.senior); _ck(_v, 1, 0, currVal_0); }); }
function View_UserListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.senior; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UserListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTableClicked(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "mx-2 badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_7)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.rightsManagementService.isAllowedToAssignSenior(); _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.context.$implicit.id); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.first_name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.last_name; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.userrole.description; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.location; _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.email; _ck(_v, 13, 0, currVal_5); var currVal_6 = _v.context.$implicit.mobile; _ck(_v, 15, 0, currVal_6); }); }
function View_UserListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "table", [["class", "table table-hover table-striped table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["K\u00FCrzel"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vorname"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachname"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rolle"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Arbeitsorte"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mobil"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_5)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_6)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rightsManagementService.isAllowedToAssignSenior(); _ck(_v, 17, 0, currVal_0); var currVal_1 = _co.copiedUsers; _ck(_v, 19, 0, currVal_1); }, null); }
export function View_UserListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "card my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mitarbeiter "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserListComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAllowedToSendMassMail(); _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.copiedUsers; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.copiedUsers; _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.copiedUsers && !_co.recalculating); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_UserListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-list", [], null, null, null, View_UserListComponent_0, RenderType_UserListComponent)), i1.ɵdid(1, 245760, null, 0, i3.UserListComponent, [i4.Store, i5.UserService, i6.NgbModal, i7.RightsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserListComponentNgFactory = i1.ɵccf("user-list", i3.UserListComponent, View_UserListComponent_Host_0, {}, {}, []);
export { UserListComponentNgFactory as UserListComponentNgFactory };

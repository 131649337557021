var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CaseActions from './case.actions';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NoteService } from '../services/note.service';
import * as NoteActions from './note.actions';
import { TeamMemberService } from '../services/team-member.service';
import { FileUploadService } from '../services/file-upload.service';
import * as TeamMemberActions from './team-member.actions';
import * as FileUploadActions from './file-upload.actions';
import { CaseService } from '../services/case.service';
import * as StammbaumActions from './stammbaum.actions';
import { StammbaumService } from '../services/stammbaum.service';
export class CaseEffects {
    constructor(actions, noteService, teamMemberService, fileUploadService, caseService, stammbaumService, store) {
        this.actions = actions;
        this.noteService = noteService;
        this.teamMemberService = teamMemberService;
        this.fileUploadService = fileUploadService;
        this.caseService = caseService;
        this.stammbaumService = stammbaumService;
        this.store = store;
        this.loadStammbaumForCase = this.actions.pipe(ofType(CaseActions.SET_ACTIVE_CASE), // if active case is set, then trigger reload stammbaum for it
        map((action) => {
            this.store.dispatch(new StammbaumActions.ReplaceActiveStammbaumPersons([])); // clear stammbaum persons for case
            this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
            this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(action.payload.id));
        }));
        this.loadNotesForCase = this.actions.pipe(ofType(CaseActions.SET_ACTIVE_CASE), // if active case is set, then trigger reload of notes, files and team members for it
        map((action) => {
            this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentCase([])); // clear note list
            this.store.dispatch(new TeamMemberActions.ReplaceTeamMemberListForCurrentCase([])); // clear team member list
            this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentCase([])); // clear file upload list
            this.noteService.triggerNotesForCaseReloadFromServer();
            this.teamMemberService.triggerTeamMemberForCaseReloadFromServer();
            this.fileUploadService.triggerFileUploadForCaseReloadFromServer();
        }));
        this.loadNotesForPerson = this.actions.pipe(ofType(CaseActions.SET_ACTIVE_PERSON), // if active case is set, then trigger reload of notes for it
        map((payload) => {
            this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentPerson([])); // clear note list
            this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentPerson([])); // clear file upload list
            this.noteService.triggerNotesForPersonReloadFromServer();
            this.fileUploadService.triggerFileUploadForPersonReloadFromServer();
        }));
        this.expandCase = this.actions.pipe(ofType(CaseActions.EXPAND_CASE_IN_CASELIST), map((action) => {
            this.store.dispatch(new CaseActions.ReplaceExpandedHeirs([]));
            if (action.payload > 0) {
                this.caseService.getExpandedHeirs(action.payload).subscribe((result) => {
                    this.store.dispatch(new CaseActions.ReplaceExpandedHeirs(result));
                }, (error) => console.log(error), () => {
                });
            }
        }));
        this.reloadCaseData = this.actions.pipe(ofType(CaseActions.REPLACE_CASES_IN_CASELIST), // if case list is renewed, then reload the active case data
        map((payload) => {
            this.caseService.reloadCaseDetailsForActiveCaseFromServer();
        }));
        this.triggerReloadActiveCase = this.actions.pipe(ofType(CaseActions.TRIGGER_RELOAD_ACTIVE_CASE), map((payload) => {
            this.caseService.reloadCaseDetailsForActiveCaseFromServer();
        }));
    }
}
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "loadStammbaumForCase", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "loadNotesForCase", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "loadNotesForPerson", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "expandCase", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "reloadCaseData", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], CaseEffects.prototype, "triggerReloadActiveCase", void 0);

<div class="m-2 p-2">
  <h3>Berechtigungen zum Bearbeiten ändern</h3>
  <div *ngIf="verzeichnis&&verzeichnis.users_allowed_to_edit&&verzeichnis.users_allowed_to_edit.length>0">
    <table class="table table-hover table-striped table-sm">
      <tr *ngFor="let user of verzeichnis.users_allowed_to_edit">
        <td><span class="badge badge-pill badge-default mx-1" style="background-color: lightgreen">{{userService.getAbbreviationForUser(user)}}</span></td>
        <td><button class="btn btn-sm btn-danger" (click)="remove(user)">entfernen</button></td>
      </tr>
    </table>
  </div>
  <div>
    Mitarbeiter
    <select [(ngModel)]="user_id">
      <option *ngFor="let item of activeUsers" [value]="item.id">{{item.abbreviation}} ({{item.first_name}} {{item.last_name}})</option>
    </select>
    <button class="btn btn-sm btn-success" (click)="add(user_id)">hinzufügen</button>
  </div>
  <button type="button" (click)="back()" class="btn btn-success btn-sm m-2">abbrechen</button>
</div>




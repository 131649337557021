/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./choose-user";
import * as i4 from "@ngrx/store";
var styles_ChooseUserComponent = [];
var RenderType_ChooseUserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChooseUserComponent, data: {} });
export { RenderType_ChooseUserComponent as RenderType_ChooseUserComponent };
function View_ChooseUserComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "col-sm-2 control-label"], ["for", "forUserId"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ChooseUserComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i1.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.abbreviation; _ck(_v, 3, 0, currVal_2); }); }
export function View_ChooseUserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChooseUserComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "col-sm-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "select", [["class", "form-control"], ["id", "forUserId"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.changed($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.SelectControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.SelectControlValueAccessor]), i0.ɵdid(7, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChooseUserComponent_2)), i0.ɵdid(11, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.disabled; var currVal_9 = _co.userId; _ck(_v, 7, 0, currVal_8, currVal_9); var currVal_10 = _co.users; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 9).ngClassValid; var currVal_6 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ChooseUserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "choose-user", [], null, null, null, View_ChooseUserComponent_0, RenderType_ChooseUserComponent)), i0.ɵdid(1, 704512, null, 0, i3.ChooseUserComponent, [i4.Store], null, null)], null, null); }
var ChooseUserComponentNgFactory = i0.ɵccf("choose-user", i3.ChooseUserComponent, View_ChooseUserComponent_Host_0, { title: "title", disabled: "disabled", userId: "userId" }, { userIdSelected: "userIdSelected" }, []);
export { ChooseUserComponentNgFactory as ChooseUserComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-detail-max-heir.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../heir-embedded-max/heir-embedded-max.component.ngfactory";
import * as i3 from "../heir-embedded-max/heir-embedded-max.component";
import * as i4 from "../../services/stammbaum.service";
import * as i5 from "../../services/case.service";
import * as i6 from "../../services/dates.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@angular/common";
import * as i9 from "../heir-embedded-max-without-version/heir-embedded-max-without-version.component.ngfactory";
import * as i10 from "../heir-embedded-max-without-version/heir-embedded-max-without-version.component";
import * as i11 from "./case-detail-max-heir.component";
import * as i12 from "@ngrx/store";
import * as i13 from "../../services/rights-management.service";
import * as i14 from "../../services/user.service";
var styles_CaseDetailMaxHeirComponent = [i0.styles];
var RenderType_CaseDetailMaxHeirComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseDetailMaxHeirComponent, data: {} });
export { RenderType_CaseDetailMaxHeirComponent as RenderType_CaseDetailMaxHeirComponent };
function View_CaseDetailMaxHeirComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "heir-embedded-max", [], null, null, null, i2.View_HeirEmbeddedMaxComponent_0, i2.RenderType_HeirEmbeddedMaxComponent)), i1.ɵdid(2, 770048, null, 0, i3.HeirEmbeddedMaxComponent, [i4.StammbaumService, i5.CaseService, i6.DatesService, i7.NgbModal], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CaseDetailMaxHeirComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxHeirComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CaseDetailMaxHeirComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "heir-embedded-max-without-version", [], null, null, null, i9.View_HeirEmbeddedMaxWithoutVersionComponent_0, i9.RenderType_HeirEmbeddedMaxWithoutVersionComponent)), i1.ɵdid(2, 770048, null, 0, i10.HeirEmbeddedMaxWithoutVersionComponent, [i4.StammbaumService, i5.CaseService, i6.DatesService, i7.NgbModal], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CaseDetailMaxHeirComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxHeirComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailMaxHeirComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasTrueStammbaum(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hasTrueStammbaum(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CaseDetailMaxHeirComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-detail-max-heir", [], null, null, null, View_CaseDetailMaxHeirComponent_0, RenderType_CaseDetailMaxHeirComponent)), i1.ɵdid(1, 4964352, null, 0, i11.CaseDetailMaxHeirComponent, [i12.Store, i13.RightsManagementService, i4.StammbaumService, i14.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseDetailMaxHeirComponentNgFactory = i1.ɵccf("case-detail-max-heir", i11.CaseDetailMaxHeirComponent, View_CaseDetailMaxHeirComponent_Host_0, { stammbaumState: "stammbaumState", ui: "ui" }, {}, []);
export { CaseDetailMaxHeirComponentNgFactory as CaseDetailMaxHeirComponentNgFactory };

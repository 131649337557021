<h3>
  NVZ-Zufallsfunde (die 1000 neusten werden angezeigt)
  <button (click)="back()" class="mx-2 btn btn-sm btn-primary">zurück</button>
</h3>

<div *ngIf="search_done">
  <table  class="table table-hover table-striped table-sm" *ngIf="search_results&&search_results.length>0">
    <tr>
      <th>Standesamt</th>
      <th>Urkunden-Art</th>
      <th>Jahrgang</th>
      <th>Urkunden-Nr.</th>
      <th>Nachname</th>
      <th>Fuzzi</th>
      <th>Geburtsname</th>
      <th>Vorname</th>
    </tr>
    <tr *ngFor="let eintrag of search_results">
      <td>{{eintrag.verzeichnis_id.standesamt_id.name}}</td>
      <td>{{showVerzeichnisArt(eintrag.verzeichnis_id.art)}}</td>
      <td>{{eintrag.verzeichnis_id.jahrgang}}</td>
      <td>{{eintrag.urkunden_nr}}</td>
      <td>{{eintrag.nachname}}</td>
      <td>{{eintrag.fuzzi}}</td>
      <td>{{eintrag.geburtsname}}</td>
      <td>{{eintrag.vorname}}</td>
    </tr>
  </table>
  <div *ngIf="!search_results || search_results.length == 0">keine Suchergebnisse vorhanden</div>
</div>
<div *ngIf="!search_done">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>




import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule, HttpErrorResponse} from '@angular/common/http';
import {NgModule, ErrorHandler, Injectable, LOCALE_ID} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill'
import {DatePipe} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ImageViewerModule } from 'ngx-image-viewer';

import { AppEntryComponent } from './components/app-entry/app-entry.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { TopmenuComponent } from './components/topmenu/topmenu.component';
import { ChangelogComponent } from './components/changelog/changelog.component';

import { AlertModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap';
import { GlobalsearchComponent } from './components/globalsearch/globalsearch.component';
import { LoginComponent } from './components/login/login.component';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { UserService} from './services/user.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './services/alert.service';
import { NoteService } from './services/note.service';
import { AuthenticationService } from './services/authentication.service';
import { CommunicationDetailService } from './services/communication-detail.service';
import { UserroleService } from './services/userrole.service';
import { CaseService } from './services/case.service';
import { BundesanzeigerService } from './services/bundesanzeiger.service';
import { FileUploadService } from './services/file-upload.service';
import { CasestatusService } from './services/casestatus.service';
import { AddressService } from './services/address.service';
import { TeamMemberService } from './services/team-member.service';
import { ConstantsService } from './services/constants.service';
import { HelpComponent } from './components/help/help.component';
import { AppRoutingModule } from './app-routing.module';
import { FocusDirective } from './directives/focus.directive';
import { BundesanzeigerListComponent } from './components/bundesanzeiger/bundesanzeiger-list/bundesanzeiger-list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import {MapComponent} from './components/map/map.component';
import {addressbookEntryReducer} from './store/addressbook-entry.reducers';
import {caseReducer} from './store/case.reducers';
import {ToolsModule} from './tools/tools.module';
import {CasesModule} from './cases/cases.module';
import {FilesModule} from './files/files.module';
import {NotesModule} from './notes/notes.module';
import {appNotificationReducer} from './store/app-notification.reducers';
import {AppNotificationModule} from './app-notifications/app-notifications.module';
import {uiReducer} from './store/ui.reducers';
import {RightsManagementService} from './services/rights-management.service';
import {authReducer} from './store/auth.reducers';
import {RoutingService} from './routing/routing.service';
import {DebugModule} from './debug/debug.module';
import {HistoryModule} from './history/history.module';
import {UsersModule} from './users/users.module';
import {networkReducer} from './store/network.reducers';
import {userRoleReducer} from './store/user-role.reducers';
import {NetworkService} from './services/network.service';
import {CalendarModule} from './calendar/calendar.module';
import {calendarEntryReducer} from './store/calendar-entry.reducers';
import {CalendarEntryService} from './services/calendar-entry.service';
import {userReducer} from './store/user.reducers';
import {AddressbookModule} from './addressbook/addressbook.module';
import {caseStatusReducer} from './store/case-status.reducers';
import {noteReducer} from './store/note.reducers';
import {AddressbookService} from './services/addressbook.service';
import {CaseEffects} from './store/case.effects';
import {teamMemberReducer} from './store/team-member.reducers';
import {fileUploadReducer} from './store/file-upload.reducers';
import {AdministrationModule} from './administration/administration.module';
import {bundesanzeigerReducer} from './store/bundesanzeiger.reducers';
import {AddressbookDetailPopupComponent} from './addressbook/addressbook-detail-popup/addressbook-detail-popup.component';
import {NamensverzeichnisseModule} from './namensverzeichnisse/namensverzeichnisse.module';
import {nvzReducer} from './store/nvz.reducers';
import {NVZService} from './services/nvz.service';
import {searchResultReducer} from './store/search-result.reducers';
import {GeneratedDocumentEffects} from './store/generated-document.effects';
import {generatedDocumentReducer} from './store/generated-document.reducers';
import {GeneratedDocumentService} from './services/generated-document.service';
import {GeneratedDocumentEmailParserService} from './services/generated-document-email-parser.service';
import {NvzAutoparserModule} from './nvz-autoparser/nvz-autoparser.module';
import {autoparserReducer} from './store/autoparser.reducers';
import {AutoparserWebsocketService} from './services/autoparser-websocket.service';
import {AutoparserService} from './services/autoparser.service';
import {StammbaumModule} from './stammbaum/stammbaum.module';
import {stammbaumReducer} from './store/stammbaum.reducers';
import {StammbaumService} from './services/stammbaum.service';
import {StammbaumEffects} from './store/stammbaum.effects';
import {WebsocketService} from './services/websocket.service';
import {NetworkManagerModule} from './network-manager/network-manager.module';
import {DebugLogService} from './services/debug-log.service';
import {AppComponent} from './app.component';
import {AuthGuardService} from './services/auth-guard.service';
import {StammbaumDisplayService} from './services/stammbaum-display.service';
import {ShowDatePipe} from './tools/show-date-pipe/show-date-pipe';
import {HotkeyModule} from 'angular2-hotkeys';
import * as Sentry from '@sentry/browser';
import {CustomUppercasePipe} from './tools/custom-uppercase-pipe/custom-uppercase-pipe';
import {CustomFirstLetterUppercasePipe} from './tools/custom-first-letter-uppercase-pipe/custom-first-letter-uppercase-pipe';
import {GlobalsearchService} from './services/globalsearch.service';
import {DocumentsModule} from './documents/douments.module';
import {DocumentsService} from './documents/documents.service';
import {DatesService} from './services/dates.service';
import {noteDraftReducer} from './store/note-draft.reducers';
import { MainComponent } from './statistics/main/main.component';
import {KassenbuchModule} from './kassenbuch/kassenbuch.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

declare var require: any;
const { version } = require('../../package.json');

Sentry.init({
  dsn: 'https://92cc43875ac6771037356a1cbeaa4e51@fehlerteufel.fuzztech.de/5',
  release: version,
  /*integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://db.ilanot.de', 'https://ilanot-2.fuzztest.de'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],*/

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    // Capture handled exception and send it to Sentry.
    const eventId = Sentry.captureException(extractedError);

    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(extractedError);
    }

    // Optionally show user dialog to provide details on what happened.
    // Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AppEntryComponent,
    SidemenuComponent,
    TopmenuComponent,
    GlobalsearchComponent,
    LoginComponent,
    DashboardComponent,
    AlertComponent,
    ChangelogComponent,
    HelpComponent,
    FocusDirective,
    BundesanzeigerListComponent,
    MapComponent,
    MainComponent
  ],
  entryComponents: [
    AddressbookDetailPopupComponent
  ],
    imports: [
        HotkeyModule.forRoot(),
        StoreModule.forRoot({
            addressbookEntry: addressbookEntryReducer,
            cases: caseReducer,
            appNotifications: appNotificationReducer,
            ui: uiReducer,
            authUser: authReducer,
            network: networkReducer,
            userRoles: userRoleReducer,
            users: userReducer,
            calendarEntry: calendarEntryReducer,
            caseStatus: caseStatusReducer,
            notes: noteReducer,
            teamMembers: teamMemberReducer,
            fileUploads: fileUploadReducer,
            bundesanzeiger: bundesanzeigerReducer,
            nvz: nvzReducer,
            searchResults: searchResultReducer,
            generatedDocument: generatedDocumentReducer,
            autoparser: autoparserReducer,
            stammbaum: stammbaumReducer,
            noteDraft: noteDraftReducer
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([CaseEffects, GeneratedDocumentEffects, StammbaumEffects]),
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AlertModule.forRoot(),
        AccordionModule.forRoot(),
        DatepickerModule.forRoot(),
        ImageViewerModule.forRoot(),
        LeafletModule,
        AppRoutingModule,
        NgbModule,
        QuillModule.forRoot(),
        MatDialogModule,
        CasesModule,
        DocumentsModule,
        FilesModule,
        ToolsModule,
        NotesModule,
        AppNotificationModule,
        DebugModule,
        HistoryModule,
        UsersModule,
        CalendarModule,
        AddressbookModule,
        AdministrationModule,
        NamensverzeichnisseModule,
        NvzAutoparserModule,
        StammbaumModule,
        NetworkManagerModule,
        AppRoutingModule,
        KassenbuchModule,
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker, registrationStrategy: 'registerImmediately' })
    ],
  providers: [
    {provide: ErrorHandler, useClass: SentryErrorHandler },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    AuthGuardService,
    AutoparserService,
    DocumentsService,
    AutoparserWebsocketService,
    GeneratedDocumentEmailParserService,
    ShowDatePipe,
    CustomUppercasePipe,
    CustomFirstLetterUppercasePipe,
    RightsManagementService,
    GeneratedDocumentService,
    AddressbookService,
    CalendarEntryService,
    NVZService,
    RoutingService,
    NetworkService,
    TeamMemberService,
    GlobalsearchService,
    DatePipe,
    BundesanzeigerService,
    NoteService,
    FileUploadService,
    CaseService,
    UserService,
    AlertService,
    CasestatusService,
    AddressService,
    CommunicationDetailService,
    AuthenticationService,
    UserroleService,
    ConstantsService,
    StammbaumService,
    StammbaumDisplayService,
    WebsocketService,
    DebugLogService,
    DatesService,
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}

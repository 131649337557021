/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editfield-contact.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../editfield-select/editfield-select.component.ngfactory";
import * as i4 from "../editfield-select/editfield-select.component";
import * as i5 from "../editfield-text/editfield-text.component.ngfactory";
import * as i6 from "../editfield-text/editfield-text.component";
import * as i7 from "./editfield-contact.component";
import * as i8 from "../../services/communication-detail.service";
var styles_EditfieldContactComponent = [i0.styles];
var RenderType_EditfieldContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditfieldContactComponent, data: {} });
export { RenderType_EditfieldContactComponent as RenderType_EditfieldContactComponent };
function View_EditfieldContactComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["entfernen"]))], null, null); }
function View_EditfieldContactComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [["style", "background-color: transparent !important; border: 0 !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-pill badge-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldContactComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.editMode; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printableContactTypes[_v.context.$implicit.type]; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 5, 0, currVal_1); }); }
function View_EditfieldContactComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "table", [["class", "table"], ["style", "border: 0 !important;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldContactComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contacts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EditfieldContactComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["hinzuf\u00FCgen"]))], null, null); }
export function View_EditfieldContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldContactComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "editfield-select", [], null, [[null, "selectChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectChange" === en)) {
        var pd_0 = ((_co.type = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditfieldSelectComponent_0, i3.RenderType_EditfieldSelectComponent)), i1.ɵdid(5, 638976, null, 0, i4.EditfieldSelectComponent, [], { select: [0, "select"], editMode: [1, "editMode"], options: [2, "options"] }, { selectChange: "selectChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.details = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EditfieldTextComponent_0, i5.RenderType_EditfieldTextComponent)), i1.ɵdid(8, 638976, null, 0, i6.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], maxLength: [2, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldContactComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contacts; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.type; var currVal_2 = _co.editMode; var currVal_3 = _co.contactTypes; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.details; var currVal_5 = _co.editMode; var currVal_6 = 50; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.editMode; _ck(_v, 11, 0, currVal_7); }, null); }
export function View_EditfieldContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "editfield-contact", [], null, null, null, View_EditfieldContactComponent_0, RenderType_EditfieldContactComponent)), i1.ɵdid(1, 638976, null, 0, i7.EditfieldContactComponent, [i8.CommunicationDetailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditfieldContactComponentNgFactory = i1.ɵccf("editfield-contact", i7.EditfieldContactComponent, View_EditfieldContactComponent_Host_0, { editMode: "editMode", personId: "personId" }, { contactsChange: "contactsChange" }, []);
export { EditfieldContactComponentNgFactory as EditfieldContactComponentNgFactory };

<div class="modal-header">
  <h4 class="modal-title ilatitle">
    Status ändern
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      Wollen Sie den Status von Beleg {{kostenposition.belegtitel}} wirklich ändern?
    </div>
  </div>
  <div class="row" *ngIf="!confirmToUndoPayment">
    <div class="col-12">
      <button *ngIf="!kostenposition.done" type="button" class="m-2 btn btn-sm btn-success" (click)="setCompleted()">Status auf erledigt setzen</button>
      <button *ngIf="kostenposition.done" type="button" class="m-2 btn btn-sm btn-success" (click)="setUnCompleted()">Status auf offen zurücksetzen</button>
      <button type="button" class="m-2 btn btn-sm btn-danger" (click)="confirmUndoPayment()">Zahlung rückgängig machen</button>
    </div>
  </div>
  <div class="row" *ngIf="confirmToUndoPayment">
    <div class="col-12">
      <p>Bitte bestätigen Sie, dass die Zahlungsinformationen tatsächlich entfernt werden sollen</p>
      <button type="button" class="m-2 btn btn-sm btn-warning" (click)="cancelUndoPayment()">zurück</button>
      <button type="button" class="m-2 btn btn-sm btn-success" (click)="undoPayment()">Zahlung rückgängig machen</button>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col-12">
    <button type="button" class="m-2 btn btn-sm btn-warning pull-right" (click)="cancel()">abbrechen</button>
  </div>
</div>



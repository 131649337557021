<h4>Adressbuch-Suche <button class="btn btn-sm btn-success pull-right" (click)="createNewEntry()">neue Institution anlegen</button></h4>
<div>
  <input class="form-control" autofocus type="text" placeholder="Institutionen und Personen, mindestens 3 Zeichen eintippen..." (keyup)="search($event)">
</div>
<div class="card-deck mt-3">
  <div class="card col-md-4 results">
    <h4>Suchergebnisse</h4>
    <table class="table table-bordered table-sm">
      <tr *ngFor="let institution of searchResultInstitutions" (click)="showInstitution(institution.id)">
        <td>{{institution.name}}</td>
        <td>{{institution.shortname}}</td>
      </tr>
    </table>
    <table class="table table-bordered table-sm">
      <tr *ngFor="let person of searchResultPersons" (click)="showInstitution(person.institution)">
        <td>{{person.last_name}}</td>
        <td>{{person.first_name}}</td>
      </tr>
    </table>
  </div>
  <div class="card col-md-8" style="border: 0">
    <div *ngIf="activeAddressbookEntry" class="card-body">
      <div class="card px-2 mt-3">
        <div class="card-body">
          <h4>{{activeAddressbookEntry.name}}
            <button class="btn btn-sm btn-danger pull-right mx-2" (click)="editActiveEntry()">bearbeiten</button>
          </h4>
          <p>
            <b>{{printInstitutionType(activeAddressbookEntry.type)}}</b><br />
            {{activeAddressbookEntry.comment}}
          </p>
          <p *ngFor="let address of activeAddressbookEntry.addresses">
            {{address.street}} {{address.housenumber}}<br />
            {{address.zipcode}} {{address.town}}<br />
            {{printAddressRegion(address.region)}}
          </p>
          <table *ngIf="activeAddressbookEntry.contacts">
            <tr *ngFor="let contact of activeAddressbookEntry.contacts">
              <td>{{printContactType(contact.type)}}</td><td>{{contact.details}}</td><td>{{contact.comment}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card px-2 my-3" *ngIf="activeAddressbookEntry.persons?.length > 0">
        <div class="card-body" *ngFor="let person of activeAddressbookEntry.persons">
          <h5 class="my-2">{{person.first_name}} {{person.last_name}}</h5>
          <b>{{person.function}}</b><br />{{person.comment}}
          <table *ngIf="person.contacts">
            <tr *ngFor="let perscont of person.contacts">
              <td>{{printContactType(perscont.type)}}</td><td>{{perscont.details}}</td><td>{{perscont.comment}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notify-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./notify-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_NotifyModalComponent = [i0.styles];
var RenderType_NotifyModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotifyModalComponent, data: {} });
export { RenderType_NotifyModalComponent as RenderType_NotifyModalComponent };
export function View_NotifyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", "\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "m-2 btn btn-success pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["ok"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); }); }
export function View_NotifyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notify-modal", [], null, null, null, View_NotifyModalComponent_0, RenderType_NotifyModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotifyModalComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotifyModalComponentNgFactory = i1.ɵccf("notify-modal", i2.NotifyModalComponent, View_NotifyModalComponent_Host_0, {}, {}, []);
export { NotifyModalComponentNgFactory as NotifyModalComponentNgFactory };

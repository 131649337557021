<h3 *ngIf="mode!='change'" class="title">Neue Aufgabe anlegen</h3>
<h3 *ngIf="mode=='change'" class="title">Aufgabe bearbeiten</h3>

<form [formGroup]="calendarEntryForm" class="form-horizontal" (ngSubmit)="onSubmit()">
  <div class="form-group row">
    <label for="title" class="col-sm-2 control-label">Titel</label>
    <div class="col-sm-10">
      <input formControlName="title" type="text" id="title" class="form-control">
      <span *ngIf="calendarEntryForm.get('title').invalid && calendarEntryForm.get('title').touched" class="help-block">Bitte einen Titel mit maximal 60 Zeichen angeben!</span>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 control-label">Datum</label>
    <div class="col-sm-10">
      <ngb-datepicker #dp formControlName="deadlineTimestamp" id="deadlineTimestamp"></ngb-datepicker>
      <span class="m-3">{{deadline | date: 'dd.MM.y'}}</span>
      <span *ngIf="calendarEntryForm.get('deadlineTimestamp').invalid && calendarEntryForm.get('deadlineTimestamp').touched" class="help-block">Bitte ein gültiges Datum für den Termin angeben!</span>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 control-label">Uhrzeit</label>
    <div class="col-sm-10">
      <ngb-timepicker #dp formControlName="deadlineTimestampTime" id="deadlineTimestampTime"></ngb-timepicker>
      <span *ngIf="calendarEntryForm.get('deadlineTimestampTime').invalid && calendarEntryForm.get('deadlineTimestampTime').touched" class="help-block">Bitte eine gültige Uhrzeit für den Termin angeben!</span>
    </div>
  </div>

  <div class="form-group row">
    <label for="message" class="col-sm-2 control-label">Details</label>
    <div class="col-sm-10">
      <textarea formControlName="message" rows="5" id="message" class="form-control"></textarea>
    </div>
  </div>

  <div *ngIf="mode!='own'" class="form-group row">
    <label for="forUserId" class="col-sm-2 control-label">Termin für Benutzer</label>
    <div class="col-sm-10">
      <select id="forUserId" formControlName="forUserId" class="form-control">
        <option *ngFor="let user of users"
          [value]="user.id">
          {{user.abbreviation}} - {{user.first_name}} {{user.last_name}}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-2"></div>
    <div class="col-sm-10">
      <button class="btn btn-danger" type="reset" (click)="onRevert()">abbrechen</button>
      <button [disabled]="!calendarEntryForm.valid" class="btn btn-success" type="submit">speichern</button>
    </div>
  </div>
</form>

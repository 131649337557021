/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-laender.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nvz-laender.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/nvz.service";
import * as i7 from "../../services/user.service";
import * as i8 from "../../services/rights-management.service";
var styles_NvzLaenderComponent = [i0.styles];
var RenderType_NvzLaenderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzLaenderComponent, data: {} });
export { RenderType_NvzLaenderComponent as RenderType_NvzLaenderComponent };
function View_NvzLaenderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "mx-2 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addLand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["neues Land anlegen"]))], null, null); }
function View_NvzLaenderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keine L\u00E4nder vorhanden"]))], null, null); }
function View_NvzLaenderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-edit mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editLand(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_NvzLaenderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-trash mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteLand(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_NvzLaenderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLand(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLand(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.rightsManagementService.isAllowedToEditAllNVZEntries(); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.rightsManagementService.isAdmin(); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.comment; _ck(_v, 4, 0, currVal_1); }); }
function View_NvzLaenderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "table", [["class", "table table-hover table-striped table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kommentar"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_5)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nvzState.laender; _ck(_v, 9, 0, currVal_0); }, null); }
function View_NvzLaenderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.nvzState || !_co.nvzState.laender) || (_co.nvzState.staedte.length == 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.nvzState && _co.nvzState.laender) && (_co.nvzState.laender.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_NvzLaenderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-lg"]], null, null, null, null, null))], null, null); }
export function View_NvzLaenderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nder "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["style", "font-size: 45%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "kbd", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Einfg"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzLaenderComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rightsManagementService.isAllowedToEditAllNVZEntries(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.nvzService.laenderFirstLoad; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.nvzService.laenderFirstLoad; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_NvzLaenderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-laender", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NvzLaenderComponent_0, RenderType_NvzLaenderComponent)), i1.ɵdid(1, 114688, null, 0, i3.NvzLaenderComponent, [i4.Store, i5.NgbModal, i6.NVZService, i7.UserService, i8.RightsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NvzLaenderComponentNgFactory = i1.ɵccf("nvz-laender", i3.NvzLaenderComponent, View_NvzLaenderComponent_Host_0, { nvzState: "nvzState", user: "user" }, {}, []);
export { NvzLaenderComponentNgFactory as NvzLaenderComponentNgFactory };

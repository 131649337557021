import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {Subscription} from "rxjs";
import {CasestatusService} from "../../services/casestatus.service";
import {CaseSaveMode, CaseService} from "../../services/case.service";
import {ITeamMember} from "../team-member.interface";
import {ICase, ICaselistScreen} from "../case.interface";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-caselist-screen',
  templateUrl: './caselist-screen.component.html',
  styleUrls: ['./caselist-screen.component.css']
})
export class CaselistScreenComponent implements OnInit, OnDestroy {
  caseSub: Subscription;
  statusId: number;
  caselist: ICaselistScreen[] = []
  clSub: Subscription;
  loading = false;
  idToEdit = 0;
  bemerkung_to_edit = '';
  auszahlung_to_edit = '';
  sum_value = 0;
  sum_total_value = 0;
  sum_auskehrungen = 0;

  constructor(private store: Store<{cases: ICaseState}>,
              private casestatusService: CasestatusService,
              public userService: UserService,
              private caseService: CaseService) {
    this.caseSub = this.store.select('cases').subscribe(
      (casesState) => {
        this.statusId = casesState.statusForCaselistScreen;
      }
    );
  }

  ngOnInit() {
    this.loading = true;
    this.clSub = this.caseService.loadCaselistScreen(this.statusId).subscribe(
      (result) => {
        this.sum_value = 0;
        this.sum_total_value = 0;
        this.sum_auskehrungen = 0;
        this.caselist = result;
        this.loading = false;
        for (const elem of result) {
          this.sum_value += +elem.value;
          this.sum_total_value += +elem.total_value;
          this.sum_auskehrungen += +elem.auszahlungssumme_fuer_fallliste;
        }
      },
      (error) => {
        console.log(error);
        this.loading = false;
      },
      () => {}
    );
  }

  recalculateSums() {
    this.sum_value = 0;
        this.sum_total_value = 0;
        this.sum_auskehrungen = 0;
    for (const elem of this.caselist) {
          this.sum_value += +elem.value;
          this.sum_total_value += +elem.total_value;
          this.sum_auskehrungen += +elem.auszahlungssumme_fuer_fallliste;
        }
  }

  ngOnDestroy() {
    this.caseSub.unsubscribe();
    this.clSub.unsubscribe();
  }

  back() {
    this.caseService.goToCaseSearch();
  }

  getStatusText() {
    return this.casestatusService.getCaseStatusText(this.statusId);
  }

  edit(caseToEdit: ICaselistScreen) {
    this.idToEdit = caseToEdit.id;
    this.bemerkung_to_edit = caseToEdit.bemerkung_fuer_fallliste;
    this.auszahlung_to_edit = caseToEdit.auszahlungssumme_fuer_fallliste;
  }

  save() {
    const caseToSave = this.caselist.find(x => x.id === this.idToEdit);
    if (!caseToSave) { return; }
    caseToSave.bemerkung_fuer_fallliste = this.bemerkung_to_edit;
    caseToSave.auszahlungssumme_fuer_fallliste = this.auszahlung_to_edit;
    this.caseService.saveCaseData(caseToSave as unknown as ICase, CaseSaveMode.Fallliste_Daten);
    this.idToEdit = 0;
    this.recalculateSums();
  }
}

<div class="row" style="padding: 5px;" id="notes-section">
  <div class="col-12">

    <p class="test" #test>
      <input [(ngModel)]="search_notes" placeholder="Notizen filtern..." type="text" class="form-control">
      <button *ngIf="copiedNotes" class="mx-1 btn btn-sm btn-info" (click)="openAll()">alle öffnen</button>
      <button *ngIf="copiedNotes" class="mx-1 btn btn-sm btn-info" (click)="closeAll()">alle schließen</button>
      <span *ngIf="copiedNotes&&mode=='case'&&!show_also_persons" class="mx-1 badge badge-pill badge-warning">Notizen nur für Fall</span>
      <span *ngIf="copiedNotes&&mode=='case'&&show_also_persons&&!show_also_auskehr" class="mx-1 badge badge-pill badge-success">Notizen auch für Personen</span>
      <span *ngIf="copiedNotes&&mode=='case'&&rightsManagementService.isAllowedToViewAuskehrTabInCase()&&show_also_auskehr" class="mx-1 badge badge-pill badge-danger">Notizen auch für Auskehr</span>
      <button *ngIf="copiedNotes&&mode=='case'" class="mx-1 btn btn-sm btn-info" (click)="toggleShowPersons()">
        umschalten
      </button>
    </p>
    <div class="scroll-in" #scrollIn>
      <ngb-accordion *ngIf="copiedNotes" [activeIds]="openAccordionPanels"
                     (panelChange)="toggleElement($event)"
                     #acc="ngbAccordion">
        <ngb-panel [id]="'new'" *ngIf="canAddNote">
          <ng-template ngbPanelTitle>
            <span class="mx-2 mt-2 fa fa-plus-circle"></span>Neue Notiz anlegen <span *ngIf="noteSubject"> für {{noteSubject}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <h4>Neue Notiz anlegen</h4>
            <table class="table">
              <tr>
                <td>Titel</td>
                <td><input class="form-control" maxlength="60" [(ngModel)]="newNote.title"
                           placeholder="Titel (@Kürzel für Mailbenachrichtigung)" type="text"></td>
              </tr>
              <tr>
                <td><span class="badge badge-pill badge-danger" style="background-color: red;"> ! </span></td>
                <td><input [(ngModel)]="newNote.important" type="checkbox"></td>
              </tr>
              <tr>
                <td>Nachricht</td>
                <td>
                  <quill-editor [modules]="quillToolbar" [(ngModel)]="newNote.text" placeholder="Notiz..."></quill-editor>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p *ngIf="createNeedsTitel()" class="error">Bitte einen Notitz-Titel angeben!</p>
                  <button [disabled]="createNeedsTitel()" class="btn btn-sm btn-info mx-1" (click)="createNote()">anlegen</button>
                  <button [disabled]="createNeedsTitel()" class="btn btn-sm btn-success mx-1" (click)="createNote(true)">anlegen + <span class="fa fa-clock"></span> in 14 Tagen</button>
                  <button [disabled]="createNeedsTitel()" class="btn btn-sm btn-primary mx-1" (click)=openDatePicker()>anlegen mit Datum</button>
                </td>
              </tr>
            </table>
          </ng-template>
        </ngb-panel>
        <ng-container *ngFor="let note of copiedNotes">
          <ngb-panel [id]="'ac'+note.id" *ngIf="noteInSearchResults(note)">
            <ng-template ngbPanelTitle>
              <div class="leftie">
                <span class="badge badge-pill badge-danger" *ngIf="note.important"> ! </span>
                <span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.user_id)}}</span>
                <span *ngIf="note.result_user_id"
                      class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.result_user_id)}}</span>
                {{note.date | date: 'dd.MM.yyyy HH:mm'}} {{note.title}} <span
                *ngIf="note.result_date">(bearbeitet {{note.result_date | date: 'dd.MM.yyyy HH:mm'}})</span>
                <span *ngIf="note.calendar_entries_for_note"><span *ngFor="let entry of note.calendar_entries_for_note" class="badge badge-pill badge-success mx-1"><span class="fa fa-calendar spacing"> </span>{{userService.getAbbreviationForUser(entry.for_user_id)}}</span></span>
                <span class="ml-3 noteid">#{{note.id}}</span>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div>
                <button class="mx-1 btn btn-sm btn-success" title="Sortierung umkehren" (click)="sortInverse(note.id)"><span class="fa fa-sort"></span></button>
                <button *ngIf="!note.important" class="btn btn-sm btn-warning mx-1" (click)="toggleImportantNote(note, true)">
                  als
                  wichtig kennzeichnen
                </button>
                <button *ngIf="note.important" class="btn btn-sm btn-info mx-1" (click)="toggleImportantNote(note, false)">
                  nicht
                  mehr als wichtig kennzeichnen
                </button>
                <button *ngIf="calendarEntryForUser(note)" (click)="editCalendarEntryOnNote(calendarEntryForUser(note))" class="btn btn-sm btn-success mx-1">Kalendereintrag bearbeiten</button>
                <button *ngIf="!calendarEntryForUser(note)" (click)="createCalendarEntry(note)" class="btn btn-sm btn-primary mx-1">Kalendereintrag hinzufügen</button>
                <span *ngFor="let entry of note.calendar_entries_for_note" class="badge badge-pill badge-info mx-1">{{userService.getAbbreviationForUser(entry.for_user_id)}}<span class="fa fa-clock-o spacing ml-1"></span>{{getCalenderDisplay(calendarEntryForUser(note, entry.for_user_id)) | date:'dd.MM.yyyy HH:mm'}}</span>
              </div>
              <ng-container *ngIf="showAnswerFieldOnTop()">
                <p>
                  <quill-editor [(ngModel)]="this.result_placeholder[note.id]" [modules]="quillToolbar"
                                placeholder="antworten..."></quill-editor>
                  <button class="btn btn-sm btn-info" (click)="saveNote(note)">antworten</button>
                </p>
              </ng-container>
              <div class="note-text">
                <span>
                  <ng-container *ngIf="note.user_id"><span class="mx-2 badge badge-pill badge-default">{{userService.getAbbreviationForUser(note.user_id)}}</span></ng-container>
                  <ng-container *ngIf="note.created"><span class="answer-date">{{note.created | date: 'dd.MM.yyyy HH:mm'}}</span></ng-container>
                </span>
                <quill-editor [(ngModel)]="note.text" readOnly="true" placeholder=""
                              [modules]="{ toolbar: false}"></quill-editor>
              </div>
              <div *ngFor="let resp of note.responses_for_note" class="note-answer">
              <span>
                <ng-container *ngIf="resp.user"><span class="mx-2 badge badge-pill badge-default">{{userService.getAbbreviationForUser(resp.user)}}</span></ng-container>
                <ng-container *ngIf="resp.result_date"><span class="answer-date">{{resp.result_date | date: 'dd.MM.yyyy HH:mm'}}</span></ng-container>
              </span>
                <quill-editor [(ngModel)]="resp.result" readOnly="true" placeholder=""
                              [modules]="{ toolbar: false}"></quill-editor>
              </div>
              <ng-container *ngIf="!showAnswerFieldOnTop()">
                <p>
                  <quill-editor [(ngModel)]="this.result_placeholder[note.id]" [modules]="quillToolbar"
                                placeholder="antworten..."></quill-editor>
                  <button class="btn btn-sm btn-info" (click)="saveNote(note)">antworten</button>
                </p>
              </ng-container>
              <p *ngIf="rightsManagementService.isAllowedToDeleteNotes()">
                <button class="btn btn-sm btn-danger" (click)="delete(note.id)">gesamte Notiz löschen</button>
              </p>
            </ng-template>
          </ngb-panel>
        </ng-container>

      </ngb-accordion>
    </div>
  </div>
</div>

<div class="m-2 p-2">
  <h4 class="ilatitle">{{printMarriage()}} austauschen gegen bestehenden Datensatz</h4>
  <p *ngIf="!marriages || marriages.length === 0">keine Eheschliessung gefunden</p>
  <table class="table-responsive table-sm" *ngIf="marriages && marriages.length > 0">
    <tr>
      <th>Partner 1</th><th>Partner 2</th><th>Datum Eheschliessung</th><th></th>
    </tr>
    <tr *ngFor="let m of marriages">
      <td>{{getPersonName(m.persons[0])}}</td><td>{{getPersonName(m.persons[1])}}</td><td>{{printDate(m.date)}}</td><td>
      <td><button class="btn btn-sm btn-warning" (click)="exchange(m)">austauschen</button></td>
    </tr>
  </table>
  <button class="btn btn-sm btn-success" (click)="close()">schliessen</button>
</div>

<case-detail-max-menu [editMode]="editMode" [mode]="mode" (modeChanged)="changeMode($event)"></case-detail-max-menu>

<div class="main-cont">
  <case-detail-max-basics *ngIf="mode === 1" (openedChanged)="changeOpened($event)" [opened]="displayOpened" [caseStatusOptions]="caseStatus" [editMode]="editMode" (editModeChanged)="changeEditMode($event)" [activeCase]="activeCase" [copiedActiveCase]="copiedActiveCase"></case-detail-max-basics>
  <case-detail-max-notes *ngIf="mode === 2" (openedChanged)="changeOpened($event)" [opened]="displayOpened" [editMode]="editMode" (editModeChanged)="changeEditMode($event)" [scrollPosition]="noteScrollPosition" (saveScrollPosition)="noteScrollPositionChanged($event)"></case-detail-max-notes>
  <case-detail-stammbaum *ngIf="mode === 3" [stammbaumState]="stammbaumState"></case-detail-stammbaum>
  <case-detail-persons *ngIf="mode === 4" [stammbaumState]="stammbaumState"></case-detail-persons>
  <case-detail-max-heir *ngIf="mode === 5" [stammbaumState]="stammbaumState"></case-detail-max-heir>
  <case-detail-max-nt-without-version *ngIf="mode === 6" [stammbaumState]="stammbaumState"></case-detail-max-nt-without-version>
  <case-detail-max-files *ngIf="mode === 7"></case-detail-max-files>
  <team-cards *ngIf="mode === 8"></team-cards>
  <case-detail-ebs-doku [stammbaumState]="stammbaumState" [editMode]="editMode" (editModeChanged)="changeEditMode($event)" [activeCase]="activeCase" [copiedActiveCase]="copiedActiveCase" *ngIf="mode === 9"></case-detail-ebs-doku>
  <app-kassenbuch-list-for-case *ngIf="mode === 10" [activeCase]="activeCase" [copiedActiveCase]="copiedActiveCase"></app-kassenbuch-list-for-case>
  <app-case-auskehr *ngIf="mode === 11" [activeCase]="activeCase" [copiedActiveCase]="copiedActiveCase" [stammbaumState]="stammbaumState"></app-case-auskehr>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-change-senior.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./user-change-senior.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/user.service";
var styles_UserChangeSeniorComponent = [i0.styles];
var RenderType_UserChangeSeniorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserChangeSeniorComponent, data: {} });
export { RenderType_UserChangeSeniorComponent as RenderType_UserChangeSeniorComponent };
function View_UserChangeSeniorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["f\u00FCr ", " ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.first_name; var currVal_1 = _co.user.last_name; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.user.abbreviation; _ck(_v, 3, 0, currVal_2); }); }
function View_UserChangeSeniorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", " ", " (", ")"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.first_name; var currVal_3 = _v.context.$implicit.last_name; var currVal_4 = _v.context.$implicit.abbreviation; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_UserChangeSeniorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Senior zuweisen"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserChangeSeniorComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 16, "form", [["class", "mt-4"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 11, "select", [["class", "form-control"], ["formControlName", "senioruser"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(13, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(15, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "option", [["value", "0"]], null, null, null, null, null)), i1.ɵdid(17, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(18, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["--- keinen Senior zuweisen ---"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserChangeSeniorComponent_2)), i1.ɵdid(21, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "mx-2 btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "mx-2 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 4, 0, currVal_0); var currVal_8 = _co.form; _ck(_v, 7, 0, currVal_8); var currVal_16 = "senioruser"; _ck(_v, 13, 0, currVal_16); var currVal_17 = "0"; _ck(_v, 17, 0, currVal_17); var currVal_18 = "0"; _ck(_v, 18, 0, currVal_18); var currVal_19 = _co.user_selection; _ck(_v, 21, 0, currVal_19); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 15).ngClassValid; var currVal_14 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_UserChangeSeniorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-change-senior", [], null, null, null, View_UserChangeSeniorComponent_0, RenderType_UserChangeSeniorComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserChangeSeniorComponent, [i5.NgbActiveModal, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserChangeSeniorComponentNgFactory = i1.ɵccf("user-change-senior", i4.UserChangeSeniorComponent, View_UserChangeSeniorComponent_Host_0, { user: "user" }, {}, []);
export { UserChangeSeniorComponentNgFactory as UserChangeSeniorComponentNgFactory };

<div class="card my-2">

  <h3>Changelog</h3>

  <div class="my-2">
    <strong>Programmversion</strong><br/>
    Frontend: "{{frontendApiInformation}}"<br>
    Backend: {{backendApiInformation}}
  </div>

  <div>
    <strong>Frontend 3.5.13</strong><br />
    <ul>
      <li>Auskehr: Möglichkeit detaillierte Übersicht auch wieder zu schließen</li>
      <li>Notizen: In Verlauf und Ansicht jetzt auch Bearbeiter und Zeitpunkt der ursprünglichen Notiz anzeigen</li>
      <li>Fälle: Möglichkeit zur Anzeige und Ausgabe statusbezogener Fall-Listen</li>
    </ul>
    <strong>Frontend 3.5.12</strong><br />
    <ul>
      <li>Bundesanzeiger: Bugfix Tab-Absturz am Handy unter bestimmten Bedingungen</li>
      <li>V+V-Erzeugung: Bugfix Textvorlage</li>
    </ul>
    <strong>Frontend 3.5.11</strong><br />
    <ul>
      <li>Auskehr: Status Steuererklärung und Unbedenklichkeitsbescheinigung für Erben</li>
    </ul>
    <strong>Frontend 3.5.10</strong><br />
    <ul>
      <li>Auskehr: Anzeige der Gesamtsummen</li>
      <li>EBS: Änderung Feld Verbleib Nachlass</li>
      <li>EBS: Überarbeitung Tabellengliederung</li>
      <li>Fall: Änderungen Felder zu Testament</li>
      <li>Erben-Daten: Änderung Text Bankverbindung</li>
      <li>Urkunden-Upload: Anzeige Fallnummern/Fallname im Fenster</li>
    </ul>
    <strong>Frontend 3.5.9</strong><br />
    <ul>
      <li>diverse kleinere Änderungen</li>
    </ul>
    <strong>Frontend 3.5.8</strong><br />
    <ul>
      <li>Auskehr: Umsetzung mehrerer Änderungswünsche</li>
      <li>Personendaten: neue Felder für ausländische Bankverbindungen</li>
      <li>Bearbeitung Personendaten: Bugfixes</li>
      <li>EV-Automatik: mehrere Bugfixes und Änderungswünsche</li>
      <li>Auskehr/Kassenbuch: 1000er-Trennzeichen nun möglich bei Zahleneingabe</li>
    </ul>
    <strong>Frontend 3.5.7</strong><br />
    <ul>
      <li>Notizen: Beantworten nun möglich durch Beantworten einer Automail</li>
    </ul>
    <strong>Frontend 3.5.6</strong><br />
    <ul>
      <li>EV: Testversion zum Erstellen eines EV-Entwurfs</li>
      <li>Notizen: Sortierung der Antworten individuell einstellbar und Default-Sortierung im Mitarbeiter-Profil wählbar</li>
      <li>Notizen: Fall- und Personenname in Verlauf und in Popup-Ansicht mit anzeigen</li>
      <li>Kassenbuch: Bugfix Voreinstellung Belegart beim Bearbeiten</li>
      <li>Mailversand: Verwaltung sind nun standardmäßig aktiviert und Entwickler-Accounts werden ausgeblendet</li>
    </ul>

    <strong>Frontend 3.5.5</strong><br />
    <ul>
      <li>Auskehr: Teilbereich Institutionen und Auskehrzahlungen in Betrieb genommen</li>
      <li>V+V-Erzeugung: Entfernung von Leerzeichen im Dateinamen</li>
      <li>Rechteverwaltung: E-Mail-Versand jetzt individuell einstellbar</li>
      <li>NVZ-Tool: Wegfall des Neuladens von Datensätzen bei Neuerfassung Einträge</li>
      <li>EBS-Antrag: mehrere kleinere Bugfixes</li>
      <li>Notizen: teilweise Filterung der nicht speicherbaren Sonderzeichen</li>
      <li>Fall: Neue Felder für Mitarbeiter, der Akteneinsicht angefragt und durchgeführt hat</li>
    </ul>
    <strong>Frontend 3.5.3</strong><br />
    <ul>
      <li>Fall: Felder für Ablagegrund und Erläuterung</li>
      <li>Adressbuch: Bugfix Länge Anmerkungen</li>
    </ul>
    <strong>Frontend 3.5.2</strong><br />
    <ul>
      <li>Personendaten: neue Felder Geburtsland und Sterbeland</li>
      <li>EBS: Bugfixes und kleinere Ergänzungen</li>
    </ul>
    <strong>Frontend 3.5.1</strong><br />
    <ul>
      <li>EBS: Bugfixes und kleinere Ergänzungen</li>
    </ul>
    <strong>Frontend 3.5.0</strong><br />
    <ul>
      <li>Notizen: Umstellung auf neues Datenmodell und Einzelanzeige der Antworten</li>
      <li>Fall: Speicherung Zeitpunkt und Nutzer bei Status-Änderungen</li>
      <li>V+V-Erzeugung: Integration Widerrufs-Belehrung </li>
    </ul>
    <strong>Frontend 3.4.5</strong><br />
    <ul>
      <li>EBS: Bugfixes und kleine Ergänzungen</li>
    </ul>
    <strong>Frontend 3.4.4</strong><br />
    <ul>
      <li>Fall-Suche: Filter für vorhandene Immobilien</li>
      <li>Fall-Infos: mehr Platz zur Angabe von Immobilien-Informationen</li>
      <li>Erbenliste: Anpassung Tabelle und Anzeige Erbquote</li>
      <li>Dokumente: Anpassung Text Vollmacht</li>
      <li>Bugix: Fallnummer in EBS-Antrag</li>
      <li>ESA-Tool: Korrektur Urkundenfarbe</li>
      <li>ESA-Tool: Korrektur Geburtsurkunde Erblasser</li>
      <li>Kassenbuch: Anpassung Kostenkategorien</li>
      <li>Kassenbuch: Anzeige aller in einem Beleg verwendeten Kategorien</li>
      <li>Kassenbuch: Möglichkeit zur Währungs-Auswahl</li>
    </ul>
    <strong>Frontend 3.4.3</strong><br />
    <ul>
      <li>Rechteverwaltung: Anpassung für EBS-Automatik</li>
    </ul>
    <strong>Frontend 3.4.2</strong><br />
    <ul>
      <li>Kassenbuch: fallübergreifende Liste offener Vorgänge</li>
      <li>Kassenbuch: fallübergreifende Auswertung</li>
    </ul>
    <strong>Frontend 3.4.1</strong><br />
    <ul>
      <li>EBS: Verbesserungen und Bugfixes</li>
    </ul>
    <strong>Frontend 3.4.0</strong><br />
    <ul>
      <li>Kassenbuch: ab sofort in Fall-Ansicht verfügbar</li>
      <li>Notizen: Notiz-ID wird mit im Notizentitel angezeigt</li>
      <li>sonstiges: Logo-Datei getauscht</li>
    </ul>
    <strong>Frontend 3.3.17</strong><br />
    <ul>
      <li>Dokumente: Anpassung an die Änderung der Rechtsform</li>
      <li>Bugfix: Probleme beim Speichern von Personendaten</li>
      <li>Pool/Börse: Anzeige des Geburtsorts</li>
      <li>Urkunden: ESC schliesst nur noch das Popup, nicht mehr den gesamten Fall</li>
    </ul>
    <strong>Frontend 3.3.16</strong><br />
    <ul>
      <li>Stammbaum: Enter-Taste schließt bei mehrzeiligen Eingabefeldern jetzt nicht mehr die Eingabe ab</li>
      <li>Bugfix: Erblasser letzter Aufenthaltsort wieder direkt nach Fall-Neuanlage bearbeitbar</li>
    </ul>
    <strong>Frontend 3.3.15</strong><br />
    <ul>
      <li>VuV-Bestätigung: Block "Email benötigt" jetzt abschaltbar</li>
      <li>VuV-Vertrag: lässt sich nach Nachtragen der Prozente jetzt ohne vorheriges Verlassen der Personenansicht anlegen</li>
      <li>Notizen: Kalendereinträge Datum/Uhrzeit jetzt auch für fremde Termine sichtbar</li>
      <li>Kalender: Einträge mit Titeln länger als 40 Zeichen können bearbeitet werden</li>
      <li>Kalendereinträge aus Notizen können jetzt auch direkt für andere angelegt werden</li>
    </ul>
    <strong>Frontend 3.3.14</strong><br />
    <ul>
      <li>Fälle: Änderungen docx-Vorlage Benennung und Inhalt</li>
      <li>Fall-Status: neue Unterscheidung archiviert - abgeschlossen und archiviert - sonstiges</li>
      <li>Bugfix: falsche Checkbox SU/GU wird aktiviert bei Klick auf Beschriftung</li>
    </ul>
    <strong>Frontend 3.3.13</strong><br />
    <ul>
      <li>Stammbaum: diverse Änderungen in Vorbereitung für EBS</li>
      <li>Rechteverwaltung: Adressbuch für einzelne Benutzer freischaltbar</li>
    </ul>
    <strong>Frontend 3.3.12</strong><br />
    <ul>
      <li>globale Suchfunktionen: jetzt für alle Mitarbeiter (außer Archiv-MA) verfügbar</li>
      <li>Stammbaum-Personen: neues Feld für außereheliche Partnerschaft</li>
      <li>Notizen: auch bei der ersten Antwort Anzeige des Bearbeiters</li>
      <li>Fall-Listen: gelöschte Team-Mitglieder werden nicht mehr angezeigt</li>
      <li>Fall-Suche: Layout-Verbesserung und Suche nach Quelle</li>
      <li>Fall: Layout-Korrektur Fall neu</li>
      <li>Mitarbeiter-Verwaltung: inaktive User nur bei Bedarf einblenden</li>
    </ul>
    <strong>Frontend 3.3.11</strong><br />
    <ul>
      <li>Stammbaum: Anzeige der Erbquote für Personen und Zweige</li>
    </ul>
    <strong>Frontend 3.3.10</strong><br />
    <ul>
      <li>Stammbaum: neues Feld Berliner Testament für Erblasser und nachverstorbene Erben</li>
      <li>Erblasser-Daten: neues Feld Testament vorhanden</li>
      <li>Kalender: neue angelegte Stammbäume beinhalten nun auch Geschlecht der Personen</li>
    </ul>
    <strong>Frontend 3.3.9</strong><br />
    <ul>
      <li>EBS-Doku-Tab: Änderungen für Felder RA, Teil/Gesamt-Erbschein, Übersetzungen</li>
      <li>EBS-Doku-Tab: Datum EBS-Doku bleibt auch nach Umstellen auf ESA-EV erhalten</li>
      <li>Stammbaum: neues Feld zur Auswahl männlich/weiblich</li>
      <li>Stammbaum: Hinweis auf nachverstorbene Erben anhand des Geburtsdatums beim Speichern</li>
      <li>Kalender: Bearbeiten der Einträge nun auch aus der Kalenderansicht möglich</li>
    </ul>
    <strong>Frontend 3.3.8</strong><br />
    <ul>
      <li>sonstiges: Sammelmail an alle (zunächst nur für Admins)</li>
    </ul>
    <strong>Frontend 3.3.7</strong><br />
    <ul>
      <li>Notizen: vor Tabwechsel geöffnete Notizen klappen bei Rückkehr wieder auf</li>
      <li>Bugfix: fehlende oder fehlerhafte Datumsangaben PA in Liste benötigte Unterlagen</li>
    </ul>
    <strong>Frontend 3.3.6</strong><br />
    <ul>
      <li>Bugfix: Speichern des Stammbaums bei fehlendem Geburtsdatum</li>
    </ul>
    <strong>Frontend 3.3.5</strong><br />
    <ul>
      <li>Notizen: getippte Notiz- und Antworttexte bleiben erhalten, wenn man innerhalb eines Falls die Ansichts-Tabs wechselt</li>
      <li>Erbenliste: Ablaufdatum PA wird jetzt mit dargestellt</li>
      <li>Stammbaum: automatische Abfrage "sicher keine Nachkommen" bei Personen unter 12 Jahren</li>
      <li>Bugfix: doppeltes Fenster beim Bearbeiten von Urkunden in Fällen ohne Stammbaum</li>
      <li>Bugfix: Kreisfreie Stadt bzw. Landkreis wurde in Namensverzeichnissen in der Kopfzeile auf der falschen Hierarchie-Ebene dargestellt</li>
    </ul>
    <strong>Frontend 3.3.4</strong><br />
    <ul>
      <li>Fall-Liste: Anzeige der Quelle in Tabelle</li>
      <li>Optimierung Stammbaum für mobile Endgeräte (Ausblenden gelber Kasten, andere Anordnung Buttons, alle Funktionen zugänglich)</li>
      <li>Optimierung Popups für mobile Endgeräte bzgl. Größe und Bedienbarkeit</li>
      <li>Optimierung Navigationsleiste Fall-Ansicht für mobile Endgeräte (Buttons besser erreichbar)</li>
    </ul>
    <strong>Frontend 3.3.3</strong><br />
    <ul>
      <li>Notizen: Erstellung von Terminerinnerungen/Wiedervorlagen</li>
      <li>Stammbaum: Ankreuzfelder "wird gesucht" und "sicher keine Nachkommen" direkt bei Anlage neuer Personen</li>
      <li>Automail: jetzt auch bei Änderung Fall-Status auf "Ablage prüfen"</li>
    </ul>
    <strong>Frontend 3.3.2</strong><br />
    <ul>
      <li>Stammbaum: Knopf zur Anzeige von Adressdaten auf Personenkarten der Erben</li>
      <li>Stammbaum: Position bleibt auch nach Hochladen von Urkunden erhalten</li>
      <li>Urkunden: neue Auswahlmöglichkeit "aus Akte"</li>
      <li>EBS-Dokumentation: Änderung Anordnung und div. Verbesserungen</li>
      <li>Stammbaum: Bugfix Größe Datumsfeld beim Bearbeiten von Personen auf mobilen Endgeräten</li>
      <li>Automail: Backup-Versand-Option bei nicht erreichbarem Mailserver</li>
    </ul>
    <strong>Frontend 3.3.1</strong><br />
    <ul>
      <li>Stammbaum-Position bleibt erhalten wenn man aus anderem Tab zurückwechselt</li>
      <li>Fall-Liste neueste Fälle zuerst als Standard-Sucheinstellung</li>
      <li>Diverse Verbesserungen in Darstellung und Bedienbarkeit auf mobilen Endgeräten</li>
      <li>Anpassung von Beschriftungen im Stammbaum-Modul</li>
      <li>Bugfix: keine Automails unter bestimmten Umständen</li>
      <li>Bugfix: Fiskus aufgehoben wird jetzt gespeichert</li>
    </ul>
    <strong>Frontend 3.3.0</strong><br />
    <ul>
      <li>Umstellung Logik und Steuerung für Fall-Status</li>
      <li>Junior-Pool entfernt</li>
    </ul>
    <strong>Frontend 3.2.6</strong><br />
    <ul>
      <li>Blanko-Dokument mit Empfängerdaten auch auf Fall-Ebene</li>
    </ul>
    <strong>Frontend 3.2.5</strong><br />
    <ul>
      <li>Gesamtzahl Urkunden (blaue Lupe) auch im Stammbaum sichtbar</li>
      <li>Rolle der Mitarbeiter mit in Mitarbeiter-Liste anzeigen</li>
      <li>Bugfix beim Editieren von Urkunden</li>
    </ul>
    <strong>Frontend 3.2.4</strong><br />
    <ul>
      <li>Dokumentenerzeugung: Blanko-Dokument mit Empfängerdaten</li>
    </ul>
    <strong>Frontend 3.2.3</strong><br />
    <ul>
      <li>Bugfix: EBS-Tab Steuerelemente springen in Firefox</li>
    </ul>
    <strong>Frontend 3.2.2</strong><br />
    <ul>
      <li>Bugifx: Umwandlung in großes ß statt Doppel-S bei Einträgen in Namensverzeichnissen</li>
    </ul>
    <strong>Frontend 3.2.1</strong><br />
    <ul>
      <li>Namenskürzel von EBS-Bearbeiter rot in Fall-Liste</li>
    </ul>
    <strong>Frontend 3.2.0</strong><br />
    <ul>
      <li>Neuer Abschnitt EBS-Doku für Fall-Ansicht</li>
      <li>Vorschau-Ansicht für Urkundensuche auf Seite Fall -> Notizen</li>
      <li>Anlegen von Notizen wieder auf altes Layout umgebaut</li>
    </ul>
    <strong>Frontend 3.1.17</strong><br />
    <ul>
      <li>VuV-Bestätigung automatisch erzeugbar</li>
      <li>Urkunden-Datum mit in Liste fehlender Urkunden</li>
      <li>Vertrag kann nicht mehr ohne Prozentangabe erzeugt werden</li>
    </ul>
    <strong>Frontend 3.1.16</strong><br />
    <ul>
      <li>VuV jetzt in weiblicher und männlicher Version</li>
      <li>neuer Status "EBS-Doku prüfen" mit Automail</li>
      <li>Anlage von neuen Standesämtern nur noch im NVZ-Tool möglich</li>
    </ul>
    <strong>Frontend 3.1.15</strong><br />
    <ul>
      <li>Erben: Vertrag jetzt automatisch aus Datenbank erzeugbar</li>
    </ul>
    <strong>Frontend 3.1.14</strong><br />
    <ul>
      <li>Erben: Vollmacht jetzt automatisch aus Datenbank erzeugbar</li>
    </ul>
    <strong>Frontend 3.1.13</strong><br />
    <ul>
      <li>Urkundenliste: keine Mehrfachanzeige fehlender Urkunden mehr</li>
      <li>Urkundenliste: gelöschte Urkunden werden nicht mehr berücksichtigt</li>
      <li>Urkundenliste: Urkunden auf die verzichtet wird, werden nicht mehr berücksichtigt</li>
      <li>Urkundenliste: Personen in Doppeldarstellung werden nur noch einmal berücksichtigt</li>
    </ul>
    <strong>Frontend 3.1.12</strong><br />
    <ul>
      <li>Stammbäume: Automail bei Speicherpunkten um Ersteller ergänzt</li>
      <li>Fall-Daten: Status-Wahlmöglichkeiten alphabetisch sortiert</li>
      <li>Bugfix: Aufklappliste Erben zeigt wieder korrekte Personen</li>
      <li>Bugfix: Personen-Bezeichnungen bei neuen Stammbäumen korrigiert</li>
    </ul>
    <strong>Frontend 3.1.11</strong><br />
    <ul>
      <li>Bugfix: Anzeige Über- und Unterfälle funktioniert wieder</li>
    </ul>
    <strong>Frontend 3.1.10</strong><br />
    <ul>
      <li>Standesämter/NVZ: Automail einstellbar bei Neuanlage Standesamt</li>
    </ul>
    <strong>Frontend 3.1.9</strong><br />
    <ul>
      <li>Stammbaum: Übersichtskasten einklappbar</li>
    </ul>
    <strong>Frontend 3.1.8</strong><br />
    <ul>
      <li>Überarbeitung Stammbaum-PDF für Gericht</li>
    </ul>
    <strong>Frontend 3.1.7</strong><br />
    <ul>
      <li>Erben und NTs: auch Anzeige von Urkunden in Personen-Detail-Ansicht</li>
      <li>Globale Suche nach Telefonnummern</li>
      <li>Bundesanzeiger: Anpassung an Änderungen</li>
      <li>Stammbaum: Bugfix gelöschte Personen</li>
    </ul>
    <strong>Frontend 3.1.6</strong><br />
    <ul>
      <li>Urkunden: einfache Kopie gilt nicht mehr automatisch als Urkunde</li>
      <li>Urkunden: neue Optionen Verzicht auf GU, HU und SU</li>
      <li>Stammbaum: gelöschte Personen werden in Namensträger verschoben</li>
      <li>Fall-Liste: zusätzliche Filter für Geburtsort und NLG</li>
      <li>Bugfix: Bankverbindung bei benötigte Unterlagen Erben</li>
    </ul>
    <strong>Frontend 3.1.5</strong><br />
    <ul>
      <li>Stammbaum: Liste fehlender Urkunden</li>
      <li>Stammbaum: Buttons rechtsbündig</li>
      <li>Personenliste um Personen-ID ergänzt</li>
    </ul>
    <strong>Frontend 3.1.4</strong><br />
    <ul>
      <li>Funktion "direkt zu Notiz (aus Verlauf)" reaktiviert</li>
      <li>Funktion "direkt zu Notiz-ID" reaktiviert</li>
      <li>Stammbaum: automatische Großschreibung jetzt auch für unterstrichene Vornamen</li>
      <li>Bugfix: Knöpfe im Stammbaum manchmal schwer klickbar</li>
    </ul>
    <strong>Frontend 3.1.3</strong><br />
    <ul>
      <li>Anzeige V+V verschickt, VV unterschrieben mit Datum in Stammbaum</li>
      <li>Erben: Kommentarfelder für benötigte Urkunden GU, HU, SU</li>
      <li>Erben: neuer Knopf für Urkunden-Upload bei benötigten Urkunden</li>
      <li>1. Zeichen von Vornamen und Geburtsnamen bei Stammbaum-Personen automatisch in Großbuchstaben umwandeln</li>
    </ul>
    <strong>Frontend 3.1.2</strong><br />
    <ul>
      <li>Stammbaum: Möglichkeit, 3. und 4. EO auszublenden</li>
      <li>Bugfix: Personenname und Rolle in Automail Notizen</li>
      <li>Anzeige Fallname und Nummer in Personen-Detail-Ansicht</li>
    </ul>
    <strong>Frontend 3.1.1</strong><br />
    <ul>
      <li>Bugfix: Filtern der Personenliste</li>
      <li>Sicherheitsabfrage für "neuen Stammbaum erstellen"</li>
      <li>Speichern von Einzelfeldern im Stammbaum auch mit Enter statt Knopfdruck</li>
      <li>Geburtsname mit anzeigen bei Urkunden-Upload</li>
      <li>Überarbeitung Stammbaum-PDFs (Entwurfsversion)</li>
    </ul>
    <strong>Frontend 3.1.0</strong><br />
    <ul>
      <li>Einführung des Stammbaum-Moduls</li>
    </ul>
    <strong>Frontend 3.0.0</strong><br />
    <ul>
      <li>Umbau einiger zentraler Programmkomponenten in Vorbereitung auf die Einführung des Stammbaum-Moduls</li>
    </ul>
  </div>

</div>



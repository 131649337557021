<div class="m-2 p-2">
  <form [formGroup]="form" class="mt-3">

    <ng-container *ngIf="mode === 'kommentar'">
      <h4 class="title">Kommentar sonstiges</h4>
      <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autofocus autosize [minRows]="6" [maxRows]="30" formControlName="kommentar" id="kommentar" class="keepnl form-control"></textarea>
    </ng-container>

    <ng-container *ngIf="mode === 'kommentar_beruf'">
      <h4 class="title">Kommentar Beruf</h4>
      <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autofocus autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_beruf" id="kommentar_beruf" class="keepnl form-control"></textarea>
    </ng-container>

    <ng-container *ngIf="mode === 'kommentar_ab_recherche'">
      <h4 class="title">Kommentar AB-Recherche</h4>
      <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autofocus autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_ab_recherche" id="kommentar_ab_recherche" class="keepnl form-control"></textarea>
    </ng-container>

    <ng-container *ngIf="mode === 'kommentar_urkunden'">
      <h4 class="title">Kommentar Urkunden</h4>
      <textarea [readonly]="!rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" cols="23" autofocus autosize [minRows]="6" [maxRows]="30" formControlName="kommentar_urkunden" id="kommentar_urkunden" class="keepnl form-control"></textarea>
    </ng-container>

    <ng-container *ngIf="mode === 'nachname'">
      <h4 class="title">Nachname</h4>
      <input type="text" autofocus id="nachname" formControlName="nachname" class="form-control">
      <h5 class="mt-2">geb.</h5>
      <input type="text" id="geburtsname" formControlName="geburtsname" class="form-control">
    </ng-container>

    <ng-container *ngIf="mode === 'vorname'">
      <h4 class="title">Vornamen (>Name< zum Unterstreichen)</h4>
      <input type="text" autofocus id="vorname" formControlName="vorname" class="form-control">
    </ng-container>

    <ng-container *ngIf="mode === 'religion'">
      <h4 class="title">Religion</h4>
      <input type="text" autofocus id="religion" formControlName="religion" class="form-control">
    </ng-container>

    <ng-container *ngIf="mode === 'geschlecht'">
      <h4 class="title">Geschlecht</h4>
      <select formControlName="geschlecht" type="text" id="geschlecht" class="form-control">
        <option value="1">weiblich</option>
        <option value="2">männlich</option>
        <option value="0">unbekannt</option>
      </select>
    </ng-container>

    <ng-container *ngIf="mode === 'birth'">
      <h4 class="title">Geburts- und Taufinfos</h4>
      <div class="row">
        <div class="bol col-4">Geburtsdatum</div>
        <div class="col">
          <date-input-form formControlName="geburtsdatum" type="text" id="geburtsdatum" class="form-control"></date-input-form>
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Geburtsort</div>
        <div class="col">
          <input type="text" autofocus id="geburtsinfo" formControlName="geburtsinfo" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Geburtsland (wenn nicht Deutschland)</div>
        <div class="col">
          <input type="text" autofocus id="geburtsland" formControlName="geburtsland" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Taufdatum</div>
        <div class="col">
          <date-input-form formControlName="taufdatum" type="text" id="taufdatum" class="form-control"></date-input-form>
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Taufinfo (Ort)</div>
        <div class="col">
          <input type="text" autofocus id="taufinfo" formControlName="taufinfo" class="form-control">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === 'death'">
      <h4 class="title">Sterbe- und Begräbnisinfos</h4>
      <div class="row">
        <div class="bol col-4">Sterbedatum</div>
        <div class="col">
          <date-input-form formControlName="sterbedatum" type="text" id="sterbedatum" class="form-control"></date-input-form>
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Sterbeort</div>
        <div class="col">
          <input type="text" autofocus id="sterbeinfo" formControlName="sterbeinfo" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Sterbeland (wenn nicht Deutschland)</div>
        <div class="col">
          <input type="text" autofocus id="sterbeland" formControlName="sterbeland" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Begräbnisdatum</div>
        <div class="col">
          <date-input-form formControlName="begraebnisdatum" type="text" id="begraebnisdatum" class="form-control"></date-input-form>
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">Begräbnisinfo (Ort)</div>
        <div class="col">
          <input type="text" autofocus id="begraebnisinfo" formControlName="begraebnisinfo" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="bol col-4">gefallen</div>
        <div class="col">
          <input type="checkbox" id="gefallen" formControlName="gefallen" class="ml-2">
        </div>
      </div>
    </ng-container>

    <button type="button" (click)="cancel()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
    <button type="button" (click)="check()" class="m-2 btn btn-sm btn-success">speichern</button>
  </form>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-pdf.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./create-pdf.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../services/stammbaum.service";
var styles_CreatePdfComponent = [i0.styles];
var RenderType_CreatePdfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreatePdfComponent, data: {} });
export { RenderType_CreatePdfComponent as RenderType_CreatePdfComponent };
export function View_CreatePdfComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PDF-Erzeugung"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00DCberblicks-Version"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "fa fa-lg fa-print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kurzversion (f\u00FCr Gericht)"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "fa fa-lg fa-print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], null, null); }
export function View_CreatePdfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "create-pdf", [], null, null, null, View_CreatePdfComponent_0, RenderType_CreatePdfComponent)), i1.ɵdid(1, 49152, null, 0, i2.CreatePdfComponent, [i3.NgbActiveModal, i4.StammbaumService], null, null)], null, null); }
var CreatePdfComponentNgFactory = i1.ɵccf("create-pdf", i2.CreatePdfComponent, View_CreatePdfComponent_Host_0, {}, {}, []);
export { CreatePdfComponentNgFactory as CreatePdfComponentNgFactory };

<h3>Verlauf</h3>
<app-notification></app-notification>

<div class="card-deck my-3">
  <div class="card mr-2">
    <h5 class="card-header">Kürzlich aufgerufene Fälle</h5>
    <div class="card-body">
      <case-history></case-history>
    </div>
  </div>
  <div class="card ml-2">
    <h5 class="card-header">Kürzlich aufgerufene Personen</h5>
    <div class="card-body">
      <heir-history></heir-history>
    </div>
  </div>
</div>

<history-notes></history-notes>



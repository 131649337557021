<h3 class="ilatitle">Fall-Liste für Status: "{{ getStatusText() }}" <button class="btn btn-sm btn-success mx-3" (click)="back()">zurück</button>
</h3>
<ng-container *ngIf="!loading">
  <table *ngIf="caselist && caselist.length > 0" class="table table-sm">
    <tr>
      <th>Fallnummer</th>
      <th>Nachname</th>
      <th>Wert</th>
      <th>Uns</th>
      <th>FallF</th>
      <th>CoF</th>
      <th>Team</th>
      <th>Fisk</th>
      <th>Wo</th>
      <th>Bemerkungen</th>
      <th>ESA einger.</th>
      <th>EV</th>
      <th style="background-color: #c9b381;">EBS erteilt</th>
      <th>Auskehrungen</th>
      <th></th>
    </tr>
    <tr *ngFor="let c of caselist">
      <td>{{ c.id }}</td>
      <td>{{ c.last_name }}</td>
      <td>{{ c.total_value }}</td>
      <td>{{ c.value }}</td>
      <td>
        <ng-container *ngFor="let member of c.team_member_details">
          <span *ngIf="!member.deleted && member.leader" class="badge badge-pill badge-success">{{userService.getAbbreviationForUser(member.user_id)}}</span>
        </ng-container>
      </td>
      <td>
        <ng-container *ngFor="let member of c.team_member_details">
          <span *ngIf="!member.deleted && member.co_leader" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
        </ng-container>
      </td>
      <td>
        <ng-container *ngFor="let member of c.team_member_details">
          <span *ngIf="!member.deleted && !member.leader && !member.co_leader" class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
        </ng-container>
      </td>
      <td><span *ngIf="c.fiscal">ja</span></td>
      <td>{{ c.place }}</td>
      <td>
        <span *ngIf="idToEdit !== c.id">{{ c.bemerkung_fuer_fallliste }}</span>
        <input *ngIf="idToEdit === c.id" type="text" maxlength="50" [(ngModel)]="bemerkung_to_edit">
      </td>
      <td><editfield-date *ngIf="c.esa_eingereicht_date" [(date)]="c.esa_eingereicht_date" [editMode]="false"></editfield-date></td>
      <td><editfield-date *ngIf="c.ev_erfolgt_date" [(date)]="c.ev_erfolgt_date" [editMode]="false"></editfield-date></td>
      <td style="background-color: #c9b381;"><editfield-date *ngIf="c.ebs_erlassen_date" [(date)]="c.ebs_erlassen_date" [editMode]="false"></editfield-date></td>
      <td>
        <span *ngIf="idToEdit !== c.id">{{ c.auszahlungssumme_fuer_fallliste }}</span>
        <input *ngIf="idToEdit === c.id" type="text" maxlength="50" [(ngModel)]="auszahlung_to_edit">
      </td>
      <td>
        <button title="bearbeiten" class="btn btn-sm btn-warning" *ngIf="idToEdit !== c.id" (click)="edit(c)"><span class="fa fa-pencil-square"></span></button>
        <button title="speichern" class="btn btn-sm btn-success" *ngIf="idToEdit === c.id" (click)="save()"><span class="fa fa-check"></span></button>
      </td>
    </tr>
    <tr>
      <th>Summen</th>
      <th></th>
      <th>{{sum_total_value}}</th>
      <th>{{sum_value}}</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>{{sum_auskehrungen}}</th>
      <th></th>
    </tr>
  </table>
  <p *ngIf="!caselist || caselist.length === 0">keine Fälle gefunden</p>
</ng-container>
<ng-container *ngIf="loading">
  <p>Die Liste wird geladen, bitte warten...</p>
</ng-container>
<button class="btn btn-sm btn-success my-1" (click)="back()">zurück</button>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-controls.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../stammbaum-tree-map-svg/stammbaum-tree-map-svg.component.ngfactory";
import * as i4 from "../stammbaum-tree-map-svg/stammbaum-tree-map-svg.component";
import * as i5 from "../../services/stammbaum-display.service";
import * as i6 from "@ngrx/store";
import * as i7 from "./stammbaum-controls.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../services/constants.service";
import * as i10 from "../../services/rights-management.service";
import * as i11 from "../../services/case.service";
import * as i12 from "../../services/stammbaum.service";
var styles_StammbaumControlsComponent = [i0.styles];
var RenderType_StammbaumControlsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumControlsComponent, data: {} });
export { RenderType_StammbaumControlsComponent as RenderType_StammbaumControlsComponent };
function View_StammbaumControlsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToPersonData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-user"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "edit-person-data")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToStructure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-code-fork"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "edit-structure")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToMorphStructure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-cogs"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "morph-structure")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTreeSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-wrench"]], null, null, null, null, null))], null, null); }
function View_StammbaumControlsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "span", [["class", "pos-ri"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.toggleKasten() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-caret-square-o-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.zoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-plus-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.zoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-minus-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToExtraInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_4)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_5)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toCheckpoints() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-floppy-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generatePdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-print"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rightsManagementService.isAllowedToEditStammbaumTreeContent(); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments(); _ck(_v, 10, 0, currVal_1); var currVal_2 = "m-1 btn btn-sm btn-success"; var currVal_3 = _ck(_v, 14, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "show-extra-info")); _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = _co.rightsManagementService.isAllowedToExchangeStammbaumPersonsAndMarriages(); _ck(_v, 17, 0, currVal_4); var currVal_5 = _co.rightsManagementService.isAllowedToChangeStammbaumSettings(); _ck(_v, 19, 0, currVal_5); }, null); }
function View_StammbaumControlsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-tree-map-svg", [], null, null, null, i3.View_StammbaumTreeMapSvgComponent_0, i3.RenderType_StammbaumTreeMapSvgComponent)), i1.ɵdid(1, 245760, null, 0, i4.StammbaumTreeMapSvgComponent, [i5.StammbaumDisplayService, i6.Store], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 1, 0, currVal_0); }, null); }
function View_StammbaumControlsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.toggleKasten() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-caret-square-o-up"]], null, null, null, null, null))], null, null); }
function View_StammbaumControlsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToPersonData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-user"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "edit-person-data")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToStructure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-code-fork"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "edit-structure")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToMorphStructure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-cogs"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-1 btn btn-sm btn-success"; var currVal_1 = _ck(_v, 3, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "morph-structure")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StammbaumControlsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTreeSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-wrench"]], null, null, null, null, null))], null, null); }
function View_StammbaumControlsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "pos-ri-off"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.zoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-plus-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.zoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-minus-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_10)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "m-1 btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stammbaumService.editModeToExtraInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "btn-active-d": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_11)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_12)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toCheckpoints() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-floppy-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "m-1 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generatePdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "span", [["class", "btw fa fa-lg fa-print"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.media_fix_controls; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.rightsManagementService.isAllowedToEditStammbaumTreeContent(); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments(); _ck(_v, 10, 0, currVal_2); var currVal_3 = "m-1 btn btn-sm btn-success"; var currVal_4 = _ck(_v, 14, 0, (((_co.stammbaumState == null) ? null : _co.stammbaumState.treeViewMode) === "show-extra-info")); _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = _co.rightsManagementService.isAllowedToExchangeStammbaumPersonsAndMarriages(); _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.rightsManagementService.isAllowedToChangeStammbaumSettings(); _ck(_v, 19, 0, currVal_6); }, null); }
export function View_StammbaumControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumControlsComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.stammbaumState && _co.stammbaumState.stammbaumDisplay) && _co.stammbaumState.stammbaumDisplay.showKasten) && !_co.media_fix_controls); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.stammbaumState && _co.stammbaumState.stammbaumDisplay) && _co.stammbaumState.stammbaumDisplay.showKasten) && !_co.media_fix_controls); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.stammbaumState && ((!_co.stammbaumState.stammbaumDisplay || !_co.stammbaumState.stammbaumDisplay.showKasten) || _co.media_fix_controls)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_StammbaumControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-controls", [], null, null, null, View_StammbaumControlsComponent_0, RenderType_StammbaumControlsComponent)), i1.ɵdid(1, 114688, null, 0, i7.StammbaumControlsComponent, [i6.Store, i8.NgbModal, i9.ConstantsService, i10.RightsManagementService, i11.CaseService, i12.StammbaumService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumControlsComponentNgFactory = i1.ɵccf("stammbaum-controls", i7.StammbaumControlsComponent, View_StammbaumControlsComponent_Host_0, { stammbaumState: "stammbaumState" }, {}, []);
export { StammbaumControlsComponentNgFactory as StammbaumControlsComponentNgFactory };

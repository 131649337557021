<h3>Aufgaben Kalenderansicht <button class="mx-3 btn btn-sm btn-primary" (click)="showList()">zur Listenansicht wechseln</button></h3>
  <h3 class="md-auto titleline">
    <button class="btn btn-sm btn-link backward" (click)="prevMonth()"><i class="fa fa-lg fa-step-backward"></i></button>
    <span class="monthname">{{currentMonthName}} {{currentDate | date:'y'}}</span>
    <button class="btn btn-sm btn-link forward" (click)="nextMonth()"><i class="fa fa-lg fa-step-forward"></i></button>
  </h3>

<div class="card-group d-none d-sm-flex">
  <div *ngFor="let weekDay of weekDays" class="card bg-light my-2">
    <div class="card-body">
      <h5 class="card-title">{{weekDay}}</h5>
    </div>
  </div>
</div>

<div *ngFor="let week of weeks" class="card-group">
  <div *ngFor="let day of week" class="card bg-light">
    <div [ngClass]="{'today': day.isSame(today, 'd')}" class="card-body calendar-day">
      <h4 *ngIf="day.month() == currentDate.month()" class="day card-title">{{day | date:'dd'}}</h4>
      <h4 *ngIf="day.month() != currentDate.month()" class="otherday card-title">{{day | date:'dd'}}</h4>
      <div class="card-text entryBox">
        <ul class='date-entries'>
          <li class="date-entries-element" *ngFor="let own of getEntries(day).own" (click)="openDetails(own)"><span class='badge badge-pill badge-warning'>{{getAbbrevationForUser(own.for_user_id)}}</span>{{convertTimeForOutput(own.deadline_hour, own.deadline_minute)}} {{own.title}}</li>
          <li class="date-entries-element" *ngFor="let others of getEntries(day).others" (click)="openDetails(others)"><span class='badge badge-pill badge-success'>{{getAbbrevationForUser(others.for_user_id)}}</span>{{convertTimeForOutput(others.deadline_hour, others.deadline_minute)}} {{others.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>





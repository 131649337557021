/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-detail-stammbaum.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../stammbaum/stammbaum-tree-svg/stammbaum-tree-svg.component.ngfactory";
import * as i3 from "../../stammbaum/stammbaum-tree-svg/stammbaum-tree-svg.component";
import * as i4 from "../../services/stammbaum-display.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@ngrx/store";
import * as i7 from "../../stammbaum/stammbaum-controls/stammbaum-controls.component.ngfactory";
import * as i8 from "../../stammbaum/stammbaum-controls/stammbaum-controls.component";
import * as i9 from "../../services/constants.service";
import * as i10 from "../../services/rights-management.service";
import * as i11 from "../../services/case.service";
import * as i12 from "../../services/stammbaum.service";
import * as i13 from "@angular/common";
import * as i14 from "./case-detail-stammbaum.component";
var styles_CaseDetailStammbaumComponent = [i0.styles];
var RenderType_CaseDetailStammbaumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseDetailStammbaumComponent, data: {} });
export { RenderType_CaseDetailStammbaumComponent as RenderType_CaseDetailStammbaumComponent };
function View_CaseDetailStammbaumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "stammbaum-tree-svg", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).updateScrollPosition($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StammbaumTreeSvgComponent_0, i2.RenderType_StammbaumTreeSvgComponent)), i1.ɵdid(3, 12828672, null, 0, i3.StammbaumTreeSvgComponent, [i4.StammbaumDisplayService, i5.NgbModal, i6.Store], { stammbaumState: [0, "stammbaumState"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "stammbaum-controls", [["class", "card fixed-pos"]], null, null, null, i7.View_StammbaumControlsComponent_0, i7.RenderType_StammbaumControlsComponent)), i1.ɵdid(5, 114688, null, 0, i8.StammbaumControlsComponent, [i6.Store, i5.NgbModal, i9.ConstantsService, i10.RightsManagementService, i11.CaseService, i12.StammbaumService], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.stammbaumState; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CaseDetailStammbaumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailStammbaumComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.stammbaumState == null) ? null : ((_co.stammbaumState.stammbaumVersions == null) ? null : _co.stammbaumState.stammbaumVersions.length)) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaseDetailStammbaumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-detail-stammbaum", [], null, null, null, View_CaseDetailStammbaumComponent_0, RenderType_CaseDetailStammbaumComponent)), i1.ɵdid(1, 4308992, null, 0, i14.CaseDetailStammbaumComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseDetailStammbaumComponentNgFactory = i1.ɵccf("case-detail-stammbaum", i14.CaseDetailStammbaumComponent, View_CaseDetailStammbaumComponent_Host_0, { stammbaumState: "stammbaumState" }, {}, []);
export { CaseDetailStammbaumComponentNgFactory as CaseDetailStammbaumComponentNgFactory };

<h3>Länder
  <button  *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="addLand()" class="mx-2 btn btn-sm btn-warning">neues Land anlegen</button>
  <span style="font-size: 45%"><kbd>Einfg</kbd></span>
</h3>
<div *ngIf="nvzService.laenderFirstLoad">
  <div *ngIf="!nvzState || !nvzState.laender || nvzState.staedte.length == 0">keine Länder vorhanden</div>
  <div *ngIf="nvzState && nvzState.laender && nvzState.laender.length > 0">
    <table class="table table-hover table-striped table-sm">
      <tr>
        <th>Name</th>
        <th>Kommentar</th>
        <th></th>
      </tr>
      <tr *ngFor="let land of nvzState.laender">
        <td (click)="selectLand(land)">{{land.name}}</td>
        <td (click)="selectLand(land)">{{land.comment}}</td>
        <td>
          <span *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" class="fa fa-edit mx-2" (click)="editLand(land)"></span>
          <span *ngIf="rightsManagementService.isAdmin()" class="fa fa-trash mx-2" (click)="deleteLand(land)"></span>
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!nvzService.laenderFirstLoad">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>


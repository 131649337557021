<div class="modal-header">
  <h4 *ngIf="address.id>0" class="modal-title">Adresse bearbeiten</h4>
  <h4 *ngIf="address.id==0" class="modal-title">Adresse anlegen</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="subForm" class="form-horizontal">
    <div class="form-group row">
      <label for="street" class="col-sm-3 control-label">Straße</label>
      <div class="col-sm-9">
        <input formControlName="street" type="text" id="street" class="form-control">
        <span *ngIf="subForm.get('street').invalid && subForm.get('street').touched" class="help-block">Bitte die Straße mit maximal 40 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="housenumber" class="col-sm-3 control-label">Hausnummer</label>
      <div class="col-sm-9">
        <input formControlName="housenumber" type="text" id="housenumber" class="form-control">
        <span *ngIf="subForm.get('housenumber').invalid && subForm.get('housenumber').touched" class="help-block">Bitte die Hausnummer mit maximal 8 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="additional_address_information" class="col-sm-3 control-label">Zusätzliche Adressinfos</label>
      <div class="col-sm-9">
        <input formControlName="additional_address_information" type="text" id="additional_address_information" class="form-control">
        <span *ngIf="subForm.get('additional_address_information').invalid && subForm.get('additional_address_information').touched" class="help-block">Bitte die zusätzlichen Adressinformationen mit maximal 40 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="zipcode" class="col-sm-3 control-label">Postleitzahl</label>
      <div class="col-sm-9">
        <input formControlName="zipcode" type="text" id="zipcode" class="form-control">
        <span *ngIf="subForm.get('zipcode').invalid && subForm.get('zipcode').touched" class="help-block">Bitte die Postleitzahl mit maximal 10 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="town" class="col-sm-3 control-label">Ort</label>
      <div class="col-sm-9">
        <input formControlName="town" type="text" id="town" class="form-control">
        <span *ngIf="subForm.get('town').invalid && subForm.get('town').touched" class="help-block">Bitte den Ort mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>
    <div class="form-group row">
      <label for="region" class="col-sm-3 control-label">Region</label>
      <div class="col-sm-9">
        <select formControlName="region" id="region" class="form-control">
          <option *ngFor="let region of addressRegions" [value]="region.id">
            {{region.name}}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="mx-2 btn btn-sm btn-warning pull-left" (click)="cancel()">schliessen</button>
  <button *ngIf="address.id>0" class="mx-2 btn btn-sm btn-danger pull-right" (click)="delete()">Adresse löschen</button>
  <button *ngIf="address.id>0" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="update()">Änderungen speichern</button>
  <button *ngIf="address.id==0" [disabled]="!subForm.valid" class="mx-2 btn btn-success btn-sm pull-right" (click)="create()">Adresse anlegen</button>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-recipient-from-user.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select-recipient-from-user.component";
import * as i4 from "@ngrx/store";
var styles_SelectRecipientFromUserComponent = [i0.styles];
var RenderType_SelectRecipientFromUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectRecipientFromUserComponent, data: {} });
export { RenderType_SelectRecipientFromUserComponent as RenderType_SelectRecipientFromUserComponent };
function View_SelectRecipientFromUserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-default m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addUser(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.abbreviation.toUpperCase(); _ck(_v, 1, 0, currVal_0); }); }
export function View_SelectRecipientFromUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Archivmitarbeiter"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ermittler"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Senior-Ermittler"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Verwaltung"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Administrator"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "pointer-target badge badge-pill badge-danger m-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Alle aktiven Mitarbeiter"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectRecipientFromUserComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; _ck(_v, 15, 0, currVal_0); }, null); }
export function View_SelectRecipientFromUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "select-recipient-from-user", [], null, null, null, View_SelectRecipientFromUserComponent_0, RenderType_SelectRecipientFromUserComponent)), i1.ɵdid(1, 180224, null, 0, i3.SelectRecipientFromUserComponent, [i4.Store], null, null)], null, null); }
var SelectRecipientFromUserComponentNgFactory = i1.ɵccf("select-recipient-from-user", i3.SelectRecipientFromUserComponent, View_SelectRecipientFromUserComponent_Host_0, {}, { userRecipientSelected: "userRecipientSelected" }, []);
export { SelectRecipientFromUserComponentNgFactory as SelectRecipientFromUserComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./abc-choose.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./abc-choose.component";
var styles_AbcChooseComponent = [i0.styles];
var RenderType_AbcChooseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AbcChooseComponent, data: {} });
export { RenderType_AbcChooseComponent as RenderType_AbcChooseComponent };
function View_AbcChooseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["style", "text-align: center;"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.letterChosen == _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
function View_AbcChooseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "table", [["class", "table table-sm table-bordered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbcChooseComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.letters; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AbcChooseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbcChooseComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.letters && (_co.letters.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AbcChooseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "abc-choose", [], null, null, null, View_AbcChooseComponent_0, RenderType_AbcChooseComponent)), i1.ɵdid(1, 49152, null, 0, i3.AbcChooseComponent, [], null, null)], null, null); }
var AbcChooseComponentNgFactory = i1.ɵccf("abc-choose", i3.AbcChooseComponent, View_AbcChooseComponent_Host_0, {}, { chosen: "chosen" }, []);
export { AbcChooseComponentNgFactory as AbcChooseComponentNgFactory };

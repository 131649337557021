<h3>{{getTitle()}}</h3>
<div class="card my-3">
  <div class="card-body">
    <div>
      <button (click)="toCaseHeir(copiedPerson.case)" class="mx-1 btn btn-success btn-sm">Zurück zur Erbenliste</button>
      <button (click)="toCase(copiedPerson.case)" class="mx-1 btn btn-info btn-sm">Zum zugehörigen Fall</button>
      <button (click)="toCaseNotes(copiedPerson.case)" class="mx-1 btn btn-info btn-sm">Zu den Fall-Notizen</button>
      <button (click)="back()" class="mx-1 btn btn-warning btn-sm">Zur Fall-Suche</button>
      <button *ngIf="isDeleteable()" (click)="deletePerson()" class="mx-1 btn btn-danger btn-sm">Person löschen</button>
    </div>
  </div>
</div>
<div *ngIf="copiedPerson.deleted" class="card my-3">
  <div class="card-body">
    <div class="alert alert-danger">Dieser Datensatz wurde gelöscht</div>
  </div>
</div>
<div *ngIf="copiedPerson" class="card-deck my-3">
  <div class="card mr-3 col-md-6">
    <div class="card-body">
      <h4 *ngIf="!editModePersonData" >Personendaten <button (click)="editPersonData()" class="btn btn-sm btn-warning">bearbeiten</button></h4>
      <h4 *ngIf="editModePersonData">Personendaten <button (click)="reloadData()" class="btn btn-sm btn-info">abbrechen</button> <button (click)="saveData()" class="btn btn-sm btn-danger">speichern</button></h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm">
          <tr><td>ID</td><td>{{copiedPerson.id}}</td></tr>
          <tr><td>Personen-Typ</td><td><editfield-select [options]="getPersonGroupOptions()" [editMode]="editModePersonData" [(select)]="copiedPerson.person_type"></editfield-select></td></tr>
          <tr><td>Name</td><td>
            <editfield-name [editMode]="editModePersonData" [(first_name)]="copiedPerson.vorname" [(last_name)]="copiedPerson.nachname" [(birth_name)]="copiedPerson.geburtsname"></editfield-name>
          </td></tr>
          <tr><td>Anschrift</td><td><editfield-address [editMode]="editModePersonData" [(address)]="copiedPerson.address"></editfield-address></td></tr>
          <tr><td>Kontakt</td><td><editfield-contact [personId]="copiedPerson.id" [editMode]="editModePersonData"></editfield-contact></td></tr>
          <tr><td>Geburtsdatum</td><td><editfield-date [(date)]="copiedPerson.geburtsdatum" [editMode]="editModePersonData"></editfield-date></td></tr>
          <tr><td>Geburtsort</td><td><editfield-text [maxLength]="30" [editMode]="editModePersonData" [(text)]="copiedPerson.geburtsinfo"></editfield-text></td></tr>
          <tr><td>Geburtsland (wenn nicht Deutschland)</td><td><editfield-text [maxLength]="30" [editMode]="editModePersonData" [(text)]="copiedPerson.geburtsland"></editfield-text></td></tr>
          <tr><td>Sterbedatum</td><td><editfield-date [(date)]="copiedPerson.sterbedatum" [editMode]="editModePersonData"></editfield-date></td></tr>
          <tr><td>Sterbeort</td><td><editfield-text [maxLength]="30" [editMode]="editModePersonData" [(text)]="copiedPerson.sterbeinfo"></editfield-text></td></tr>
          <tr><td>Sterbeland (wenn nicht Deutschland)</td><td><editfield-text [maxLength]="30" [editMode]="editModePersonData" [(text)]="copiedPerson.sterbeland"></editfield-text></td></tr>
          <tr><td>Sprache</td><td><editfield-text [maxLength]="50" [editMode]="editModePersonData" [(text)]="copiedPerson.language"></editfield-text></td></tr>
          <tr><td>Staatsbürgerschaft</td><td><editfield-select [editMode]="editModePersonData" [options]="stammbaumService.staatsbuergerschaft_options" [(select)]="copiedPerson.staatsbuergerschaft"></editfield-select></td></tr>
          <tr><td>Erbberechtigung</td><td><editfield-text [maxLength]="50" [editMode]="editModePersonData" [(text)]="copiedPerson.inheritance_rights"></editfield-text></td></tr>
          <tr><td>Vertreter</td><td><editfield-text [maxLength]="50" [editMode]="editModePersonData" [(text)]="copiedPerson.representative"></editfield-text></td></tr>
          <tr><td>Ansprechpartner</td><td><editfield-text [maxLength]="50" [editMode]="editModePersonData" [(text)]="copiedPerson.contact_person"></editfield-text></td></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="card mr-3 col-md-6">
    <div card="card-body">
      <h4 *ngIf="!editModeAdditionalData">sonstige Daten <button (click)="editAdditionalData()" class="btn btn-sm btn-warning">bearbeiten</button></h4>
      <h4 *ngIf="editModeAdditionalData">sonstige Daten <button (click)="reloadData()" class="btn btn-sm btn-info">abbrechen</button> <button (click)="saveData()" class="btn btn-sm btn-danger">speichern</button></h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm">
          <tr><td>Erstkontakt am</td><td><editfield-date [(date)]="copiedPerson.first_contact_date" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr><td>Angerufen</td><td><editfield-checkbox [(checkbox)]="copiedPerson.phone_call_made" [editMode]="editModeAdditionalData"></editfield-checkbox></td></tr>
          <tr><td>Ergebnis Anruf</td><td><editfield-text [maxLength]="30" [editMode]="editModeAdditionalData" [(text)]="copiedPerson.result"></editfield-text></td></tr>
          <tr><td>Angeschrieben am</td><td><editfield-date [(date)]="copiedPerson.contract_send_out_date" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr><td>Vertrag vom</td><td><editfield-date [(date)]="copiedPerson.contract_date" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr><td>Prozentsatz</td><td><editfield-text [maxLength]="50" [editMode]="editModeAdditionalData" [(text)]="copiedPerson.contract_send_out_percentage"></editfield-text></td></tr>
          <tr><td>Unterschrieben am</td><td><editfield-date [(date)]="copiedPerson.signature_date" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr><td>Ende Widerruf am</td><td><editfield-date [date]="getRevocationPeriodEndDate()" [editMode]="false"></editfield-date></td></tr>
          <tr><td>Vollmacht</td><td><editfield-checkbox [editMode]="editModeAdditionalData" [(checkbox)]="copiedPerson.mandate"></editfield-checkbox></td></tr>
          <tr><td>Erbnachweis</td><td><editfield-checkbox [editMode]="editModeAdditionalData" [(checkbox)]="copiedPerson.inheritance_proof"></editfield-checkbox></td></tr>
          <tr><td>Termin Zwischenbericht Erbe</td><td><editfield-date [(date)]="copiedPerson.heir_midterm_report_date" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['ausgeschlagener Erbe']"><td>Ausschlagung belegt</td><td><editfield-checkbox [(checkbox)]="copiedPerson.ausschlagung_belegt" [editMode]="editModeAdditionalData"></editfield-checkbox></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['ausgeschlagener Erbe']"><td>für minderjährige Nachkommen ausgeschlagen</td><td><editfield-checkbox [(checkbox)]="copiedPerson.ausschlagung_for_minor_kids" [editMode]="editModeAdditionalData"></editfield-checkbox></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['ausgeschlagener Erbe']"><td>Datum der Ausschlagung</td><td><editfield-date [(date)]="copiedPerson.ausschlagung_datum" [editMode]="editModeAdditionalData"></editfield-date></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['ausgeschlagener Erbe']"><td>Aktenbezug der Ausschlagung</td><td><editfield-text [maxLength]="100" [(text)]="copiedPerson.ausschlagung_aktenbezug" [editMode]="editModeAdditionalData"></editfield-text></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['ausgeschlagener Erbe']"><td>Ort der Ausschlagung</td><td><editfield-text [maxLength]="100" [(text)]="copiedPerson.ausschlagung_ort" [editMode]="editModeAdditionalData"></editfield-text></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['unwilliger Erbe']"><td>unwilligen Erben trotzdem in EBS aufnehmen</td><td><editfield-checkbox [(checkbox)]="copiedPerson.unwillig_still_include_ebs" [editMode]="editModeAdditionalData"></editfield-checkbox></td></tr>
          <tr *ngIf="+copiedPerson.person_type === PersonType['Erbe (nachverstorben)']"><td>ESA-Zweig NICHT ausformulieren</td><td><editfield-checkbox [(checkbox)]="copiedPerson.nachverstorben_stop_esa" [editMode]="editModeAdditionalData"></editfield-checkbox></td></tr>
        </table>
        <div>
          <button class="m-1 btn btn-sm btn-success" (click)="createVundV()">V+V erzeugen</button>
          <button class="m-1 btn btn-sm btn-success" (click)="createDocx()">Leeres DOCX</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="copiedPerson" class="card-deck my-3">
  <div class="card mr-3 col-md-6">
    <div class="card-body">
        <h4>Urkunden aus Urkundenmodul <button class="mx-2 btn btn-sm btn-success" (click)="showUrkunden()">Urkunden anzeigen</button></h4>
        <file-upload-list [componentHeadline]="'Urkunden und Dokumente'" [mode]="'person'"></file-upload-list>
    </div>
  </div>
  <div class="card mr-3 col-md-6">
    <div class="card-body">
      <note-list-max [mode]="'person'" [folderId]="0"></note-list-max>
    </div>
  </div>
</div>

<div *ngIf="copiedPerson && isHeir()" class="card-deck my-3">
  <div class="card mr-3">
    <div class="card-body">
      <h4>Benötigte Unterlagen für Erben
        <button (click)="uploadUrkunden()" class="mx-1 btn btn-sm btn-info">Urkunden hochladen</button>
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm">
          <tr><th>Unterlagen</th><th></th><th>benötigt</th><th>vorhanden</th><th>bestellt</th><th>bestellt durch</th><th>bestellt am</th><th></th></tr>
          <tr>
            <td>GU</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('gu')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'gu')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'gu')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><show-boolean-value [value]="copiedPerson.needs_gu"></show-boolean-value></td>
            <td><show-boolean-value [value]="copiedPerson.urkunden_vorhanden?.has_gu"></show-boolean-value></td>
            <td><editfield-checkbox [(checkbox)]="copiedPerson.ordered_gu" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'gu')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments ||  (editModeRequiredDocsPart !== 'gu')" [userId]="copiedPerson.ordered_gu_user" (userIdSelected)="updateUserField('ordered_gu_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'gu')" [(date)]="copiedPerson.ordered_gu_date"></editfield-date></td>
            <td><editfield-text [maxLength]="'200'" [editMode]="editModeRequiredDocuments  && (editModeRequiredDocsPart === 'gu')" [(text)]="copiedPerson.needs_gu_comment"></editfield-text></td>
          </tr>
          <tr *ngFor="let mar of copiedPerson.needs_hu_marriages; let i = index;">
            <td>HU {{i + 1}}: {{mar.marriage_text}}</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments(mar.marriage_id.toString())" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === mar.marriage_id.toString())" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === mar.marriage_id.toString())" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><show-boolean-value [value]="mar.needs_hu"></show-boolean-value></td>
            <td><show-boolean-value [value]="mar.has_hu"></show-boolean-value></td>
            <td><editfield-checkbox [(checkbox)]="mar.ordered_hu" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === mar.marriage_id.toString())"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== mar.marriage_id.toString())" [userId]="mar.ordered_hu_user" (userIdSelected)="updateUserField('ordered_hu_user', $event, mar.marriage_id)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === mar.marriage_id.toString())" [(date)]="mar.ordered_hu_date"></editfield-date></td>
            <td><editfield-text [maxLength]="'200'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === mar.marriage_id.toString())" [(text)]="mar.needs_hu_comment"></editfield-text></td>
          </tr>
          <tr>
            <td>SU</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('su')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'su')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'su')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><show-boolean-value [value]="copiedPerson.needs_su"></show-boolean-value></td>
            <td><show-boolean-value [value]="copiedPerson.urkunden_vorhanden?.has_su"></show-boolean-value></td>
            <td><editfield-checkbox [(checkbox)]="copiedPerson.ordered_su" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'su')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'su')" [userId]="copiedPerson.ordered_su_user" (userIdSelected)="updateUserField('ordered_su_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'su')" [(date)]="copiedPerson.ordered_su_date"></editfield-date></td>
            <td><editfield-text [maxLength]="'200'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'su')" [(text)]="copiedPerson.needs_su_comment"></editfield-text></td>
          </tr>
          <tr>
            <td>PA</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('pa')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pa', 0, $event)" [checkbox]="checkValue('required_pa', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pa', 2, $event)" [checkbox]="checkValue('required_pa', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pa', 1, $event)" [checkbox]="checkValue('required_pa', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'pa')" [userId]="copiedPerson.required_pa_user" (userIdSelected)="updateUserField('required_pa_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')" [(date)]="copiedPerson.required_pa_date"></editfield-date></td>
            <td>gültig bis <editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pa')" [(date)]="copiedPerson.required_pa_ablaufdatum"></editfield-date></td>
          </tr>
          <tr>
            <td>Pass</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('pass')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pass', 0, $event)" [checkbox]="checkValue('required_pass', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pass', 2, $event)" [checkbox]="checkValue('required_pass', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_pass', 1, $event)" [checkbox]="checkValue('required_pass', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'pass')" [userId]="copiedPerson.required_pass_user" (userIdSelected)="updateUserField('required_pass_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')" [(date)]="copiedPerson.required_pass_date"></editfield-date></td>
            <td>gültig bis <editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'pass')" [(date)]="copiedPerson.required_pass_ablaufdatum"></editfield-date></td>
          </tr>
          <tr>
            <td>Bankverbind.</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('bank')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_bankverb', 0, $event)" [checkbox]="checkValue('required_bankverb', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_bankverb', 2, $event)" [checkbox]="checkValue('required_bankverb', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_bankverb', 1, $event)" [checkbox]="checkValue('required_bankverb', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'bank')" [userId]="copiedPerson.required_bankverbindung_user" (userIdSelected)="updateUserField('required_bankverbindung_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(date)]="copiedPerson.required_bankverbindung_date"></editfield-date></td>
            <td>
              IBAN <editfield-text [maxLength]="'50'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(text)]="copiedPerson.required_bankverb_iban"></editfield-text><br />
              BIC/SWIFT <editfield-text [maxLength]="'50'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(text)]="copiedPerson.required_bankverb_bic"></editfield-text><br />
              BRANCH NUMBER <editfield-text [maxLength]="'50'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(text)]="copiedPerson.required_bankverb_branch_number"></editfield-text><br />
              BANK-NAME <editfield-text [maxLength]="'50'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(text)]="copiedPerson.required_bankverb_name"></editfield-text><br />
              ADRESSE <editfield-text [maxLength]="'100'" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'bank')" [(text)]="copiedPerson.required_bankverb_adresse"></editfield-text>
            </td>
          </tr>
          <tr>
            <td>Betreuerausweis</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('betreuer')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_betreuerausweis', 0, $event)" [checkbox]="checkValue('required_betreuerausweis', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_betreuerausweis', 2, $event)" [checkbox]="checkValue('required_betreuerausweis', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_betreuerausweis', 1, $event)" [checkbox]="checkValue('required_betreuerausweis', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'betreuer')" [userId]="copiedPerson.required_betreuerausweis_user" (userIdSelected)="updateUserField('required_betreuerausweis_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'betreuer')" [(date)]="copiedPerson.required_betreuerausweis_date"></editfield-date></td>
            <td></td>
          </tr>
          <tr>
            <td>Testament</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('testament')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_testament', 0, $event)" [checkbox]="checkValue('required_testament', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_testament', 2, $event)" [checkbox]="checkValue('required_testament', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_testament', 1, $event)" [checkbox]="checkValue('required_testament', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'testament')" [userId]="copiedPerson.required_testament_user" (userIdSelected)="updateUserField('required_testament_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'testament')" [(date)]="copiedPerson.required_testament_date"></editfield-date></td>
            <td></td>
          </tr>
          <tr>
            <td>Eröffnungsprotokoll</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('protokoll')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_eroeffnungsprotokoll', 0, $event)" [checkbox]="checkValue('required_eroeffnungsprotokoll', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_eroeffnungsprotokoll', 2, $event)" [checkbox]="checkValue('required_eroeffnungsprotokoll', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_eroeffnungsprotokoll', 1, $event)" [checkbox]="checkValue('required_eroeffnungsprotokoll', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'protokoll')" [userId]="copiedPerson.required_eroeffnungsprotokoll_user" (userIdSelected)="updateUserField('required_eroeffnungsprotokoll_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'protokoll')" [(date)]="copiedPerson.required_eroeffnungsprotokoll_date"></editfield-date></td>
            <td></td>
          </tr>
          <tr>
            <td>Erbschein</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('erbschein')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_erbschein', 0, $event)" [checkbox]="checkValue('required_erbschein', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_erbschein', 2, $event)" [checkbox]="checkValue('required_erbschein', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_erbschein', 1, $event)" [checkbox]="checkValue('required_erbschein', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')"></editfield-checkbox></td>
            <td><choose-user [disabled]="!editModeRequiredDocuments || (editModeRequiredDocsPart !== 'erbschein')" [userId]="copiedPerson.required_erbschein_user" (userIdSelected)="updateUserField('required_erbschein_user', $event)"></choose-user></td>
            <td><editfield-date [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'erbschein')" [(date)]="copiedPerson.required_erbschein_date"></editfield-date></td>
            <td></td>
          </tr>
          <tr>
            <td>sonstiges</td>
            <td>
              <button *ngIf="!editModeRequiredDocuments" (click)="editRequiredDocuments('sonst')" class="mx-1 btn btn-sm btn-warning">bearbeiten</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')" (click)="reloadData()" class="btn btn-sm btn-info mx-1">abbrechen</button>
              <button *ngIf="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')" (click)="saveData()" class="btn btn-sm btn-danger mx-1">speichern</button>
            </td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_sonst', 0, $event)" [checkbox]="checkValue('required_sonst', 0)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_sonst', 2, $event)" [checkbox]="checkValue('required_sonst', 2)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')"></editfield-checkbox></td>
            <td><editfield-checkbox (checkboxChange)="updateValue('required_sonst', 1, $event)" [checkbox]="checkValue('required_sonst', 1)" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')"></editfield-checkbox></td>
            <td></td>
            <td></td>
            <td>
              <span *ngIf="editModeRequiredDocuments || !copiedPerson.required_sonst_text">Beschreibung</span>
              <editfield-text [maxLength]="200" [editMode]="editModeRequiredDocuments && (editModeRequiredDocsPart === 'sonst')" [(text)]="copiedPerson.required_sonst_text"></editfield-text>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>





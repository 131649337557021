<table class="table no-marg">
  <tr>
    <td style="width: 50%;">
      <input size="20" maxlength="20" class="form-control col-sm-8" [disabled]="disabled" [value]="text" (input)="setValue($event.target.value)" placeholder="ttmmjjjj ?-+#">
    </td>
  </tr>
  <tr>
    <td>
      <span [class]="cssClass">{{dateDisplay}}</span>
    </td>
  </tr>
</table>





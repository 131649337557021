/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@asymmetrik/ngx-leaflet/dist/leaflet/core/leaflet.directive";
import * as i2 from "./map.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/common";
var styles_MapComponent = [];
var RenderType_MapComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.show() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["anzeigen"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "col-xs-12 sidebar-map"], ["id", "mapid"], ["leaflet", ""]], null, [[null, "leafletMapReady"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("leafletMapReady" === en)) {
        var pd_1 = (_co.onMapReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 606208, null, 0, i1.LeafletDirective, [i0.ElementRef, i0.NgZone], { options: [0, "options"], zoom: [1, "zoom"], center: [2, "center"] }, { mapReady: "leafletMapReady" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; var currVal_1 = _co.mapzoom; var currVal_2 = _co.mapcenter; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MapComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i0.ɵdid(1, 245760, null, 0, i2.MapComponent, [i3.MatDialog, i0.ElementRef, i4.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i0.ɵccf("app-map", i2.MapComponent, View_MapComponent_Host_0, {}, {}, []);
export { MapComponentNgFactory as MapComponentNgFactory };

<div class="card my-3">
  <div class="card-text">
    <div class="table-responsive">
      <h3>Mitarbeiter <button *ngIf="isAllowedToSendMassMail()" id="btn-open-mail" (click)="openMail()"  class="my-2 mx-2 btn btn-sm btn-warning pull-right">Email an Mitarbeiter schreiben</button></h3>
      <h5 *ngIf="!copiedUsers">keine Datensätze gefunden</h5>
      <h5 *ngIf="copiedUsers">{{resultCount}} Datensätze gefunden</h5>
      <table *ngIf="copiedUsers && !recalculating" class="table table-hover table-striped table-sm">
        <tr>
          <th>Kürzel</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Rolle</th>
          <th>Arbeitsorte</th>
          <th>Email</th>
          <th>Mobil</th>
          <th *ngIf="rightsManagementService.isAllowedToAssignSenior()">Betreuer</th>
        </tr>
        <tr (click)="onTableClicked(currentUser.id)" *ngFor="let currentUser of copiedUsers">
          <td><span class="mx-2 badge badge-pill badge-success">{{userService.getAbbreviationForUser(currentUser.id)}}</span></td>
          <td>{{currentUser.first_name}}</td>
          <td>{{currentUser.last_name}}</td>
          <td>{{currentUser.userrole.description}}</td>
          <td>{{currentUser.location}}</td>
          <td>{{currentUser.email}}</td>
          <td>{{currentUser.mobile}}</td>
          <td  *ngIf="rightsManagementService.isAllowedToAssignSenior()">
            <span *ngIf="currentUser.senior" class="mx-2 badge badge-pill badge-info">{{userService.getAbbreviationForUser(currentUser.senior)}}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

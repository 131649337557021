<h4>Mitarbeiter-Struktur</h4>

<h5>Senior- und Junior-Ermittler</h5>

<div class="card-deck">
  <div *ngFor="let sen of user_hierarchy" class="card m-3">
    <div class="card-header">{{sen.senior}}</div>
    <div *ngFor="let jun of sen.juniors" class="card-body px-3 py-1">
      {{jun.name}}
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./heir-history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./heir-history.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/case.service";
var styles_HeirHistoryComponent = [i0.styles];
var RenderType_HeirHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeirHistoryComponent, data: {} });
export { RenderType_HeirHistoryComponent as RenderType_HeirHistoryComponent };
function View_HeirHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "heir-badge badge badge-pill badge-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToHeirId(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_HeirHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeirHistoryComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.personHistory; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HeirHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeirHistoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.personHistory; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeirHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "heir-history", [], null, null, null, View_HeirHistoryComponent_0, RenderType_HeirHistoryComponent)), i1.ɵdid(1, 180224, null, 0, i3.HeirHistoryComponent, [i4.Store, i5.CaseService], null, null)], null, null); }
var HeirHistoryComponentNgFactory = i1.ɵccf("heir-history", i3.HeirHistoryComponent, View_HeirHistoryComponent_Host_0, {}, {}, []);
export { HeirHistoryComponentNgFactory as HeirHistoryComponentNgFactory };

<h3 *ngIf="nvzState && nvzState.activeVerzeichnis">Einträge
  <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || (rightsManagementService.isAllowedToEditAllNVZEntries()) || nvzState.activeVerzeichnis.users_allowed_to_edit.includes(+this.user.id)" (click)="addEintrag()" class="mx-2 btn btn-sm btn-warning">neuen Eintrag anlegen</button>
  <span style="font-size: 45%"><kbd>Einfg</kbd></span>
</h3>
<div *ngIf="nvzService.eintraegeLoaded">
  <h4 *ngIf="nvzState && nvzState.activeStandesamt && nvzState.activeVerzeichnis">für Verzeichnis {{showVerzeichnisArt(nvzState.activeVerzeichnis.art)}}, {{nvzState.activeVerzeichnis.jahrgang}}, Standesamt {{nvzState.activeStandesamt.name}}</h4>
  <div *ngIf="!nvzState || !nvzState.eintraege || nvzState.eintraege.length == 0">keine Einträge vorhanden</div>
  <div *ngIf="nvzState && nvzState.eintraege && nvzState.eintraege.length > 0">
    <table class="table table-hover table-striped table-sm">
      <tr>
        <th>Urkunden-Nr.</th>
        <th>Nachname</th>
        <th>Geburtsname</th>
        <th>Fuzzi</th>
        <th>Vornamen</th>
        <th>ZF</th>
        <th>jüd.</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <ng-container *ngFor="let eintrag of nvzState.eintraege">
        <tr *ngIf="eintrag.verzeichnis_id === nvzState.activeVerzeichnis.id">
          <td (click)="selectEintrag(eintrag)">{{eintrag.urkunden_nr}}</td>
          <td (click)="selectEintrag(eintrag)">{{eintrag.nachname}}</td>
          <td (click)="selectEintrag(eintrag)">{{eintrag.geburtsname}}</td>
          <td (click)="selectEintrag(eintrag)">{{eintrag.fuzzi}}</td>
          <td (click)="selectEintrag(eintrag)">{{eintrag.vorname}}</td>
          <td *ngIf="eintrag.zufallsfund" (click)="selectEintrag(eintrag)"><i class="fa fa-check"></i></td>
          <td *ngIf="!eintrag.zufallsfund" (click)="selectEintrag(eintrag)"><i class="fa fa-times"></i></td>
          <td *ngIf="eintrag.juedisch" (click)="selectEintrag(eintrag)"><i class="fa fa-check"></i></td>
          <td *ngIf="!eintrag.juedisch" (click)="selectEintrag(eintrag)"><i class="fa fa-times"></i></td>
          <td (click)="selectEintrag(eintrag)"><span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(eintrag.owner_id)}}</span></td>
          <td *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || nvzState.activeVerzeichnis.users_allowed_to_edit.includes(+this.user.id)" (click)="editEintrag(eintrag)"><i class="fa fa-edit"></i></td>
          <td *ngIf="!rightsManagementService.isAllowedToEditAllNVZEntries() && !nvzState.activeVerzeichnis.users_allowed_to_edit.includes(+this.user.id)"></td>
          <td *ngIf="rightsManagementService.isAdmin() || eintrag.owner_id == this.user.id" (click)="deleteEintrag(eintrag)"><i class="fa fa-trash"></i></td>
          <td *ngIf="!rightsManagementService.isAdmin() && eintrag.owner_id != this.user.id"></td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
<div *ngIf="!nvzService.eintraegeLoaded">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>

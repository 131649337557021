<div class="modal-header">
  <h4 class="modal-title ilatitle">
    Kostenposition löschen
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12"><b>Wollen Sie den Beleg {{kostenposition.belegtitel}} wirklich löschen?</b></div>
      <div class="col-12 custom-control-label">Grund für Löschung</div>
      <div class="col-12"><input type="text" maxlength="50" class="form-control" formControlName="deleted_reason" id="deleted_reason"></div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="col-12 help-block" *ngIf="form.invalid && form.controls.deleted_reason.touched">Bitte geben Sie einen Grund für die Löschung an</div>
  <div class="col-12">
    <button type="button" class="m-2 btn btn-sm btn-warning" (click)="cancel()">abbrechen</button>
    <button type="button" class="m-2 btn btn-sm btn-danger" [disabled]="form.invalid" (click)="confirm()">löschen</button>
  </div>
</div>


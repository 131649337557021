/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-docs-module.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin-docs-module.component";
var styles_AdminDocsModuleComponent = [i0.styles];
var RenderType_AdminDocsModuleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminDocsModuleComponent, data: {} });
export { RenderType_AdminDocsModuleComponent as RenderType_AdminDocsModuleComponent };
export function View_AdminDocsModuleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "card my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "ilacolor"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verwaltung Dokumentenversand"])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email-Signatur"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dokument-Kategorien verwalten"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Einzelvorlagen verwalten"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "td", [], null, null, null, null, null))], null, null); }
export function View_AdminDocsModuleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-docs-module", [], null, null, null, View_AdminDocsModuleComponent_0, RenderType_AdminDocsModuleComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdminDocsModuleComponent, [], null, null)], null, null); }
var AdminDocsModuleComponentNgFactory = i1.ɵccf("admin-docs-module", i2.AdminDocsModuleComponent, View_AdminDocsModuleComponent_Host_0, {}, {}, []);
export { AdminDocsModuleComponentNgFactory as AdminDocsModuleComponentNgFactory };

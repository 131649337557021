<div>
  <span (click)="addGroup(1)" class="pointer-target badge badge-pill badge-danger m-1">Archivmitarbeiter</span>
  <span (click)="addGroup(2)" class="pointer-target badge badge-pill badge-danger m-1">Ermittler</span>
  <span (click)="addGroup(3)" class="pointer-target badge badge-pill badge-danger m-1">Senior-Ermittler</span>
  <span (click)="addGroup(4)" class="pointer-target badge badge-pill badge-danger m-1">Verwaltung</span>
  <span (click)="addGroup(5)" class="pointer-target badge badge-pill badge-danger m-1">Administrator</span>
  <span (click)="addAll()" class="pointer-target badge badge-pill badge-danger m-1">Alle aktiven Mitarbeiter</span>
</div>
<div>
  <span *ngFor="let user of users" (click)="addUser(user)" class="pointer-target badge badge-pill badge-default m-1">{{user.abbreviation.toUpperCase()}}</span>
</div>


/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-edit-navigation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nvz-edit-navigation.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/nvz.service";
var styles_NvzEditNavigationComponent = [i0.styles];
var RenderType_NvzEditNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzEditNavigationComponent, data: {} });
export { RenderType_NvzEditNavigationComponent as RenderType_NvzEditNavigationComponent };
function View_NvzEditNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nderwahl"]))], null, null); }
function View_NvzEditNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLaenderwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nderwahl"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeLand == null) ? null : _co.nvzState.activeLand.name)); _ck(_v, 5, 0, currVal_0); }); }
function View_NvzEditNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nvzState.activeStadt.type; _ck(_v, 1, 0, currVal_0); }); }
function View_NvzEditNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLaenderwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nderwahl"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStadtwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) && (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) !== "")); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeLand == null) ? null : _co.nvzState.activeLand.name)); _ck(_v, 6, 0, currVal_0); var currVal_2 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.name)); _ck(_v, 10, 0, currVal_2); }); }
function View_NvzEditNavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nvzState.activeStadt.type; _ck(_v, 1, 0, currVal_0); }); }
function View_NvzEditNavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLaenderwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nderwahl"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStadtwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStandesamtwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) && (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) !== "")); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeLand == null) ? null : _co.nvzState.activeLand.name)); _ck(_v, 6, 0, currVal_0); var currVal_2 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.name)); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeStandesamt == null) ? null : _co.nvzState.activeStandesamt.name)); _ck(_v, 13, 0, currVal_3); }); }
function View_NvzEditNavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nvzState.activeStadt.type; _ck(_v, 1, 0, currVal_0); }); }
function View_NvzEditNavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLaenderwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4nderwahl"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStadtwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStandesamtwahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToVerzeichniswahl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", " ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) && (((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.type)) !== "")); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeLand == null) ? null : _co.nvzState.activeLand.name)); _ck(_v, 6, 0, currVal_0); var currVal_2 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeStadt == null) ? null : _co.nvzState.activeStadt.name)); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeStandesamt == null) ? null : _co.nvzState.activeStandesamt.name)); _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.showVerzeichnisArt(((_co.nvzState == null) ? null : ((_co.nvzState.activeVerzeichnis == null) ? null : _co.nvzState.activeVerzeichnis.art))); var currVal_5 = ((_co.nvzState == null) ? null : ((_co.nvzState.activeVerzeichnis == null) ? null : _co.nvzState.activeVerzeichnis.jahrgang)); _ck(_v, 16, 0, currVal_4, currVal_5); }); }
export function View_NvzEditNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "nav", [["aria-label", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzEditNavigationComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === _co.MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === _co.MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === _co.MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === _co.MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === _co.MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_NvzEditNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-edit-navigation", [], null, null, null, View_NvzEditNavigationComponent_0, RenderType_NvzEditNavigationComponent)), i1.ɵdid(1, 49152, null, 0, i3.NvzEditNavigationComponent, [i4.Store, i5.NVZService], null, null)], null, null); }
var NvzEditNavigationComponentNgFactory = i1.ɵccf("nvz-edit-navigation", i3.NvzEditNavigationComponent, View_NvzEditNavigationComponent_Host_0, { nvzState: "nvzState", ui: "ui" }, {}, []);
export { NvzEditNavigationComponentNgFactory as NvzEditNavigationComponentNgFactory };

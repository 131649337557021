<div class="m-2 p-2">
  <h4>Partner hinzufügen</h4>

  <form autocomplete="off" [formGroup]="form" class="mt-3">
    <table class="table table-sm">
      <tr>
        <td>Nachname</td>
        <td><input formControlName="nachname" type="text" id="nachname" class="form-control"></td>
      </tr>
      <tr>
        <td>Vornamen</td>
        <td><input formControlName="vorname" type="text" id="vorname" class="form-control"></td>
      </tr>
      <tr>
        <td>Geburtsname</td>
        <td><input formControlName="geburtsname" type="text" id="geburtsname" class="form-control"></td>
      </tr>
      <tr>
        <td>Geschlecht</td>
        <td><select formControlName="geschlecht" id="geschlecht" class="form-control">
          <option value="1">weiblich</option>
          <option value="2">männlich</option>
          <option [defaultSelected]=true value="0">unbekannt</option>
        </select></td>
      </tr>
      <tr>
        <td>Kommentar</td>
        <td><textarea autosize [minRows]="6" [maxRows]="30" formControlName="kommentar" id="kommentar" class="form-control"></textarea></td>
      </tr>
      <tr>
        <td>
          <input type="checkbox" id="wird_gesucht" formControlName="wird_gesucht" class="ml-2 form-check-input">
        </td>
        <td>
          <label class="form-check-label" for="wird_gesucht">wird gesucht</label>
        </td>
      </tr>
      <tr>
        <td>
          <input type="checkbox" id="sicher_keine_nachkommen" formControlName="sicher_keine_nachkommen"
                 class="ml-2 form-check-input">
        </td>
        <td>
          <label class="form-check-label" for="sicher_keine_nachkommen">hat sicher keine Nachkommen</label>
        </td>
      </tr>
      <tr>
        <td>
          <input type="checkbox" id="aussereheliche_partnerschaft" formControlName="aussereheliche_partnerschaft"
                 class="ml-2 form-check-input">
        </td>
        <td>
          <label class="form-check-label" for="aussereheliche_partnerschaft">außereheliche Partnerschaft</label>
        </td>
      </tr>
    </table>
  </form>
  <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
  <button (click)="save()" [disabled]="false" class="m-2 btn btn-sm btn-success">speichern</button>
</div>

<div class="m-2 p-2">
  <h4 class="ilatitle">{{getPersonName()}} austauschen gegen bestehenden Person-Datensatz</h4>
  <p *ngIf="!persons || persons.length === 0">keine Personen gefunden</p>
  <table class="table-responsive table-sm" *ngIf="persons && persons.length > 0">
    <tr>
      <th>Nachname</th><th>Vorname</th><th>Geburtsname</th><th>Geburtsdatum</th><th>Geburtsinfo (Ort)</th><th>Personen-Typ</th><th></th>
    </tr>
    <tr *ngFor="let p of persons">
      <td>{{p.nachname}}</td><td>{{p.vorname}}</td><td>{{p.geburtsname}}</td><td>{{printDate(p.geburtsdatum)}}</td><td>{{p.geburtsinfo}} <span *ngIf="p.geburtsland">({{p.geburtsland}})</span></td>
      <td>{{printPersonType(p)}}</td>
      <td><button class="btn btn-sm btn-warning" (click)="exchange(p)">austauschen</button></td>
    </tr>
  </table>
  <button class="btn btn-sm btn-success" (click)="close()">schliessen</button>
</div>

<div class="card my-3">
  <div class="card-body">
    <h4 class="ilacolor">Verwaltung Dokumentenversand</h4>
    <table class="table table-striped">
      <tr><td>Email-Signatur</td><td></td></tr>
      <tr><td>Dokument-Kategorien verwalten</td><td></td></tr>
      <tr><td>Einzelvorlagen verwalten</td><td></td></tr>
    </table>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-zufall.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nvz-zufall.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/nvz.service";
var styles_NvzZufallComponent = [i0.styles];
var RenderType_NvzZufallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzZufallComponent, data: {} });
export { RenderType_NvzZufallComponent as RenderType_NvzZufallComponent };
function View_NvzZufallComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.verzeichnis_id.standesamt_id.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showVerzeichnisArt(_v.context.$implicit.verzeichnis_id.art); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.verzeichnis_id.jahrgang; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.urkunden_nr; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.nachname; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.fuzzi; _ck(_v, 12, 0, currVal_5); var currVal_6 = _v.context.$implicit.geburtsname; _ck(_v, 14, 0, currVal_6); var currVal_7 = _v.context.$implicit.vorname; _ck(_v, 16, 0, currVal_7); }); }
function View_NvzZufallComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "table", [["class", "table table-hover table-striped table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Standesamt"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkunden-Art"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jahrgang"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkunden-Nr."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachname"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fuzzi"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsname"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vorname"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzZufallComponent_3)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.search_results; _ck(_v, 19, 0, currVal_0); }, null); }
function View_NvzZufallComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keine Suchergebnisse vorhanden"]))], null, null); }
function View_NvzZufallComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzZufallComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzZufallComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.search_results && (_co.search_results.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.search_results || (_co.search_results.length == 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_NvzZufallComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-lg"]], null, null, null, null, null))], null, null); }
export function View_NvzZufallComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NVZ-Zufallsfunde (die 1000 neusten werden angezeigt) "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "mx-2 btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur\u00FCck"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzZufallComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzZufallComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.search_done; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.search_done; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_NvzZufallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-zufall", [], null, null, null, View_NvzZufallComponent_0, RenderType_NvzZufallComponent)), i1.ɵdid(1, 114688, null, 0, i3.NvzZufallComponent, [i4.Store, i5.NVZService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NvzZufallComponentNgFactory = i1.ɵccf("nvz-zufall", i3.NvzZufallComponent, View_NvzZufallComponent_Host_0, { nvzState: "nvzState" }, {}, []);
export { NvzZufallComponentNgFactory as NvzZufallComponentNgFactory };

<h3 class="ilatitle">Auskehr und Abrechnung</h3>
<h4 class="ilatitle mt-3">Auskehr <button class="ml-3 btn btn-sm btn-success">neue Institution</button>
</h4>
<ng-container *ngFor="let i of institutionen">
  <table class="mb-2 table table-sm">
  <tr>
    <th class="col-2">{{i.name}} <button class="pull-right btn btn-sm btn-success">neue Quelle</button></th>
    <th class="col-2">Erwartete Summe</th>
    <th class="col-2">Erhaltene Summe</th>
    <th class="col-2">Abgeschlossen</th>
    <th [rowSpan]="i.quellen.length + 1">
      <textarea class="form-control" placeholder="Hier stehen Notizen zum Verlauf der Auskehr mit dieser Institution oder ein editierbares Textfeld"></textarea>
    </th>
  </tr>
    <tr *ngFor="let q of i.quellen">
      <td>{{q.name}}</td>
      <td>€ {{q.vermutung | number: '1.2-2'}}</td>
      <td>€ {{q.erhalten | number: '1.2-2'}}</td>
      <td><show-boolean-value [value]="q.abgeschlossen"></show-boolean-value></td>
    </tr>
  </table>
</ng-container>
<table class="table table-sm">
    <tr>
      <th class="col-2">Gesamt</th>
      <th class="col-2">Erwartet: € 49.000,00</th>
      <th class="col-2">Erhaltene: € 40.885,90</th>
      <th class="col-2"></th>
      <th></th>
    </tr>
  </table>

<h4 class="ilatitle mt-3">Abrechnung</h4>
<table class="table table-sm">
  <tr>
    <th class="col-3">Erbe</th><th class="col-1">Erbquote</th><th class="col-4">Status</th><th class="col-4">Notizen</th>
  </tr>
  <tr *ngFor="let e of erben">
    <td>{{e.name}}</td>
    <td>{{e.quote | number:'1.2-2'}}</td>
    <td>
      <span *ngIf="e.abgerechnet === true">abgeschlossen <button class="btn btn-sm btn-primary">Abrechnung anzeigen</button></span>
      <span *ngIf="e.abgerechnet !== true">offen <button class="btn btn-sm btn-primary">abrechnen</button></span>
    </td>
    <td>
      <textarea class="form-control" placeholder="Hier stehen Notizen zum Verlauf der Abrechnung mit diesem Erben oder ein editierbares Textfeld"></textarea>
    </td>
  </tr>
</table>
<button class="btn btn-sm btn-primary">Sammelabrechnung für alle offenen Erben</button>

<input set-focus type="text" class="form-control" [(ngModel)]="searchValue "(input)="search()" placeholder="Adressbuch-Auswahl: mind. 4 Zeichen eingeben">

<div *ngIf="searchResultInstitutions && (searchResultInstitutions.length > 0)">
  <div class="card p-1" *ngFor="let obj of searchResultInstitutions">
    <div class="card-body">
      <span class="mx-2 border-round pointer-target" (click)="chooseInstitution(obj)">{{obj.name}}</span>
      <span *ngFor="let person of obj.persons" class="m-2 badge badge-pill badge-warning pointer-target" (click)="choosePerson(person, obj.name)">{{person.last_name.toUpperCase()}} {{person.first_name}}</span>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-ap-result.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../tools/show-boolean-value/show-boolean-value.component.ngfactory";
import * as i3 from "../../tools/show-boolean-value/show-boolean-value.component";
import * as i4 from "@angular/common";
import * as i5 from "./nvz-ap-result.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../../services/autoparser.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
var styles_NvzApResultComponent = [i0.styles];
var RenderType_NvzApResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzApResultComponent, data: {} });
export { RenderType_NvzApResultComponent as RenderType_NvzApResultComponent };
function View_NvzApResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "show-boolean-value", [], null, null, null, i2.View_ShowBooleanValueComponent_0, i2.RenderType_ShowBooleanValueComponent)), i1.ɵdid(11, 49152, null, 0, i3.ShowBooleanValueComponent, [], { value: [0, "value"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "fa fa-pencil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "span", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.show(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "fa fa-eye"]], null, null, null, null, null))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.juedisch; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.nachname; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.geburtsname; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.vorname; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.urkunden_nr; _ck(_v, 8, 0, currVal_3); var currVal_5 = _v.context.$implicit.row_count; _ck(_v, 13, 0, currVal_5); }); }
function View_NvzApResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "table", [["class", "table table-sm table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachname"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Geburtsname"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vornamen"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urkunden-Nr."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["j\u00FCdisch"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Zeilen-Nr"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApResultComponent_2)), i1.ɵdid(18, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoparserState.postprocessedEntries; _ck(_v, 18, 0, currVal_0); }, null); }
export function View_NvzApResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h4", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Eintragungen f\u00FCr Verzeichnis ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-sm btn-warning mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur\u00FCck"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApResultComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.autoparserState && _co.autoparserState.postprocessedEntries) && (_co.autoparserState.postprocessedEntries.length > 0)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.autoparserState.activeDir == null) ? null : _co.autoparserState.activeDir.name); _ck(_v, 1, 0, currVal_0); }); }
export function View_NvzApResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-ap-result-list", [], null, null, null, View_NvzApResultComponent_0, RenderType_NvzApResultComponent)), i1.ɵdid(1, 245760, null, 0, i5.NvzApResultComponent, [i6.Store, i7.AutoparserService, i8.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NvzApResultComponentNgFactory = i1.ɵccf("nvz-ap-result-list", i5.NvzApResultComponent, View_NvzApResultComponent_Host_0, {}, {}, []);
export { NvzApResultComponentNgFactory as NvzApResultComponentNgFactory };

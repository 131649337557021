<div class="m-2 p-2">
  <h4>Stammbaum-Versionen</h4>

  <table class="table table-sm" *ngIf="stammbaumState?.stammbaumVersions?.length > 0">
    <tr><th>Version</th><th>Anmerkung</th><th>erstellt von</th><th>zuletzt geändert von</th><th>letzte Änderung</th><th></th></tr>
    <tr *ngFor="let version of stammbaumState?.stammbaumVersions" [ngClass]="{selected: version?.id === stammbaumState?.stammbaum?.version?.id}">
      <td>{{version.title}}</td>
      <td>{{version.comment}}</td>
      <td>{{version.created_by}}</td>
      <td>{{version.last_changed_by}}</td>
      <td>{{version.updated}}</td>
      <td *ngIf="version?.id === stammbaumState?.stammbaum?.version?.id" class="bol">ausgewählt</td>
      <td *ngIf="version?.id !== stammbaumState?.stammbaum?.version?.id"><button (click)="changeStb(version)" class="btn btn-sm btn-success">auswählen</button></td>
    </tr>
  </table>

  <span>
    <button (click)="createStb('eo2')" class="btn btn-sm btn-warning mx-1">2. EO neu anlegen</button>
    <button (click)="createStb('eo3')" class="btn btn-sm btn-warning mx-1">3. EO neu anlegen</button>
    <button (click)="createStb('eo4')" class="btn btn-sm btn-warning mx-1">4. EO neu anlegen</button>
  </span>

  <div></div>
  <button class="btn btn-sm btn-success my-2" (click)="close()">schliessen</button>
</div>

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as GeneratedDocumentActions from './generated-document.actions';
import { Store } from '@ngrx/store';
import { GeneratedDocumentService } from "../services/generated-document.service";
export class GeneratedDocumentEffects {
    constructor(actions, generateDocumentService, store) {
        this.actions = actions;
        this.generateDocumentService = generateDocumentService;
        this.store = store;
        this.reloadDocumentCategories = this.actions.pipe(ofType(GeneratedDocumentActions.TRIGGER_RELOAD_GENERATED_DOCUMENT_CATEGORIES), (tap(() => {
            this.generateDocumentService.reloadCategories();
        })));
    }
}
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], GeneratedDocumentEffects.prototype, "reloadDocumentCategories", void 0);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addressbook-autocomplete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./addressbook-autocomplete.component";
import * as i5 from "../../services/addressbook.service";
import * as i6 from "../../services/rights-management.service";
import * as i7 from "../../routing/routing.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "@ngrx/store";
var styles_AddressbookAutocompleteComponent = [i0.styles];
var RenderType_AddressbookAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressbookAutocompleteComponent, data: {} });
export { RenderType_AddressbookAutocompleteComponent as RenderType_AddressbookAutocompleteComponent };
function View_AddressbookAutocompleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "form-control"], ["readonly", "readonly"], ["type", "text"]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.fieldValueText, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AddressbookAutocompleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-trash"]], null, null, null, null, null))], null, null); }
function View_AddressbookAutocompleteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-info"]], null, null, null, null, null))], null, null); }
function View_AddressbookAutocompleteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["style", "margin-left: 0; padding-left: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.fieldValueText != ""); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldValueText; _ck(_v, 1, 0, currVal_0); }); }
function View_AddressbookAutocompleteComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["class", "form-control"], ["placeholder", "mind. 3 Zeichen eingeben"], ["set-focus", ""], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.search() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchValue; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AddressbookAutocompleteComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showInputField; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressbookAutocompleteComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [["r", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["style", "background-color: indianred;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["neuen Eintrag anlegen"]))], null, null); }
function View_AddressbookAutocompleteComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.choose(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_AddressbookAutocompleteComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "table", [["class", "table table-sm table-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_9)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAllowedToCreate; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.searchResultInstitutions; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AddressbookAutocompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressbookAutocompleteComponent_7)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.editMode; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.searched; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_AddressbookAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "addressbook-autocomplete", [], null, null, null, View_AddressbookAutocompleteComponent_0, RenderType_AddressbookAutocompleteComponent)), i1.ɵdid(1, 638976, null, 0, i4.AddressbookAutocompleteComponent, [i5.AddressbookService, i6.RightsManagementService, i7.RoutingService, i8.NgbModal, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressbookAutocompleteComponentNgFactory = i1.ɵccf("addressbook-autocomplete", i4.AddressbookAutocompleteComponent, View_AddressbookAutocompleteComponent_Host_0, { editMode: "editMode", fieldValue: "fieldValue" }, { fieldValueChange: "fieldValueChange" }, []);
export { AddressbookAutocompleteComponentNgFactory as AddressbookAutocompleteComponentNgFactory };

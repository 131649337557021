/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./show-date.component";
var styles_ShowDateComponent = [];
var RenderType_ShowDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShowDateComponent, data: {} });
export { RenderType_ShowDateComponent as RenderType_ShowDateComponent };
function View_ShowDateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color: green; font-weight: bold;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ".", ".", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value.substring(0, 2); var currVal_1 = _co.value.substring(2, 4); var currVal_2 = _co.value.substring(4, 8); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ShowDateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-remove"], ["style", "color: red;"]], null, null, null, null, null))], null, null); }
function View_ShowDateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "fa fa-question"], ["style", "color: orange;"]], null, null, null, null, null))], null, null); }
export function View_ShowDateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShowDateComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShowDateComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShowDateComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value && (_co.value.length == 8)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.value || (_co.value == "false")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.value && !(_co.value.length == 8)) && (_co.value != "false")) && (_co.value != "")); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ShowDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "show-date", [], null, null, null, View_ShowDateComponent_0, RenderType_ShowDateComponent)), i0.ɵdid(1, 49152, null, 0, i2.ShowDateComponent, [], null, null)], null, null); }
var ShowDateComponentNgFactory = i0.ɵccf("show-date", i2.ShowDateComponent, View_ShowDateComponent_Host_0, { value: "value" }, {}, []);
export { ShowDateComponentNgFactory as ShowDateComponentNgFactory };

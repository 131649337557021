/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-exchange-marriage.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stammbaum-exchange-marriage.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/case.service";
import * as i7 from "../../tools/show-date-pipe/show-date-pipe";
import * as i8 from "../../services/stammbaum.service";
var styles_StammbaumExchangeMarriageComponent = [i0.styles];
var RenderType_StammbaumExchangeMarriageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumExchangeMarriageComponent, data: {} });
export { RenderType_StammbaumExchangeMarriageComponent as RenderType_StammbaumExchangeMarriageComponent };
function View_StammbaumExchangeMarriageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keine Eheschliessung gefunden"]))], null, null); }
function View_StammbaumExchangeMarriageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-sm btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exchange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["austauschen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPersonName(_v.context.$implicit.persons[0]); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getPersonName(_v.context.$implicit.persons[1]); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.printDate(_v.context.$implicit.date); _ck(_v, 6, 0, currVal_2); }); }
function View_StammbaumExchangeMarriageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "table", [["class", "table-responsive table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Partner 1"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Partner 2"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Datum Eheschliessung"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangeMarriageComponent_3)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.marriages; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_StammbaumExchangeMarriageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " austauschen gegen bestehenden Datensatz"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangeMarriageComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumExchangeMarriageComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["schliessen"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.marriages || (_co.marriages.length === 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.marriages && (_co.marriages.length > 0)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.printMarriage(); _ck(_v, 2, 0, currVal_0); }); }
export function View_StammbaumExchangeMarriageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-select-marriage", [], null, null, null, View_StammbaumExchangeMarriageComponent_0, RenderType_StammbaumExchangeMarriageComponent)), i1.ɵdid(1, 245760, null, 0, i3.StammbaumExchangeMarriageComponent, [i4.NgbActiveModal, i5.Store, i6.CaseService, i7.ShowDatePipe, i8.StammbaumService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumExchangeMarriageComponentNgFactory = i1.ɵccf("stammbaum-select-marriage", i3.StammbaumExchangeMarriageComponent, View_StammbaumExchangeMarriageComponent_Host_0, {}, {}, []);
export { StammbaumExchangeMarriageComponentNgFactory as StammbaumExchangeMarriageComponentNgFactory };

<div class="modal-header">
  <h4 class="modal-title ilatitle">Institution für Auskehr hinzufügen</h4>
</div>

<div class="mx-2">
  <label for="inst" class="control-label"><b>Bezeichnung</b></label>
   <addressbook-autocomplete id="inst" [editMode]="true" [(fieldValue)]="institutionId"></addressbook-autocomplete>
</div>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="save()" [disabled]="!institutionId || saving" type="button" class="m-2 btn btn-success pull-right">speichern</button>
</div>


<div class="modal-header">
  <h4 *ngIf="calendarEntry" class="modal-title">
    {{calendarEntry?.title}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="calendarEntry" class="modal-body">
  <button *ngIf="calendarEntry.case" type="button" class="m-2 btn btn-sm btn-success" (click)="goToCase()">direkt zum Fall</button>
  <button *ngIf="+calendarEntry.person" type="button" class="m-2 btn btn-sm btn-warning" (click)="goToPerson()">direkt zur Person</button>
  <button *ngIf="calendarEntry.note" type="button" class="m-2 btn btn-sm btn-primary" (click)="goToNote()">direkt zur Notiz</button>
  <p>
    Erstellt für<span class="mx-1 badge badge-pill badge-success">{{userService.getAbbreviationForUser(calendarEntry.for_user_id)}}</span>
    von<span class="mx-1 badge badge-pill badge-warning">{{userService.getAbbreviationForUser(calendarEntry.created_by_user_id)}}</span>
  </p>
  <p>
    Termin: {{calendarEntry.deadline_timestamp | date: 'dd.MM.y'}} {{calendarEntryService.convertTimeForOutputWithText(calendarEntry.deadline_hour, calendarEntry.deadline_minute)}} (erstellt am: {{calendarEntry.created_timestamp | date: 'dd.MM.y'}})
  </p>
  <p>Details:<br>{{calendarEntry?.message}}</p>
  <p>
    <button *ngIf="!calendarEntry?.completed" (click)="setCalendarEntryDone()" type="button" class="btn btn-outline-warning">Termin erledigt setzen</button>
    <button *ngIf="calendarEntry?.completed" (click)="setCalendarEntryOpen()" type="button" class="btn btn-outline-danger">Termin wieder aktivieren</button>
    <button (click)="editCalendarEntry()" type="button" class="btn btn-outline-primary pull-right">Termin bearbeiten</button>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="activeModal.close('Close click')">schliessen</button>
</div>

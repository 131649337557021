/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-notification-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-notification-popup.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@ngrx/store";
var styles_UserNotificationPopupComponent = [i0.styles];
var RenderType_UserNotificationPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserNotificationPopupComponent, data: {} });
export { RenderType_UserNotificationPopupComponent as RenderType_UserNotificationPopupComponent };
function View_UserNotificationPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_UserNotificationPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserNotificationPopupComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserNotificationPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "schlepptheme"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aktuelle Benutzungshinweise"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserNotificationPopupComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Hinweise zur Kenntnis genommen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.messages && (_co.messages.length > 0)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_UserNotificationPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-notifiction-popup", [], null, null, null, View_UserNotificationPopupComponent_0, RenderType_UserNotificationPopupComponent)), i1.ɵdid(1, 245760, null, 0, i3.UserNotificationPopupComponent, [i4.NgbActiveModal, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserNotificationPopupComponentNgFactory = i1.ɵccf("user-notifiction-popup", i3.UserNotificationPopupComponent, View_UserNotificationPopupComponent_Host_0, {}, {}, []);
export { UserNotificationPopupComponentNgFactory as UserNotificationPopupComponentNgFactory };

export const environment = {
  production: true,
  apiServer: 'https://db.ilanot.de/',
  // apiServer: 'https://ilanot-prod-new.fuzztest.de/',
  fileUploadServer: 'https://db.ilanot.de/',
  // fileUploadServer: 'https://ilanot-prod-new.fuzztest.de/',
  websocketServer: 'wss://db.ilanot.de/',
  // websocketServer: 'wss://ilanot-prod-new.fuzztest.de/',
  isTest: false,
  showDebugMessages: false,
  enableServiceWorker: false
};


<span *ngIf="stammbaumState && stammbaumState.stammbaumDisplay && stammbaumState.stammbaumDisplay.showKasten && !media_fix_controls" class="pos-ri">
  <button class="m-1 btn btn-sm btn-primary" (click)="stammbaumService.toggleKasten()"><span class="btw fa fa-lg fa-caret-square-o-down"></span></button>
  <button class="m-1 btn btn-sm btn-warning" (click)="stammbaumService.zoomIn()"><span class="btw fa fa-lg fa-plus-circle"></span></button>
  <button class="m-1 btn btn-sm btn-warning" (click)="stammbaumService.zoomOut()"><span class="btw fa fa-lg fa-minus-circle"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'edit-person-data'}" *ngIf="rightsManagementService.isAllowedToEditStammbaumTreeContent()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToPersonData()"><span class="btw fa fa-lg fa-user"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'edit-structure'}" *ngIf="rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToStructure()"><span class="btw fa fa-lg fa-code-fork"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'show-extra-info'}" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToExtraInfo()"><span class="btw fa fa-lg fa-info-circle"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'morph-structure'}" *ngIf="rightsManagementService.isAllowedToExchangeStammbaumPersonsAndMarriages()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToMorphStructure()"><span class="btw fa fa-lg fa-cogs"></span></button>
  <button class="m-1 btn btn-sm btn-success" *ngIf="rightsManagementService.isAllowedToChangeStammbaumSettings()" (click)="changeTreeSettings()"><span class="btw fa fa-lg fa-wrench"></span></button>
  <button class="m-1 btn btn-sm btn-primary" (click)="toCheckpoints()"><span class="btw fa fa-lg fa-floppy-o"></span></button>
  <button class="m-1 btn btn-sm btn-primary" (click)="generatePdf()"><span class="btw fa fa-lg fa-print"></span></button>
</span>
<stammbaum-tree-map-svg [stammbaumState]="stammbaumState" *ngIf="stammbaumState && stammbaumState.stammbaumDisplay && stammbaumState.stammbaumDisplay.showKasten && !media_fix_controls"></stammbaum-tree-map-svg>

<div *ngIf="stammbaumState && (!stammbaumState.stammbaumDisplay || !stammbaumState.stammbaumDisplay.showKasten || media_fix_controls)" class="pos-ri-off">
  <button *ngIf="!media_fix_controls" class="m-1 btn btn-sm btn-primary" (click)="stammbaumService.toggleKasten()"><span class="btw fa fa-lg fa-caret-square-o-up"></span></button>
  <button class="m-1 btn btn-sm btn-warning" (click)="stammbaumService.zoomIn()"><span class="btw fa fa-lg fa-plus-circle"></span></button>
  <button class="m-1 btn btn-sm btn-warning" (click)="stammbaumService.zoomOut()"><span class="btw fa fa-lg fa-minus-circle"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'edit-person-data'}" *ngIf="rightsManagementService.isAllowedToEditStammbaumTreeContent()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToPersonData()"><span class="btw fa fa-lg fa-user"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'edit-structure'}" *ngIf="rightsManagementService.isAllowedToChangeStammbaumTreeStructureAndComments()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToStructure()"><span class="btw fa fa-lg fa-code-fork"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'show-extra-info'}" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToExtraInfo()"><span class="btw fa fa-lg fa-info-circle"></span></button>
  <button [ngClass]="{'btn-active-d': stammbaumState?.treeViewMode === 'morph-structure'}" *ngIf="rightsManagementService.isAllowedToExchangeStammbaumPersonsAndMarriages()" class="m-1 btn btn-sm btn-success" (click)="stammbaumService.editModeToMorphStructure()"><span class="btw fa fa-lg fa-cogs"></span></button>
  <button class="m-1 btn btn-sm btn-success" *ngIf="rightsManagementService.isAllowedToChangeStammbaumSettings()" (click)="changeTreeSettings()"><span class="btw fa fa-lg fa-wrench"></span></button>
  <button class="m-1 btn btn-sm btn-primary" (click)="toCheckpoints()"><span class="btw fa fa-lg fa-floppy-o"></span></button>
  <button class="m-1 btn btn-sm btn-primary" (click)="generatePdf()"><span class="btw fa fa-lg fa-print"></span></button>
</div>

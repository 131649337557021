<div class="card mod" *ngIf="!current_row">
  <h4 class="title" *ngIf="dir">Verzeichnis {{dir.name}}</h4>
  <p>Ergebnisse werden geladen...</p>
  <p *ngIf="server_fehler">{{server_fehler}}</p>
  <button class="btn btn-sm btn-danger" (click)="close()">schliessen</button>
</div>

<div *ngIf="current_row" class="card mod">
  <h4 class="title" *ngIf="dir">Verzeichnis {{dir.name}}
    <span class="badge badge-primary mx-2">Zeile {{current_row.row_count}} von {{dir.nr_of_rows}}</span>
    <div *ngIf="current_row.row_error" class="badge badge-danger mx-2">{{current_row.row_error}}</div>
    <button type="button" (click)="openPageSettings()" class="btn btn-sm btn-warning mx-2">Seiteneinstellungen</button>
    <button type="button" (click)="openList()" class="btn btn-sm btn-warning mx-2">Liste bearbeiten</button>
    <button type="button" class="btn btn-sm btn-info mx-2" (click)="export()">Ergebnisse exportieren</button>
    <button type="button" class="btn btn-sm btn-warning mx-2" (click)="close()">schliessen</button>
    <button type="button" class="btn btn-sm btn-danger mx-2" (click)="endThisPage()">Seite beenden</button>
  </h4>

  <form [formGroup]="editForm">

    <div class="row">
      <div class="col-md-8">
        <ng-container *ngFor="let col of current_row.columns; let i = index">
          <div class="row">
            <div class="col-md-1">{{i}}</div>
            <div class="col-md-5">
              <img class="col-image" [src]="'https://db.ilanot.de/backend/autoparser/view-image/?filename=row-' + current_row.row_count + '-column-' + col.column_count + '.jpg&document=' + dir.name + '&folder=partial-pics'">
            </div>
            <div class="col-md-3">
              <div class="py-2" *ngIf="column_settings.columnVorname === i">Vorname</div>
              <div class="py-2" *ngIf="column_settings.columnNachname === i">Nachname</div>
              <div class="py-2" *ngIf="column_settings.columnGeburtsname === i || ((column_settings.columnNachname === i) && (column_settings.columnGeburtsname === -2))">Geburtsname</div>
              <div class="py-2" *ngIf="column_settings.columnUrkundennummer === i">Urkunden-Nummer</div>
              <div class="py-2" *ngIf="column_settings.columnJuedisch === i">jüdisch</div>
            </div>
            <div class="col-md-3">
              <input #vorname type="text" *ngIf="column_settings.columnVorname === i" formControlName="vorname" class="form-control" [ngClass]="{'warning': editForm.controls.vorname.invalid}">
              <input id="nachname" type="text" *ngIf="column_settings.columnNachname === i" formControlName="nachname" class="form-control" [ngClass]="{'warning': editForm.controls.nachname.invalid}">
              <input #geburtsname type="text" *ngIf="column_settings.columnGeburtsname === i || ((column_settings.columnNachname === i) && (column_settings.columnGeburtsname === -2))" formControlName="geburtsname" class="form-control" [ngClass]="{'warning': editForm.controls.geburtsname.invalid}">
              <input (focus)="resetExisting()" (blur)="checkExisting()" #urkunden_nr type="text" *ngIf="column_settings.columnUrkundennummer === i" formControlName="urkunden_nr" class="form-control" [ngClass]="{'warning': editForm.controls.urkunden_nr.invalid}">
              <input #juedisch type="checkbox" *ngIf="column_settings.columnJuedisch === i" formControlName="juedisch" class="form-control">
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="column_settings.columnVorname === -1">
          <div class="col-md-6"></div>
          <div class="col-md-3">Vorname</div>
          <div class="col-md-3"><input #vorname type="text" formControlName="vorname" class="form-control" [ngClass]="{'warning': editForm.controls.vorname.invalid}"></div>
        </div>
        <div class="row" *ngIf="column_settings.columnNachname === -1">
          <div class="col-md-6"></div>
          <div class="col-md-3">Nachname</div>
          <div class="col-md-3"><input id="nachname_2" type="text" formControlName="nachname" class="form-control" [ngClass]="{'warning': editForm.controls.nachname.invalid}"></div>
        </div>
        <div class="row" *ngIf="column_settings.columnGeburtsname === -1">
          <div class="col-md-6"></div>
          <div class="col-md-3">Geburtsname</div>
          <div class="col-md-3"><input #geburtsname type="text" formControlName="geburtsname" class="form-control" [ngClass]="{'warning': editForm.controls.geburtsname.invalid}"></div>
        </div>
        <div class="row" *ngIf="column_settings.columnUrkundennummer === -1">
          <div class="col-md-6"></div>
          <div class="col-md-3">Urkunden-Nr.</div>
          <div class="col-md-3"><input (focus)="resetExisting()" (blur)="checkExisting()" #urkunden_nr type="text" formControlName="urkunden_nr" class="form-control" [ngClass]="{'warning': editForm.controls.urkunden_nr.invalid}"></div>
        </div>
        <div class="row" *ngIf="column_settings.columnJuedisch === -1">
          <div class="col-md-6"></div>
          <div class="col-md-3">jüdisch (ALT+J)</div>
          <div class="col-md-3"><input type="checkbox" formControlName="juedisch" class="form-control mt-2"></div>
        </div>

        <div *ngIf="entriesWithSameNumber&&entriesWithSameNumber.length>0">
          <h4 style="color: red;">Achtung, es gibt bereits folgende Einträge mit dieser Urkunden-Nummer:</h4>
          <table class="table table-hover table-striped table-sm">
            <tr *ngFor="let entry of entriesWithSameNumber">
              <td>{{entry.urkunden_nr}}</td>
              <td>{{entry.nachname}}</td>
              <td>{{entry.geburtsname}}</td>
              <td>{{entry.vorname}}</td>
            </tr>
          </table>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button type="button" tabindex="5" class="btn btn-sm btn-success m-2" (click)="next(true, true)">eintragen (Shift+ENTER)</button>
            <button type="button" tabindex="6" class="btn btn-sm btn-warning m-2" (click)="next(true, false)">weiterer Eintrag (ALT+W)</button>
            <button type="button" tabindex="7" class="btn btn-sm btn-danger m-2" (click)="next(false, true)">ohne Eintrag weiter (CTRL+I)</button>
            <button type="button" tabindex="8" class="btn btn-sm btn-primary m-2" (click)="empty()">leeren (CTRL+C)</button>
          </div>
        </div>
        <table *ngIf="last_nvz_entries && last_nvz_entries.length > 0" class="table table-sm">
          <tr>
            <th>Zeile</th>
            <th>Nachname</th>
            <th>Geburtsname</th>
            <th>Vorname</th>
            <th>Urkunden-Nr.</th>
            <th>jüdisch</th>
          </tr>
          <tr *ngFor="let entry of last_nvz_entries.slice().reverse()">
            <td>{{entry.row_count}}</td>
            <td>{{entry.nachname}}</td>
            <td>{{entry.geburtsname}}</td>
            <td>{{entry.vorname}}</td>
            <td>{{entry.urkunden_nr}}</td>
            <td><show-boolean-value [value]="entry.juedisch"></show-boolean-value></td>
          </tr>
        </table>
      </div>

      <div class="col-md-4">
        <ngx-image-viewer [src]="filename_row_highlight_image"></ngx-image-viewer>
      </div>
    </div>

    <div style="width: 100%; height: 25px;"></div>
    <ngx-image-viewer [src]="filename_row_image"></ngx-image-viewer>
    <div style="width: 100%; height: 25px;"></div>
  </form>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-map-svg.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../stammbaum-tree-marriage-connection/stammbaum-tree-marriage-connection.component.ngfactory";
import * as i3 from "../stammbaum-tree-marriage-connection/stammbaum-tree-marriage-connection.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/stammbaum-display.service";
import * as i7 from "../stammbaum-tree-children-connection/stammbaum-tree-children-connection.component.ngfactory";
import * as i8 from "../stammbaum-tree-children-connection/stammbaum-tree-children-connection.component";
import * as i9 from "../stammbaum-tree-person/stammbaum-tree-person.component.ngfactory";
import * as i10 from "../stammbaum-tree-person/stammbaum-tree-person.component";
import * as i11 from "../../tools/show-date-pipe/show-date-pipe";
import * as i12 from "../../services/stammbaum.service";
import * as i13 from "../../services/case.service";
import * as i14 from "@angular/common";
import * as i15 from "./stammbaum-tree-map-svg.component";
var styles_StammbaumTreeMapSvgComponent = [i0.styles];
var RenderType_StammbaumTreeMapSvgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeMapSvgComponent, data: {} });
export { RenderType_StammbaumTreeMapSvgComponent as RenderType_StammbaumTreeMapSvgComponent };
function View_StammbaumTreeMapSvgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-marriage-connection", ""]], null, null, null, i2.View_StammbaumTreeMarriageConnectionComponent_0, i2.RenderType_StammbaumTreeMarriageConnectionComponent)), i1.ɵdid(2, 180224, null, 0, i3.StammbaumTreeMarriageConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], { treeViewMode: [0, "treeViewMode"], m: [1, "m"], version: [2, "version"], mapOnly: [3, "mapOnly"], p1: [4, "p1"], p2: [5, "p2"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._stammbaumState.treeViewMode; var currVal_1 = _v.context.$implicit.m; var currVal_2 = ((_co._stammbaumState == null) ? null : _co._stammbaumState.stammbaum.version); var currVal_3 = true; var currVal_4 = _v.context.$implicit.p1; var currVal_5 = _v.context.$implicit.p2; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_StammbaumTreeMapSvgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-children-connection", ""]], null, null, null, i7.View_StammbaumTreeChildrenConnectionComponent_0, i7.RenderType_StammbaumTreeChildrenConnectionComponent)), i1.ɵdid(2, 180224, null, 0, i8.StammbaumTreeChildrenConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], { treeViewMode: [0, "treeViewMode"], version: [1, "version"], mapOnly: [2, "mapOnly"], p: [3, "p"], c: [4, "c"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._stammbaumState.treeViewMode; var currVal_1 = ((_co._stammbaumState == null) ? null : _co._stammbaumState.stammbaum.version); var currVal_2 = true; var currVal_3 = _v.context.$implicit.p; var currVal_4 = _v.context.$implicit.c; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_StammbaumTreeMapSvgComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-person", ""]], null, null, null, i9.View_StammbaumTreePersonComponent_0, i9.RenderType_StammbaumTreePersonComponent)), i1.ɵdid(2, 4964352, null, 0, i10.StammbaumTreePersonComponent, [i4.Store, i11.ShowDatePipe, i12.StammbaumService, i6.StammbaumDisplayService, i5.NgbModal, i13.CaseService], { person: [0, "person"], version: [1, "version"], treeViewMode: [2, "treeViewMode"], mapOnly: [3, "mapOnly"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co._stammbaumState == null) ? null : _co._stammbaumState.stammbaum.version); var currVal_2 = _co._stammbaumState.treeViewMode; var currVal_3 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_StammbaumTreeMapSvgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, ":svg:svg", [[":xmlns:svg", "http://www.w3.org/1999/XSL/Transform"], ["class", "stb-main"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0], [1, "viewBox", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMapSvgComponent_2)), i1.ɵdid(2, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMapSvgComponent_3)), i1.ɵdid(4, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMapSvgComponent_4)), i1.ɵdid(6, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:rect", [["style", "fill:rgb(255,255,0);fill-opacity:0.35;stroke-width:4;stroke-opacity:0.9;stroke:rgb(255,0,0)"]], [[1, "width", 0], [1, "height", 0], [1, "x", 0], [1, "y", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.connections_m; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.connections_c; _ck(_v, 4, 0, currVal_4); var currVal_5 = ((_co._stammbaumState == null) ? null : _co._stammbaumState.stammbaum.persons); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; var currVal_2 = _co.getViewBox(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _co.scrollPos.width; var currVal_7 = _co.scrollPos.height; var currVal_8 = _co.scrollPos.x; var currVal_9 = _co.scrollPos.y; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_StammbaumTreeMapSvgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeMapSvgComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.width > 0) && (_co.height > 0)) && _co.original_height) && _co.original_width); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_StammbaumTreeMapSvgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-tree-map-svg", [], null, null, null, View_StammbaumTreeMapSvgComponent_0, RenderType_StammbaumTreeMapSvgComponent)), i1.ɵdid(1, 245760, null, 0, i15.StammbaumTreeMapSvgComponent, [i6.StammbaumDisplayService, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumTreeMapSvgComponentNgFactory = i1.ɵccf("stammbaum-tree-map-svg", i15.StammbaumTreeMapSvgComponent, View_StammbaumTreeMapSvgComponent_Host_0, { stammbaumState: "stammbaumState" }, {}, []);
export { StammbaumTreeMapSvgComponentNgFactory as StammbaumTreeMapSvgComponentNgFactory };

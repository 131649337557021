/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-ap-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../nvz-ap-messages/nvz-ap-messages.component.ngfactory";
import * as i4 from "../nvz-ap-messages/nvz-ap-messages.component";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "../nvz-ap-edit-results/nvz-ap-edit-results.component.ngfactory";
import * as i8 from "../nvz-ap-edit-results/nvz-ap-edit-results.component";
import * as i9 from "../../services/autoparser.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "../../services/constants.service";
import * as i12 from "../nvz-ap-result-list/nvz-ap-result.component.ngfactory";
import * as i13 from "../nvz-ap-result-list/nvz-ap-result.component";
import * as i14 from "./nvz-ap-entry.component";
var styles_NvzApEntryComponent = [i0.styles];
var RenderType_NvzApEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NvzApEntryComponent, data: {} });
export { RenderType_NvzApEntryComponent as RenderType_NvzApEntryComponent };
function View_NvzApEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h4", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NVZ-Autoparser "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "mx-2 btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.end() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["zur\u00FCck zur NVZ-Suche"]))], null, null); }
function View_NvzApEntryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_NvzApEntryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nvz-ap-messages", [["class", "card"]], null, null, null, i3.View_NvzApMessagesComponent_0, i3.RenderType_NvzApMessagesComponent)), i1.ɵdid(3, 180224, null, 0, i4.NvzApMessagesComponent, [i5.Store], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 34, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 33, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "card-header background-autoparser"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kommandos"])), (_l()(), i1.ɵeld(8, 0, null, null, 30, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 29, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(11, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["class", "form-control"], ["disabled", ""], ["type", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 11, "select", [["class", "form-control my-2"], ["formControlName", "directory"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(18, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(20, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 3, "option", [["value", "-1"]], null, null, null, null, null)), i1.ɵdid(22, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(23, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["kein Verzeichnis ausgew\u00E4hlt"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApEntryComponent_3)), i1.ɵdid(26, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"], ["disabled", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startPreprocessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Preprocessing"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"], ["disabled", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOCR() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Texterkennung"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startPostprocessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachbearbeitung"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"], ["disabled", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Kontroll-Liste"])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"], ["disabled", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFirstNames() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Vornamen-Liste"])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "button", [["class", "m-2 btn btn-sm btn-info"], ["disabled", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showLastNames() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Nachnamen-Liste"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.actionForm; _ck(_v, 11, 0, currVal_7); var currVal_15 = "directory"; _ck(_v, 18, 0, currVal_15); var currVal_16 = "-1"; _ck(_v, 22, 0, currVal_16); var currVal_17 = "-1"; _ck(_v, 23, 0, currVal_17); var currVal_18 = _co.directories; _ck(_v, 26, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 20).ngClassValid; var currVal_13 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_19 = !_co.actionForm.valid; _ck(_v, 31, 0, currVal_19); }); }
function View_NvzApEntryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-ap-edit-results", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_NvzApEditResultsComponent_0, i7.RenderType_NvzApEditResultsComponent)), i1.ɵdid(1, 4440064, null, 0, i8.NvzApEditResultsComponent, [i9.AutoparserService, i10.NgbModal, i11.ConstantsService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_NvzApEntryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-ap-result-list", [], null, null, null, i12.View_NvzApResultComponent_0, i12.RenderType_NvzApResultComponent)), i1.ɵdid(1, 245760, null, 0, i13.NvzApResultComponent, [i5.Store, i9.AutoparserService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_NvzApEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApEntryComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApEntryComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApEntryComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NvzApEntryComponent_5)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activePage === "entry"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.activePage === "entry"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.activePage === "postprocess"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.activePage === "list"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_NvzApEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-ap-entry", [], null, null, null, View_NvzApEntryComponent_0, RenderType_NvzApEntryComponent)), i1.ɵdid(1, 245760, null, 0, i14.NvzApEntryComponent, [i5.Store, i9.AutoparserService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NvzApEntryComponentNgFactory = i1.ɵccf("nvz-ap-entry", i14.NvzApEntryComponent, View_NvzApEntryComponent_Host_0, {}, {}, []);
export { NvzApEntryComponentNgFactory as NvzApEntryComponentNgFactory };

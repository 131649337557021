<div class="m-2 p-2">
  <h3 *ngIf="!editMode">Stadt/Kreis anlegen</h3>
  <h3 *ngIf="editMode">Stadt/Kreis bearbeiten</h3>

  <form [formGroup]="form" class="form-horizontal">

    <div class="form-group row">
      <label for="name" class="col-sm-3 control-label">Name der Stadt/des Kreises</label>
      <div class="col-sm-9">
        <input #name formControlName="name" type="text" id="name" class="form-control">
        <span *ngIf="form.get('name').invalid && form.get('name').touched" class="help-block">Bitte einen Stadt-Namen mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="type" class="col-sm-3 control-label">Art</label>
      <div class="col-sm-9">
        <input #type formControlName="type" type="text" id="type" class="form-control">
        <span *ngIf="form.get('type').invalid && form.get('type').touched" class="help-block">Bitte die Art mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="bundesland" class="col-sm-3 control-label">Bundesland</label>
      <div class="col-sm-9">
        <input #bundesland formControlName="bundesland" type="text" id="bundesland" class="form-control">
        <span *ngIf="form.get('bundesland').invalid && form.get('bundesland').touched" class="help-block">Bitte das Bundesland mit maximal 30 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="gemeindeschluessel" class="col-sm-3 control-label">AGS (Stellen 1-5)</label>
      <div class="col-sm-9">
        <input #gemeindeschluessel formControlName="gemeindeschluessel" type="text" id="gemeindeschluessel" class="form-control">
        <span *ngIf="form.get('gemeindeschluessel').invalid && form.get('gemeindeschluessel').touched" class="help-block">Bitte einen Gemeindeschlüssel mit maximal 15 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="comment" class="col-sm-3 control-label">Kommentar</label>
      <div class="col-sm-9">
        <input #comment formControlName="comment" type="text" id="comment" class="form-control">
        <span *ngIf="form.get('comment').invalid && form.get('comment').touched" class="help-block">Bitte einen Kommentar mit maximal 100 Zeichen angeben!</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="status" class="col-sm-3 control-label">Status</label>
      <div class="col-sm-9">
        <select #status formControlName="status" id="status" class="form-control">
          <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
        </select>
        <span *ngIf="form.get('status').invalid && form.get('status').touched" class="help-block">Bitte den Status für diese/n Stadt/Kreis auswählen!</span>
      </div>
    </div>

    <button type="button" (click)="back()" class="btn btn-danger btn-sm m-2">abbrechen</button>
    <span style="font-size: 65%"><kbd>Esc</kbd></span>
    <button *ngIf="form.valid" type="button" (click)="save()" class="btn btn-success btn-sm m-2">speichern</button>
    <button *ngIf="!form.valid" type="button" disabled="disabled" class="btn btn-success btn-sm m-2">speichern</button>
    <span style="font-size: 65%"><kbd>Shift</kbd> + <kbd>Enter</kbd></span>
  </form>
</div>

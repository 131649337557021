/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-ebs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./show-ebs.component";
import * as i3 from "../../../services/constants.service";
import * as i4 from "@angular/common/http";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ShowEbsComponent = [i0.styles];
var RenderType_ShowEbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowEbsComponent, data: {} });
export { RenderType_ShowEbsComponent as RenderType_ShowEbsComponent };
export function View_ShowEbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erbscheinsantrag"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger mx-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Schlie\u00DFen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ebsText; _ck(_v, 4, 0, currVal_0); }); }
export function View_ShowEbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-ebs", [], null, null, null, View_ShowEbsComponent_0, RenderType_ShowEbsComponent)), i1.ɵdid(1, 114688, null, 0, i2.ShowEbsComponent, [i3.ConstantsService, i4.HttpClient, i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowEbsComponentNgFactory = i1.ɵccf("app-show-ebs", i2.ShowEbsComponent, View_ShowEbsComponent_Host_0, {}, {}, []);
export { ShowEbsComponentNgFactory as ShowEbsComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-detail-persons.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../stammbaum/stammbaum-list-persons/stammbaum-list-persons.component.ngfactory";
import * as i3 from "../../stammbaum/stammbaum-list-persons/stammbaum-list-persons.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../services/case.service";
import * as i6 from "../../services/stammbaum.service";
import * as i7 from "@ngrx/store";
import * as i8 from "../../services/constants.service";
import * as i9 from "@angular/common";
import * as i10 from "../../stammbaum/stammbaum-list-persons-without-version/stammbaum-list-persons-without-version.component.ngfactory";
import * as i11 from "../../stammbaum/stammbaum-list-persons-without-version/stammbaum-list-persons-without-version.component";
import * as i12 from "./case-detail-persons.component";
var styles_CaseDetailPersonsComponent = [i0.styles];
var RenderType_CaseDetailPersonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseDetailPersonsComponent, data: {} });
export { RenderType_CaseDetailPersonsComponent as RenderType_CaseDetailPersonsComponent };
function View_CaseDetailPersonsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "stammbaum-list-persons", [], null, null, null, i2.View_StammbaumListPersonsComponent_0, i2.RenderType_StammbaumListPersonsComponent)), i1.ɵdid(2, 573440, null, 0, i3.StammbaumListPersonsComponent, [i4.NgbModal, i5.CaseService, i6.StammbaumService, i7.Store, i8.ConstantsService], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CaseDetailPersonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailPersonsComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.stammbaumState == null) ? null : ((_co.stammbaumState.stammbaumVersions == null) ? null : _co.stammbaumState.stammbaumVersions.length)) > 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CaseDetailPersonsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "stammbaum-list-persons-without-version", [], null, null, null, i10.View_StammbaumListPersonsWithoutVersionComponent_0, i10.RenderType_StammbaumListPersonsWithoutVersionComponent)), i1.ɵdid(2, 573440, null, 0, i11.StammbaumListPersonsWithoutVersionComponent, [i4.NgbModal, i5.CaseService, i6.StammbaumService, i7.Store, i8.ConstantsService], { stammbaumState: [0, "stammbaumState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stammbaumState; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CaseDetailPersonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailPersonsComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaseDetailPersonsComponent_3)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasTrueStammbaum(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hasTrueStammbaum(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CaseDetailPersonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-detail-persons", [], null, null, null, View_CaseDetailPersonsComponent_0, RenderType_CaseDetailPersonsComponent)), i1.ɵdid(1, 4243456, null, 0, i12.CaseDetailPersonsComponent, [i6.StammbaumService], null, null)], null, null); }
var CaseDetailPersonsComponentNgFactory = i1.ɵccf("case-detail-persons", i12.CaseDetailPersonsComponent, View_CaseDetailPersonsComponent_Host_0, { stammbaumState: "stammbaumState" }, {}, []);
export { CaseDetailPersonsComponentNgFactory as CaseDetailPersonsComponentNgFactory };

<div class="p-2 m-2">
  <h4>Ehe zur Bearbeitung auswählen</h4>
  <table class="table table-sm">
    <tr><th>Partner</th><th>Datum</th></tr>
    <tr (click)="goToMarriage(mar)" *ngFor="let mar of this.marriages">
      <td>
        {{mar.persons[0].nachname}} {{mar.persons[0].vorname}}
        <span *ngIf="!mar.aussereheliche_partnerschaft" class="fa fa-venus-mars fa-sm mx-1"></span>
        <span *ngIf="mar.aussereheliche_partnerschaft" class="fa fa-heart fa-sm mx-1"></span>
        {{mar.persons[1].nachname}} {{mar.persons[1].vorname}}
      </td>
      <td>{{mar.date | showDate}}</td>
    </tr>
  </table>
  <button class="btn btn-sm btn-success" (click)="close()">abbrechen</button>
</div>

<div class="popup">
  <h4 class="mt-2 ilacolor">Email</h4>
  <div *ngIf="recipients && (recipients.length > 0)">
    <p>
      <b>Empfänger:</b>
    </p>
    <p>
      <span class="m-2" *ngFor="let recipient of recipients">{{recipient.name}}</span>
    </p>
  </div>
  <div *ngIf="template">
    <b>Betreff</b>
    <input class="form-control" [(ngModel)]="subject">
    <b>Text</b>
    <textarea class="form-control" rows="10" [(ngModel)]="text"></textarea>
    <b>Signatur</b>
    <textarea class="form-control"></textarea>
    <button class="m-2 btn btn-sm btn-success">abschicken</button>
  </div>
</div>


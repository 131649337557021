<div class="modal-header">
  <h4 *ngIf="!editMode" class="modal-title ilatitle">Neue Quelle anlegen für {{institution.name}}</h4>
  <h4 *ngIf="editMode" class="modal-title ilatitle">Geldquelle {{geldquelle?.name}} für {{institution.name}} bearbeiten</h4>
</div>

<form [formGroup]="form" class="form">
 <div class="form-group mx-3">
   <label for="name" class="control-label">Bezeichnung</label>
   <input maxlength="60" formControlName="name" type="text" id="name" class="form-control">
   <span *ngIf="form.get('name').invalid && form.get('name').touched" class="help-block">Bitte einen Namen mit maximal 60 Zeichen angeben!</span>
 </div>
  <div class="form-group mx-3">
   <label for="name" class="control-label">Beschreibung</label>
    <textarea maxlength="300" rows="3" formControlName="description" id="description" class="form-control"></textarea>
   <span *ngIf="form.get('description').invalid && form.get('description').touched" class="help-block">Die Beschreibung darf nicht mehr als 300 Zeichen lang sein!</span>
 </div>
  <div class="form-group mx-3">
   <label for="name" class="control-label">Erwartete Summe</label>
   <input formControlName="sum_expected" type="text" id="sum_expected" class="form-control">
   <input formControlName="sum_expected_formatted" type="text" id="sum_expected_formatted" readonly="true" class="form-control">
   <span *ngIf="form.get('sum_expected').invalid && form.get('sum_expected').touched" class="help-block">Die erwartete Summe muss eine Zahl >=0 sein</span>
 </div>
  <div class="form-group mx-3">
   <label for="completed" class="control-label">Abgeschlossen</label>
   <select formControlName="completed" id="completed" class="form-control">
     <option value="0">nein</option>
     <option value="1">ja</option>
   </select>
 </div>
</form>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="save()" [disabled]="!form.valid || saving" type="button" class="m-2 btn btn-success pull-right">speichern</button>
</div>


<div class="modal-header">
  <h4 class="modal-title ilatitle">Institution vom Fall entfernen</h4>
</div>

<div class="mx-2">
  Wollen Sie <b>{{institution.name}}</b> wirklich aus der Auskehr des Falles entfernen?
</div>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="save()" [disabled]="saving" type="button" class="m-2 btn btn-success pull-right">entfernen</button>
</div>

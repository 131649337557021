/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-erben-steuer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../tools/editfield-checkbox/editfield-checkbox.component.ngfactory";
import * as i4 from "../../tools/editfield-checkbox/editfield-checkbox.component";
import * as i5 from "./edit-erben-steuer.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@ngrx/store";
import * as i8 from "../../services/case.service";
var styles_EditErbenSteuerComponent = [i0.styles];
var RenderType_EditErbenSteuerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditErbenSteuerComponent, data: {} });
export { RenderType_EditErbenSteuerComponent as RenderType_EditErbenSteuerComponent };
function View_EditErbenSteuerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.erbe.vorname; var currVal_1 = _co.erbe.nachname; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_EditErbenSteuerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "p-2 m-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h4", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erbe "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditErbenSteuerComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" bearbeiten"])), (_l()(), i1.ɵeld(6, 0, null, null, 24, "table", [["class", "table table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erbschaftssteuer-Erkl\u00E4rung gew\u00FCnscht"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "editfield-checkbox", [], null, [[null, "checkboxChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkboxChange" === en)) {
        var pd_0 = ((_co.erbe.steuererklaerung_needed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditfieldCheckboxComponent_0, i3.RenderType_EditfieldCheckboxComponent)), i1.ɵdid(12, 638976, null, 0, i4.EditfieldCheckboxComponent, [], { checkbox: [0, "checkbox"], editMode: [1, "editMode"] }, { checkboxChange: "checkboxChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erbschaftssteuer-Erkl\u00E4rung erledigt"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "editfield-checkbox", [], null, [[null, "checkboxChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkboxChange" === en)) {
        var pd_0 = ((_co.erbe.steuererklaerung_done = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditfieldCheckboxComponent_0, i3.RenderType_EditfieldCheckboxComponent)), i1.ɵdid(18, 638976, null, 0, i4.EditfieldCheckboxComponent, [], { checkbox: [0, "checkbox"], editMode: [1, "editMode"] }, { checkboxChange: "checkboxChange" }), (_l()(), i1.ɵeld(19, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unbedenklichkeitsbescheinigung ben\u00F6tigt"])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "editfield-checkbox", [], null, [[null, "checkboxChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkboxChange" === en)) {
        var pd_0 = ((_co.erbe.unbedenklichkeit_needed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditfieldCheckboxComponent_0, i3.RenderType_EditfieldCheckboxComponent)), i1.ɵdid(24, 638976, null, 0, i4.EditfieldCheckboxComponent, [], { checkbox: [0, "checkbox"], editMode: [1, "editMode"] }, { checkboxChange: "checkboxChange" }), (_l()(), i1.ɵeld(25, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unbedenklichkeitsbescheinigung vorhanden"])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "editfield-checkbox", [], null, [[null, "checkboxChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkboxChange" === en)) {
        var pd_0 = ((_co.erbe.unbedenklichkeit_done = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditfieldCheckboxComponent_0, i3.RenderType_EditfieldCheckboxComponent)), i1.ɵdid(30, 638976, null, 0, i4.EditfieldCheckboxComponent, [], { checkbox: [0, "checkbox"], editMode: [1, "editMode"] }, { checkboxChange: "checkboxChange" }), (_l()(), i1.ɵeld(31, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["speichern"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.erbe; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.erbe.steuererklaerung_needed; var currVal_2 = true; _ck(_v, 12, 0, currVal_1, currVal_2); var currVal_3 = _co.erbe.steuererklaerung_done; var currVal_4 = true; _ck(_v, 18, 0, currVal_3, currVal_4); var currVal_5 = _co.erbe.unbedenklichkeit_needed; var currVal_6 = true; _ck(_v, 24, 0, currVal_5, currVal_6); var currVal_7 = _co.erbe.unbedenklichkeit_done; var currVal_8 = true; _ck(_v, 30, 0, currVal_7, currVal_8); }, null); }
export function View_EditErbenSteuerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-erben-steuer", [], null, null, null, View_EditErbenSteuerComponent_0, RenderType_EditErbenSteuerComponent)), i1.ɵdid(1, 114688, null, 0, i5.EditErbenSteuerComponent, [i6.NgbActiveModal, i7.Store, i8.CaseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditErbenSteuerComponentNgFactory = i1.ɵccf("app-edit-erben-steuer", i5.EditErbenSteuerComponent, View_EditErbenSteuerComponent_Host_0, {}, {}, []);
export { EditErbenSteuerComponentNgFactory as EditErbenSteuerComponentNgFactory };

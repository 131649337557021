<div class="m-2 p-2">
  <h4>Eheschließung zwischen {{marriage.persons[0].vorname}} {{marriage.persons[0].nachname}} und {{marriage.persons[1].vorname}} {{marriage.persons[1].nachname}} bearbeiten</h4>

  <form [formGroup]="form" class="mt-3">
    <table class="table table-sm">
      <tr>
        <td>außereheliche Partnerschaft</td>
        <td><input type="checkbox" id="aussereheliche_partnerschaft" class="m-1" formControlName="aussereheliche_partnerschaft"></td>
      </tr>
      <tr>
        <td>Heiratsdatum</td>
        <td><date-input-form formControlName="date" id="date"></date-input-form></td>
      </tr>
      <tr>
        <td>Heiratsinfo (Ort)</td>
        <td><input formControlName="kommentar" type="text" id="kommentar" class="form-control"></td>
      </tr>
      <tr>
        <td>Trauungsdatum</td>
        <td><date-input-form formControlName="trauungsdatum" id="trauungsdatum"></date-input-form></td>
      </tr>
      <tr>
        <td>Trauungsinfo (Ort)</td>
        <td><input formControlName="trauungsinfo" type="text" id="trauungsinfo" class="form-control"></td>
      </tr>
      <tr>
        <td>Scheidung</td>
        <td>
          <div class="form">
            <div class="row">
              <input type="checkbox" id="divorced" class="ml-4 m-1" formControlName="divorced">
              <label class="label" for="divorced">Ehe geschieden am</label>
            </div>
            <div class="row">
              <date-input-form id="divorced_date" class="ml-4" formControlName="divorced_date"></date-input-form>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Urkunde</td>
        <td>
          <div class="form-check">
            <div class="row">
              <input type="checkbox" id="needs_hu" formControlName="needs_hu" class="ml-4 m-1">
              <label class="form-check-label" for="needs_hu">HU benötigt</label>
            </div>
            <div class="row">
              <input type="checkbox" id="ordered_hu" formControlName="ordered_hu" class="ml-4 m-1">
              <label class="form-check-label" for="ordered_hu">HU bestellt</label>
            </div>
            <div class="row">
              <input type="checkbox" id="needs_hu_but_do_without" formControlName="needs_hu_but_do_without" class="ml-4 m-1">
              <label class="form-check-label" for="needs_hu_but_do_without">Verzicht auf HU</label>
            </div>

            <div class="col">
              <choose-user-reactive-forms id="ordered_hu_user" formControlName="ordered_hu_user"></choose-user-reactive-forms>
            </div>
            <div class="row">
              <date-input-form id="ordered_hu_date" class="ml-4" formControlName="ordered_hu_date"></date-input-form>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </form>
  <button (click)="cancel()" class="m-2 btn btn-sm btn-warning">abbrechen</button>
  <button [disabled]="form.invalid" (click)="save()" class="m-2 btn btn-sm btn-success">speichern</button>
</div>

<nav aria-label="breadcrumb">
  <ol *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER" class="breadcrumb">
    <li class="breadcrumb-item active">Länderwahl</li>
  </ol>
  <ol *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE" class="breadcrumb">
    <li class="breadcrumb-item"><a href="#" (click)="goToLaenderwahl()">Länderwahl</a></li>
    <li class="breadcrumb-item active">{{nvzState?.activeLand?.name}}</li>
  </ol>
  <ol *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER" class="breadcrumb">
    <li class="breadcrumb-item"><a href="#" (click)="goToLaenderwahl()">Länderwahl</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToStadtwahl()">{{nvzState?.activeLand?.name}}</a></li>
    <li class="breadcrumb-item active"><span *ngIf="nvzState?.activeStadt?.type && nvzState?.activeStadt?.type !== ''">{{nvzState.activeStadt.type}} </span>{{nvzState?.activeStadt?.name}}</li>
  </ol>
  <ol *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE" class="breadcrumb">
    <li class="breadcrumb-item"><a href="#" (click)="goToLaenderwahl()">Länderwahl</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToStadtwahl()">{{nvzState?.activeLand?.name}}</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToStandesamtwahl()"><span *ngIf="nvzState?.activeStadt?.type && nvzState?.activeStadt?.type !== ''">{{nvzState.activeStadt.type}} </span>{{nvzState?.activeStadt?.name}}</a></li>
    <li class="breadcrumb-item active">{{nvzState?.activeStandesamt?.name}}</li>
  </ol>
  <ol *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE === MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG" class="breadcrumb">
    <li class="breadcrumb-item"><a href="#" (click)="goToLaenderwahl()">Länderwahl</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToStadtwahl()">{{nvzState?.activeLand?.name}}</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToStandesamtwahl()"><span *ngIf="nvzState?.activeStadt?.type && nvzState?.activeStadt?.type !== ''">{{nvzState.activeStadt.type}} </span>{{nvzState?.activeStadt?.name}}</a></li>
    <li class="breadcrumb-item"><a href="#" (click)="goToVerzeichniswahl()">{{nvzState?.activeStandesamt?.name}}</a></li>
    <li class="breadcrumb-item active">{{showVerzeichnisArt(nvzState?.activeVerzeichnis?.art)}} {{nvzState?.activeVerzeichnis?.jahrgang}}</li>
  </ol>
</nav>

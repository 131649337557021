<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  {{message}}
</div>

<div class="modal-footer">
  <button (click)="cancel()" type="button" class="m-2 btn btn-outline-danger pull-left">abbrechen</button>
  <button (click)="confirm()" type="button" class="m-2 btn btn-success pull-right">weiter</button>
</div>

<div class="popup">

  <h4 *ngIf="person===0" class="ilacolor">Neues Dokument für Fall {{case}} ({{caseService.getNameForCaseId(case)}})</h4>
  <h4 *ngIf="person!==0" class="ilacolor">Neues Dokument für Person {{person}} ({{heirService.getNameForHeirId(person)}}), Fall {{case}} ({{caseService.getNameForCaseId(case)}})</h4>

  <div class="card m-2 p-2">
    <div class="card-body">
      <p>
        <b class="mr-2">1. Empfänger hinzufügen</b>
        <button *ngIf="person!==0" class="m-1 btn btn-sm btn-outline-success" (click)="selectPerson()">{{heirService.getNameForHeirId(person)}}</button>
        <button *ngIf="selectRecipientMode!='addressbook'" class="m-1 btn btn-sm btn-outline-warning" (click)="selectFromAdressbook()">Empfänger aus Adressbuch</button>
        <button *ngIf="selectRecipientMode=='addressbook'" class="m-1 btn btn-sm btn-warning">Empfänger aus Adressbuch</button>
        <button *ngIf="selectRecipientMode!='user'" class="m-1 btn btn-sm btn-outline-danger" (click)="selectFromUsers()">Mitarbeiter</button>
        <button *ngIf="selectRecipientMode=='user'" class="m-1 btn btn-sm btn-danger">Mitarbeiter</button>
      </p>
      <p *ngIf="selectRecipientMode=='addressbook'"><select-recipient-from-addressbook (addressbookRecipientSelected)="recipientSelected($event)"></select-recipient-from-addressbook></p>
      <p *ngIf="selectRecipientMode=='user'"><select-recipient-from-user (userRecipientSelected)="recipientSelected($event)"></select-recipient-from-user></p>
      <div class="mt-2" *ngIf="recipients && (recipients.length > 0)"><b>Ausgewählte Empfänger:</b></div>
      <p>
        <ng-container *ngFor="let recipient of recipients">
          <span *ngIf="recipient.case_person_id > 0" title="entfernen" (click)="removeRecipient(recipient)" class="m-1 badge badge-pill badge-success">{{recipient.name}} <i class="fa fa-remove"></i></span>
          <span *ngIf="recipient.addressbook_institution_id > 0" title="entfernen" (click)="removeRecipient(recipient)" class="m-1 badge badge-pill badge-default">{{recipient.name}} <i class="fa fa-remove"></i></span>
          <span *ngIf="recipient.addressbook_person_id > 0" title="entfernen" (click)="removeRecipient(recipient)" class="m-1 badge badge-pill badge-warning">{{recipient.name}} <i class="fa fa-remove"></i></span>
          <span *ngIf="recipient.user_id > 0" title="entfernen" (click)="removeRecipient(recipient)" class="m-1 badge badge-pill badge-default">{{recipient.name}} <i class="fa fa-remove"></i></span>
        </ng-container>
      </p>
    </div>
  </div>

  <div class="card m-2 p-2">
    <div class="card-body">
      <b>2. Dokumentenart wählen</b>
      <select [(ngModel)]="documentType" name="document-type" class="form-control">
        <option [value]="option.value" *ngFor="let option of documentTypeOptions">{{option.name}}</option>
      </select>
    </div>
  </div>

  <div class="card m-2 p-2">
    <div class="card-body">
      <b>3. Kategorie auswählen</b>
      <select [(ngModel)]="documentCategory" (ngModelChange)="changedCategory()" name="document-category" class="form-control">
        <option [value]="option.id" *ngFor="let option of documentCategories">{{option.title}}</option>
      </select>
    </div>
  </div>

  <div class="card m-2 p-2">
    <div class="card-body">
      <b>4. Vorlage auswählen</b>
      <select [(ngModel)]="documentTemplate" (ngModelChange)="changedTemplate()" name="document-template" class="form-control">
        <option [value]="option.id" *ngFor="let option of documentTemplates">{{option.title}}</option>
      </select>
    </div>
  </div>

  <div class="card m-2 p-2">
    <div class="card-body">
      <b>5. Dokument erstellen</b>
      <button *ngIf="recipients && (recipients.length > 0) && documentTemplate" class="m-1 btn btn-sm btn-success" (click)="createDocument()">erstellen</button>
    </div>
  </div>

</div>

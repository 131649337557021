<div class="card my-3">
  <div class="card-text">
    <div class="table-responsive">
      <h3 *ngIf="!searchResults || searchResults.length === 0">0 Fälle gefunden</h3>
      <h3 *ngIf="searchResults && searchResults.length > 0">{{searchResults.length}} Fälle gefunden (max. 500
        Ergebnisse)</h3>
      <table *ngIf="searchResults && searchResults.length > 0" class="table table-hover table-striped table-sm">
        <tr>
          <th><i class="fa fa-user"></i></th>
          <th><i class="fa fa-tasks"></i></th>
          <th>Fall-ID</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Geburtsname</th>
          <th>Gericht</th>
          <th>Geburtsort</th>
          <th>Wert</th>
          <th>Immob.</th>
          <th>Fisk.</th>
          <th>Team</th>
          <th>Quelle</th>
          <th>Status</th>
        </tr>
        <ng-container *ngFor="let currentCase of searchResults">
          <tr (click)="onTableClicked(currentCase)">
            <td *ngIf="currentCase.heir_count > 0" (click)="expandCase(currentCase.id); $event.stopPropagation();"
                style="color: darkblue;">
              <span *ngIf="currentCase.id == caseExpanded"><i
                class="fa fa-caret-down">{{currentCase.heir_count}}</i></span>
              <span *ngIf="currentCase.id != caseExpanded"><i
                class="fa fa-caret-right">{{currentCase.heir_count}}</i></span>
            </td>
            <td *ngIf="currentCase.heir_count === 0"></td>
            <td>
              <span *ngIf="currentCase.parent_case && currentCase.parent_case > 0">U</span>
              <span *ngIf="currentCase.child_cases.length > 0">{{currentCase.child_cases.length}}</span>
            </td>
            <td>{{currentCase.id}}</td>
            <td>{{currentCase.first_name}}</td>
            <td>{{currentCase.last_name}}</td>
            <td>{{currentCase.birth_name}}</td>
            <td>{{currentCase.court}}</td>
            <td>{{currentCase.birth_place}}</td>
            <td>{{currentCase.value}}</td>
            <td>
              <i *ngIf="!currentCase.real_estate || currentCase.real_estate=='false'" class="fa fa-remove"
                 style="color: red;"></i>
              <i *ngIf="currentCase.real_estate && currentCase.real_estate!='false' && currentCase.real_estate !=''"
                 class="fa fa-check" style="color: green;"></i>
            </td>
            <td>
              <i *ngIf="!currentCase.fiscal||currentCase.fiscal=='false'" class="fa fa-remove" style="color: red;"></i>
              <i *ngIf="currentCase.fiscal&&currentCase.fiscal!='false'&&currentCase.fiscal!=''" class="fa fa-check"
                 style="color: green;"></i>
            </td>
            <td>
              <ng-container *ngFor="let member of currentCase.team_member_details">
                <span *ngIf="!member.deleted && member.leader"
                      class="badge badge-pill badge-success">{{userService.getAbbreviationForUser(member.user_id)}}</span>
                <span *ngIf="!member.deleted && !member.leader && member.ebs_doku"
                      class="badge badge-pill badge-danger">{{userService.getAbbreviationForUser(member.user_id)}}</span>
                <span *ngIf="!member.deleted && !member.leader && !member.ebs_doku"
                      class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
                <span *ngIf="false && member.deleted" style="background-color: lightgray;"
                      class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(member.user_id)}}</span>
              </ng-container>
            </td>
            <td>{{currentCase.source}}</td>
            <td>{{currentCase.status}}</td>
          </tr>
          <tr *ngIf="currentCase && (currentCase.id == caseExpanded)">
            <td colspan="14"><heirs-for-caselist></heirs-for-caselist></td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editfield-name.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../editfield-text/editfield-text.component.ngfactory";
import * as i3 from "../editfield-text/editfield-text.component";
import * as i4 from "@angular/common";
import * as i5 from "./editfield-name.component";
import * as i6 from "../custom-uppercase-pipe/custom-uppercase-pipe";
var styles_EditfieldNameComponent = [i0.styles];
var RenderType_EditfieldNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditfieldNameComponent, data: {} });
export { RenderType_EditfieldNameComponent as RenderType_EditfieldNameComponent };
function View_EditfieldNameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.nameDisplay; _ck(_v, 1, 0, currVal_1); }); }
function View_EditfieldNameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = (_co.first_nameChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("textChange" === en)) {
        var pd_1 = ((_co.first_name = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(2, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = (_co.last_nameChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("textChange" === en)) {
        var pd_1 = ((_co.last_name = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(4, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "editfield-text", [], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = (_co.birth_nameChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("textChange" === en)) {
        var pd_1 = ((_co.birth_name = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EditfieldTextComponent_0, i2.RenderType_EditfieldTextComponent)), i1.ɵdid(6, 638976, null, 0, i3.EditfieldTextComponent, [], { text: [0, "text"], editMode: [1, "editMode"], placeholder: [2, "placeholder"], maxLength: [3, "maxLength"] }, { textChange: "textChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.first_name; var currVal_1 = _co.editMode; var currVal_2 = "Vorname"; var currVal_3 = 50; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.last_name; var currVal_5 = _co.editMode; var currVal_6 = "Nachname"; var currVal_7 = 50; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.birth_name; var currVal_9 = _co.editMode; var currVal_10 = "Geburtsname"; var currVal_11 = 50; _ck(_v, 6, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_EditfieldNameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldNameComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditfieldNameComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EditfieldNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "editfield-name", [], null, null, null, View_EditfieldNameComponent_0, RenderType_EditfieldNameComponent)), i1.ɵdid(1, 638976, null, 0, i5.EditfieldNameComponent, [i6.CustomUppercasePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditfieldNameComponentNgFactory = i1.ɵccf("editfield-name", i5.EditfieldNameComponent, View_EditfieldNameComponent_Host_0, { first_name: "first_name", last_name: "last_name", birth_name: "birth_name", editMode: "editMode" }, { first_nameChange: "first_nameChange", last_nameChange: "last_nameChange", birth_nameChange: "birth_nameChange" }, []);
export { EditfieldNameComponentNgFactory as EditfieldNameComponentNgFactory };

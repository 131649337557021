<h4 *ngIf="activePage === 'entry'" class="title">
    NVZ-Autoparser
    <button (click)="end()" class="mx-2 btn btn-sm btn-danger">zurück zur NVZ-Suche</button>
</h4>

<div *ngIf="activePage === 'entry'" class="row">
  <div class="col-md-6">
    <nvz-ap-messages class="card"></nvz-ap-messages>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header background-autoparser">Kommandos</div>
      <div class="card-body">
        <form [formGroup]="actionForm">
          <input disabled type="file" class="form-control">
          <select formControlName="directory" class="form-control my-2">
            <option value="-1">kein Verzeichnis ausgewählt</option>
            <option *ngFor="let dir of directories" [value]="dir.id">{{dir.name}}</option>
          </select>
          <button disabled (click)="startPreprocessing()" class="m-2 btn btn-sm btn-info">Preprocessing</button>
          <button disabled (click)="startOCR()" class="m-2 btn btn-sm btn-info">Texterkennung</button>
          <button [disabled]="!actionForm.valid" (click)="startPostprocessing()" class="m-2 btn btn-sm btn-info">Nachbearbeitung</button>
          <button disabled (click)="exportList()" class="m-2 btn btn-sm btn-info">Kontroll-Liste</button>
          <button disabled (click)="showFirstNames()" class="m-2 btn btn-sm btn-info">Vornamen-Liste</button>
          <button disabled (click)="showLastNames()" class="m-2 btn btn-sm btn-info">Nachnamen-Liste</button>
        </form>
      </div>
    </div>
  </div>
</div>

<nvz-ap-edit-results *ngIf="activePage === 'postprocess'"></nvz-ap-edit-results>
<nvz-ap-result-list *ngIf="activePage === 'list'"></nvz-ap-result-list>





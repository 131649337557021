<div class="my-2" *ngIf="appNotifications">
  <div *ngIf="appNotifications?.length>0 && !limitToSlot">
    <div class="alert {{appNotifications[0].css_class}}">
      <span *ngIf="appNotifications[0].icon_class"><i class="{{appNotifications[0].icon_class}}"></i> </span>
      {{appNotifications[0].text}}
    </div>
  </div>
  <div *ngIf="appNotifications?.length>0 && limitToSlot">
    <ng-container *ngFor="let appNote of appNotifications">
      <div *ngIf="limitToSlot == appNote.slot || appNote.slot == SLOT_GLOBAL" class="alert {{appNote.css_class}}">
          <span *ngIf="appNote.icon_class"><i class="{{appNote.icon_class}}"></i> </span>
          {{appNote.text}}
      </div>
    </ng-container>
  </div>
  <div *ngIf="!(appNotifications?.length>0) && !limitToSlot">
    <div class="alert alert-info">
      <span><i class="fa fa-check"></i></span>
      Die lokalen Daten sind auf aktuellem Stand
    </div>
  </div>
</div>

<app-notification *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE!=MODULE_PAGES.NAMENSVERZEICHNISSE.AUTOPARSER"></app-notification>
<nvz-search *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH" [nvzState]="nvzState"></nvz-search>
<nvz-zufall *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.ZUFALL" [nvzState]="nvzState"></nvz-zufall>
<nvz-edit-navigation *ngIf="showNavigation()" [nvzState]="nvzState" [ui]="ui"></nvz-edit-navigation>
<nvz-laender *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER" [user]="user" [nvzState]="nvzState"></nvz-laender>
<nvz-staedte *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE" [user]="user" [nvzState]="nvzState"></nvz-staedte>
<nvz-standesaemter *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER" [user]="user" [nvzState]="nvzState"></nvz-standesaemter>
<nvz-verzeichnisse *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE" [user]="user" [nvzState]="nvzState"></nvz-verzeichnisse>
<nvz-eintraege *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG" [user]="user" [nvzState]="nvzState"></nvz-eintraege>
<nvz-ap-entry *ngIf="ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE==MODULE_PAGES.NAMENSVERZEICHNISSE.AUTOPARSER"></nvz-ap-entry>

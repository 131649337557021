/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nvz-userrights-form.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./nvz-userrights-form.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/user.service";
import * as i7 from "../../services/nvz.service";
import * as i8 from "@ngrx/store";
var styles_NVZUserrightsFormComponent = [i0.styles];
var RenderType_NVZUserrightsFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NVZUserrightsFormComponent, data: {} });
export { RenderType_NVZUserrightsFormComponent as RenderType_NVZUserrightsFormComponent };
function View_NVZUserrightsFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-pill badge-default mx-1"], ["style", "background-color: lightgreen"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["entfernen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.getAbbreviationForUser(_v.context.$implicit); _ck(_v, 3, 0, currVal_0); }); }
function View_NVZUserrightsFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "table", [["class", "table table-hover table-striped table-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NVZUserrightsFormComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verzeichnis.users_allowed_to_edit; _ck(_v, 3, 0, currVal_0); }, null); }
function View_NVZUserrightsFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i3.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i3.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i3.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", " (", " ", ")"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.abbreviation; var currVal_3 = _v.context.$implicit.first_name; var currVal_4 = _v.context.$implicit.last_name; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_NVZUserrightsFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "m-2 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Berechtigungen zum Bearbeiten \u00E4ndern"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NVZUserrightsFormComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mitarbeiter "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "select", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.user_id = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i3.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectControlValueAccessor]), i1.ɵdid(10, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(12, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NVZUserrightsFormComponent_3)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add(_co.user_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["hinzuf\u00FCgen"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-success btn-sm m-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["abbrechen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.verzeichnis && _co.verzeichnis.users_allowed_to_edit) && (_co.verzeichnis.users_allowed_to_edit.length > 0)); _ck(_v, 4, 0, currVal_0); var currVal_8 = _co.user_id; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.activeUsers; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_NVZUserrightsFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nvz-userrights-form", [], null, null, null, View_NVZUserrightsFormComponent_0, RenderType_NVZUserrightsFormComponent)), i1.ɵdid(1, 245760, null, 0, i4.NVZUserrightsFormComponent, [i5.NgbActiveModal, i6.UserService, i7.NVZService, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NVZUserrightsFormComponentNgFactory = i1.ɵccf("nvz-userrights-form", i4.NVZUserrightsFormComponent, View_NVZUserrightsFormComponent_Host_0, {}, {}, []);
export { NVZUserrightsFormComponentNgFactory as NVZUserrightsFormComponentNgFactory };

<ng-container *ngIf="hasTrueStammbaum()">
  <div *ngIf="stammbaumState?.stammbaumVersions?.length > 0">
    <stammbaum-list-persons [stammbaumState]="stammbaumState"></stammbaum-list-persons>
  </div>
</ng-container>

<ng-container *ngIf="!hasTrueStammbaum()">
  <stammbaum-list-persons-without-version [stammbaumState]="stammbaumState"></stammbaum-list-persons-without-version>
</ng-container>



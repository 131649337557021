<app-notification></app-notification>

<h3>{{bas.length}} neue Bundesanzeiger-Fälle</h3>

<ngb-accordion *ngIf="bas" [activeIds]="openAccordionPanels" #acc="ngbAccordion">
  <div *ngFor="let ba of bas">
    <ngb-panel [id]="'ac'+ba.id">
      <ng-template ngbPanelTitle>
        <p>{{ba.bundesanzeiger_date}} {{ba.court}} {{ba.court_reference}} {{ba.value}}</p>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>{{ba.bundesanzeiger_date}} {{ba.court}} {{ba.court_reference}} {{ba.value}}</p>
        <p>{{ba.first_name}} {{ba.last_name}} <span *ngIf="ba.birth_name">, geb. {{ba.birth_name}}</span></p>
        <p [innerHTML]="ba.text"></p>
        <p>
          <button class="btn btn-sm btn-success" (click)="createCase(ba)">als Fall übernehmen</button>
          <button class="btn btn-sm btn-danger" (click)="delete(ba)">löschen</button>
        </p>
      </ng-template>
    </ngb-panel>
  </div>
</ngb-accordion>


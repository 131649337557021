<h3>Standesämter
  <button *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries()" (click)="addAmt()" class="mx-2 btn btn-sm btn-warning">neues Standesamt anlegen</button>
  <span style="font-size: 45%"><kbd>Einfg</kbd></span>
</h3>
<div *ngIf="nvzService.standesaemterLoaded">
  <h4 *ngIf="nvzState && nvzState.activeStadt">für <span *ngIf="nvzState.activeStadt.type && nvzState.activeStadt.type !== ''">{{nvzState.activeStadt.type}} </span> {{nvzState.activeStadt.name}}</h4>
  <abc-choose (chosen)="filterLetter($event)"></abc-choose>
  <filter-field [hint]="'Name, Standesamts-Nummer, ...'" (searchstring)="filterBy($event)"></filter-field>
  <div *ngIf="!copiedStandesaemter || copiedStandesaemter.length == 0">keine Standesämter vorhanden</div>
  <div *ngIf="copiedStandesaemter && copiedStandesaemter.length > 0">
    <table class="table table-hover table-striped table-sm">
      <tr>
        <th>Name</th>
        <th>Standesamts-Nummer</th>
        <th>Kommentar</th>
        <th>Status</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr *ngFor="let amt of copiedStandesaemter">
        <td (click)="selectAmt(amt)">{{amt.name}}</td>
        <td (click)="selectAmt(amt)">{{amt.schluessel}}</td>
        <td (click)="selectAmt(amt)">{{amt.comment}}</td>
        <td (click)="selectAmt(amt)">{{showAmtStatus(amt.status)}}</td>
        <td (click)="selectAmt(amt)"><span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(amt.owner_id)}}</span></td>
        <td *ngIf="rightsManagementService.isAllowedToEditAllNVZEntries() || amt.owner_id == this.user.id" (click)="editAmt(amt)"><i class="fa fa-edit"></i></td>
        <td *ngIf="!rightsManagementService.isAllowedToEditAllNVZEntries() && amt.owner_id != this.user.id"></td>
        <td *ngIf="rightsManagementService.isAdmin() || amt.owner_id == this.user.id" (click)="deleteAmt(amt)"><i class="fa fa-trash"></i></td>
        <td *ngIf="!rightsManagementService.isAdmin() && amt.owner_id != this.user.id"></td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!nvzService.standesaemterLoaded">
  <i class="fa fa-refresh fa-spin fa-lg"></i>
</div>


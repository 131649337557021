import {Component, ElementRef, ViewChild, HostListener, OnInit, AfterViewInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import * as NVZOptions from "../nvz-options";
import {INVZVerzeichnis} from "../nvz-verzeichnis.interface";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {INVZEintrag} from "../nvz-eintrag.interface";
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';


@Component({
  selector: 'nvz-eintrag-form',
  templateUrl: './nvz-eintrag-form.component.html',
  styleUrls: ['./nvz-eintrag-form.component.css']
})
export class NVZEintragFormComponent implements  OnInit, AfterViewInit {
  form: FormGroup;
  editMode: boolean = false;
  eintrag: INVZEintrag = <INVZEintrag>{};
  vorhandeneEintraege: INVZEintrag[];
  alreadyExistingEintraege: INVZEintrag[] = [];
  @ViewChild('urkunden_nr', { static: true }) urkunden_nrElement: ElementRef;
  @ViewChild('nachname', { static: true }) nachnameElement: ElementRef;
  @ViewChild('fuzzi', { static: true }) fuzziElement: ElementRef;
  @ViewChild('geburtsname', { static: true }) geburtsnameElement: ElementRef;
  @ViewChild('vorname', { static: true }) vornameElement: ElementRef;
  @ViewChild('zufallsfund', { static: true }) zufallsfundElement: ElementRef;
  @ViewChild('juedisch', { static: true }) juedischElement: ElementRef;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      if (this.editMode) {this.save()} else {this.save(true)};
    }
  }

  constructor(private activeModal: NgbActiveModal, private customUppercasePipe: CustomUppercasePipe) {}

  ngOnInit() {
     this.form = new FormGroup({
        'urkunden_nr': new FormControl(null, [Validators.required, Validators.maxLength(15)]),
        'nachname': new FormControl(null, [Validators.required, Validators.maxLength(30)]),
        'fuzzi': new FormControl(null, [Validators.maxLength(30)]),
        'geburtsname': new FormControl(null, [Validators.maxLength(30)]),
        'vorname': new FormControl(null, [Validators.maxLength(50)]),
        'zufallsfund': new FormControl(false),
        'juedisch': new FormControl(false)
      });
      if (this.editMode) {
      this.form.setValue({
        urkunden_nr: this.eintrag.urkunden_nr,
        nachname: this.eintrag.nachname,
        fuzzi: this.eintrag.fuzzi,
        geburtsname: this.eintrag.geburtsname,
        vorname: this.eintrag.vorname,
        zufallsfund: this.eintrag.zufallsfund,
        juedisch: this.eintrag.juedisch
      });
    }
  }

  ngAfterViewInit() {
    this.urkunden_nrElement.nativeElement.focus();
  }

  checkExisting() {
    this.alreadyExistingEintraege = [];
    if (this.form.controls.urkunden_nr.value) {
      this.alreadyExistingEintraege = this.vorhandeneEintraege.filter(x => x.urkunden_nr == this.form.controls.urkunden_nr.value);
    }
  }

  resetExisting() {
    this.alreadyExistingEintraege = [];
  }

  save(next: boolean = false) {
    if (this.form.valid) {
      this.eintrag.urkunden_nr = this.form.controls.urkunden_nr.value;
      this.eintrag.nachname = this.form.controls.nachname.value;
      this.eintrag.nachname = this.customUppercasePipe.transform(this.eintrag.nachname);
      this.eintrag.fuzzi = this.form.controls.fuzzi.value;
      this.eintrag.geburtsname = this.form.controls.geburtsname.value;
      this.eintrag.vorname = this.form.controls.vorname.value;
      this.eintrag.zufallsfund = this.form.controls.zufallsfund.value;
      this.eintrag.juedisch = this.form.controls.juedisch.value;
      this.activeModal.close({eintrag: this.eintrag, next: next});
    }
  }

  back() {
    this.activeModal.close();
  }

}

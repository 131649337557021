<h4 class="title">Eintragungen für Verzeichnis {{autoparserState.activeDir?.name}} <button class="btn btn-sm btn-warning mx-2" (click)="back()">zurück</button></h4>

<table class="table table-sm table-striped" *ngIf="autoparserState && autoparserState.postprocessedEntries && autoparserState.postprocessedEntries.length > 0">
  <tr>
    <th>Nachname</th>
    <th>Geburtsname</th>
    <th>Vornamen</th>
    <th>Urkunden-Nr.</th>
    <th>jüdisch</th>
    <th>Zeilen-Nr</th>
    <th></th>
    <th></th>
    <th></th>
  </tr>
  <tr *ngFor="let entry of autoparserState.postprocessedEntries">
    <td>{{entry.nachname}}</td>
    <td>{{entry.geburtsname}}</td>
    <td>{{entry.vorname}}</td>
    <td>{{entry.urkunden_nr}}</td>
    <td><show-boolean-value [value]="entry.juedisch"></show-boolean-value></td>
    <td>{{entry.row_count}}</td>
    <td (click)="edit(entry)"><span class="fa fa-pencil"></span></td>
    <td (click)="delete(entry.id)"><span class="fa fa-trash"></span></td>
    <td (click)="show(entry)"><span class="fa fa-eye"></span></td>
  </tr>
</table>

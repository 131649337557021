/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-boerse.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../case-list-show/case-list-show.component.ngfactory";
import * as i3 from "../case-list-show/case-list-show.component";
import * as i4 from "../../services/case.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/rights-management.service";
import * as i7 from "../../services/user.service";
import * as i8 from "./case-boerse.component";
var styles_CaseBoerseComponent = [i0.styles];
var RenderType_CaseBoerseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseBoerseComponent, data: {} });
export { RenderType_CaseBoerseComponent as RenderType_CaseBoerseComponent };
export function View_CaseBoerseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "ilatitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fall-B\u00F6rse"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "case-show-list", [], null, null, null, i2.View_CaseListShowComponent_0, i2.RenderType_CaseListShowComponent)), i1.ɵdid(3, 245760, null, 0, i3.CaseListShowComponent, [i4.CaseService, i5.Store, i1.Renderer2, i6.RightsManagementService, i7.UserService], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = 6; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CaseBoerseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "case-boerse", [], null, null, null, View_CaseBoerseComponent_0, RenderType_CaseBoerseComponent)), i1.ɵdid(1, 49152, null, 0, i8.CaseBoerseComponent, [], null, null)], null, null); }
var CaseBoerseComponentNgFactory = i1.ɵccf("case-boerse", i8.CaseBoerseComponent, View_CaseBoerseComponent_Host_0, {}, {}, []);
export { CaseBoerseComponentNgFactory as CaseBoerseComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammbaum-tree-svg.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../stammbaum-tree-marriage-connection/stammbaum-tree-marriage-connection.component.ngfactory";
import * as i3 from "../stammbaum-tree-marriage-connection/stammbaum-tree-marriage-connection.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/stammbaum-display.service";
import * as i7 from "../stammbaum-tree-children-connection/stammbaum-tree-children-connection.component.ngfactory";
import * as i8 from "../stammbaum-tree-children-connection/stammbaum-tree-children-connection.component";
import * as i9 from "../stammbaum-tree-person/stammbaum-tree-person.component.ngfactory";
import * as i10 from "../stammbaum-tree-person/stammbaum-tree-person.component";
import * as i11 from "../../tools/show-date-pipe/show-date-pipe";
import * as i12 from "../../services/stammbaum.service";
import * as i13 from "../../services/case.service";
import * as i14 from "@angular/common";
import * as i15 from "./stammbaum-tree-svg.component";
var styles_StammbaumTreeSvgComponent = [i0.styles];
var RenderType_StammbaumTreeSvgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammbaumTreeSvgComponent, data: {} });
export { RenderType_StammbaumTreeSvgComponent as RenderType_StammbaumTreeSvgComponent };
function View_StammbaumTreeSvgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-marriage-connection", ""]], null, null, null, i2.View_StammbaumTreeMarriageConnectionComponent_0, i2.RenderType_StammbaumTreeMarriageConnectionComponent)), i1.ɵdid(2, 180224, null, 0, i3.StammbaumTreeMarriageConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], { treeViewMode: [0, "treeViewMode"], m: [1, "m"], version: [2, "version"], p1: [3, "p1"], p2: [4, "p2"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._stammbaumState.treeViewMode; var currVal_1 = _v.context.$implicit.m; var currVal_2 = _co.version; var currVal_3 = _v.context.$implicit.p1; var currVal_4 = _v.context.$implicit.p2; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_StammbaumTreeSvgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-children-connection", ""]], null, null, null, i7.View_StammbaumTreeChildrenConnectionComponent_0, i7.RenderType_StammbaumTreeChildrenConnectionComponent)), i1.ɵdid(2, 180224, null, 0, i8.StammbaumTreeChildrenConnectionComponent, [i4.Store, i5.NgbModal, i6.StammbaumDisplayService], { treeViewMode: [0, "treeViewMode"], version: [1, "version"], p: [2, "p"], c: [3, "c"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._stammbaumState.treeViewMode; var currVal_1 = _co.version; var currVal_2 = _v.context.$implicit.p; var currVal_3 = _v.context.$implicit.c; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_StammbaumTreeSvgComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:g", [["stammbaum-tree-person", ""]], null, null, null, i9.View_StammbaumTreePersonComponent_0, i9.RenderType_StammbaumTreePersonComponent)), i1.ɵdid(2, 4964352, null, 0, i10.StammbaumTreePersonComponent, [i4.Store, i11.ShowDatePipe, i12.StammbaumService, i6.StammbaumDisplayService, i5.NgbModal, i13.CaseService], { person: [0, "person"], version: [1, "version"], treeViewMode: [2, "treeViewMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.version; var currVal_2 = _co._stammbaumState.treeViewMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_StammbaumTreeSvgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["print", 1]], null, 6, ":svg:svg", [[":xmlns:svg", "http://www.w3.org/1999/XSL/Transform"], ["class", "stb-main"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0], [1, "viewBox", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSvgComponent_2)), i1.ɵdid(2, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSvgComponent_3)), i1.ɵdid(4, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSvgComponent_4)), i1.ɵdid(6, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.connections_m; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.connections_c; _ck(_v, 4, 0, currVal_4); var currVal_5 = ((_co._stammbaumState == null) ? null : _co._stammbaumState.stammbaum.persons); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; var currVal_2 = _co.getViewBox(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_StammbaumTreeSvgComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { printRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammbaumTreeSvgComponent_1)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.width > 0) && (_co.height > 0)) && _co.original_height) && _co.original_width); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StammbaumTreeSvgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stammbaum-tree-svg", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).updateScrollPosition($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StammbaumTreeSvgComponent_0, RenderType_StammbaumTreeSvgComponent)), i1.ɵdid(1, 12828672, null, 0, i15.StammbaumTreeSvgComponent, [i6.StammbaumDisplayService, i5.NgbModal, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammbaumTreeSvgComponentNgFactory = i1.ɵccf("stammbaum-tree-svg", i15.StammbaumTreeSvgComponent, View_StammbaumTreeSvgComponent_Host_0, { stammbaumState: "stammbaumState" }, {}, []);
export { StammbaumTreeSvgComponentNgFactory as StammbaumTreeSvgComponentNgFactory };
